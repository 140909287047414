import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { isVehicleReportLoading } from "_helpers/reduxSelector";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  divisionClass: ASC_CLASS,
  categoryClass: ASC_CLASS,
  idClass: ASC_CLASS,
  descriptionClass: ASC_CLASS,
  yearClass: ASC_CLASS,
  makeClass: ASC_CLASS,
  modelClass: ASC_CLASS,
  licenseClass: ASC_CLASS,
  serialClass: ASC_CLASS,
  insuranceClass: ASC_CLASS,
  ownerClass: ASC_CLASS,
  resourceClass:ASC_CLASS
};

const VehicleReportList = ({ getVehicleReport, data, lastPage, currentFilters, setSortParams }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state) => isVehicleReportLoading(state));
  const [
    {
      divisionClass,
      categoryClass,
      idClass,
      descriptionClass,
      yearClass,
      makeClass,
      // modelClass,
      licenseClass,
      serialClass,
      insuranceClass,
      ownerClass,
      resourceClass
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getVehicleReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getVehicleReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  React.useEffect(() => {
    setPage(0);
  }, [currentFilters]);

  const noDataAvailable = !isLoading && !data?.length;
  return (
    <>
    <div className="history_lists outer_list table__scroll">
      <table className="mobile_hide report_list_width_full long__report tab_hide">
        <thead>
          <tr className="report_list_table_row">
            <th onClick={() => sortList("divisionClass", divisionClass, "division")}>
              <div className="report_table_header">
                <span>Division</span>
                <span className={divisionClass} />
              </div>
            </th>
            <th onClick={() => sortList("categoryClass", categoryClass, "type")}>
              <div className="report_table_header">
                <span>Category</span>
                <span className={categoryClass} />
              </div>
            </th>
            <th onClick={() => sortList("idClass", idClass, "unique_id")}>
              <div className="report_table_header">
                <span>Unique ID</span>
                <span className={idClass} />
              </div>
            </th>
            <th onClick={() => sortList("resourceClass", resourceClass, "resource_name")}>
              <div className="report_table_header">
                <span>Resource</span>
                <span className={idClass} />
              </div>
            </th>
            <th onClick={() => sortList("descriptionClass", descriptionClass, "description")}>
              <div className="report_table_header">
                <span>Description</span>
                <span className={descriptionClass} />
              </div>
            </th>
            <th onClick={() => sortList("yearClass", yearClass, "year")}>
              <div className="report_table_header">
                <span>Year</span>
                <span className={yearClass} />
              </div>
            </th>
            <th onClick={() => sortList("makeClass", makeClass, "make")}>
              <div className="report_table_header">
                <span>Make/ Model</span>
                <span className={makeClass} />
              </div>
            </th>
            {/* <th onClick={() => sortList("modelClass", modelClass, "model")}>
              <div className="report_table_header">
                <span>Model</span>
                <span className={modelClass} />
              </div>
            </th> */}
            <th onClick={() => sortList("licenseClass", licenseClass, "license")}>
              <div className="report_table_header">
                <span>License</span>
                <span className={licenseClass} />
              </div>
            </th>
            <th onClick={() => sortList("serialClass", serialClass, "serial_vin_number")}>
              <div className="report_table_header">
                <span>Serial/ VIN</span>
                <span className={serialClass} />
              </div>
            </th>
            <th onClick={() => sortList("insuranceClass", insuranceClass, "insurance")}>
              <div className="report_table_header">
                <span>Insurance</span>
                <span className={insuranceClass} />
              </div>
            </th>
            <th onClick={() => sortList("ownerClass", ownerClass, "equipment_owner")}>
              <div className="report_table_header">
                <span>Owner</span>
                <span className={ownerClass} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td colspan="10">
                <div className="dashboard_expiration_date_no_data">No Data Found</div>
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item?.division_name ?? "-"}</td>
                <td>{item?.type ?? "-"}</td>
                <td>{item?.unique_id ?? "-"}</td>
                <td>{item?.resource_name ?? "-"}</td>

                <td>{item?.description ?? "-"}</td>
                <td>{item?.year ?? "-"}</td>
                <td>{item?.vehicle_model_or_make ?? "-"}</td>
                <td>{item?.vehicle_license ?? "-"}</td>
                <td>{item?.serial_vin_number ?? "-"}</td>
                <td>{item?.insurance ?? "-"}</td>
                <td>{item?.equipment_owner ?? "-"}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">No Data Found</div>
      ) : (
        <VehicleReportListMobileView data={data} getVehicleReport={getVehicleReport} setSortParams={setSortParams} />
      )}

      
    </div>
    {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default VehicleReportList;

const VehicleReportListMobileView = ({ data, getVehicleReport ,setSortParams }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("unique_id");
  const [
    {
      divisionClass,
      categoryClass,
      idClass,
      descriptionClass,
      yearClass,
      makeClass,
      // modelClass,
      licenseClass,
      serialClass,
      insuranceClass,
      ownerClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getVehicleReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("divisionClass", divisionClass, "division")}>
              <strong>Division</strong>
              <span className={divisionClass} />
            </div>
            <div className="list_item">{item?.division_name ?? "-"}</div>
          </div>
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("categoryClass", categoryClass, "type")}>
              <strong>Category</strong>
              <span className={categoryClass} />
            </div>
            <div className="list_item">{item?.type ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("idClass", idClass, "unique_id")}>
              <strong>Unique ID</strong>
              <span className={idClass} />
            </div>
            <div className="list_item">{item?.unique_id ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("descriptionClass", descriptionClass, "description")}>
              <strong>Description</strong>
              <span className={descriptionClass} />
            </div>
            <div className="list_item">{item?.description ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("yearClass", yearClass, "year")}>
              <strong>Year</strong>
              <span className={yearClass} />
            </div>
            <div className="list_item">{item?.year ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("makeClass", makeClass, "make")}>
              <strong>Make/ Model</strong>
              <span className={makeClass} />
            </div>
            <div className="list_item">{item?.vehicle_model_or_make ?? "-"}</div>
          </div>

          {/* <div className="status_item">
            <div className="list_item" onClick={() => sortList("modelClass", modelClass, "model")}>
              <strong>Model</strong>
              <span className={modelClass} />
            </div>
            <div className="list_item">{item?.engine_model ?? "-"}</div>
          </div> */}

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("licenseClass", licenseClass, "license")}>
              <strong>License</strong>
              <span className={licenseClass} />
            </div>
            <div className="list_item">{item?.vehicle_license ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("serialClass", serialClass, "serial_vin_number")}>
              <strong>Serial/ VIN</strong>
              <span className={serialClass} />
            </div>
            <div className="list_item">{item?.serial_vin_number ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("insuranceClass", insuranceClass, "insurance")}>
              <strong>Insurance</strong>
              <span className={insuranceClass} />
            </div>
            <div className="list_item">{item?.insurance ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("ownerClass", ownerClass, "equipment_owner")}>
              <strong>Owner</strong>
              <span className={ownerClass} />
            </div>
            <div className="list_item">{item?.equipment_owner ?? "-"}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
