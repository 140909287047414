import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import DatePicker from 'react-datepicker';
import moment from 'moment'; 
import '../assets/styles/react-datepicker.css';
import '../assets/styles/application.css';
import { AlertPopup } from '../_components/AlertPopup';
import NumberFormat from 'react-number-format';

class Application extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            AnticipatedLocation: [],
            ApplyingPosition: [],
            CertificationStatus: [],
            GeneralQualification: [],
            PositionOn: [],
            Qualification: [],
            RaceList: [],
            SourceOfKnowledgeList: [],
            ReportingLocation: [],
            allData: [],
            redirect: "",

            sourceOfKnowledge: "",
            fname: "",
            lname: "",
            sname: "",
            employee_name: "",
            mailingAddress1: "",
            mailingAddress2: "",
            city: "",
            state: "",
            zip: "",
            email: "",
            main_phone: "",
            secondary_phone: "",
            anticipatedLocationList: "",
            
            reporting_location: "",
            chkPositionOn: [],
            chkCurrentQualification: [],
            applying_position: [],
            certification_status: "",
            qualifications: "",
            agreement: "",
            fileTaskbook1:"",
            fileTaskbook2:"",
            fileTaskbook3:"",
            fileTaskbook4:"",
            fileTaskbook1file:"",
            fileTaskbook2file:"",
            fileTaskbook3file:"",
            fileTaskbook4file:"",
            txtDate: moment(),
            high_school: "",
            school_duration: "",
            school_taken: "",
            is_school_graduate: "",
            university: "",
            degree_duration: "",
            degree_taken: "",
            is_degree_graduate: "",
            university1: "",
            degree_duration1: "",
            degree_taken1: "",
            is_degree_graduate1: "",
            addition_info: "",
            firestorm_experience: "",
            seperation_reason: "",
            last_employer1: "",
            last_employer2: "",
            last_employer3: "",
            // reference1StartDate: moment(),
            reference1Position: "",
            // reference1EndDate: moment(),
            reference1Address: "",
            reference1City: "",
            reference1State: "",
            reference1Supervisor: "",
            reference1AltContact: "",
            reference1Telephone: "",
            reference1Fax: "",
            reference1Responsibilities: "",
            reference1ResonForLeaving: "",
            // reference2StartDate: moment(),
            reference2Position: "",
            // reference2EndDate: moment(),
            reference2Address: "",
            reference2City: "",
            reference2State: "",
            reference2Supervisor: "",
            reference2AltContact: "",
            reference2Telephone: "",
            reference2Fax: "",
            reference2Responsibilities: "",
            reference2ResonForLeaving: "",
            // reference3StartDate: moment(),
            reference3Position: "",
            // reference3EndDate: moment(),
            reference3Address: "",
            reference3City: "",
            reference3State: "",
            reference3Supervisor: "",
            reference3AltContact: "",
            reference3Telephone: "",
            reference3Fax: "",
            reference3Responsibilities: "",
            reference3ResonForLeaving: "",
            reference1name: "",
            reference1Relationship: "",
            reference1Phone: "",
            reference1Known: "",
            reference2name: "",
            reference2Relationship: "",
            reference2Phone: "",
            reference2Known: "",
            reference3name: "",
            reference3Relationship: "",
            reference3Phone: "",
            reference3Known: "",
            licenseState: "",
            licenseNumber: "",
            // licenseExpDate: moment(),
            licenseClass: "",
            licenseEndorsement: "",
            DeniedLicense: "",
            LicenseRevoked: "",
            ReasonLicenseRevoked: "",
            Question1: "",
            Question2: "",
            Question3: "",
            gender: "",
            Race: "",
            Veteran: "",
            FullName: "",
            sourceOfKnowledgeError: "",
            employee_nameError: "",
            fnameError: "",
            lnameError: "",
            snameError: "",
            miError: "",
            mailingAddress1Error: "",
            // address2Error: "",
            cityError: "",
            stateError: "",
            zipError: "",
            emailError: "",
            main_phoneError: "",
            // secondPhoneError: "",
            reloacationDateError: "",
            certification_statusError: "",
            currentQualifyError: "",
            firestorm_experienceError: "",
            Question1Error: "",
            Question2Error: "",
            Question3Error: "",
            agreementError: "",
            FullNameError: "",
            anticipatedLocationListError: "",
            todayError: "",
            seperation_reasonError: "",
            empClass: "hide",
            file_list: [],
            // reloacationDate: moment(),
            // reloacationdate: moment(),
            date: moment(),            
            alertPop: false,
            permissions: [],
            saveClick: false
        }   
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearFile = this.clearFile.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
        this.goBack = this.goBack.bind(this);
    }
    componentDidMount() {

        fetch(config.apiUrl + '/api/getlistall')
        .then((res) => {               
            return res.json() })
        .then((data) =>{
            this.setState({
                allData : data,
                AnticipatedLocation: data.AnticipatedLocation,
                ApplyingPosition: data.ApplyingPosition,
                CertificationStatus: data.CertificationStatus,
                GeneralQualification: data.GeneralQualification,
                PositionOn: data.PositionOn,
                Qualification: data.Qualification,
                RaceList: data.Race,
                ReportingLocation: data.ReportingLocation,
                SourceOfKnowledgeList: data.SourceOfKnowledge
            })
        }).catch((err)=>console.log(err))

        var self = this
        fetch(config.apiUrl + '/api/permission/getuserpermissions',
        {
          headers: authHeader(),
          method: "GET",
        })
        .then(function(res){ return res.json(); })
        .then(function(data){  

        self.setState({ permissions: data.data })
        })
    }

    handleDateChange( name, date ) {
        this.setState({
          [name]: date,
        });
        if(name === "txtDate"){
            this.setState({
                txtDateError: ""
            })
        }
        // e.target.setAttribute('errors',"");
    }
    clearFile(file){
        this.setState({
            [file]: ""
        })
    }

    closeAlert(){
        this.setState({
            alertPop: false
        })
    }
    
    handleCheckbox(e){
        var value = e.target.value, a, name = e.target.name;
        if(name === "chkCurrentQualification"){
            a = this.state.chkCurrentQualification.indexOf(value);
            if(a !== -1){
                this.state.chkCurrentQualification.splice(a, 1);
            } 
            else{
                this.state.chkCurrentQualification.push(value);
            }
        }
        if(name === "applying_position"){
            a = this.state.applying_position.indexOf(value);
            if(a !== -1){
                this.state.applying_position.splice(a, 1);
            } 
            else{
                this.state.applying_position.push(value);
            }
        }
        if(name === "chkPositionOn"){
            a = this.state.chkPositionOn.indexOf(value);
            if(a !== -1){
                this.state.chkPositionOn.splice(a, 1);
            } 
            else{
                this.state.chkPositionOn.push(value);
            }
        }
    }

    handleFile(e){
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        if(!file) return ;
        var name = e.target.name, value = e.target.value, file_format, file_name= "", finalName="";
        file_format = value.split('.').pop().toLowerCase();
        var targetValue = value.toString();
        var fileName = targetValue.replace("C:\\fakepath\\", "");  
        if(file_format === "doc" || file_format === "docx" || file_format === "pdf" || file_format === "jpg" || file_format === "jpeg"){
            // this.setState({ 
            //     [name]: value,
            //     [name+"file"]: file
            // }); 
            file_name = value;
            finalName = file;
        }
        else{
            // alert("Invalid file types. Please select any valid document types. [eg: .doc, .docx, .pdf]");
            this.setState({ 
                alertPop: true, 
                alertHead: "Error", 
                alertMsg: "Invalid file types. Please select any valid document types. [eg: .doc, .docx, .pdf, .jpg, .jpeg]",
                alertFunction: ""
            })
        }
        if(file.size / 1024 / 1024 > 2){
            this.setState({ 
                alertPop: true, 
                alertHead: "Error", 
                alertMsg: "File size exceeds.",
                alertFunction: ""
            })
            file_name = "";
            finalName = "";
        } else{
            file_name = value;
            finalName = file;
        }
        this.setState({ 
            [name]: file_name,
            [name+"file"]: finalName
        }); 
        e.target.setAttribute('errors',"");
    }

    handleChange(e) { 
        var name = e.target.name, value = e.target.value;

        if(name === "sourceOfKnowledge" && value === '1'){
            this.setState({
                empClass: "show"
            })
        }
        else if(name === "sourceOfKnowledge" && value !== '1'){
            this.setState({
                empClass: "hide"
            })
        }
        var errorVar = name+"Error"
        this.setState({ 
            [name]: value,
            [errorVar]: ""
        });
        if(value !== ""){
            e.target.setAttribute('errors',"");            
        }
    }
    validate(e) {
        let isError = false;

        if(this.state.txtDate === "" || this.state.txtDate === undefined){
            isError = true;
            this.setState({
                txtDateError: "error"
            })
            // this.txtDateInput.focus();
            // document.getElementById('txtDate').focus();
            // var elem = document.getElementById('txtDate');
            // window.scrollTo(elem.scrollHeight, 0);
            const elementTop = this.FullNameInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.FullName.length < 1){
            isError = true;
            this.setState({
                FullNameError: "error"
            })
            const elementTop = this.FullNameInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.agreement === "No" || this.state.agreement === ""){
            isError = true;
            this.setState({
                agreementError: "error"
            })
            const elementTop = this.AgreementInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.Question3.length < 1){
            isError = true;
            this.setState({
                Question3Error: "error"
            })
            const elementTop = this.Question3Input.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.Question2.length < 1){
            isError = true;
            this.setState({
                Question2Error: "error"
            })
            const elementTop = this.Question2Input.offsetTop - 110;
            window.scrollTo(0, elementTop);

        }
        if(this.state.Question1.length < 1){
            isError = true;
            this.setState({
                Question1Error: "error"
            })
            const elementTop = this.Question1Input.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.firestorm_experience === ""){
            isError = true;
            this.setState({
                firestorm_experienceError: "error"
            })
            const elementTop = this.fExperienceInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.firestorm_experience === "Yes"){
            if(this.state.seperation_reason === ""){
                isError = true;
                this.setState({
                    seperation_reasonError: "error"
                })
                const elementTop = this.separationInput.offsetTop - 110;
                window.scrollTo(0, elementTop);
            }
        }
        if(this.state.certification_status.length < 1){
            isError = true;
            this.setState({
                certification_statusError: "error"
            })
            const elementTop = this.certStatusInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.qualifications === ""){
            isError = true;
            this.setState({
                qualificationsError: "error"
            })
            const elementTop = this.qualificationsInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if( !this.state.reloacationDate || this.state.reloacationDate === ""){ 
            isError = true;
            this.setState({
                reloacationDateError: "error"
            })
            const elementTop = this.relocDateInput.offsetTop - 110;
            window.scrollTo(0, elementTop) ;
        }

        if(this.state.anticipatedLocationList === ""){
            isError = true;
            this.setState({
                anticipatedLocationListError: "error"
            })
            const elementTop = this.AntiLocInput.offsetTop - 110;
            window.scrollTo(0, elementTop);  
        }
        if(this.state.main_phone.length < 1){
            isError = true;
            this.setState({
                main_phoneError: "error"
            })
            const elementTop = this.phoneInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        // if(this.state.main_phone.length > 1 || this.validatePhone(this.state.main_phone)){
        //     isError = false;
        //     this.setState({
        //       phoneError: ""
        //     })
        // }
        if(this.state.email.length < 1){
            isError = true;
            this.setState({
                emailError: "error"
            })
            const elementTop = this.emailInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        // if(this.state.email.length > 1 || this.IsValidEmail(this.state.email)){
        //     isError = false;
        //     this.setState({
        //       emailError: ""
        //     })
        // }

        if(this.state.zip === "" ){
            isError = true;
            this.setState({
                zipError: "error"
            })
            const elementTop = this.zipInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.state === "" ){
            isError = true;
            this.setState({
                stateError: "error"
            })
            const elementTop = this.stateInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.city === "" ){
            isError = true;
            this.setState({
                cityError: "error"
            })
            const elementTop = this.cityInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.mailingAddress1 === ""){
            isError = true;
            this.setState({
                mailingAddress1Error: "error"
            })
            const elementTop = this.address1Input.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.sname === ""){
            isError = true;
            this.setState({
                snameError: "error"
            })
            const elementTop = this.snameInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        if(this.state.lname === ""){
            isError = true;
            this.setState({
                lnameError: "error"
            })
            const elementTop = this.lnameInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }

        if(this.state.fname === ""){
            isError = true;
            this.setState({
                fnameError: "error"
            })
            const elementTop = this.fnameInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }
        
        if(this.state.sourceOfKnowledge === ""){
            isError = true;
            this.setState({
                sourceOfKnowledgeError: "error"
            })
            const elementTop = this.sourceInput.offsetTop - 110;
            window.scrollTo(0, elementTop);
        }

        if(this.state.sourceOfKnowledge !== "" && this.state.sourceOfKnowledge === "1"){
            if(this.state.employee_name === ""){
                isError = true;
                this.setState({
                    employee_nameError: "error"
                })
                const elementTop = this.empNameInput.offsetTop - 110;
                window.scrollTo(0, elementTop);
            }
        }

        return isError;
    }
    IsValidEmail(email) {
        var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return expr.test(email);
    };
    validatePhone(phone){ 
        // http://stackoverflow.com/a/1964403/5624899

        if(phone.length > 10) return false;
      
        // var re = /[2-9]{2}\d{8}/; //the first two digits do not start with 0 or 1
        var re = /\d{10}/;
        return re.test(phone);
    }
    onSubmit(e){        
        e.preventDefault();
        // console.log(console.log(this.state.SourceOfKnowledgeList))
        this.setState({ saveClick: true })
        const err = this.validate();
        var targetValue, fileName;
        this.setState({
            file_list: []
        })
        if(this.state.fileTaskbook1file !== ""){
            this.state.file_list.push(this.state.fileTaskbook1file);
        }
        if(this.state.fileTaskbook2file !== ""){ 
            this.state.file_list.push(this.state.fileTaskbook2file);
        }
        if(this.state.fileTaskbook3file !== ""){
            this.state.file_list.push(this.state.fileTaskbook3file);
        }
        if(this.state.fileTaskbook4file !== ""){
            this.state.file_list.push(this.state.fileTaskbook4file);
        }        
        if(this.state.reloacationDate === undefined){
            this.setState({ reloacationDate : "" })
        }
        if(this.state.txtDate === undefined){
            this.setState({ txtDate : "" })
        }
        if(this.state.reference1StartDate === undefined){
            this.setState({ reference1StartDate : "" })
        }
        if(this.state.reference1EndDate === undefined){
            this.setState({ reference1EndDate : "" })
        }
        if(this.state.reference2StartDate === undefined){
            this.setState({ reference2StartDate : "" })
        }
        if(this.state.reference2EndDate === undefined){
            this.setState({ reference2EndDate : "" })
        }
        if(this.state.reference3StartDate === undefined){
            this.setState({ reference3StartDate : "" })
        }
        if(this.state.reference3EndDate === undefined){
            this.setState({ reference3EndDate : "" })
        }
        if(this.state.licenseExpDate === undefined){
            this.setState({ licenseExpDate : "" })
        }

        if(!err){
            let data = new FormData();
            data.append('sourceOfKnowledge', this.state.sourceOfKnowledge)
            data.append('position_applying_for', this.state.position_applying_for || '')
            data.append('fname', this.state.fname)
            data.append('lname', this.state.lname)
            data.append('sname', this.state.sname)
            data.append('employee_name', this.state.employee_name) 
            data.append('mailingAddress1', this.state.mailingAddress1)
            data.append('mailingAddress2', this.state.mailingAddress2)
            data.append('city', this.state.city)
            data.append('state', this.state.state)
            data.append('zip', this.state.zip)
            data.append('email', this.state.email)
            data.append('main_phone', this.state.main_phone)
            data.append('secondary_phone', this.state.secondary_phone)
            data.append('anticipatedLocationList', this.state.anticipatedLocationList)
            data.append('reloacationDate', this.state.reloacationDate) 
            // data.append('chkPositionOn', this.state.chkPositionOn)
            data.append('applying_position', this.state.applying_position.toString())
            data.append('reporting_location', this.state.reporting_location)             
            // data.append('chkCurrentQualification', this.state.chkCurrentQualification)
            data.append('chkCurrentQualification', this.state.chkCurrentQualification.toString())
            data.append('certification_status', this.state.certification_status)
            data.append('qualifications', this.state.qualifications) 
            data.append('Question1', this.state.Question1)
            data.append('Question2', this.state.Question2)
            data.append('Question3', this.state.Question3)
            data.append('FullName', this.state.FullName) 
            data.append('txtDate', this.state.txtDate)
            data.append('high_school', this.state.high_school)
            data.append('school_duration', this.state.school_duration)
            data.append('school_taken', this.state.school_taken)
            data.append('is_school_graduate', this.state.is_school_graduate)
            data.append('university', this.state.university)
            data.append('degree_duration', this.state.degree_duration)
            data.append('degree_taken', this.state.degree_taken)
            data.append('is_degree_graduate', this.state.is_degree_graduate)
            data.append('university1', this.state.university1)
            data.append('degree_duration1', this.state.degree_duration1)
            data.append('degree_taken1', this.state.degree_taken1)
            data.append('is_degree_graduate1', this.state.is_degree_graduate1)
            data.append('addition_info', this.state.addition_info)
            data.append('firestorm_experience', this.state.firestorm_experience)
            data.append('seperation_reason', this.state.seperation_reason)
            data.append('last_employer1', this.state.last_employer1)
            data.append('last_employer2', this.state.last_employer2)
            data.append('last_employer3', this.state.last_employer3)
            data.append('reference1StartDate', this.state.reference1StartDate)
            data.append('reference1Position', this.state.reference1Position)
            data.append('reference1EndDate', this.state.reference1EndDate)            
            data.append('reference1Address', this.state.reference1Address)
            data.append('reference1City', this.state.reference1City)
            data.append('reference1State', this.state.reference1State)
            data.append('reference1Supervisor', this.state.reference1Supervisor)
            data.append('reference1AltContact', this.state.reference1AltContact)
            data.append('reference1Telephone', this.state.reference1Telephone)
            data.append('reference1Fax', this.state.reference1Fax)            
            data.append('reference1Responsibilities', this.state.reference1Responsibilities)
            data.append('reference1ResonForLeaving', this.state.reference1ResonForLeaving)
            data.append('reference2StartDate', this.state.reference2StartDate)
            data.append('reference2Position', this.state.reference2Position)
            data.append('reference2EndDate', this.state.reference2EndDate)
            data.append('reference2Address', this.state.reference2Address)
            data.append('reference2City', this.state.reference2City)
            data.append('reference2State', this.state.reference2State)
            data.append('reference2Supervisor', this.state.reference2Supervisor)
            data.append('reference2AltContact', this.state.reference2AltContact)
            data.append('reference2Telephone', this.state.reference2Telephone)
            data.append('reference2Fax', this.state.reference2Fax)
            data.append('reference2Responsibilities', this.state.reference2Responsibilities)
            data.append('reference2ResonForLeaving', this.state.reference2ResonForLeaving)
            data.append('reference3StartDate', this.state.reference3StartDate)
            data.append('reference3Position', this.state.reference3Position)
            data.append('reference3EndDate', this.state.reference3EndDate)
            data.append('reference3Address', this.state.reference3Address)
            data.append('reference3City', this.state.reference3City)
            data.append('reference3State', this.state.reference3State)
            data.append('reference3Supervisor', this.state.reference3Supervisor)
            data.append('reference3AltContact', this.state.reference3AltContact)            
            data.append('reference3Telephone', this.state.reference3Telephone)
            data.append('reference3Fax', this.state.reference3Fax)
            data.append('reference3Responsibilities', this.state.reference3Responsibilities)
            data.append('reference3ResonForLeaving', this.state.reference3ResonForLeaving)
            data.append('reference1name', this.state.reference1name)
            data.append('reference1Relationship', this.state.reference1Relationship)
            data.append('reference1Phone', this.state.reference1Phone)
            data.append('reference1Known', this.state.reference1Known)
            data.append('reference2name', this.state.reference2name)
            data.append('reference2Relationship', this.state.reference2Relationship)
            data.append('reference2Phone', this.state.reference2Phone)
            data.append('reference2Known', this.state.reference2Known)
            data.append('reference3name', this.state.reference3name)
            data.append('reference3Relationship', this.state.reference3Relationship)
            data.append('reference3Phone', this.state.reference3Phone)
            data.append('reference3Known', this.state.reference3Known)
            data.append('licenseState', this.state.licenseState)
            data.append('licenseNumber', this.state.licenseNumber)
            data.append('licenseExpDate', this.state.licenseExpDate)
            data.append('licenseClass', this.state.licenseClass)
            data.append('licenseEndorsement', this.state.licenseEndorsement)
            data.append('DeniedLicense', this.state.DeniedLicense)
            data.append('LicenseRevoked', this.state.LicenseRevoked)
            data.append('ReasonLicenseRevoked', this.state.ReasonLicenseRevoked)
            data.append('gender', this.state.gender)
            data.append('Race', this.state.Race)
            data.append('Veteran', this.state.Veteran)
            data.append('agreement', this.state.agreement)
            data.append('chkPositionOn', this.state.chkPositionOn.toString())            

            this.state.file_list.map((file, index) => {
                return(
                    data.append('qualification_file[]', file)
                )                
            })

            // for (var pair of data.entries()) {
            //     console.log(pair[0]+ ', ' + pair[1]); 
            // }
            var self = this
            fetch(config.apiUrl + '/api/applicationsubmit',
            {
                method: "POST",
                body: data
            })
            .then(function(res){ return res.json(); })
            .then(function(data){ 
                if( data.success ){
                    self.setState({ 
                        alertPop: true, 
                        alertHead: "Success", 
                        alertMsg: data.message,
                        alertFunction: self.goBack.bind(this),
                        saveClick: false
                    })
                }else{ 
                    var message= data.message  , str = "" ;
                    if(typeof message !== "string" ){
                        if( Object.keys(message).length ){
                            for( var key in message ){
                                var arr = message[key] ;
                                if(Array.isArray(arr)){
                                    str+=arr.join(" ")+" " ;
                                }else{
                                    str+= arr+" ";
                                }
                            }
                        }
                    }else{
                        str = message ;
                    }
                     self.setState({ 
                        alertPop: true, 
                        alertHead: "Error", 
                        alertMsg: str ,
                        saveClick: false
                    })
                }
            })
        } else{
            var testDiv = document.querySelector('[errors="error"]');
            this.setState({
                saveClick : false
            });
            // document.getElementById("demo").innerHTML = testDiv.offsetTop;
            // console.log(testDiv)
        }
    }

    goBack() {
        this.props.history.push('/applicants')
    }

    viewCloseModal() {
        this.setState({ viewOpen: false, redirect: "/applicants" });
    }
    // redirectFunc(data){
    //     // if(data){
    //         this.setState({ redirect: "/applicants"})
    //     // }
    // }

    render() {
        if(this.state.redirect === "/applicants"){
            return <Redirect to='/applicants' />
        }
        const { application } = this.props;
        const { permissions } = this.state;
        return (
            permissions.length>0 && ( 
                !permissions.includes("Manage Applicants") ?
                (
                    <div className="content_div">
                        <div className="no_permission">No permission to this page</div>
                    </div>
                ) : (
                    <div className="application_container new_application"> 
                        <div className="breadcrumbs"><Link to={"/applicants"} className="green">Applicants</Link> / Employment Application</div>               
                        <h1 className="page_title">Employment Application</h1>
                        <div className="inner_container">
                            <form name="newApplication">
                                {/*<Link to={"/applicants"} className="goback">Back</Link>*/}
                                <div className="formField">
                                    <p>Please fill out all of the necessary fields in this form. Incomplete applications will not be accepted. For job application inquiries, Please ONLY contact us Tuesdays and Thursdays between 10:00am & 2:00pm.</p>
                                </div>

                                <div className="formField">
                                    <label>How did you hear about applying to Firestorm?*</label>
                                    <select name="sourceOfKnowledge" ref={(input) => { this.sourceInput = input; }} id="sourceOfKnowledge" defaultValue={this.state.sourceOfKnowledge} errors={this.state.sourceOfKnowledgeError} onChange={this.handleChange} >
                                        <option value="">---</option>
                                        {this.state.SourceOfKnowledgeList.map((item, key) =>
                                            <option key={key} value={item.id}>{item.value}</option>
                                        )}
                                    </select>

                                    <input id="employee_name" type="text" ref={(input) => { this.empNameInput = input; }} name="employee_name" id="employee_name" defaultValue={this.state.employee_name} size="40" className={"employee_name " + this.state.empClass} errors={this.state.employee_nameError} placeholder="Name of Firestorm Employee*" onChange={this.handleChange} />
                                </div>

                                <div className="formField">
                                    <label>What position are you applying for?</label>
                                    <input
                                        type="text"
                                        ref={input => {
                                        this.positionApplyingForInput = input;
                                        }}
                                        name="position_applying_for"
                                        id="position_applying_for"
                                        value={
                                        this.state.position_applying_for && this.state.position_applying_for !== "null"
                                            ? this.state.position_applying_for
                                            : ""
                                        }
                                        placeholder="Position"
                                        onChange={this.handleChange}
                                    />
                                </div>

                                <hr />
                                <div className="formField">
                                    <label>Full Legal Name*</label>
                                    <div className="legal_name app_input">
                                        <input type="text" ref={(input) => { this.fnameInput = input; }} name="fname" id="fname" defaultValue={this.state.fname} placeholder="First Name*" onChange={this.handleChange} errors={this.state.fnameError} />
                                        <input type="text" ref={(input) => { this.lnameInput = input; }} name="lname" id="lname" defaultValue={this.state.lname} placeholder="Last Name*" onChange={this.handleChange} errors={this.state.lnameError} />
                                        <input type="text" ref={(input) => { this.snameInput = input; }} name="sname" id="sname" className="small_input" defaultValue={this.state.sname} placeholder="MI*" errors={this.state.snameError} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Current Mailing Address*</label>
                                    <div className="mailing_address app_input">
                                        <input type="text" ref={(input) => { this.address1Input = input; }} name="mailingAddress1" id="mailingAddress1" errors={this.state.mailingAddress1Error} defaultValue={this.state.mailingAddress1} placeholder="Address*" onChange={this.handleChange}  />
                                        <input type="text" name="mailingAddress2" defaultValue={this.state.mailingAddress2} placeholder="Address Line 2" onChange={this.handleChange} />
                                    </div>
                                    <div className="mailing_address2 app_input">
                                        <input type="text" ref={(input) => { this.cityInput = input; }} name="city" id="city" defaultValue={this.state.city} placeholder="City*" errors={this.state.cityError} onChange={this.handleChange}  />
                                        <input type="text" ref={(input) => { this.stateInput = input; }} name="state" id="state" defaultValue={this.state.state} placeholder="State*" errors={this.state.stateError} onChange={this.handleChange}  />
                                        <input type="text" ref={(input) => { this.zipInput = input; }} name="zip" id="zip" defaultValue={this.state.zip} className="small_input" errors={this.state.zipError} placeholder="Zip*" onChange={this.handleChange}  />
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Contact Information*</label>
                                    <div className="contact_info app_input">
                                        <input type="text" ref={(input) => { this.emailInput = input; }} name="email" id="email" defaultValue={this.state.email} placeholder="Email Address*" onChange={this.handleChange} errors={this.state.emailError} />
                                        {/*<input type="tel" ref={(input) => { this.phoneInput = input; }} name="main_phone" id="main_phone" defaultValue={this.state.main_phone} placeholder="Cell Phone (Main Contact)*" onChange={this.handleChange} errors={this.state.main_phoneError} maxLength="10" />
                                        <input type="tel" name="secondary_phone" defaultValue={this.state.secondary_phone} placeholder="Secondary Phone Number" onChange={this.handleChange} maxLength="10" />*/}

                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} ref={(input) => { this.phoneInput = input; }} name="main_phone" id="main_phone" defaultValue={this.state.main_phone} placeholder="Cell Phone (Main Contact)*" errors={this.state.main_phoneError} />

                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="secondary_phone" defaultValue={this.state.secondary_phone} placeholder="Secondary Phone"/>
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Select Anticipated Living Location for Fire Season*</label>                        
                                    <select name="anticipatedLocationList" ref={(input) => { this.AntiLocInput = input; }} id="anticipatedLocationList" defaultValue={this.state.anticipatedLocationList} onChange={this.handleChange} errors={this.state.anticipatedLocationListError}>
                                        <option value="">---</option>
                                        { this.state.AnticipatedLocation.map((item, key)=>
                                            <option key={key} value={item.id}>{item.value}</option>
                                        )}
                                    </select>
                                    <div className="">
                                    <div className="" errors={this.state.reloacationDateError}>
                                    <DatePicker
                                        className={"anticipated_date "+this.state.error}
                                        name="reloacationDate" id="reloacationDate"
                                        ref={(input) => { this.relocDateInput = input; }}
                                        placeholderText="Anticipated relocation date*"
                                        selected={this.state.reloacationDate}
                                        onChange={this.handleDateChange.bind(this,'reloacationDate')}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    </div>
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Position Applying For</label>
                                    <p className="sub_label">Applying Position (Check all that apply)</p>

                                    
                                    { this.state.ApplyingPosition.map((item, key)=>(
                                        <p className="checkbox_div" key={key} >
                                            <input type="checkbox" name="applying_position" defaultValue={item.id} onChange={this.handleCheckbox}  />
                                            <span>{item.value}</span>
                                        </p>
                                    ))}

                                    <p className="sub_label">Desired Reporting Location (select one)</p>                        
                                    <select name="reporting_location" id="reporting_location" defaultValue={this.state.reporting_location} onChange={this.handleChange} >
                                        <option value="">---</option>
                                        { this.state.ReportingLocation.map((item, key)=>
                                            <option key={key} value={item.id}>{item.value}</option>
                                        )}
                                    </select>

                                    <p className="sub_label">I would like to be considered for a position on (Check all that apply)</p>

                                    { this.state.PositionOn.map((item, key)=>(
                                        <p className="checkbox_div" key={key} >
                                            <input type="checkbox" name="chkPositionOn" defaultValue={item.id} onChange={this.handleCheckbox} />
                                            <span>{item.value}</span>
                                        </p>
                                    ))}

                                </div>

                                <div className="formField">
                                    <label>Current Fire Qualifications: as specified in PMS 310-1 NWCG guidelines</label>
                                    <p className="sub_label">Check all that apply</p>

                                    { this.state.Qualification.map((item, key)=>(
                                        <p className="checkbox_div" key={key} >
                                            <input type="checkbox" name="chkCurrentQualification" value={item.id} onChange={this.handleCheckbox}  />
                                            <span>{item.value}</span>
                                        </p>
                                    ))}

                                    <p className="sub_label">Current Certification Status*</p>                        
                                    <select name="certification_status" ref={(input) => { this.certStatusInput = input; }} id="certification_status" defaultValue={this.state.certification_status} onChange={this.handleChange} errors={this.state.certification_statusError}>
                                        <option value="">---</option>
                                        { this.state.CertificationStatus.map((item, key)=>
                                            <option key={key} value={item.id}>{item.value}</option>
                                        )}
                                    </select>

                                    <p className="sub_label">Qualifications*</p>
                                    <select name="qualifications" ref={(input) => { this.qualificationsInput = input; }} id="qualifications" defaultValue={this.state.qualifications} onChange={this.handleChange} errors={this.state.qualificationsError}>
                                        <option value="">---</option>
                                        { this.state.GeneralQualification.map((item, key)=>
                                            <option key={key} value={item.id}>{item.name}</option>
                                        )}
                                    </select>

                                    <p>Upload copies of your qualification (taskbooks) and any certificates that apply to desired position.</p>

                                    <div className="file_div_outrt">
                                        <div className="file_div">
                                            <span className="file-1">
                                                <input id="fileTaskbook1" type="file" size="40" name="fileTaskbook1"  className="file-download fileTaskbook" onChange={this.handleFile} />
                                            </span>
                                            <a href="javascript:;" onClick={this.clearFile.bind(this,'fileTaskbook1')} className="green">Clear Chosen File</a>
                                        </div>
                                        <div className="file_div">
                                            <span className="file-2">
                                                <input id="fileTaskbook2" type="file" size="40" 
                                                name="fileTaskbook2" className="file-download fileTaskbook" onChange={this.handleFile} />
                                            </span>
                                            <a href="javascript:;" onClick={this.clearFile.bind(this,'fileTaskbook2')} className="green">Clear Chosen File</a>
                                        </div>
                                        <div className="file_div">
                                            <span className="file-3">
                                                <input id="fileTaskbook3" type="file" size="40" name="fileTaskbook3" className="file-download fileTaskbook" onChange={this.handleFile} />
                                            </span>
                                            <a href="javascript:;" onClick={this.clearFile.bind(this,'fileTaskbook3')} className="green">Clear Chosen File</a>
                                        </div>
                                        <div className="file_div">
                                            <span className="file-4">
                                                <input id="fileTaskbook4" type="file" size="40" name="fileTaskbook4"  className="file-download fileTaskbook" onChange={this.handleFile} />
                                            </span>
                                            <a href="javascript:;" onClick={this.clearFile.bind(this,'fileTaskbook4')} className="green">Clear Chosen File</a>
                                        </div>
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Education</label>
                                    <div className="education_div">
                                        <p className="sub_label">High School:</p>                        
                                        <input type="text" name="high_school" placeholder="High School Name & Location" defaultValue={this.state.high_school} onChange={this.handleChange} />
                                        <input type="text" name="school_duration" placeholder="No. Years/Units Completed" defaultValue={this.state.school_duration} onChange={this.handleChange} />
                                        <input type="text" name="school_taken" placeholder="Degree/Courses Taken" defaultValue={this.state.school_taken} onChange={this.handleChange} />
                                        <input type="text" name="is_school_graduate" placeholder="Did you graduate? (yes/no)" defaultValue={this.state.is_school_graduate} onChange={this.handleChange} />
                                    </div>

                                    <div className="education_div">
                                        <p className="sub_label">University / Tech School:</p>
                                        <input type="text" name="university" defaultValue={this.state.university} placeholder="University/Tech Name & Location" onChange={this.handleChange} />
                                        <input type="text" name="degree_duration" defaultValue={this.state.degree_duration} placeholder="No. Years/Units Completed" onChange={this.handleChange} />
                                        <input type="text" name="degree_taken" defaultValue={this.state.degree_taken} placeholder="Degree/Courses Taken" onChange={this.handleChange} />
                                        <input type="text" name="is_degree_graduate" defaultValue={this.state.is_degree_graduate} placeholder="Did you graduate? (yes/no)" onChange={this.handleChange} />
                                    </div>

                                    <div className="education_div">
                                        <p className="sub_label">University / Tech School:</p>
                                        <input type="text" name="university1" defaultValue={this.state.university1} placeholder="University/Tech Name & Location" onChange={this.handleChange} />
                                        <input type="text" name="degree_duration1" defaultValue={this.state.degree_duration1} placeholder="No. Years/Units Completed" onChange={this.handleChange} />
                                        <input type="text" name="degree_taken1" defaultValue={this.state.degree_taken1} placeholder="Degree/Courses Taken" onChange={this.handleChange} />
                                        <input type="text" name="is_degree_graduate1" defaultValue={this.state.is_degree_graduate1} placeholder="Did you graduate? (yes/no)" onChange={this.handleChange} />
                                    </div>
                                </div>

                                <div className="formField">
                                    <label>Additional Information</label>
                                    <p>Present below any other information which relates to your ability to perform the job for which you are applying such as licenses, memberships, language skills, academic and athletic achievements, community involvement, and volunteer experiences. Leave out information indicating religion, gender or any other protected category.</p>
                                    <textarea name="addition_info" id="addition_info" defaultValue={this.state.addition_info} placeholder="Please enter additional information here..." onChange={this.handleChange}></textarea>
                                </div>

                                <div className="formField">
                                    <label>Professional References</label>
                                    <p className="sub_label">Have you worked for Firestorm in the past?*</p>
                                    <select name="firestorm_experience" ref={(input) => { this.fExperienceInput = input; }} className="small_dropdown" id="firestorm_experience" defaultValue={this.state.firestorm_experience} onChange={this.handleChange} errors={this.state.firestorm_experienceError}>
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 

                                    <p className="sub_label">If Yes, what was the reason for separation? Who was your supervisor?</p>
                                    <input type="text" ref={(input) => { this.separationInput = input; }} name="seperation_reason" id="seperation_reason" placeholder="If Yes, please explain..."defaultValue={this.state.seperation_reason} onChange={this.handleChange} errors={this.state.seperation_reasonError} />    

                                    <p className="sub_label">Past Experiences / Employment Record / References - #1</p>    
                                    <div className="referance_div">  
                                        <input type="text" name="last_employer1" placeholder="Last Employer" defaultValue={this.state.last_employer1} onChange={this.handleChange} />  
                                        <input type="text" name="reference1Position" placeholder="Position Held" defaultValue={this.state.reference1Position} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference1StartDate" placeholder="Start Date (mm/dd/yy)" defaultValue={this.state.reference1StartDate} onChange={this.handleChange} /> */}
                                        <DatePicker
                                            selected={this.state.reference1StartDate}
                                            name="reference1StartDate" 
                                            placeholderText="Start Date (mm/dd/yyyy)"
                                            onChange={this.handleDateChange.bind(this,'reference1StartDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />          
                                        {/*<input type="text" name="reference1EndDate" placeholder="End Date (mm/dd/yy)" defaultValue={this.state.reference1EndDate} onChange={this.handleChange} />*/}
                                        <DatePicker
                                            selected={this.state.reference1EndDate}
                                            name="reference1EndDate" 
                                            placeholderText="End Date (mm/dd/yyyy)"
                                            onChange={this.handleDateChange.bind(this,'reference1EndDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        /> 
                                        <input type="text" name="reference1Address" placeholder="Address" defaultValue={this.state.reference1Address} onChange={this.handleChange} />
                                        <input type="text" name="reference1City" placeholder="City" defaultValue={this.state.reference1City} onChange={this.handleChange} />
                                        <input type="text" name="reference1State" placeholder="State" defaultValue={this.state.reference1State} onChange={this.handleChange} />
                                        <input type="text" name="reference1Supervisor" placeholder="Supervisors Name" defaultValue={this.state.reference1Supervisor} onChange={this.handleChange} />
                                        <input type="text" name="reference1AltContact" placeholder="Alternate Contact Name" defaultValue={this.state.reference1AltContact} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference1Telephone" placeholder="Cell Phone" defaultValue={this.state.reference1Telephone} onChange={this.handleChange}  />*/}
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="reference1Telephone" placeholder="Cell Phone" defaultValue={this.state.reference1Telephone} />
                                        <input type="text" name="reference1Fax" placeholder="Fax" defaultValue={this.state.reference1Fax} onChange={this.handleChange} />                        

                                        <p>Responsibilities</p>
                                        <textarea name="reference1Responsibilities" placeholder="Please fill out your responsibilities for this position..." defaultValue={this.state.reference1Responsibilities} onChange={this.handleChange}></textarea>

                                        <p>Reason For Leaving</p>
                                        <textarea name="reference1ResonForLeaving" placeholder="Please describe your reason for leaving..." defaultValue={this.state.reference1ResonForLeaving} onChange={this.handleChange}></textarea>
                                    </div>

                                    <div className="referance_div">  
                                        <p className="sub_label">Past Experiences / Employment Record / References - #2 (if applicable)</p>
                                        <input type="text" name="last_employer2" placeholder="Last Employer" defaultValue={this.state.last_employer2} onChange={this.handleChange}/>  
                                        <input type="text" name="reference2Position" placeholder="Position Held" defaultValue={this.state.reference2Position} onChange={this.handleChange}/>
                                        {/*<input type="text" name="reference2StartDate" placeholder="Start Date (mm/dd/yy)" defaultValue={this.state.reference2StartDate} onChange={this.handleChange}/>*/}
                                        <DatePicker
                                            selected={this.state.reference2StartDate}
                                            name="reference2StartDate" 
                                            placeholderText="Start Date (mm/dd/yy)"
                                            onChange={this.handleDateChange.bind(this,'reference2StartDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />            
                                        {/*<input type="text" name="reference2EndDate" placeholder="End Date (mm/dd/yy)" defaultValue={this.state.reference2EndDate} onChange={this.handleChange}/>*/}
                                        <DatePicker
                                            selected={this.state.reference2EndDate}
                                            name="reference2EndDate" 
                                            placeholderText="End Date (mm/dd/yy)"
                                            onChange={this.handleDateChange.bind(this,'reference2EndDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        /> 
                                        <input type="text" name="reference2Address" placeholder="Address" defaultValue={this.state.reference2Address} onChange={this.handleChange}/>
                                        <input type="text" name="reference2City" placeholder="City" defaultValue={this.state.reference2City} onChange={this.handleChange} />
                                        <input type="text" name="reference2State" placeholder="State" defaultValue={this.state.reference2State}  onChange={this.handleChange}/>
                                        <input type="text" name="reference2Supervisor" placeholder="Supervisors Name" defaultValue={this.state.reference2Supervisor}  onChange={this.handleChange}/>
                                        <input type="text" name="reference2AltContact" placeholder="Alternate Contact Name" defaultValue={this.state.reference2AltContact}  onChange={this.handleChange}/>
                                        {/*<input type="text" name="reference2Telephone" placeholder="Cell Phone" defaultValue={this.state.reference2Telephone}  onChange={this.handleChange}/>*/}
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="reference2Telephone" placeholder="Cell Phone" defaultValue={this.state.reference2Telephone} />
                                        <input type="text" name="reference2Fax" placeholder="Fax"  defaultValue={this.state.reference2Fax} onChange={this.handleChange}/>  

                                        <p>Responsibilities</p>
                                        <textarea name="reference2Responsibilities" placeholder="Please fill out your responsibilities for this position..." defaultValue={this.state.reference2Responsibilities} onChange={this.handleChange}></textarea>

                                        <p>Reason For Leaving</p>
                                        <textarea name="reference2ResonForLeaving" placeholder="Please describe your reason for leaving..." defaultValue={this.state.reference2ResonForLeaving} onChange={this.handleChange}></textarea>
                                    </div>


                                    <div className="referance_div">  
                                        <p className="sub_label">Past Experiences / Employment Record / References - #3 (if applicable)</p>
                                        <input type="text" name="last_employer3" placeholder="Last Employer" defaultValue={this.state.last_employer3} onChange={this.handleChange} />  
                                        <input type="text" name="reference3Position" placeholder="Position Held" defaultValue={this.state.reference3Position} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference3StartDate" placeholder="Start Date (mm/dd/yy)" defaultValue={this.state.reference3StartDate} onChange={this.handleChange} />           
                                        <input type="text" name="reference3EndDate" placeholder="End Date (mm/dd/yy)" defaultValue={this.state.reference3EndDate} onChange={this.handleChange} />*/}
                                        <DatePicker
                                            selected={this.state.reference3StartDate}
                                            name="reference3StartDate" 
                                            placeholderText="Start Date (mm/dd/yy)"
                                            onChange={this.handleDateChange.bind(this,'reference3StartDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        /> 
                                        <DatePicker
                                            selected={this.state.reference3EndDate}
                                            name="reference3EndDate" 
                                            placeholderText="End Date (mm/dd/yy)"
                                            onChange={this.handleDateChange.bind(this,'reference3EndDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        /> 
                                        <input type="text" name="reference3Address" placeholder="Address" defaultValue={this.state.reference3Address} onChange={this.handleChange} />
                                        <input type="text" name="reference3City" placeholder="City" defaultValue={this.state.reference3City} onChange={this.handleChange} />
                                        <input type="text" name="reference3State" placeholder="State" defaultValue={this.state.reference3State} onChange={this.handleChange} />
                                        <input type="text" name="reference3Supervisor" placeholder="Supervisors Name" defaultValue={this.state.reference3Supervisor} onChange={this.handleChange} />
                                        <input type="text" name="reference3AltContact" placeholder="Alternate Contact Name" defaultValue={this.state.reference3AltContact} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference3Telephone" placeholder="Cell Phone" defaultValue={this.state.reference3Telephone} onChange={this.handleChange} />*/}
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange}  name="reference3Telephone" placeholder="Cell Phone" defaultValue={this.state.reference3Telephone} />
                                        <input type="text" name="reference3Fax" placeholder="Fax" defaultValue={this.state.reference3Fax} onChange={this.handleChange} />  

                                        <p>Responsibilities</p>
                                        <textarea name="reference3Responsibilities" placeholder="Please fill out your responsibilities for this position..." defaultValue={this.state.reference3Responsibilities} onChange={this.handleChange} ></textarea>

                                        <p>Reason For Leaving</p>
                                        <textarea name="reference3ResonForLeaving" placeholder="Please describe your reason for leaving..." defaultValue={this.state.reference3ResonForLeaving} onChange={this.handleChange} ></textarea>
                                    </div>
                                </div>
                                <div className="formField">
                                    <label>Professional References</label>
                                    <div className="personal_referance_div">  
                                        <p>Reference - #1</p>
                                        <input type="text" name="reference1name" placeholder="Name" defaultValue={this.state.reference1name} onChange={this.handleChange} /> 
                                        <input type="text" name="reference1Relationship" placeholder="Relationship" defaultValue={this.state.reference1Relationship} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference1Phone" placeholder="Cell Phone" defaultValue={this.state.reference1Phone} onChange={this.handleChange} />   */}
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="reference1Phone" placeholder="Cell Phone" defaultValue={this.state.reference1Phone} />       
                                        <input type="text" name="reference1Known" placeholder="How long have you known them?" defaultValue={this.state.reference1Known} onChange={this.handleChange} />
                                    </div>

                                    <div className="personal_referance_div"> 
                                        <p>Reference - #2</p>
                                        <input type="text" name="reference2name" placeholder="Name" defaultValue={this.state.reference2name} onChange={this.handleChange} /> 
                                        <input type="text" name="reference2Relationship" placeholder="Relationship" defaultValue={this.state.reference2Relationship} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference2Phone" placeholder="Cell Phone" defaultValue={this.state.reference2Phone} onChange={this.handleChange} /> */}
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="reference2Phone" placeholder="Cell Phone" defaultValue={this.state.reference2Phone} />          
                                        <input type="text" name="reference2Known" placeholder="How long have you known them?" defaultValue={this.state.reference2Known} onChange={this.handleChange} />
                                    </div>

                                    <div className="personal_referance_div"> 
                                        <p>Reference - #3</p>
                                        <input type="text" name="reference3name" placeholder="Name" defaultValue={this.state.reference3name} onChange={this.handleChange} /> 
                                        <input type="text" name="reference3Relationship" placeholder="Relationship" defaultValue={this.state.reference3Relationship} onChange={this.handleChange} />
                                        {/*<input type="text" name="reference3Phone" placeholder="Cell Phone" defaultValue={this.state.reference3Phone} onChange={this.handleChange} />  */}   
                                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} name="reference3Phone" placeholder="Cell Phone" defaultValue={this.state.reference3Phone} />     
                                        <input type="text" name="reference3Known" placeholder="How long have you known them?" defaultValue={this.state.reference3Known} onChange={this.handleChange} /> 
                                    </div>       
                                </div> 
                                <div className="formField driver_qualification">
                                    <label>Driver Qualifications</label>
                                    <p>If you would like to be considered for a driving position during your employment and you have a valid driver's license. You need to complete the section below to be trained in and operate a commercial vehicle.</p>

                                    <p className="sub_label">If you do not have a valid or current driver's license you can skip this section.</p>
                                    <p className="sub_label">Driver License(s) past 3 years: </p>
                                    <p>License - #1 (if applicable)</p>
                                    <div className="license_div">
                                        <input type="text" name="licenseState" placeholder="State" defaultValue={this.state.licenseState} onChange={this.handleChange} /> 
                                        <input type="text" name="licenseNumber" placeholder="License#" defaultValue={this.state.licenseNumber} onChange={this.handleChange} />
                                        {/*<input type="text" name="licenseExpDate" placeholder="Exp Date" defaultValue={this.state.licenseExpDate} onChange={this.handleChange} />*/} 
                                        <DatePicker
                                            selected={this.state.licenseExpDate}
                                            name="licenseExpDate" 
                                            placeholderText="Exp Date"
                                            onChange={this.handleDateChange.bind(this,'licenseExpDate')}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                        />           
                                        <input type="text" name="licenseClass" placeholder="Class A, B, C" defaultValue={this.state.licenseClass} onChange={this.handleChange} />
                                        <input type="text" name="licenseEndorsement" placeholder="Endorsements" defaultValue={this.state.licenseEndorsement} onChange={this.handleChange} />
                                    </div>

                                    <p className="sub_label">Have you ever been denied a license, permit or privilege to operate a motor vehicle?</p>
                                    <select name="DeniedLicense" id="DeniedLicense" onChange={this.handleChange} className="small_dropdown" defaultValue={this.state.DeniedLicense}>
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 

                                    <p className="sub_label">Has any license, permit or privilege ever been revoked?</p>
                                    <select name="LicenseRevoked" id="LicenseRevoked" defaultValue={this.state.LicenseRevoked} className="small_dropdown" onChange={this.handleChange} >
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 
                                    <input type="text" defaultValue={this.state.ReasonLicenseRevoked} name="ReasonLicenseRevoked" placeholder="If Yes, please explain..." onChange={this.handleChange}/>
                                </div> 

                                <div className="formField">
                                    <label>Please Answer the Following Questions</label>
                                    <p>Are you willing to be on call 24 hours a day, 7 days a week, work long shifts, go weeks without a day off, endure adverse weather conditions, perform strenuous and prolonged physical labor and go extended periods without a bed, shower or phone?*</p>                        
                                    <select name="Question1" ref={(input) => { this.Question1Input = input; }} id="Question1" className="small_dropdown" defaultValue={this.state.Question1} onChange={this.handleChange} errors={this.state.Question1Error}>
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 

                                    <p>I have read and understand the physical and mental requirements needed in order to be a wildland firefighter that are explained in the link below. I am qualified and fit to perform these tasks. (For more information on fitness for this job go here: <a target="_blank" href="http://www.nifc.gov/FireFit/fitness.htm">http://www.nifc.gov/FireFit/fitness.htm</a>)*</p>                        
                                    <select name="Question2" ref={(input) => { this.Question2Input = input; }} id="Question2" className="small_dropdown" defaultValue={this.state.Question2} onChange={this.handleChange} errors={this.state.Question2Error} >
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 

                                    <p>Firestorm W.F.S. Inc. is a drug free and smoke-free work place. If hired, will you comply to our company drug and smoking policies?*</p>                        
                                    <select name="Question3" ref={(input) => { this.Question3Input = input; }} id="Question3" className="small_dropdown" defaultValue={this.state.Question3} onChange={this.handleChange} errors={this.state.Question3Error} >
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select> 
                                </div> 

                                <div className="formField">
                                    <label>Legal and Final Remarks</label>
                                    <p className="sub_label">Firestorm W.F.S. Inc. is an equal opportunity employer. All candidates will be considered for employment without regard to race, color, sex, religion, national origin and age.</p>

                                    <p>Firestorm is a Federal Contractor and is required to meet certain guidelines for our Affirmative Action Plan; therefore, we request that you submit the following information. This information is voluntary and will not affect your hiring eligibility. This information will remain confidential and hiring supervisors will not see this information.</p>

                                    <p className="sub_label">Select Gender</p>
                                    <select name="gender" id="gender" className="small_dropdown" defaultValue={this.state.gender} onChange={this.handleChange} >
                                        <option value="">---</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>

                                    <p className="sub_label">Select Race</p>
                                    <select name="Race" id="race" defaultValue={this.state.Race} onChange={this.handleChange} >
                                        <option value="">---</option>
                                        { this.state.RaceList.map((item, key)=>
                                            <option key={key} value={item.id}>{item.value}</option>
                                        )}
                                    </select>

                                    <p className="sub_label">Are you a Veteran</p>
                                    <select name="Veteran" id="veteran" className="small_dropdown" defaultValue={this.state.Veteran} onChange={this.handleChange}>
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>

                                    <div className="paragragh">
                                        <p>Submission of false information is grounds for employment and candidacy termination.</p>

                                        <p>I hereby authorize Firestorm Wildland Fire Suppression, Inc. to thoroughly investigate my references, work record, education and other matters related to my suitability for employment (excluding criminal background information) unless otherwise specified above. I further, authorize the references I have listed to disclose to the company any and all letters, reports and other information related to my work records, without giving me prior notice of such disclosure. In addition, I hereby release the Company, my former employers and all other persons, corporations, partnerships and associations from any and all claims, demands or liabilities arising out of or in any way related to such investigation or disclosure.</p>

                                        <p>I verify that all information provided on this application is in fact true to the best of my knowledge and understand that if information is not true, my candidacy and/or employment will be terminated. By filling out your name and dating below is a legal agreement between you (the applicant) and Firestorm Inc.</p>
                                    
                                    </div>

                                    <p className="sub_label">Do you agree to the above statement?*</p>

                                    <select name="agreement" ref={(input) => { this.AgreementInput = input; }} id="agreement" defaultValue={this.state.agreement} className="small_dropdown" onChange={this.handleChange} errors={this.state.agreementError} >
                                        <option value="">---</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div> 

                                <div className="formField">
                                    <p className="sub_label">Full Name*</p>
                                    <input type="text" ref={(input) => { this.FullNameInput = input; }} name="FullName" id="FullName" className="FullName"  placeholder="Type Legal Full Name" defaultValue={this.state.FullName} onChange={this.handleChange} errors={this.state.FullNameError}/>

                                    <p className="sub_label">Todays Date*</p>
                                    {/*<input type="text" name="txtDate" className="txtDate" placeholder="mm/dd/yy" defaultValue={this.state.txtDate} onChange={this.handleChange} errors={this.state.todayError}/>  */}                      

                                    <DatePicker
                                        className={"txtDate "+this.state.txtDateError}
                                        ref={(input) => { this.txtDateInput = input; }}
                                        selected={this.state.txtDate}
                                        errors={this.state.txtDateError}
                                        name="txtDate" id="txtDate" 
                                        // placeholderText="mm/dd/yy"                            
                                        onChange={this.handleDateChange.bind(this,'txtDate')}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                </div>

                                <div className="formField right">
                                    { this.state.saveClick &&
                                        <img className="login_loader" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                    }
                                    <input type="submit" name="submit" value="submit application" className="submit_appln" onClick={this.onSubmit} />
                                </div>
                            </form>
                        </div>

                        { this.state.alertPop &&
                            <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)} />
                        }
                    </div>   
                )
            ) 
        );
    }
}

function mapStateToProps(state) {
    const { application } = state.application;
    return {
        application,
    };
}

const connectedApplication = connect(mapStateToProps)(Application);
export { connectedApplication as Application };

// export default Application;


