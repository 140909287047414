import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import footerLogo from "../assets/images/firestorm.png";
import Dropzone from "react-dropzone";
import { AlertPopup } from "../_components/AlertPopup";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ReactModal from "react-modal";
import { userActions } from "../_actions";

ReactModal.setAppElement("#app");

class ProfilePic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submit_button: true,
      uploadedFile: null,
      _loader: false,
      uploadedFileCloudinaryUrl: "",
      imgPreview: "",
      data: [],
      redirect: "",
      crop: {
        x: "",
        y: "",
        width: "",
        height: ""
      },
      pixelCrop: {
        x: "",
        y: "",
        width: "",
        height: ""
      },
      alertPop: false,
      statusOpen: false
    };

    this.goBack = this.goBack.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
    this.apply = this.apply.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
  }

  componentDidMount() {}

  onCropChange(crop) {
    this.setState({ crop });
  }

  onCropComplete(crop, pixelCrop) {
    this.setState({
      pixelCrop
    });
  }

  apply(file) {
    this.setState({
      imgPreview: window.URL.createObjectURL(file),
      resource_photo: file
    });
  }

  onImageDrop(files) {
    /*
    Validation
    */
    var allowed = ["png", "jpg", "jpeg"],
      file = files[0] || null;
    if (!file) {
      return this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Please select image file",
        alertFunction: ""
      });
    }
    var name = file.name,
      extension = name.split(".").pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      // Invalid file format.
      this.setState({
        alertPop: true,
        alertHead: "Error",
        alertMsg: "Invalid file format!",
        alertFunction: ""
      });
      return;
    }
    this.setState({
      statusOpen: true
    });

    this.handleImageUpload(files[0]);
  }

  _crop() {}

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  cropImage() {
    this.cropper.getCroppedCanvas().toBlob(
      (blob) => {
        var imgData = this.cropper.getData();
        this.setState({
          cropResult: this.cropper.getCroppedCanvas().toDataURL(),
          statusOpen: false,
          uploadedFile: blob,
          imgData
        });
      },
      "image/jpeg",
      0.45
    );
  }

  closeAlert() {
    this.setState({
      alertPop: false
    });
  }

  handleImageUpload(file) {
    this.setState({ imgPreview: file.preview });
    var pic = file.preview;
    this.setState({ picName: pic.split("/").pop() });
  }

  onSubmit() {
    this.setState({
      _loader: true
    });

    const dataList = this.props.user;

    let data = new FormData();

    for (var key in dataList) {
      data.append(key, dataList[key]);
    }

    if (this.state.uploadedFile !== null) {
      data.append("profilePic", this.state.uploadedFile);
    }

    var self = this;

    this.props.dispatch(userActions.register_new(data)).then((res) => {
      if (res.success) {
        this.setState({
          _loader: false,
          submit_button: false
        });
        // () => window.setTimeout(() => history.push('/login'), 2000)
      } else {
        // if res.success = false
        this.setState({
          _loader: false,
          submit_button: true,
          alertPop: true,
          alertHead: "Error",
          alertMsg: res.message,
          alertFunction: ""
        });
      }
    });
  }

  backToLogin() {
    this.setState({ redirect: "/login" });
  }

  goBack() {
    this.props.information(this.props.type, this.props.position);
  }

  render() {
    if (this.state.redirect === "/login") {
      return <Redirect to="/login" />;
    }
    var img;
    return (
      <div className="resource_type">
        <div className="login_header">
          <Link to={"/login"}>
            <img src={footerLogo} alt="Firestorm" />
          </Link>
          <p>Employee Management System</p>
        </div>

        <div className="top_head">
          <span className="back" onClick={this.goBack}>
            Back
          </span>
          <span className="title">Step 4 - Profile Picture</span>
          <span className="backToLogin">
            <Link to={"/login"}>Back to Login Page</Link>
          </span>
        </div>
        <div className="profile_pic_div">
          <div className="prof_pic upload_or_drag">
            <Dropzone multiple={false} accept="image/*" onDrop={this.onImageDrop.bind(this)}>
              <p>Upload or Drag/Drop Here</p>
            </Dropzone>
            {this.state.cropResult !== "" && this.state.cropResult !== undefined && (
              <img className="ReactCrop" style={{ width: "100%" }} src={this.state.cropResult} alt="cropped" />
            )}
          </div>

          {/* { this.state.imgPreview ?
                        ( <div className="resource_upload_div" style={{background:'url("'+this.state.imgPreview+'") no-repeat',width:'50%',height:'300px'}}>
                                <AvatarImageCropper apply={this.apply.bind(this)} />
                        </div>) : (
                            <div className="resource_upload_div" style={{background:'url('+img+') no-repeat',width:'50%',height:'300px'}}>
                                    <AvatarImageCropper apply={this.apply.bind(this)} />
                            </div>
                        )
                    }*/}
          <div className="clearFix" />
          <p className="notes">
            Note: Add a full face image of yourself so your crew can easily identify you by this picture. You may need
            to crop the image before uploading.
          </p>
          <div className="center">
            {this.state._loader && (
              <img
                alt="loader"
                className="login_loader"
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
              />
            )}
            {/* <input
              type="submit"
              className="button completeProfile"
              value="Complete Profile"
              disabled={!this.state.submit_button}
              onClick={this.onSubmit}
            /> */}
            <button
              type="submit"
              className={`button completeProfile ${!this.state.submit_button && "disabled"}`}
              value="Complete Profile"
              disabled={!this.state.submit_button || this.state._loader}
              onClick={this.onSubmit}
            >
              {this.state.submit_button ? "Complete Profile" : "Profile Created !!!"}
            </button>
          </div>
          <div className="clearfix" />
        </div>
        <ReactModal
          isOpen={this.state.statusOpen}
          onClose={this.statusCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="imageCropPopup"
        >
          <div className="modal-head">
            <img
              alt="close"
              className="closeModal"
              onClick={this.statusCloseModal.bind(this)}
              src={require("../assets/images/close_green.png").default}
            />
          </div>
          <div className="modal-body register_popup">
            <Cropper
              ref={(cropper) => {
                this.cropper = cropper;
              }}
              src={this.state.imgPreview}
              style={{ height: 400, width: "100%" }}
              // Cropper.js options
              aspectRatio={16 / 16}
              guides={false}
              crop={this._crop.bind(this)}
            />
            <div className="button_list">
              <button onClick={this.cropImage.bind(this)} className="button">
                Crop Image
              </button>
            </div>
          </div>
        </ReactModal>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return { alert };
}

const connectedRegisterPage = connect(mapStateToProps)(ProfilePic);
export { connectedRegisterPage as ProfilePic };
