import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "_actions";
import DatePicker from "react-datepicker";
import MileageHobbsList from "./MileageHobbsList";
import { isMileageLoading, mileageHobbsReport } from "_helpers/reduxSelector";
import { dashboardService } from "../_services/dashboard.service";
import { redirectToPdf } from "_helpers/redirectToPdf";

const DATE_FORMAT = "YYYY-MM-DD";

function MileageHobbsListContainer(props) {
  const dispatch = useDispatch();

  const mileageHobbsData = useSelector((state) => mileageHobbsReport(state));

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [globalSort, setGlobalSort] = useState("unique_id");
  const [globalOrder, setGlobalOrder] = useState("asc");

  const getMileageHobbsReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ""
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("page", newPage || 0);
    data.append("sort", sort || "unique_id");
    data.append("order", order || "asc");
    dispatch(dashboardActions.getMileageHobbsReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? "asc" : "desc";
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onDateFilter = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ""
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "equipment_name");
    data.append("order", globalOrder || "asc");
    dispatch(dashboardActions.getMileageHobbsReport(data));
  };

  const onExport = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ""
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "equipment_name");
    data.append("order", globalOrder || "asc");
    data.append("report_type","csv")
    dashboardService.generateMileageHobbsReport(data);
  };

  const onPrint = () => {
    let data = new FormData();
    data.append(
      "from_date",
      fromDate ? moment(fromDate).format(DATE_FORMAT) : ""
    );
    data.append("to_date", toDate ? moment(toDate).format(DATE_FORMAT) : "");
    data.append("sort", globalSort || "equipment_name");
    data.append("order", globalOrder || "asc");
    data.append("report_type","pdf")
    dashboardService.printMileageHobbsReportPdf(data).then(res=>redirectToPdf(res))
  }

  return (
    <div>
      <div className={props.isReportMenu ? "" : ""}>
        <div className="white">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Mileage/Hobbs List</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp milage__head-wrap">
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="From"
                        id="fromDate"
                        name="fromDate"
                        selected={fromDate}
                        onChange={(e) => setFromDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>
                    <div className="inp_sub_container date_filter_container">
                      <DatePicker
                        className="date_picker_cust"
                        placeholderText="To"
                        id="toDate"
                        name="toDate"
                        selected={toDate}
                        onChange={(e) => setToDate(e)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                      />
                    </div>

                      <div className="milage__btn-wraper">
                        <button
                          className="print_button"
                          onClick={() => {
                            onDateFilter();
                          }}
                        >
                          Search
                        </button>
                        {!!mileageHobbsData?.data?.length && (<>
                          <button
                            className="print_button"
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              onExport();
                            }}
                          >
                            Export
                          </button>
                          <button
                            className="print_button"
                            onClick={() => {
                              onPrint();
                            }}
                          >
                            Print
                          </button>
                        </>)}
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MileageHobbsList
                fromDate={fromDate}
                toDate={toDate}
                data={mileageHobbsData?.data || []}
                lastPage={mileageHobbsData?.last_page}
                getMileageHobbsReport={getMileageHobbsReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MileageHobbsListContainer;
