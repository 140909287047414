import React from "react";
import styles from "./Button.module.scss";

export const Button = (props) => {
  const { name, disabled, fontColor, backgroundColor, loading, onClick } = props;
  return (
    <button
      className={`${styles.Button} ${disabled && styles.disabled}`}
      style={{ fontColor, backgroundColor }}
      disabled={disabled}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
