import { ReactComponent as DownArrow } from "assets/icons/down-arrow-black.svg";
import { useState } from "react";

const DropdownBtn = (props) => {
  const { setPrintOption, options, label } = props;
  const [isOpen, setIsOpen] = useState(false);

  const triggerDropDown = () => {
    setIsOpen(!isOpen);
  };

  const onDropDownSelect = (option) => {
    setPrintOption(option);
    setIsOpen(false);
  };

  return (
    <div className="DropDownBtnWrapper">
      <label className="dropdown">
        <div className="dd-button" onClick={triggerDropDown}>
          {label}
          <DownArrow className={`down-arrow-icon ${isOpen ? "arrow-up" : ""}`} />
        </div>

        <input type="checkbox" className="dd-input" id="test" />

        <ul className="dd-menu" style={{ display: isOpen ? "block" : "none" }}>
          {options.map((option, index) => (
            <li key={index} onClick={() => onDropDownSelect(option.value)}>
              {option.label}
            </li>
          ))}
        </ul>
      </label>
    </div>
  );
};

export default DropdownBtn;
