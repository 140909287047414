import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import deleteIcon from '../assets/icons/delete-btn.svg';
import emailICons from '../assets/icons/resource-email-icon.svg';
import editIcon from '../assets/icons/doc-edit.svg';
import historyIcon from '../assets/icons/history.svg';
import { isResourceOrderFormsLoading, getCurrentUserInfo } from "_helpers/reduxSelector";
import { config } from "_helpers";
import { manifestService } from "_services";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import CreatableAsyncSelect from "_components/utils/CreatableAsyncSelect/CreatableAsyncSelect";
import {resourceActions} from "_actions"

const ROF_TYPE = {
  "CREATED" : 1,
  "UPLOADED" : 2
}

const ResourceOrderFormList = ({
  getResourceOrderForms,
  data,
  lastPage,
  redirectToCreatedForm,
  isCreatedForm,
  handleEditRof,
  setSelectedEmployees,
  selectedEmployees
}) => {
  const [page, setPage] = React.useState(0);
  const isLoading = useSelector((state) => isResourceOrderFormsLoading(state));
  const[deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
  const [selectedRO, setSelectedRO] = React.useState();
  const [sendEmailLoading,setSendEmailLoading] = React.useState(false)
  const currentUserInfo = useSelector(getCurrentUserInfo);

  const dispatch = useDispatch()

  const [emailPopup,setEmailPopup] = React.useState()

  const handlePageChange = (newPage) => {
    getResourceOrderForms(newPage?.selected + 1);
    setPage(newPage?.selected);
  };

  const handleDeleteRof = ({id, type}) => {
    let formData = new FormData();
    formData.append("rof_id", id);
    formData.append("rof_type", type);
    manifestService?.deleteResourceOrderPermanently(formData).then(({success, message}) => {
      if (success) {
        toast.success(message);
        getResourceOrderForms(page+1);
      } else {
        toast.error(message);
      }
      setDeleteConfirmModal(false);
    }).catch(()=> { setDeleteConfirmModal(false) });
  }

  const loadCreatedFormActions = (item) => { 
    return (
      <React.Fragment>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => redirectToCreatedForm?.(item?.id)}
          >
            <img
              src={`${config?.assetUrl}icons/doc-admin-view.svg`}
              alt="view document"
            />
          </span>
        </td>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleEditRof(item?.id)}
          >
            <img src={editIcon} alt="edit document" />
          </span>
        </td>

        <td className="resource-order-email-icon" style={{ minWidth: "auto" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => onEmailClick(item)}
          >
            <img src={emailICons} alt="email doc" />
          </span>
        </td>

        <td style={{ minWidth: "auto" }}>
          <Link to={`/resource-history/${item?.id}`}>
            <span style={{ cursor: "pointer" }}>
              <img src={historyIcon} alt="edit document" />
            </span>
          </Link>
        </td>
        <td>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDeleteClick(item, ROF_TYPE?.CREATED);
            }}
          >
            <img
              src={deleteIcon}
              alt="delete document"
              style={{ width: "15px" }}
            />
          </span>
        </td>
      </React.Fragment>
    );
  };

  const onEmailClick = (item) => {
    setSelectedRO(item)
    setEmailPopup(true)
  }

  const onDeleteClick = (item, type) => {
    setSelectedRO({
      id: item?.id,
      type: type,
    });
    setDeleteConfirmModal(true);
  };

  const handleCancelEmailClick = () => {
    setSelectedEmployees([])
    setEmailPopup(false)
  }

  const handleSendEmail = async () => {
    setSendEmailLoading(true)
    const data = new FormData();
    data.append("file_hash", isCreatedForm ? "" : selectedRO.file_hash);
    selectedEmployees?.forEach((employee, index) => {
      data.append(`email[${index}]`, employee.email);
    });
    data.append("resource_order_id", isCreatedForm ? selectedRO.id : "");
    data.append("file_name",selectedRO?.file_name || selectedRO?.project_name);
    data.append("sender_name", currentUserInfo?.full_name);


    const response = await dispatch(
      resourceActions.resourceOrderEmailEmployees(data)
    );
    
    if (response.success) {
      toast.success(response.message);
      handleCancelEmailClick();
    } else {
      toast.error(response.message);
    }
    setSendEmailLoading(false)
  };

  const onClickRemoveEmployeeFromMailList = (data) => {
    setSelectedEmployees(selectedEmployees.filter(item=>item.label !== data.label))
  }



  const loadActions = (item) => { 
    return (<React.Fragment>
      <td width="40" className="resource-order-cta-icon">
        <a
          href={`${config?.s3BucketUrl}${item?.file_hash}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src={`${config?.assetUrl}icons/doc-admin-view.svg`}
            alt=""
          />
        </a>
      </td>
      
      <td width="40" className="resource-order-email-icon">
        <span
          style={{ cursor: "pointer" }}
          onClick={()=>onEmailClick(item)}>
          <img
            src={emailICons}
            alt="email doc"
          />
        </span>
      </td>
      
      <td width="40" className="resource-order-delete-icon">
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            onDeleteClick(item, ROF_TYPE?.UPLOADED)
          }}>
          <img
            src={deleteIcon}
            alt="delete document"
            style={{width: '15px'}}
          />
        </span>
      </td>
    </React.Fragment>);
  }

  const ConfirmationPopup = () => (
    <ReactModal isOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} >
      <div id="statusPopup" className="popupModal">
        <div className="modal-head">
          <h4 className="modal-title">Confirm Action</h4>
        </div>
        <div className="modal-body">
          <p>{"Deleting RO will remove all the details from corresponding manifests, are you sure want to continue?"}</p>
          <div className="resource_order_form_delete_modal_action">
            <button className="button grey" onClick={() => setDeleteConfirmModal(false)}>Cancel</button>
            <button name="save" className="button" onClick={() => handleDeleteRof(selectedRO)}>Confirm</button>
          </div>
        </div>
      </div>
    </ReactModal>
  );


  const EmailPopup = () => (
    <ReactModal
      isOpen={emailPopup}
      onClose={() => setEmailPopup(false)}
    >
      <div
        id="statusPopup"
        className="popupModal"
      >
        <div className="modal-head ro-email-modal-head">
          <h4 className="modal-title">Email "{selectedRO?.file_name || selectedRO?.project_name}"</h4>
        </div>
        <div className="modal-body ro-modal-body">
          <CreatableAsyncSelect setSelectedEmployees={setSelectedEmployees} selectedEmployees={selectedEmployees} />
          {!!selectedEmployees.length && <div className="mail-maifest-wrapper">
                  <div className="mail-manifest wrapper">
                    <div className="head">
                      <div className="name ro-email-modal-name" style={{fontWeight:"bold"}}>Name</div>
                      <div className="email" style={{fontWeight:"bold"}}>Email</div>
                    </div>
                    {selectedEmployees?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="employee employee_item selected-employee"
                        >
                          <div className="pic image-holder ro-profile-pic-wrapper">
                            {item.profilePic ? (
                              <img
                                src={config.apiUrl + "/" + item.profilePic}
                                onError={(e) =>
                                  (e.target.src = `${
                                    config.apiUrl + "/" + config.altImg
                                  }`)
                                }
                                alt="prof-pic"
                              />
                            ) : item.name ? (
                              <img
                                src={config.apiUrl + "/" + config.altImg}
                                alt="prof-pic"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="name">
                            <span className="">{item.name}</span>
                          </div>
                          <div className="email">{item.email}</div>
                          <div className="close-btn">
                            <img
                              alt="close"
                              src={
                                require("../assets/images/close_button.png")
                                  .default
                              }
                              onClick={() => onClickRemoveEmployeeFromMailList(item)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>}
          <div className="resource_order_form_delete_modal_action">
            <button
              className="button grey"
              onClick={handleCancelEmailClick}
            >
              Cancel
            </button>
            <button
              name="save"
              className="button"
              onClick={handleSendEmail}
              disabled={!selectedEmployees.length || sendEmailLoading}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );

  const noDataAvailable = !isLoading && !data?.length;

  return (
    <div className="history_lists outer_list">
      <div className="history_lists_wap">
      <table className="mobile_hide report_list_width_full">
        <tr className="report_list_table_row">
          <th>Name</th>
          { isCreatedForm && <th>Project #</th> }
          <th></th>
        </tr>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td>
                <div className="dashboard_expiration_date_no_data">
                  No Data Found
                </div>
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index} className="resource-order-item">
                <td className="resource-order-item-title" style={{ width: isCreatedForm ? "50%" : "75%", wordBreak: "break-all" }}>{item?.file_name ?? item?.project_name ?? "-"}</td>
                { isCreatedForm && <td style={{ width: "25%", wordBreak: "break-all" }}>{item?.project_no ? item.project_no : "-"}</td>}
                <td style={{ width: "25%" }}>
                  {isCreatedForm ? (
                    loadCreatedFormActions(item)
                  ) : (
                    loadActions(item)
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      </div>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">
          No Data Found
        </div>
      ) : (
        <ResourceOrderFormListMobileView
          isCreatedForm={isCreatedForm}
          data={data}
          redirectToCreatedForm={redirectToCreatedForm}
          onEmailClick={onEmailClick}
          onDeleteClick={onDeleteClick}
          handleEditRof={handleEditRof}
        />
      )}

      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer ro-pagination-wrapper" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
      <ConfirmationPopup/>
      <EmailPopup/>
    </div>
  );
};

export default ResourceOrderFormList;

const ResourceOrderFormListMobileView = ({
  data,
  isCreatedForm,
  redirectToCreatedForm,
  onEmailClick,
  onDeleteClick,
  handleEditRof
}) => {
  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items resource_order_form_mobile">
          <div className="status_item">
            <div className="list_item">
              <b>Name</b>
            </div>
            <div className="list_item">
              {item?.file_name ?? item?.project_name ?? "-"}
            </div>
            {isCreatedForm ? (
              <>
            <div className="list_item">
              <b>Project #</b>
            </div>
            <div className="list_item">
              {item?.project_no ? item?.project_no : "-"}
            </div>
            </>) : ''}
          </div>
          <div className="status_item">
            {isCreatedForm ? (
              <>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectToCreatedForm?.(item?.id)}
                  >
                    <img
                      src={`${config?.assetUrl}icons/doc-admin-view.svg`}
                      alt=""
                    />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditRof(item?.id)}
                  >
                    <img src={editIcon} alt="edit document" />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onEmailClick(item)}
                  >
                    <img src={emailICons} alt="email doc" />
                  </span>
                </div>
                <div className="list_item">
                  <Link to={`/resource-history/${item?.id}`}>
                    <span style={{ cursor: "pointer" }}>
                      <img src={historyIcon} alt="edit document" />
                    </span>
                  </Link>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onDeleteClick(item, ROF_TYPE?.CREATED);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete document"
                      style={{ width: "15px" }}
                    />
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="list_item">
                  <a
                    href={`${config?.s3BucketUrl}${item?.file_hash}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={`${config?.assetUrl}icons/doc-admin-view.svg`}
                      alt=""
                    />
                  </a>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => onEmailClick(item)}
                  >
                    <img src={emailICons} alt="email doc" />
                  </span>
                </div>
                <div className="list_item">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onDeleteClick(item,ROF_TYPE?.UPLOADED);
                    }}
                  >
                    <img
                      src={deleteIcon}
                      alt="delete document"
                      style={{ width: "15px" }}
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
