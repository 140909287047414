import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment";
import _invert from "lodash/invert";

import { isUsersExpirationDatesLoading } from "_helpers/reduxSelector";
import { userStatus } from "_constants";
import { formatDate } from "_helpers/helpers";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  rtClass: ASC_CLASS,
  statusClass:ASC_CLASS
};

const ExpirationDateReportList = ({
  getRefresherReport,
  refresherReports,
  selectedResource,
}) => {
  const isLoading = useSelector((state) =>
    isUsersExpirationDatesLoading(state)
  );
  const data = refresherReports?.data ?? [];

  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");
  const [page, setPage] = useState(0);

  const [{ nameClass, rtClass, statusClass }, setStyleState] = useState(initialStyleState);

  const status = React.useMemo(() => _invert(userStatus), []);


  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getRefresherReport(
      newPage?.selected + 1,
      sortItem || "last_name",
      sortOrder
    );
    setPage(newPage?.selected + 1);
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getRefresherReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order, selectedResource]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return (
    <>
    <div className="history_lists outer_list table__scroll">
      <table className="mobile_hide report_list_width_full long__report tab_hide expiry_report">
        <tr className="report_list_table_row">
          <th onClick={() => sortList("nameClass", nameClass, "first_name")}>
            <div className="report_table_header">
              <span>Employee Name</span>
              <span className={nameClass} />
            </div>
          </th>
          <th onClick={() => sortList("rtClass", rtClass, "rt_130_expiry")}>
            <div className="report_table_header">
              <span>RT-130</span>
              <span className={rtClass} />
            </div>
          </th>
          <th onClick={() => sortList("statusClass", statusClass, "status")}>
            <div className="report_table_header">
              <span>Status</span>
              <span className={statusClass} />
            </div>
          </th>
        </tr>
        <tbody className="report_list_table_tbody">
          {data?.length
            ? data.map((item, index) => (
                <tr key={index}>
                  <td>{`${item?.last_name} ${item?.first_name}`}</td>
                  <td>{formatDate(item?.rt_130_expiry)}</td>
                  <td>{status?.[item?.active]}</td>
                </tr>
              ))
            : !isLoading && (
                <tr>
                  <td colSpan={10}>
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              )}
        </tbody>
      </table>

      {data?.length ? (
        <>
          <RefresherReportMobileView
            data={data}
            getRefresherReport={getRefresherReport}
          />
          
        </>
      ) : (
        !isLoading && (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        )
      )}
    </div>
    {data?.length ? (
            <div className="fs-pagination-wrapper-outer" key={7}>
              <div className="customised-pagination right-align">
                <ReactPaginate
                  previousLabel={""}
                  nextLabel={""}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={refresherReports?.last_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(data) => handlePageChange(data)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          ) : null}
    </>
  );
};

export default ExpirationDateReportList;

const RefresherReportMobileView = ({ data, getRefresherReport }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("last_name");

  const [{ nameClass, rtClass }, setStyleState] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getRefresherReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "first_name")}
            >
              <strong>Employee Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">{`${item?.first_name} ${item?.last_name}`}</div>
          </div>
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("rtClass", rtClass, "rt_130_expiry")}
            >
              <strong>RT-130</strong>
              <span className={rtClass} />
            </div>
            <div className="list_item">{formatDate(item?.rt_130_expiry)}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
