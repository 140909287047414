import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";


import { getAllResources } from "_helpers/reduxSelector";
import { resourceActions } from "../_actions";

const EmployeeResourceFilter = ({ handleResourceTypeChange }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(resourceActions.getAll());
  }, []);

  const allResources = useSelector((state) => getAllResources(state));

  const groupedOptions = React.useMemo(() => {
    return Object.keys(allResources).map((resourceType) => ({
      label: allResources?.[resourceType]?.[0]?.resource_type_name,
      options: allResources?.[resourceType]?.map((resource) => ({
        label: resource?.resource_name,
        value: resource?.id,
      })),
    }));
  }, [allResources]);

  return (
    <Select
      isClearable={true}
      placeholder="Select Resource"
      options={groupedOptions}
      onChange={(event) => {
        handleResourceTypeChange(event?.value);
      }}
    />
  );
};

export default EmployeeResourceFilter;
