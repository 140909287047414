import React from "react";
import ReactModal from "react-modal";
import styles from "./alertBox.module.css";

export const AlertBox = ({ openAlert, handleAlertAction }) => {
  const handleOnClose = () => {
    handleAlertAction(false);
  };

  return (
    <ReactModal isOpen={openAlert} onClose={() => handleOnClose()}>
      <div className={styles.header}>
        <div className={styles.headerText}>
            <h4>Alert Message</h4>
        </div>
        <div onClick={() => handleOnClose()}>
          <img alt="close" className={`closeModal ${styles.closeModal}`} src={require("../../../assets/images/close_green.png").default} />
        </div>
      </div>

      <div className={styles.alertContainer}>
        <p>
          You have selected to receive your paycheck through Direct Deposit. To fulfill this request, you need to
          complete a direct deposit form and provide banking verification. An example of banking verification would be a
          printout from the bank or a void check with your account and routing number.
        </p>
        <p>
          Once you complete your onboarding documents, you will receive an email with a DD form attached to complete and
          return to <a href="mailto:naomi.tora@fwfsinc.com">naomi.tora@fwfsinc.com</a>. Include your banking verification document as well. If we do not receive the
          2 required documents before Monday of payday week your check will be mailed with postage charged.
        </p>
      </div>

      <div className={styles?.footer}>
        <button onClick={() => handleOnClose()} className="button">
          Close
        </button>
      </div>
    </ReactModal>
  );
};
