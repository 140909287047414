/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import checkIfDocsShouldBeLocked from "../../_helpers/lockDocs"
import moment from "moment";


class CandidateInfoPageExtra extends Component {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      user: {
        mailing_address: {},
        emgcontact1: {},
        emgcontact2: {},
        gender: "Male",
        personal_licence_plate_number_check: 0
      },
      form: {
        date: moment().format("MM/DD/YYYY"),
        dob: "",
      },
      errors: {},
      form_edited: false,
    };

    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { value, checked, type } = e.target;

      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handlePhoneChange(e) {
    e.target.value = usPhone(e.target.value);
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();
    if (err) {
      return;
    }

    let { doc, user, form } = this.state;
    let updatedUserFields = { ...user}
   

    for (const [key, value] of Object.entries(updatedUserFields)) {
      if (!updatedUserFields[key]) delete updatedUserFields[key]
    }
    updatedUserFields.personal_licence_plate_number_check = Number(updatedUserFields?.personal_licence_plate_number_check ?? 0)

    if (updatedUserFields?.personal_licence_plate_number_check) {
      updatedUserFields.personal_licence_plate_number_check = 1
    } else {
      updatedUserFields.personal_licence_plate_number_check = 0
      delete updatedUserFields.plpn_make_or_model
      delete updatedUserFields.personal_licence_plate_number
      delete form.plpn_make_or_model
      delete form.personal_licence_plate_number
    }

  
    
    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form: { ...form, ...updatedUserFields},
      user: updatedUserFields,
    };
    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);
    
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        toast.success("Candidate Information Submitted")
        if (this.props.mobileMode) {
          this.props.setScreen("sidebar");
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { user, form } = res.data;
        
        // date needs to a moment object or Datepicker breaks.
        form.date = form.date || currentState.form.date;
        form.dob = form.dob || currentState.form.dob;
        form.disclaimer_check = false
        // spread the data coming from API into state
        let mappedUserData={
          ...form,
        }


        if (!mappedUserData?.id){
          mappedUserData.id = user?.id
        }
        if (!mappedUserData?.emgcontact1){
          mappedUserData.emgcontact1={
            id: user?.id
          }
        }
        if (!mappedUserData?.emgcontact2) {
          mappedUserData.emgcontact2 = {
            id: user?.id
          }
        }
        if (!mappedUserData?.physical_address) {
          mappedUserData.physical_address = {
            id: user?.id
          }
        }
        if (!mappedUserData?.mailing_address) {
          mappedUserData.mailing_address = {
            id: user?.id
          }
        }
        
        this.setState({
          user: { ...mappedUserData },
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    this.setState({ doc });
    this.getFormData(doc.id);
  }

  render() {
    // still linked to state after destructuring
    let { user, form, errors } = this.state; 
    
    // docs_status
    let { adminMode } = this.props;
    let { classifications, pantSize, shirtSize, race, crewsList } = this.props.docs;

    const shouldLockDocs = checkIfDocsShouldBeLocked(user, form, adminMode)
    
   
    return (
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Candidate Information Pages</h3>
        </div>

        {/* Form */}
        <div className='doc_info-form'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name='candidate_info_page'
            noValidate>
            <fieldset disabled={shouldLockDocs}>

            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Full Name
                  </div>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        className={`${errors["user.first_name"] ? "error" : ""}`}
                        type='text'
                        name='first_name'
                        id='first_name'
                        value={user.first_name}
                        field='user.first_name'
                        placeholder='First Name'
                      />
                      {errors["user.first_name"] && (
                        <label htmlFor='first_name' className='error_label'>
                          {errors["user.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.middle_name"] ? "error" : ""}`}
                        name='middle_name'
                        id='middle_name'
                        value={user.middle_name}
                        field='user.middle_name'
                        placeholder='Middle Name (Optional)'
                      />
                      {errors["user.middle_name"] && (
                        <label htmlFor='middle_name' className='error_label'>
                          {errors["user.middle_name"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.last_name"] ? "error" : ""}`}
                        name='last_name'
                        id='last_name'
                        value={user.last_name}
                        field='user.last_name'
                        placeholder='Last Name'
                        
                      />
                      {errors["user.last_name"] && (
                        <label htmlFor='last_name' className='error_label'>
                          {errors["user.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Preferred Name <span>(Optional)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.prefered_name"] ? "error" : ""}`}
                      name='prefered_name'
                      id='prefered_name'
                      value={form.prefered_name}
                      field='form.prefered_name'
                      placeholder='- Enter here -'
                    />
                    {errors["form.prefered_name"] && (
                      <label htmlFor='prefered_name' className='error_label'>
                        {errors["form.prefered_name"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Date
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.date"] ? "error" : ""} w-100`}
                      name='date'
                      id='date'
                      
                      selected={moment(form.date)}
                      field='form.date'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {/* <input type="text" className={`${errors['form.date'] ? 'error' : ''}`} name="date" id="date" value={form.date} field="form.date" placeholder="mm / dd / yyyy" /> */}
                    {errors["form.date"] && (
                      <label htmlFor='date' className='error_label'>
                        {errors["form.date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Position
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.position"] ? "error" : ""}`}
                      name='position'
                      id='position'
                      value={form.position}
                      field='form.position'
                      >
                         <option value=''>- Select -</option>
                      <option value='Crew' selected={form.position === 'Crew'}>Crew</option>
                      <option value='Engine' selected={form.position === 'Engine'}>Engine</option>
                      <option value='Fuels' selected={form.position === 'Fuels'}>Fuels</option>
                      <option value='Admin' selected={form.position === 'Admin'}>Admin</option>
                      <option value='Tree' selected={form.position === 'Tree'}>Tree</option>
                      <option value='Other' selected={form.position === 'Other'}>Other</option>                     
                    </select>
                    {errors["form.position"] && (
                      <label htmlFor='position' className='error_label'>
                        {errors["form.position"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Classification
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.classification"] ? "error" : ""}`}
                      name='classification'
                      id='classification'
                      value={form.classification}
                      field='form.classification'
                      >
                      <option value=''>- Select -</option>
                      {classifications.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.classification"] && (
                      <label htmlFor='classification' className='error_label'>
                        {errors["form.classification"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>CREW</div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.crew_identifier_id"] ? "error" : ""}`}
                      name='crew'
                      id='crew'
                      value={user.crew_identifier_id}
                      field='user.crew_identifier_id'>
                      <option value=''>- Select -</option>
                      {crewsList.map((crew) => (
                        <option value={crew.id} key={crew.id}>
                          {crew.resource_name}
                        </option>
                      ))}
                    </select>
                    {errors["user.crew_identifier_id"] && (
                      <label htmlFor='crew' className='error_label'>
                        {errors["user.crew_identifier_id"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    SSN
                    {/*  */}
                  </div>
                  <div className='inputs-items '>
                    <input
                      type='number'
                      className={`${errors["form.ssn"] ? "error" : ""}`}
                      name='ssn'
                      id='ssn'
                      value={form.ssn}
                      field='form.ssn'
                      placeholder='- Enter here -'
                      
                    />
                    {errors["form.ssn"] && (
                      <label htmlFor='ssn' className='error_label'>
                        {errors["form.ssn"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Work eligibility will be confirmed via E-Verify System</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Driver License or State ID
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.drivers_license"] ? "error" : ""}`}
                        name='drivers_license'
                        id='drivers_license'
                        value={user.drivers_license}
                        field='user.drivers_license'
                        placeholder='- Enter here -'
                        
                      />
                      {errors["user.drivers_license"] && (
                        <label htmlFor='drivers_license' className='error_label'>
                          {errors["user.drivers_license"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.DLState"] ? "error" : ""}`}
                        name='DLState'
                        id='DLState'
                        value={user.DLState}
                        field='user.DLState'
                        placeholder='State'
                        
                      />
                      {errors["user.DLState"] && (
                        <label htmlFor='DLState' className='error_label'>
                          {errors["user.DLState"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <DatePicker
                        className={`${errors["user.useritemdates.dl_expiry"] ? "error" : ""}`}
                        name='dl_expiry'
                        id='dl_expiry'
                        required
                        field='user.useritemdates.dl_expiry'
                        placeholder='Expiry date'
                        selected={moment(user?.useritemdates?.dl_expiry)?.isValid() ? moment(user?.useritemdates?.dl_expiry) : ""}
                        onChange={this.handleDateChange.bind(this, "user.useritemdates.dl_expiry")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        autoComplete='off'
                      />
                      {errors["user.useritemdates.dl_expiry"] && (
                        <label htmlFor='dl_expiry' className='error_label'>
                          {errors["user.useritemdates.dl_expiry"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div className='row form-row'>
                <div className='col'>
                  <div className='inputs-col'>
                    <div className='inputs-head'>
                      Personal License Plate Number
                      {user.personal_licence_plate_number_check == 1 && (
                        <span className='required-star'>&#x2A;</span>
                      )}
                    </div>
                    <div className='check_radio_group gender_radio'>
                      <div className='check_radio_item agreement_radio'>
                        <input
                          type='radio'
                          name='personal_licence_plate_number_check'
                          value={0}
                          field='user.personal_licence_plate_number_check'
                          id='0'
                          checked={user.personal_licence_plate_number_check == 0}
                        />
                        <span className='radio_checkbox'></span>
                        <label htmlFor='male'>No</label>
                      </div>
                      <div className='check_radio_item agreement_radio'>
                        <input
                          type='radio'
                          name='personal_licence_plate_number_check'
                          value={1}
                          field='user.personal_licence_plate_number_check'
                          id='1'
                          checked={user.personal_licence_plate_number_check == 1}

                        />
                        <span className='radio_checkbox'></span>
                        <label htmlFor='female'>Yes</label>
                      </div>
                    </div>
                    {user.personal_licence_plate_number_check == 1 && (
                      <>
                        <div className='inputs-items group two-item personal_licence_plate_container'>
                          <div className='grouped'>
                            <input
                              type='text'
                              className={`${errors["user.personal_licence_plate_number"] ? "error" : ""}`}
                              name='personal_licence_plate_number'
                              id='personal_licence_plate_number'
                              value={user.personal_licence_plate_number}
                              field='user.personal_licence_plate_number'
                              placeholder='License Number'
                              required={user.personal_licence_plate_number_check == 1}
                            />
                            {errors["user.personal_licence_plate_number"] && (
                              <label htmlFor='personal_licence_plate_number' className='error_label'>
                                {errors["user.personal_licence_plate_number"]}
                              </label>
                            )}
                          </div>
                          <div className='grouped'>
                            <input
                              type='text'
                              className={`${errors["user.plpn_make_or_model"] ? "error" : ""}`}
                              name='plpn_make_or_model'
                              id='plpn_make_or_model'
                              value={user.plpn_make_or_model}
                              field='user.plpn_make_or_model'
                              placeholder='Make / Model'
                              required={user.personal_licence_plate_number_check == 1}
                            />
                            {errors["user.plpn_make_or_model"] && (
                              <label htmlFor='plpn_make_or_model' className='error_label'>
                                {errors["user.plpn_make_or_model"]}
                              </label>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Sex
                    {/*  */}
                  </div>
                  <div className='check_radio_group gender_radio'>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Male'
                        field='user.gender'
                        id='male'
                        checked={user.gender === "Male"}
                        
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='male'>Male</label>
                    </div>
                    <div className='check_radio_item agreement_radio'>
                      <input
                        type='radio'
                        name='gender'
                        value='Female'
                        field='user.gender'
                        id='female'
                        checked={user.gender === "Female"}
                        
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='female'>Female</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col half-col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Height
                     {/*  */}
                    <span>(in cm)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.height"] ? "error" : ""}`}
                      name='height'
                      id='height'
                      value={form.height}
                      field='form.height'
                      placeholder='- Enter here -'
                      
                    />
                    {errors["form.height"] && (
                      <label htmlFor='height' className='error_label'>
                        {errors["form.height"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Weight 
                    {/*  */}
                    <span>(in lbs)</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.weight"] ? "error" : ""}`}
                      name='weight'
                      id='weight'
                      value={user.weight}
                      field='user.weight'
                      placeholder='- Enter here -'
                      
                    />
                    {errors["user.weight"] && (
                      <label htmlFor='weight' className='error_label'>
                        {errors["user.weight"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Pant Size
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.pant_size"] ? "error" : ""}`}
                      name='pant_size'
                      id='pant_size'
                      value={user.pant_size}
                      field='user.pant_size'
                      >
                      <option value=''>- Select -</option>
                      {pantSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.pant_size"] && (
                      <label htmlFor='pant_size' className='error_label'>
                        {errors["user.pant_size"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Shirt Size
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["user.shirt_size"] ? "error" : ""}`}
                      name='shirt_size'
                      id='shirt_size'
                      value={user.shirt_size}
                      field='user.shirt_size'
                      >
                      <option value=''>- Select -</option>
                      {shirtSize.map((size) => (
                        <option value={size.id} key={size.id}>
                          {size.value}
                        </option>
                      ))}
                    </select>
                    {errors["user.shirt_size"] && (
                      <label htmlFor='shirt_size' className='error_label'>
                        {errors["user.shirt_size"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    DOB
                  </div>
                  <div className='inputs-items'>
                    <DatePicker
                      className={`${errors["form.dob"] ? "error" : ""} w-100`}
                      name='dob'
                      id='dob'
                      
                      selected={form.dob ? moment(form.dob) : ""}
                      field='form.dob'
                      placeholder='mm/dd/yyyy'
                      onChange={this.handleDateChange.bind(this, "form.dob")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                      autoComplete='off'
                    />
                    {errors["form.dob"] && (
                      <label htmlFor='dob' className='error_label'>
                        {errors["form.dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Race or Ethnicity
                    
                  </div>
                  <div className='inputs-items'>
                    <select
                      className={`${errors["form.ethnicity"] ? "error" : ""}`}
                      name='ethnicity'
                      id='ethnicity'
                      value={form.ethnicity}
                      field='form.ethnicity'
                      >
                      <option value=''>- Select -</option>
                      {race.map((option) => (
                        <option value={option.id} key={option.id}>
                          {option.value}
                        </option>
                      ))}
                    </select>
                    {errors["form.ethnicity"] && (
                      <label htmlFor='ethnicity' className='error_label'>
                        {errors["form.ethnicity"]}
                      </label>
                    )}
                  </div>
                  <p className='notes'>*Required for Affirmative Action Program</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Mailing Address
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["user.mailing_address.address"] ? "error" : ""}`}
                      name='address'
                      id='address'
                      value={user?.mailing_address?.address}
                      field='user.mailing_address.address'
                      placeholder='Street or Box Number'
                      
                    />
                    {errors["user.mailing_address.address"] && (
                      <label htmlFor='address' className='error_label'>
                        {errors["user.mailing_address.address"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col mailing-address'>
                <div className='inputs-col'>
                  <div className='inputs-items group'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.city"] ? "error" : ""}`}
                        name='city'
                        id='city'
                        value={user?.mailing_address?.city}
                        field='user.mailing_address.city'
                        placeholder='City'
                        
                      />
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.state"] ? "error" : ""}`}
                        name='state'
                        id='state'
                        value={user?.mailing_address?.state}
                        field='user.mailing_address.state'
                        placeholder='State'
                        
                      />
                      {errors["user.mailing_address.state"] && (
                        <label htmlFor='state' className='error_label'>
                          {errors["user.mailing_address.state"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.mailing_address.zip"] ? "error" : ""}`}
                        name='zip'
                        id='zip'
                        value={user?.mailing_address?.zip}
                        field='user.mailing_address.zip'
                        placeholder='Zip'
                        
                      />
                      {errors["user.mailing_address.zip"] && (
                        <label htmlFor='zip' className='error_label'>
                          {errors["user.mailing_address.zip"]}
                        </label>
                      )}
                    </div>
                  </div>
                  <p className='notes'>*Paychecks, W-2, memos, etc will be mailed here</p>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Contact Numbers
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.home_phone"] ? "error" : ""}`}
                        name='home_phone'
                        id='home_phone'
                        value={form.home_phone}
                        field='form.home_phone'
                        placeholder='Home Phone'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["form.home_phone"] && (
                        <label htmlFor='home_phone' className='error_label'>
                          {errors["form.home_phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.cell_phone"] ? "error" : ""}`}
                        name='cell_phone'
                        id='cell_phone'
                        value={form.cell_phone}
                        field='form.cell_phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["form.cell_phone"] && (
                        <label htmlFor='cell_phone' className='error_label'>
                          {errors["form.cell_phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["form.message_number"] ? "error" : ""}`}
                        name='message_number'
                        id='message_number'
                        value={form.message_number}
                        field='form.message_number'
                        placeholder='Message Number'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["form.message_number"] && (
                        <label htmlFor='message_number' className='error_label'>
                          {errors["form.message_number"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Email Address
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='email'
                        className={`${errors["user.email"] ? "error" : ""}`}
                        name='email'
                        id='email'
                        value={user.email}
                        field='user.email'
                        placeholder='Enter here'
                        
                      />
                      {errors["user.email"] && (
                        <label htmlFor='email' className='error_label'>
                          {errors["user.email"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #1
                    {/*  */}
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.fname"] ? "error" : ""}`}
                        name='fname'
                        id='emgContact1FName'
                        value={user?.emgcontact1?.fname}
                        field='user.emgcontact1.fname'
                        placeholder='Name'
                        
                      />
                      {errors["user.emgcontact1.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact1.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact1.relation"] ? "error" : ""}`}
                        name='relation'
                        id='emgcontact1Relation'
                        value={user?.emgcontact1?.relation}
                        field='user.emgcontact1.relation'
                        placeholder='Relationship'
                        
                      />
                      {errors["user.emgcontact1.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact1.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.phone"] ? "error" : ""}`}
                        name='phone'
                        id='emgcontact1Phone'
                        value={user?.emgcontact1?.phone}
                        field='user.emgcontact1.phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["user.emgcontact1.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact1.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact1.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='emgcontact1AltPhone'
                        value={user?.emgcontact1?.alt_phone}
                        field='user.emgcontact1.alt_phone'
                        placeholder='Secondary Phone Number'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["user.emgcontact1.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact1.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Emergency Contact #2
                    {/*  */}
                  </div>
                  <div className='inputs-items group two-item'>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.fname"] ? "error" : ""}`}
                        name='fname'
                        id='emgContact2FName'
                        value={user?.emgcontact2?.fname}
                        field='user.emgcontact2.fname'
                        placeholder='Name'
                        
                      />
                      {errors["user.emgcontact2.fname"] && (
                        <label htmlFor='fname' className='error_label'>
                          {errors["user.emgcontact2.fname"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='text'
                        className={`${errors["user.emgcontact2.relation"] ? "error" : ""}`}
                        name='relation'
                        id='emgcontact2Relation'
                        value={user?.emgcontact2?.relation}
                        field='user.emgcontact2.relation'
                        placeholder='Relationship'
                        
                      />
                      {errors["user.emgcontact2.relation"] && (
                        <label htmlFor='relation' className='error_label'>
                          {errors["user.emgcontact2.relation"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.phone"] ? "error" : ""}`}
                        name='phone'
                        id='emgcontact2Phone'
                        value={user?.emgcontact2?.phone}
                        field='user.emgcontact2.phone'
                        placeholder='Cell Phone'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["user.emgcontact2.phone"] && (
                        <label htmlFor='phone' className='error_label'>
                          {errors["user.emgcontact2.phone"]}
                        </label>
                      )}
                    </div>
                    <div className='grouped'>
                      <input
                        type='tel'
                        className={`${errors["user.emgcontact2.alt_phone"] ? "error" : ""}`}
                        name='alt_phone'
                        id='emgcontact2AltPhone'
                        value={user?.emgcontact2?.alt_phone}
                        field='user.emgcontact2.alt_phone'
                        placeholder='Secondary Phone Number'
                        onChange={this.handlePhoneChange}
                        
                      />
                      {errors["user.emgcontact2.alt_phone"] && (
                        <label htmlFor='alt_phone' className='error_label'>
                          {errors["user.emgcontact2.alt_phone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col medical-info'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Medical Info
                    {/*  */}
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.allergies"] ? "error" : ""}`}
                      name='allergies'
                      id='allergies'
                      value={form.allergies}
                      field='form.allergies'
                      placeholder='If any Allergies, Illnesses, etc please mention here, if not write N/A'
                      
                    />
                    {errors["form.allergies"] && (
                      <label htmlFor='allergies' className='error_label'>
                        {errors["form.allergies"]}
                      </label>
                    )}
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      type='text'
                      className={`${errors["form.prescriptions"] ? "error" : ""}`}
                      name='prescriptions'
                      id='prescriptions'
                      value={form.prescriptions}
                      field='form.prescriptions'
                      placeholder='If any Prescription Medications please mention here, if not write N/A '
                      
                    />
                    {errors["form.prescriptions"] && (
                      <label htmlFor='prescriptions' className='error_label'>
                        {errors["form.prescriptions"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        
                      />
                      <span className='checkbox_checkbox'></span>

                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              isExtraDoc={true}
              single_doc={true}
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

let connectedCandidateInfoPage = compose(withRouter, connect(mapStateToProps))(CandidateInfoPageExtra);
export { connectedCandidateInfoPage as CandidateInfoPageExtra };
