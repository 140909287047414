import React from "react";
import { formatDateTime } from "_helpers/helpers";


const ApplicantStatusCard = (props) => {
  const { title, content } = props;
  return (
    <div className="ApplicantStatusCard">
      <p className="applicant-status-title">{title}</p>
      <div className="applicant-status-content">{formatDateTime(content)}</div>
    </div>
  );
};

export default ApplicantStatusCard;
