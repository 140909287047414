import RadioButton from "Docs/Components/RadioButton";
import React from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { config } from "_helpers";
import { inventoryService } from "_services/inventory.service";
import { docsService, manifestService } from "_services";
import { useDispatch, useSelector } from "react-redux";
import {  manifestActions, resourceActions } from "_actions";
import {  selectAllResourceOrderForm, selectIsAllResourceOrderFormLoading, selectSelectedManifestRofId, selectSelectedManifestRofType } from "_helpers/reduxSelector";
import AsyncSelect from 'react-select/async';
import styles from "./resourceOrderFormUploader.module.css";
import PdfIcon from "../../../src/assets/images/pdf.png";

const TABS = {
  UPLOAD_TYPE: 0,
  SELECT_FILE: 1,
  CREATE_FILE: 2,
};

const FILE_TYPE = {
  CREATE_FORM: 1,
  UPLOADED_FORM: 2,
};

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999
  }),
  menuPortal: provided  => ({ ...provided , zIndex: 9999 }),
  menu: provided => ({
    ...provided ,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const ResourceOrderFormUploader = ({ onClose, onComplete, onCreate, disableExistingFiles, resourceId, manifestId, fromManifestForm, ...props }) => {
  const [isFileUploading, setIsFileUploading] = React.useState(false);
  const [selectedExistingFile, setSelectedExistingFile] = React.useState(null);
  const [tabValue, setCurrentTabValue] = React.useState(TABS?.UPLOAD_TYPE);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploadingStatus, setUploadingStatus] = React.useState("");
  const [selectedResource, setSelectedResource] = React.useState();

  const fileDrop = (files) => {
    setSelectedFile(files?.[0]);
    // uploadFile(files?.[0]);
  };

  const handleOnCreate = () => {
    onCreate?.();
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose?.();
  };

  const handleOnSelect = () => {
    switch (tabValue) {
      case TABS?.UPLOAD_TYPE:
        uploadFile(selectedFile);
        break;
      case TABS?.SELECT_FILE:
        onComplete(selectedExistingFile);
        break;
      default:
        break;
    }
  };

  const uploadFile = (file) => {
    try {
      setUploadingStatus("Uploading...");
      setIsFileUploading(true);
      // Generate Signed URL with Digital Ocean
      let signedUrlPayload = new FormData();
      signedUrlPayload.append("type", file?.name);
      inventoryService.getUploadUrl(signedUrlPayload).then((res) => {
        const GENERATED_URL = res?.data?.THUMB_URL;
        const digitalOceanResponse = res?.data;

        const s3BucketPayload = new FormData();
        s3BucketPayload.append("File", file);

        docsService?.uploadPdfToStore(s3BucketPayload, GENERATED_URL).then((res) => {
          const data = new FormData();
          const assignedCrews = [];
          selectedResource.forEach((resource) => {
            assignedCrews.push(resource.value);
          });
          data.append("file_rof", file);
          data.append("thumb_name", digitalOceanResponse?.THUMB_PATH);
          data.append("resource_id",  assignedCrews);
          manifestService
            ?.uploadResourceOrderForm(data)
            .then((result) => {
              if (result?.success) {
                onComplete({
                  rof_type: FILE_TYPE?.UPLOADED_FORM,
                  rof_id: result?.data,
                  fileName: file?.name,
                });
              }
              setUploadingStatus("Done...");
              setIsFileUploading(false);
            })
            .catch(() => {
              setUploadingStatus("Error...");
              setIsFileUploading(false);
            });
        });
      });
    } catch (error) {
      setUploadingStatus("Error...");
      setIsFileUploading(false);
    }
  };

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("search", text);
    data.append("resource_id", resourceId);
    data.append("manifest_id", manifestId);
    const resources = props.dispatch(manifestActions.getAllResourceOrderForm(data));
      let resourceList = [];

      const response = await resources;
      const items = response.data;
          var res = [];
          for (var key in items) {
            var arr = items[key];
            if (arr.length) {
              res = res.concat(arr);
            }
          }
          if (res.length > 0) {
            res.forEach((item) => {
              let resource = {
                label: item.resource_name,
                value: item.id
              };
              resourceList.push(resource);
            });
          }
      return resourceList;
  }
  

  const handleChooseResources = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = props.dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  }


  const loadResources = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(handleSearch(inputValue));
    }, 1000);
  });

  return (
    <div className={styles?.container}>
      <div className={styles?.dialog_title}>
        {/* Back Button */}
        <div style={{ display: "flex", alignItems: "center" }}>
          {tabValue === TABS?.SELECT_FILE && (
            <span
              disabled={isFileUploading}
              className="close-button"
              style={{
                marginRight: 10,
              }}
              onClick={() => (isFileUploading ? {} : setCurrentTabValue(TABS?.UPLOAD_TYPE))}
            >
              <img className="closeModal" src={`${config.assetUrl}icons/back-btn.svg`} />
            </span>
          )}
          <h4 style={{ margin: 0 }}>
            {tabValue === TABS?.SELECT_FILE ? "Select From Assigned Files" : "Upload Resource Order"}
          </h4>
        </div>
        {/* Close Button */}
        <div onClick={() => (isFileUploading ? {} : handleOnClose())}>
          <img alt="close" className="closeModal" src={require("../../assets/images/close_green.png").default} />
        </div>
      </div>
      <div className={styles?.content}>
        {tabValue === TABS?.UPLOAD_TYPE && (
          <div
            style={{
              display: "flex",
              justifyContent: "stretch",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Dropzone
              className={styles?.resource_order_form_drop_zone}
              multiple={false}
              accept="application/pdf"
              onDrop={(file) => fileDrop(file)}
            >
              {selectedFile ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 10,
                    flexDirection: "column",
                  }}
                >
                  <img src={PdfIcon} style={{ maxWidth: 40, marginRight: 10 }} />
                  <h6
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {selectedFile?.name}
                  </h6>
                </div>
              ) : (
                <p>Upload or Drag/Drop Here</p>
              )}
              <h6>{uploadingStatus}</h6>
            </Dropzone>
            {(!disableExistingFiles && !fromManifestForm) && (
              <div style={{ margin: "20px 0px" }}>
                <button
                  style={{ minWidth: 300 }}
                  onClick={() => setCurrentTabValue(TABS?.SELECT_FILE)}
                  className="button"
                >
                  Select From Assigned Files
                </button>
              </div>
            )}

            <div className={styles.crew_select_card}>
              <label>Choose Resources</label>
              <AsyncSelect
                cacheOptions
                loadOptions={handleChooseResources}
                onChange={selection => setSelectedResource(selection)}
                isMulti
                defaultOptions
                styles={selectStyles}
              />
            </div>


              <div>
                <button
                  style={{ minWidth: 300, marginBottom: 10 }}
                  className="button"
                onClick={() => fromManifestForm ? setCurrentTabValue(TABS?.SELECT_FILE) : handleOnCreate()}
                >
                {fromManifestForm ? "Select From Assigned Files" : "Create RO"}
                </button>
              </div>
      


            {!disableExistingFiles && <p>** Please save the manifest to add this Resource Order to the manifest. **</p>}
          </div>
        )}
        {tabValue === TABS?.SELECT_FILE && (
          <ExistingFiles
            resourceId={resourceId}
            manifestId={manifestId}
            selectedExistingFile={selectedExistingFile}
            setSelectedExistingFile={setSelectedExistingFile}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 20,
          borderTop: "1px solid #eee",
        }}
      >
        <button className="button grey" disabled={isFileUploading} onClick={() => onClose()}>
          Cancel
        </button>
        <button
          disabled={isFileUploading || tabValue === TABS?.SELECT_FILE ? !selectedExistingFile : !selectedFile || !selectedResource?.length }
          onClick={() => handleOnSelect()}
          className="button"
        >
          Select
         {isFileUploading && <img className="" alt="loader" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />} 
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { resource } = state;
  return {
    resource,
  };
}

export default connect(mapStateToProps)(ResourceOrderFormUploader);

const ExistingFiles = ({ selectedExistingFile, setSelectedExistingFile, resourceId, manifestId }) => {
  


  const [keyword, setKeyword] = React.useState("");
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => selectIsAllResourceOrderFormLoading(state));
  
  const resourceOrderForms = useSelector((state) => selectAllResourceOrderForm(state));
  const selectedManifestRofId = useSelector((state) => selectSelectedManifestRofId(state))
  const selectedManifestRofType= useSelector((state) => selectSelectedManifestRofType(state))

  React.useEffect(() => {
    getResourceOrderForms();
  }, [keyword]);

  const getResourceOrderForms = () => {
    let data = new FormData();
    data?.append("search", keyword ?? "");
    data?.append("resource_id", resourceId);
    data?.append("manifest_id", manifestId);
    dispatch(manifestActions?.getAllResourceOrderForm(data));
  };

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const updateSearchKeyword = debounce(function (event) {
    setKeyword(event?.target?.value);
  }, 1000);

  const data = resourceOrderForms;

  React.useEffect(() => {
    if (selectedManifestRofId && selectedManifestRofType) {
      let selectedRof = resourceOrderForms?.find(item => (item?.id === selectedManifestRofId) && (item?.rof_type === selectedManifestRofType))
      if (selectedRof) {
        setSelectedExistingFile({
          rof_id: selectedRof?.id,
          rof_type: selectedRof?.rof_type,
          fileName: selectedRof?.file_name ?? selectedRof?.project_name,
          fileType: selectedRof?.fileType
        })
      }
    }
  }, [selectedManifestRofId, selectedManifestRofType,resourceOrderForms])

  

  const handleOnChange = (selectedItem) => {
    setSelectedExistingFile({
      rof_id: selectedItem?.id,
      rof_type: selectedItem?.rof_type,
      fileName: selectedItem?.file_name ?? selectedItem?.project_name,
      fileType: selectedItem?.fileType
    })
  }
  return (
    <div>
      <div>
        <input placeholder="Search File" onChange={(event) => updateSearchKeyword(event)}></input>
      </div>
      <div style={{ minHeight: 400, maxHeight: 400, overflow: "auto" }}>
        {isLoading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img
              alt="loader"
              className="login_loader"
              src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
            />
          </div>
        ) : data?.length ? (
          data.map((item, index) => (
            <div key={index} onClick={() => handleOnChange(item)} >
              <RadioButton
                checked={`${item?.id}${item?.fileType}` === `${selectedExistingFile?.rof_id}${selectedExistingFile?.fileType}`}
                key={index}
                label={`${item?.file_name ?? item?.project_name}`}
                onChange={(event) =>{
                }}
              />
            </div>
          ))
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p>No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
};
