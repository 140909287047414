import React from "react";
import { connect } from "react-redux";
// import { authHeader, config } from '../_helpers';
import Modal from "react-responsive-modal";
import "../assets/styles/settings.css";
import "../assets/styles/docs.scss";
import "../assets/styles/dashboard.scss";
import { Profile } from "./Profile";
import { BulletinBoard } from "./BulletinBoard";
import { EditProfile } from "./EditProfile";
import { Permissions } from "./Permissions";
import { DocsList } from "./DocsList";
import { notificationActions, permissionActions } from "../_actions";
import { userActions } from "../_actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import ChangePassword from "ChangePassword";
import HandbookView from "./HandbookView"
import ErrorBoundary from "_components/ErrorBoundary";
import UpdateEmployeeTraining from "EmployeeTraining";
import UserQualifications from "UserQualifications";
import SystemManage from "./SystemManage";
import Management from "./Management";
import ApplicantsTab from "ApplicantTab";
import { profileService } from "_services/profile.service";
import { toast } from "react-toastify";
import { handleErrorResponse } from "_helpers/helpers";
import { formatDate } from "_helpers/helpers";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainHtml: "",
      step: window.location.hash && "#bulletin" === window.location.hash ? "Bulletin" : "Profile",
      statusOpen: false,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      permission: [],
      h1: "",
      unavailableErr: "",
      unavailableDescriptionErr: "",
      unavailableReason: 0,
      otherReason: "",
      showHandBookView: false,
      applicant_hash: "",
      sendingDDForm: false,
      send_dd_form_confirm: false,
    };
    this.changeTab = this.changeTab.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.setStep = this.setStep.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.cancel = this.cancel.bind(this);
    this.editProfile = this.editProfile.bind(this);
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false, unavailableReason: 0, unavailableErr: "", unavailableDescriptionErr: "", otherReason: "" });
  }

  changeTab(tab) {
    if (tab === "Profile") {
      var hash = "";
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        hash = "?hash=" + this.props.match.params.hash;
      }
      this.props.dispatch(userActions.getProfile(hash));
    }
    this.props.dispatch(permissionActions.getPermission());
    this.setState({ step: tab }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  //Change the reason for unavailability
  changeUnavailabilityReason = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      unavailableDescriptionErr: "",
      unavailableErr: "",
    });
    e.target.setAttribute("errors", "");
  };

  setStatusReturn(status) {
    if (Number(this.state.unavailableReason) === Number(0)) {
      this.setState({
        unavailableErr: "error",
      });
      return;
    }
    if (!this.state.otherReason.trim()) {
      this.setState({
        unavailableDescriptionErr: "error",
      });
      return;
    }
    if (status) {
      var step = "2a";
    } else {
      step = "2b"
      this.setState({
        returnDate: ""
      })
    };

    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  changeStatus(status, statVal) {
    if (status) {
      var stat = statVal === "Available" ? "Unavailable" : "Available";

      let data = new FormData();
      this.statusCloseModal();
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        data.append("hash", this.props.match.params.hash);
      }
      data.append("end_date", this.state.returnDate);
      data.append("status", stat);
      data.append("unavailableReason", this.state.unavailableReason ? this.state.unavailableReason : 0);
      // if (this.state.unavailableReason === "4") {
        data.append("otherReason", this.state.otherReason ? this.state.otherReason : "");
      // } else {
        // data.append("otherReason", "");
      // }
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        var hash = "?hash=" + this.props.match.params.hash;
        if (this.props.match.params.hash === localStorage.getItem("hash")) {
          this.props.dispatch(userActions.changeSelfStatus(data));
        } else {
          this.props.dispatch(userActions.changeStatus(data, hash));
        }
      } else {
        this.props.dispatch(userActions.changeSelfStatus(data));
      }
      this.props.dispatch(notificationActions.getCount());
      this.setState({ status: stat }, function () {
        this.componentWillReceiveProps(this.props);
      });
    }
  }

  editProfile() {
    this.setState({ step: "EditProfile" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }
  cancel() {
    this.setState({ step: "Profile" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }


  componentDidMount() {
    var hash = "";
    if (
      this.props.match.path === "/profile/:hash" ||
      this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
    ) {
      hash = "?hash=" + this.props.match.params.hash;
    }
    this.props.dispatch(userActions.getProfile(hash));
    this.componentWillReceiveProps(this.props);
    this.props.dispatch(permissionActions.getPermission());
    if (this.props.match.path === "/profile/:hash/docs/:category/:doc/:id") {
      this.changeTab("DocsList");
    }



    // Dynamic Link for equipment resp form
    const urlParams = new URLSearchParams(this.props.location?.search);
    const isEquipmentRespForm = urlParams?.get("slug") === "acknowledge-form"
    const isWarningManagementDoc = urlParams?.get("slug") === "warning-management"
    if (isEquipmentRespForm || isWarningManagementDoc) {
      this.changeTab("DocsList");
    }
  }

  componentWillReceiveProps(nextProps) {
    const { permission } = this.props;
    if (permission.permissions) {
      // permList = permission.permissions.data
      this.setState({ permission: permission.permissions.data });
    }

    if (nextProps.user.items) {
      var admin = nextProps.user.items.data.is_admin !== 0 ? true : false;
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        this.setState({
          h1: nextProps.user.items.data.first_name + " " + nextProps.user.items.data.last_name,
          admin,
          namePrefix: nextProps.user.items.data.first_name,
        });
      } else {
        this.setState({ h1: "Profile & Settings", admin, namePrefix: "you" });
      }
      this.setState({
        applicant_hash: nextProps.user.items.data.applicant_hash
      });
      var available = nextProps.user.items.data.active === "Available" ? true : false;
      this.setState({ status: nextProps.user.items.data.active, available: available });
    }
    var options;
    if (this.state.step === "Profile") {
      options = (
        <div>
          <Profile {...nextProps} editProfile={this.editProfile.bind(this)} />
        </div>
      );
    }
    if (this.state.step === "DocsList") {
      options = (
        <div>
          <DocsList />
        </div>
      );
    }
    if (this.state.step === "system-management") {
      options = (
        <div>
          <SystemManage />
        </div>
      );
    }
    if (this.state.step === "application") {
      options = (
        <div>
          <ApplicantsTab
           hash={this.state.applicant_hash}
           isApplicationEditable={false}
           applnType={this.state.applnType}
           goBack=""
           email={this.state.email}
           sendMail={this.sendMail}
           editApplicant=""
           viewHistory={this.viewHistory} />
        </div>
      );
    }
    if (this.state.step === "management") {
      options = (
        <div>
          <Management/>
        </div>
      );
    }
    if (this.state.step === "Bulletin") {
      options = (
        <div>
          <BulletinBoard />
        </div>
      );
    }
    if (this.state.step === "Permissions") {
      options = (
        <div>
          <Permissions />
        </div>
      );
    }
    if (this.state.step === "EditProfile") {
      options = (
        <div>
          <EditProfile {...nextProps} cancel={this.cancel.bind(this)} />
        </div>
      );
    }
    if (this.state.step === "Trainings") {
      options = (
        <ErrorBoundary>
          <UpdateEmployeeTraining />
        </ErrorBoundary>
      );
    }
    if (this.state.step === "Qualifications") {
      options = (
        <ErrorBoundary>
          <UserQualifications {...nextProps} />
        </ErrorBoundary>);
    }

    this.setState({ mainHtml: options });
  }
  handleHandBookView() {
      this.setState((prev) => ({ showHandBookView: !prev.showHandBookView }))
  }

  handleOnClickHandbook(fileUrl){
    let url = new URL(fileUrl);
    window.open(url.href);
  }

  
  openDdformConfirmModal() {
    this.setState({ send_dd_form_confirm: true });
  }

  ddFormConfirmCloseModal() {
    this.setState({
      send_dd_form_confirm: false
    })
  }

  sendDDForm() {
    if (this.props.user.items.data.hash) {
      this.setState({
        ...this.state,
        sendingDDForm: true,
        send_dd_form_confirm: false,
      });
      let data = new FormData();
      data.append("user_hash", this.props.user.items.data.hash)
      profileService.sendDDForm(data).then((res) => {
        if(res.success){
          toast.success(res.message)
        }
        else{
          handleErrorResponse(res);
        }
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      })
      .catch((error) => {
        toast.error("Failed to send email to user.");
        this.setState({
          ...this.state,
          sendingDDForm: false
        });
      });
    } else {
      toast.error("An unexpected error occurred.")
    }
  }

  render() {
    const { permission, user } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    var admin = false,
      editPerm = true,
      userItem;
    if (this.props.user.profile) {
      userItem = this.props.user.profile;
    } else if (this.props.user.items) {
      userItem = this.props.user.items;
    }
    var namePrefix = "";
    if (userItem) {
      var status = userItem.data.active;
      var available = userItem.data.active === "Available" ? true : false;
      admin = userItem.data.is_admin !== 0 ? true : false;
      var h1 = "Settings";
      namePrefix = "you";
      if (
        this.props.match.path === "/profile/:hash" ||
        this.props.match.path === "/profile/:hash/docs/:category/:doc/:id"
      ) {
        h1 = userItem.data.first_name + " " + userItem.data.last_name;
        editPerm = permList.includes("Status Update") ? true : false;
        namePrefix = this.state.namePrefix;
      }
    }

    let userData = user.profile ? user.profile.data : {};

    
    return (
      <div>
        <div>
          <div className="settings_container settings-pre-wrapper">
            {/* {this.state.showHandBookView ? <HandbookView onCancel={() => this.handleHandBookView()} fileUrl={userData?.handbook_link} />:(
              <React.Fragment> */}
            <div className="page_title float">
              <h1 className="">{this.state.h1}</h1>

              <div className="btn_list mobile_btn__list">
                {userData?.handbook_link ? (
                  <button
                    className="button"
                    style={{ fontSize: "13px", padding: "6px 16px" }}
                    onClick={() =>
                      this.handleOnClickHandbook(userData?.handbook_link)
                    }
                  >
                    View Handbook
                  </button>
                ) : null}
                <a
                  className="button"
                  href="https://firestormfire.com/online-booking"
                  rel="noopener"
                  target="_blank"
                  style={{ textAlign: "center", fontSize: "13px", padding: "6px 16px" }}
                >
                  Book Training Courses
                </a>
                {editPerm && (
                  <button
                    className="button edit_profile"
                    onClick={this.statusOpenModal.bind(this)}
                    style={{ fontSize: "13px", padding: "6px 16px" }}

                  >
                    Edit User's Status
                  </button>
                )}
                {permList.includes("Edit User Profile") && (
                  <button
                    className="button edit_profile"
                    onClick={this.editProfile}
                    style={{ fontSize: "13px", padding: "6px 16px" }}
                  >
                    Edit Profile
                  </button>
                )}
                {permList.includes("Send DD Form") && (
                  <button
                    className="button edit_profile"
                    onClick={this.openDdformConfirmModal.bind(this)}
                    disabled={this.state.sendingDDForm}
                    style={{ fontSize: "13px", padding: "6px 16px" }}
                  >
                    Send DD Form
                  </button>
                )}
                {permList.includes("Manage Passwords") && (
                  <ChangePassword
                    userType="user"
                    user={userItem?.data}
                    isAdmin={
                      this.props?.user?.profile?.data?.is_admin !== 0
                        ? true
                        : false
                    }
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="inner_head">
                  <h2
                    className={this.state.step === "Profile" ? "active" : ""}
                    onClick={this.changeTab.bind(this, "Profile")}
                  >
                    Profile
                  </h2>
                  {/* userData.docs_status >= 2 &&  */}
                  {
                    <h2
                      className={this.state.step === "DocsList" ? "active" : ""}
                      onClick={this.changeTab.bind(this, "DocsList")}
                    >
                      Documents
                    </h2>
                  }
                  {
                    <h2
                      className={
                        this.state.step === "Qualifications" ? "active" : ""
                      }
                      onClick={this.changeTab.bind(this, "Qualifications")}
                    >
                      Qualifications
                    </h2>
                  }
                  {
                    <h2
                      className={
                        this.state.step === "Trainings" ? "active" : ""
                      }
                      onClick={this.changeTab.bind(this, "Trainings")}
                    >
                      Trainings
                    </h2>
                  }

                  {admin &&
                    this.props.match.path !== "/profile/:hash" &&
                    this.props.match.path !==
                      "/profile/:hash/docs/:category/:doc/:id" && (
                      <h2
                        className={
                          this.state.step === "Permissions" ? "active" : ""
                        }
                        onClick={this.changeTab.bind(this, "Permissions")}
                      >
                        Permissions
                      </h2>
                    )}
                  {this.state.permission.includes("Bulletin Board") &&
                    this.props.match.path !== "/profile/:hash" &&
                    this.props.match.path !==
                      "/profile/:hash/docs/:category/:doc/:id" && (
                      <h2
                        className={
                          this.state.step === "Bulletin" ? "active" : ""
                        }
                        onClick={this.changeTab.bind(this, "Bulletin")}
                      >
                        Bulletin Board
                      </h2>
                    )}
                  {
                    this.state.permission.includes(
                      "Manage System Notification"
                    ) && (
                      <h2
                        className={
                          this.state.step === "system-management"
                            ? "active"
                            : ""
                        }
                        onClick={this.changeTab.bind(this, "system-management")}
                      >
                        System Management
                      </h2>
                    )}
                     {userItem?.data?.applicant_hash && <h2
                        className={
                          this.state.step === "application"
                            ? "active"
                            : ""
                        }
                        onClick={this.changeTab.bind(this, "application")}
                      >
                        Application
                      </h2>}
                   
                </div>
                <div className="tab-contents">{this.state.mainHtml}</div>
              </div>
            </div>
            <Modal
              id="remove_popup"
              className="status_popup"
              open={this.state.send_dd_form_confirm}
              onClose={this.ddFormConfirmCloseModal.bind(this)}
              little
            >
              <div id="removePopup">
                <div className="modal-head">
                  <h4 className="modal-title">
                    Direct Deposit Form
                  </h4>
                </div>
                <div className="modal-body">
                  <label>This will send an email to {userData ? userData.first_name + " " + userData.last_name : "this employee"}, with Direct Deposit form link.</label>
                  <br />
                  <button className="button grey" onClick={this.ddFormConfirmCloseModal.bind(this)}>
                    Cancel
                  </button>
                  <button className="button" onClick={this.sendDDForm.bind(this)}>
                    Send
                  </button>
                </div>
              </div>
            </Modal>
            <Modal
              id="status_popup"
              className="status_popup"
              classNames={{ modal: "status_popup" }}
              open={this.state.statusOpen}
              onClose={this.statusCloseModal.bind(this)}
              little
            >
              <div id="statusPopup">
                <div className="modal-head">
                  <h4 className="modal-title">Current Status</h4>
                </div>
                {this.state.statusStep === "1" && (
                  <div className="modal-body">
                    <div className="status_step step1">
                      <label>
                        <span className="capitalize">
                          {this.props.match.path === "/profile/:hash" ||
                          this.props.match.path ===
                            "/profile/:hash/docs/:category/:doc/:id"
                            ? namePrefix
                            : "You"}
                        </span>
                        {this.props.match.path === "/profile/:hash" ||
                        this.props.match.path ===
                          "/profile/:hash/docs/:category/:doc/:id"
                          ? " is: "
                          : " are: "}
                        <span className="green">{status}</span>
                      </label>
                      <br></br>
                      <button
                        className="button"
                        onClick={this.setStep.bind(this, "2")}
                      >
                        Change Current Status
                      </button>
                      <p className="step_no">
                        {status === "Available" ? "1/3" : "1/2"}
                      </p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && available && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Reason for Unavailability: </label>
                      <select
                        name="unavailableReason"
                        className="unavailability_reason"
                        value={this.state.unavailableReason}
                        onChange={this.changeUnavailabilityReason}
                        errors={this.state.unavailableErr}
                      >
                        <option value="0">-- Select Reason --</option>
                        <option value="1">Time Off</option>
                        <option value="2">Sick</option>
                        <option value="3">Injury</option>
                        <option value="4">Other</option>
                      </select>
                      {(this.state.unavailableReason === "1" ||
                      this.state.unavailableReason === "2" ||
                      this.state.unavailableReason === "3" ||
                      this.state.unavailableReason === "4" ) && (
                        <div classNames="full-width">
                          <textarea
                            rows="3"
                            maxLength={1200}
                            className="other_reason"
                            value={this.state.otherReason}
                            name="otherReason"
                            onChange={this.changeUnavailabilityReason}
                            errors={this.state.unavailableDescriptionErr}
                          />
                        </div>
                      )}
                      <label>Do you know when {namePrefix} will return?</label>
                      <button
                        className="button"
                        onClick={this.setStatusReturn.bind(this, true)}
                      >
                        Yes
                      </button>
                      <button
                        className="button grey"
                        onClick={this.setStatusReturn.bind(this, false)}
                      >
                        No
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2" && !available && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Make Available?</label>
                      <button
                        className="button"
                        onClick={this.changeStatus.bind(this, true, status)}
                      >
                        Yes
                      </button>
                      <button
                        className="button grey"
                        onClick={this.statusCloseModal.bind(this)}
                      >
                        No
                      </button>
                      <p className="step_no">2/2</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2a" && (
                  <div className="modal-body">
                    <div className="status_step step4">
                      <label>Choose date of return:</label>
                      <DatePicker
                        className="returnDateField"
                        name="returnDateField"
                        selected={this.state.returnDateField}
                        onChange={this.handleDateChange.bind(this)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={moment()}
                      />
                      <button
                        className="button btn_margin"
                        onClick={this.setStep.bind(this, "3")}
                      >
                        Next
                      </button>
                      <p className="step_no">2/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "2b" && (
                  <div className="modal-body">
                    <div className="status_step step2">
                      <label>Make Unavailable?</label>
                      <button
                        className="button"
                        onClick={this.changeStatus.bind(this, true, status)}
                      >
                        Yes
                      </button>
                      <button
                        className="button grey"
                        onClick={this.statusCloseModal.bind(this)}
                      >
                        No
                      </button>
                      <p className="step_no">3/3</p>
                    </div>
                  </div>
                )}
                {this.state.statusStep === "3" && (
                  <div className="modal-body">
                    <div className="status_step step5">
                      <label>
                        Are you sure you want to switch {namePrefix} to
                        Unavailable?
                      </label>
                      <p>
                        (returning{" "}
                        {this.state.returnDate === ""
                          ? moment().format("MM/DD/YYYY")
                          : formatDate(this.state.returnDate)}
                        )
                      </p>
                      <button
                        className="button"
                        onClick={this.changeStatus.bind(this, true, status)}
                      >
                        Yes
                      </button>
                      <button
                        className="button grey"
                        onClick={this.statusCloseModal.bind(this)}
                      >
                        No
                      </button>
                      <p>Time off must be approved by your supervisor.</p>
                      <p className="step_no">3/3</p>
                    </div>
                  </div>
                )}
              </div>
            </Modal>
            {/* </React.Fragment>
            )} */}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { applicants, user, permission } = state;
  return {
    applicants,
    user,
    permission,
  };
}
const connectedHomePage = connect(mapStateToProps)(Settings);
export { connectedHomePage as Settings };
