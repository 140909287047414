import { docsConstants } from "../_constants/docs.constants";

let startState = {
  all: [],
  userDocs: [],
  selected: {},
  shirtSize: [],
  pantSize: [],
  inseamPantSize: [],
  classifications: [],
  race: [],
  crewsList: [],
  stats: [],
  certificates:[],
  isCertificatesLoading: true,
  certificatesError: null

};

export function docs(state = startState, action) {
  switch (action.type) {
    // ------------------------------------------------------------
    case docsConstants.DOCS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    case docsConstants.DOCS_LIST_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_LIST_ALL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_LIST_ALL_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    case docsConstants.DOCS_LIST_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_LIST_FILTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_LIST_FILTER_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    case docsConstants.DOCS_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_TYPE_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    case docsConstants.DOCS_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_CATEGORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_CATEGORY_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    case docsConstants.CATEGORY_DOCS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.CATEGORY_DOCS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.CATEGORY_DOCS_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------

    case docsConstants.DOCS_FORM_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_FORM_GET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_FORM_GET_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------

    case docsConstants.FORM_OPTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.FORM_OPTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.FORM_OPTIONS_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------

    case docsConstants.DOCS_PDF_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_PDF_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_PDF_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------

    case docsConstants.DOCS_STATS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DOCS_STATS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DOCS_STATS_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------

    case docsConstants.GET_CERTIFICATES_REQUEST:
      return {
        ...state,
        isCertificatesLoading: true,
      };
    case docsConstants.GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        isCertificatesLoading: false,
        certificates: action.data
      };
    case docsConstants.GET_CERTIFICATES_FAILURE:
      return {
        ...state,
        isCertificatesLoading: false,
        certificatesError: action.error
      };
    // ------------------------------------------------------------
    case docsConstants.ADD_CERTIFICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.ADD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.ADD_CERTIFICATE_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------
    
    case docsConstants.DELETE_CERTIFICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case docsConstants.DELETE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case docsConstants.DELETE_CERTIFICATE_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    // ------------------------------------------------------------  
    default:
      return state;
  }
}
