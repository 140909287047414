import { resourceActions } from "_actions";
import { config } from "_helpers";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import styles from "./ResourceOverviewCard.module.scss";
import { toast } from "react-toastify";
import { ResourceStatus } from "_helpers/helpers";

const empState = {
  locked: 1,
  guest: 1,
};

const ColorCode = ({ bgColor }) => {
  return (
    <div className={styles.ColorCode}>
      <span style={{ backgroundColor: bgColor }}></span>
    </div>
  );
};

const EmployeeListing = ({ loading, employees }) => {
  return (
    <ul className="emp_list_item">
      <li className="list-header">Name</li>
      {loading && (
        <img
          className="loader"
          alt="Loader"
          src={require("../../assets/images/loader.gif").default}
        />
      )}
      {!loading &&
        employees?.map((emp) => (
          <li key={emp.hash}>
            <div className="employee-list-card">
              <img
                className="profile-picture"
                src={
                  emp.profile_photo
                    ? config.apiUrl + "/" + emp.profile_photo
                    : config.apiUrl + "/" + config.altImg
                }
                alt="profile_pic"
              />
              <p className="width-45">
                {emp.first_name} {emp.last_name}
              </p>
              {emp.is_locked !== empState.locked &&
                emp.is_guest !== empState.guest &&
                emp.unavailable_reason_text && (
                  <p className="width-45">Reason - {emp.unavailable_reason_text}</p>
                )}
            </div>
          </li>
        ))}
    </ul>
  );
};

const RenderEmployeeList = ({ loading, employees, listType }) => {
  const unavailable_emp = employees?.filter(
    (emp) =>
      emp.active === "Unavailable" && emp.is_guest !== 1 && emp?.is_locked !== 1
  );

  const pending_emp = employees?.filter((emp) => emp?.is_guest === 1);

  const locked_emp = employees?.filter((emp) => emp?.is_locked === 1);

  switch (listType) {
    case "Not Committed Employees":
      return (
        <ul>
          <p className="not_committed_title">Unavailable</p>
          <EmployeeListing loading={loading} employees={unavailable_emp} />
          <p className="not_committed_title">Pending</p>
          <EmployeeListing loading={loading} employees={pending_emp} />
          <p className="not_committed_title">Locked</p>
          <EmployeeListing loading={loading} employees={locked_emp} />
        </ul>
      );

    default:
      return <EmployeeListing loading={loading} employees={employees} />;
  }
};

const EmployeesPopup = ({
  title,
  loading,
  employees,
  count,
  listType,
  onClose,
}) => {
  const renderColorCode = () => {
    switch (listType) {
      case "Committed Employees":
        return <ColorCode bgColor={"#808080"} />;
      case "Available & Not Committed Employees":
        return <ColorCode bgColor={"#43A047"} />;
      case "Not Committed Employees":
        return <ColorCode bgColor={"#DC435D"} />;
      case "Locked Employees":
        return <ColorCode bgColor={"#A8A8A8"} />;
      default:
        break;
    }
  };

  return (
    <div className={styles.EmployeesPopup}>
      <div className="header">
        <p>{title}</p>
        <CloseIcon onClick={onClose} />
      </div>
      <div className="employees-list">
        <p className="list-type-name">
          {!loading && (
            <>
              {renderColorCode()} {listType} : {count}
            </>
          )}
        </p>
        <RenderEmployeeList
          employees={employees}
          loading={loading}
          listType={listType}
        />
      </div>
    </div>
  );
};

const ResourceOverviewCard = (props) => {
  const {
    id,
    img,
    title,
    status,
    recentIncident,
    superintendent,
    totalEmployees,
    committed_employees,
    available_not_committed,
    unavailable_not_committed,
  } = props;

  const dispatch = useDispatch();
  const [selectedResource, setSelectedResource] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState("");

  const renderStatusColor = (status) => {
    switch (status) {
      case "Committed":
        return "#1976D2";
      case "Available":
        return "#388E3C";
      case "Not available":
        return "#DC435D";
      default:
        return "#000";
    }
  };

  const handleOutsideClick = () => {
    setSelectedResource(null);
  };

  const onClickResource = () => {
    props.history.push(`/resources/${id}`);
  };

  const fetchEmployees = async (status) => {
    try{
    setLoading(true);
    let data = new FormData();
    data.append("crew_identifier_id", id);
    data.append("active", true);
    data.append("locked", true);
    data.append("guest", true);
    data.append("pagination", false);
    const res = await dispatch(resourceActions.employeeList(data));
    if (res.success) {
      setLoading(false);
      switch (status) {
        case "committed":
          setListType("Committed Employees");
          setFilteredEmployees(
            res?.data?.data?.filter(
              (emp) =>
                emp?.commited_status === true
            )
          );
          return;
        case "available-not-committed":
          setListType("Available & Not Committed Employees");
          setFilteredEmployees(
            res?.data?.data?.filter(
              (emp) =>
                emp?.active === "Available" && emp?.commited_status === false && emp?.is_locked === 0 && emp?.is_guest === 0
            )
          );
          return;
        case "unavailable-not-committed":
          setListType("Not Committed Employees");
          setFilteredEmployees(
            res?.data?.data?.filter(
              (emp) =>  emp?.commited_status === false &&  !( emp?.active === "Available" && emp?.is_locked === 0 && emp?.is_guest === 0)
            )
          );

          return;
        case "locked":
          setListType("Locked Employees");
          res.data.data.filter((emp) => emp?.is_locked === 1);
          return;
        default:
          break;
      }
    }
    else{
      setLoading(false);
      switch (status) {
        case "committed":
          setListType("Committed Employees");
          return;
        case "available-not-committed":
          setListType("Available & Not Committed Employees");
          return;
        case "unavailable-not-committed":
          setListType("Not Committed Employees");
          return;
        case "locked":
          setListType("Locked Employees");
          return;
        default:
          break;
      }

    }
  }
  catch(error){
    setLoading(false);
    toast?.error(error?.message ?? "Something went wrong");
  }
  };

  const onClickChip = (status) => {
    setSelectedResource(id);
    fetchEmployees(status);
  };

  return (
    <div className={styles.ResourceOverviewCard}>
      <div className="crew-details">
        <div className="resource-image" onClick={onClickResource}>
          <img src={img} alt="resource" />
        </div>
        <div className="resource-details">
          <p className="resource-title">{title}</p>
          <p>
            Current Status:{" "}
            <span
              className="detail-item"
              style={{ color: renderStatusColor(ResourceStatus[status]) }}
            >
              {ResourceStatus[status]}
            </span>
          </p>
          <p>
            Recent Incident:{" "}
            <span className="detail-item">{recentIncident}</span>
          </p>
          <p>
            Superintendent:{" "}
            <span className="detail-item">{superintendent}</span>
          </p>
        </div>
      </div>
      <div className="employees-count">
        <div className="employees-total">{totalEmployees} Employees</div>
        <div className="outside-click-wrapper">
          <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <div className="chips-wrapper">
              <div
                className="employee-status-chip"
                onClick={() => onClickChip("committed")}
              >
                <ColorCode bgColor={"#808080"} />
                {committed_employees}
              </div>
              <div
                className="employee-status-chip"
                onClick={() => onClickChip("available-not-committed")}
              >
                <ColorCode bgColor={"#43A047"} />
                {available_not_committed}
              </div>
              <div
                className="employee-status-chip"
                onClick={() => onClickChip("unavailable-not-committed")}
              >
                <ColorCode bgColor={"#DC435D"} />
                {unavailable_not_committed}
              </div>
            </div>

            {selectedResource && (
              <EmployeesPopup
                loading={loading}
                employees={filteredEmployees}
                title={title}
                listType={listType}
                count={filteredEmployees?.length}
                onClose={handleOutsideClick}
              />
            )}
          </OutsideClickHandler>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResourceOverviewCard);
