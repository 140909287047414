import { authHeader, config } from '../_helpers';

export const assignmentStatsService = {
    getAssignemntStatusTypes,
    getAssignemntStatus,
    getAssignemntHistory,
    saveStatus,
    getAssignemntStatusGraphView,
    getAssignemntStatusProgress,
    historyDelete,
    getAssignmentComplexity,
    getAssignmentFireSize,
    getAssignmentFuelType
};

function getAssignemntStatusTypes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(config.apiUrl + '/api/getlist/assignmenttype', requestOptions).then(handleResponse, handleError);
}
function getAssignemntStatus(crewId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(config.apiUrl + '/api/assignment/getstatus?id=' + crewId, requestOptions).then(handleResponse, handleError);
}
function getAssignemntHistory(crewId, pageNum) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(config.apiUrl + '/api/assignment/gethistory?id=' + crewId + '&page=' + pageNum, requestOptions).then(handleResponse, handleError);
}
function historyDelete(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(config.apiUrl + '/api/assignment/deletehistory?id=' + id, requestOptions).then(handleResponse, handleError);
}

function saveStatus(data) {
    const requestOptions = {
        headers: authHeader(),
        method: "POST",
        body: data
    };

    return fetch(config.apiUrl + '/api/assignment/saveincidentprojects', requestOptions).then(handleResponse, handleError);
}

function getAssignemntStatusGraphView(data, pageNum = 0) {


    const requestOptions = {
        headers: authHeader(),
        method: "POST",
        body: data
    };
    let url = (pageNum > 0) ? '/api/assignment/getreportsummary?page=' + pageNum : '/api/assignment/getreportsummary';
    return fetch(config.apiUrl + url, requestOptions).then(handleResponse, handleError);
}
function getAssignemntStatusProgress() {
    const requestOptions = {
        headers: authHeader(),
        method: "GET"
    };
    return fetch(config.apiUrl + '/api/stats/assignment_stats', requestOptions).then(handleResponse, handleError);
}
// 




function getAssignmentComplexity() {
    const requestOptions = {
      headers: authHeader(),
    };
    return fetch(
      config.apiUrl + "/api/getlist/assignmentcomplexity",
      requestOptions
    ).then(handleResponse, handleError);
  }
  
  function getAssignmentFireSize() {
    const requestOptions = {
      headers: authHeader(),
    };
    return fetch(
      config.apiUrl + "/api/getlist/assignmentfiresize",
      requestOptions
    ).then(handleResponse, handleError);
  }
  
  function getAssignmentFuelType() {
    const requestOptions = {
      headers: authHeader(),
    };
    return fetch(
      config.apiUrl + "/api/getlist/assignmentfueltype",
      requestOptions
    ).then(handleResponse, handleError);
  }
  


function handleResponse(response) {
    return new Promise((resolve, reject) => {
        if (response.ok) {
            // return json if it was returned in the response
            var contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
            } else {
                resolve();
            }
        } else {
            // return error message from response body
            response.text().then(text => reject(text));
        }
    });
}

function handleError(error) {
    return Promise.reject(error && error.message);
}

