import { authHeader, config } from "../_helpers";

export const docsService = {
  generateDocs,
  getDocs,
  requestToFillDocs,
  getAllDocs,
  getTORDocs,
  filterDocs,
  saveDoc,
  getDocTypes,
  getUserDocs,
  getForm,
  getFormOptions,
  getDocPdf,
  getDocsStats,
  getDocCategories,
  getCategoryDocs,
  createDocument,
  getExtraDocs,
  uploadPdfToStore,
  submitWarningDoc,
  getCertificates,
  addCertificate,
  deleteCertificate,
  downloadCertificate
};

function generateDocs(rehire = false) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/api/docs/start?rehire=${rehire}`, requestOptions).then(handleResponse, handleError);
}
// Get Docs from user login, without hash
function getDocs() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(config.apiUrl + "/api/docs/get", requestOptions).then(handleResponse, handleError);
}

// Get Extra Docs When Locked
function getExtraDocs(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(config.apiUrl + `/api/docs/getExtraDocs`, requestOptions).then(handleResponse, handleError);
}
// Request to fill Rehire Docs
function requestToFillDocs(hash,doc) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/api/docs/${doc}/enable/${hash}`, requestOptions).then(handleResponse, handleError);
}
// Get Doc Types
function getDocTypes() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(config.apiUrl + "/api/getlist/docstypes", requestOptions).then(handleResponse, handleError);
}

// Get Doc Categories
function getDocCategories() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(config.apiUrl + "/api/getlist/docscategory", requestOptions).then(handleResponse, handleError);
}

// Get Docs in Category/Type{Onboarding,Rehire,Other}
function getCategoryDocs(type) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/api/docs/gettypes?type=${type}`, requestOptions).then(handleResponse, handleError);
}

// Get all docs (multiple docs) by admin
function getAllDocs(params) {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/docs/get/all`);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function getTORDocs(params, type) {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/docs/tor/${type}`);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function getUserDocs(hash) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`${config.apiUrl}/api/docs/get/${hash}`, requestOptions).then(handleResponse, handleError);
}
// not using, replaced with getAllDocs (with params)
function filterDocs(data) {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/docs/get/all`);
  Object.keys(data).forEach((key) => url.searchParams.append(key, data[key]));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}
// get form details by id
function getForm(id) {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/docs/getcontents/${id}`);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}

// save form
function saveDoc(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  return fetch(`${config.apiUrl}/api/docs/update?single_doc=${data.single_doc}&rehire=${data.rehire}`, requestOptions).then(handleResponse, handleError);
}

// create individual form
function createDocument(type, docs_category, userHash) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    }
  };
  let url = `${config.apiUrl}/api/docs/create?type=${type}&docs_category=${docs_category}`
  if (userHash){
    url = url + `&hash=${userHash}`
  }
  return fetch(url, requestOptions).then(handleResponse, handleError);
}
function getFormOptions() {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/getlistprofile`);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function getDocsStats() {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/api/stats/docs_stats`);
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

// get pdf for docs
function getDocPdf(data, slug) {
  // appending queryParams with URL
  let url = new URL(`${config.apiUrl}/docs/pdf/${slug}`);
  Object.keys(data).forEach((key) => url.searchParams.append(key, data[key]));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(url, requestOptions).then(handleResponse, handleError);
}


function uploadPdfToStore(data, url) {
  const requestOptions = {
    method: "PUT",
    headers: {
      'x-amz-acl': 'public-read',
      'Content-Type': 'application/pdf'
    },
    body: data
  };
  return fetch(url, requestOptions).then(
    handleResponse,
    handleError
  );
}

function handleError(error) {
  return Promise.reject(error && error.message);
}

//Verbal warning doc
function submitWarningDoc(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/management/acknowledge", requestOptions).then(
    handleResponse,
    handleError
  );
}

function getCertificates(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/employee/certificate", requestOptions).then(
    handleResponse,
    handleError
  );
}

function addCertificate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/employee/certificate/add", requestOptions).then(
    handleResponse,
    handleError
  );
}

function deleteCertificate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/employee/certificate/delete", requestOptions).then(
    handleResponse,
    handleError
  );
}

function downloadCertificate(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(config.apiUrl + "/api/employee/certificate/download", requestOptions).then(
    handleResponse,
    handleError
  );
}
