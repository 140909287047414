import React from "react";
import { manifestService } from "_services";
import { manifest } from "_reducers/manifest.reducer";

export function AllManifest({ resourceId, onSelect, setIncident }) {
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState("");
  const [data, setData] = React.useState([]);

  const getAllManifest = async () => {
    try {
      let formData = new FormData();
      formData.append("resource_id", resourceId);
      // #1 Get all data without pagination
      formData.append("fire_report_list", 1);
      const response = await manifestService?.manifestList(formData);
      const payload = response?.data?.data ?? [];
      const itemWithMostRecentDate = payload[0];
      setValue(itemWithMostRecentDate ? itemWithMostRecentDate.mid : "")
      setIncident(itemWithMostRecentDate ? itemWithMostRecentDate.incident_name : "");
      setData(payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const generateLabel = (item) => {
    let label = "";
    if (item?.abbreviation) {
      label = item?.abbreviation;
    }
    if (item?.contract_number) {
      label = label + "-" + item?.contract_number;
    }
    if (item?.incident_name) {
      label = label + "-" + item?.incident_name;
    }
    return label;
  };

  React.useEffect(() => {
    getAllManifest();
  }, []);

  React.useEffect(() => {
    onSelect?.(value);
    const manifest = data.find((item) => item.mid == value);
    setIncident?.(manifest ? manifest.incident_name: "" );
  }, [value, loading]);

  return (
    <div>
      <div className="form-group">
        <label htmlFor="exampleFormControlInput1">Select Manifest</label>
        <select
          disabled={loading}
          className="form-control"
          id="exampleFormControlSelect1"
          value={value}
          onChange={(e) => setValue(e?.target?.value)}
        >
          <option value={""}>
            {loading ? "Loading.." : "--Select Manifest--"} 
          </option>
          {data?.map((item) => (
            <option key={item?.mid} value={item?.mid} selected={item?.mid === value}> 
              {generateLabel(item)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default AllManifest;
