import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import { isAffirmativeActionReportLoading } from "_helpers/reduxSelector";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  fullNameClass: ASC_CLASS,
  positionClass: ASC_CLASS,
  raceClass: ASC_CLASS,
  eeoClassificationClass: ASC_CLASS,
  veteranClass: ASC_CLASS,
  disabilityClass: ASC_CLASS,
  document8850Class: ASC_CLASS,
};

const AffirmativeActionReportList = ({ getAffirmativeActionReport, data, lastPage, currentFilters, setSortParams }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("full_name");
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state) => isAffirmativeActionReportLoading(state));
  const [
    {
      fullNameClass,
      positionClass,
      raceClass,
      eeoClassificationClass,
      veteranClass,
      disabilityClass,
      document8850Class,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getAffirmativeActionReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getAffirmativeActionReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected);
  };

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  React.useEffect(() => {
    setPage(0);
  }, [currentFilters]);

  const noDataAvailable = !isLoading && !data?.length;
  return (
    <>
    <div className="history_lists outer_list table__scroll">
      <table className="mobile_hide report_list_width_full long__report tab_hide">
        <thead>
          <tr className="report_list_table_row">
            <th onClick={() => sortList("fullNameClass", fullNameClass, "full_name")}>
              <div className="report_table_header">
                <span>Name</span>
                <span className={fullNameClass} />
              </div>
            </th>
            <th onClick={() => sortList("positionClass", positionClass, "position")}>
              <div className="report_table_header">
                <span>Position</span>
                <span className={positionClass} />
              </div>
            </th>
            <th onClick={() => sortList("raceClass", raceClass, "race")}>
              <div className="report_table_header">
                <span>Race</span>
                <span className={raceClass} />
              </div>
            </th>
            <th onClick={() => sortList("eeoClassificationClass", eeoClassificationClass, "eeo_classification")}>
              <div className="report_table_header">
                <span>EEO Classification</span>
                <span className={eeoClassificationClass} />
              </div>
            </th>
            <th onClick={() => sortList("veteranClass", veteranClass, "veteran")}>
              <div className="report_table_header">
                <span>Vets Status</span>
                <span className={veteranClass} />
              </div>
            </th>
            <th onClick={() => sortList("disabilityClass", disabilityClass, "disability")}>
              <div className="report_table_header">
                <span>Disability Status</span>
                <span className={disabilityClass} />
              </div>
            </th>
            <th onClick={() => sortList("document8850Class", document8850Class, "document8850")}>
              <div className="report_table_header">
                <span>8850</span>
                <span className={document8850Class} />
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td colspan="10">
                <div className="dashboard_expiration_date_no_data">No Data Found</div>
              </td>
            </tr>
          ) : (
            data.map((item, index) => (
              <tr key={index}>
                <td>{item?.full_name ?? "-"}</td>
                <td>{item?.position ?? "-"}</td>
                <td>{item?.race ?? "-"}</td>
                <td>{item?.eeo_classification ?? "-"}</td>
                <td>{item?.veteran ?? "-"}</td>
                <td>{item?.disability ?? "-"}</td>
                <td>{item?.document8850 ?? "-"}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">No Data Found</div>
      ) : (
        <AffirmativeActionReportListMobileView data={data} getAffirmativeActionReport={getAffirmativeActionReport} setSortParams={setSortParams} />
      )}

      
    </div>
    {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AffirmativeActionReportList;

const AffirmativeActionReportListMobileView = ({ data, getAffirmativeActionReport ,setSortParams }) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("full_name");
  const [
    {
      fullNameClass,
      positionClass,
      raceClass,
      eeoClassificationClass,
      veteranClass,
      disabilityClass,
      document8850Class,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getAffirmativeActionReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list tab_show">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div className="list_item" onClick={() => sortList("fullNameClass", fullNameClass, "full_name")}>
              <strong>Name</strong>
              <span className={fullNameClass} />
            </div>
            <div className="list_item">{item?.full_name ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("positionClass", positionClass, "position")}>
              <strong>Position</strong>
              <span className={positionClass} />
            </div>
            <div className="list_item">{item?.position ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("raceClass", raceClass, "race")}>
              <strong>Race</strong>
              <span className={raceClass} />
            </div>
            <div className="list_item">{item?.race ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("eeoClassificationClass", eeoClassificationClass, "eeo_classification")}>
              <strong>EEO Classification</strong>
              <span className={eeoClassificationClass} />
            </div>
            <div className="list_item">{item?.eeo_classification ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("veteranClass", veteranClass, "veteran")}>
              <strong>Vets Status</strong>
              <span className={veteranClass} />
            </div>
            <div className="list_item">{item?.veteran ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("disabilityClass", disabilityClass, "disability")}>
              <strong>Disability Status</strong>
              <span className={disabilityClass} />
            </div>
            <div className="list_item">{item?.disability ?? "-"}</div>
          </div>

          <div className="status_item">
            <div className="list_item" onClick={() => sortList("document8850Class", document8850Class, "document8850")}>
              <strong>8850</strong>
              <span className={document8850Class} />
            </div>
            <div className="list_item">{item?.document8850 ?? "-"}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
