import React, { useEffect, useState } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { config } from "_helpers/config";
import ErrorBoundary from "_components/ErrorBoundary";
import { withRouter } from "react-router-dom";
import styles from "./VerbalWarningAck.module.scss";
import CustomSignaturePad from "Docs/Components/SignaturePad";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions";
import Modal from "react-modal";

const US_DATE_FORMAT = "MM/DD/YYYY";
const APIReqDateFormat = "YYYY-MM-DD";
const momentInstance = moment();

const VerbalWarningAck = ({ adminMode, docDetails, goBack, history }) => {
  const dispatch = useDispatch();
  const signature = useSelector((state) => state.chat.settings.signature);
  const loggedInUserHash = useSelector(
    (state) => state.user?.profile?.data?.hash
  );

  const [doc, setDoc] = useState(docDetails);
  const [docsUserDetails, setDocsUserDetails] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitDate, setSubmitDate] = useState(momentInstance);
  const [sign, setSign] = useState(doc?.signature);
  const [signError, setSignError] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onSubmitClick = () => {
    if (!sign?.length) return setSignError(true);
    setIsSubmitting(true);
    const reqData = new FormData();
    reqData.append("date", moment(submitDate).format(APIReqDateFormat));
    reqData.append("signature", sign);
    reqData.append("doc_id", doc.doc_id);
    reqData.append("user_id", docsUserDetails.id);
    reqData.append("type", doc.type.id);

    dispatch(docsActions.submitWarningDoc(reqData))
      .then((res) => {
        setIsSubmitting(false);
        setSign("");
        setIsConfirmModalOpen(true);
        getCurrentDocDetails()
      })
      .catch((err) => {
        toast.error("Failed to acknowledge the document.");
        throw err;
      });
  };

  const getCurrentDocDetails = async () => {
    window.scrollTo(0, 0);
    setIsFormLoading(true);
    dispatch(docsActions.getForm(docDetails.id)).then((res) => {
      setDoc(res.data.form[0]);
      setDocsUserDetails(res.data.user);
      setIsFormLoading(false);
    });
  }

  useEffect(() => {
    const unListen = history.listen(() => {
      goBack();
    });
    return () => {
      unListen();
    };
  }, [history, goBack]);

  useEffect(() => {
    getCurrentDocDetails()
  }, []);

  const renderConfirmModal = () => {
    return (
      <Modal
        isOpen={isConfirmModalOpen}
        className="manage-modal"
        overlayClassName="manage-modal-overlay"
      >
        <div className="manage-modal-header">
          <h3>Submission Successful</h3>
        </div>
        <div className="manage-modal-body">
          Your acknowledgement has been successfully submitted. We appreciate
          your cooperation and understanding regarding the matter. If you have
          any further questions or concerns, please feel free to reach out to
          the HR team for assistance.
        </div>
        <div className="mange-modal-footer">
          <button
            className="button"
            onClick={()=>setIsConfirmModalOpen(false)}
          >
            Okay
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <ErrorBoundary>
      {renderConfirmModal()}
      <div className={styles.VerbalWarningAckDoc}>
        {isFormLoading && (
          <center id="feedLoader">
            <img
              alt="Loader"
              src={require("../../../assets/images/loader.gif").default}
            />
          </center>
        )}
        {!isFormLoading && (
          <>
            <div className="warning-doc-header">
              <img
                alt="doc-icon"
                src={`${config.assetUrl}icons/doc-title-img.svg`}
                className="warning-doc-title-image"
              />
              <h3>{doc?.type?.type} Acknowledgement</h3>
            </div>
            <div className="warning-doc-body">
              <div className="warning-doc-body-left">
                <table className="custom-table">
                  <tbody>
                    <tr>
                      <td className="table-item-name">Date</td>
                      <td>
                        {moment(doc?.created_at).format(US_DATE_FORMAT) || "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="table-item-name">Type</td>
                      <td>{doc?.type?.type}</td>
                    </tr>
                    <tr>
                      <td className="table-item-name">Notes</td>
                      <td>{doc?.notes}</td>
                    </tr>
                    <tr>
                      <td className="table-item-name">Attachments</td>
                      <td className="warning-doc-attachment-td">
                        <span>
                          <a
                            href={
                              config.apiUrl +
                              `/management/get/file/${doc?.file_hash}?signature=${signature}&hash=${loggedInUserHash}`
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            {doc?.file_name}
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="disclaimer">
                  <p>
                    By signing below, you acknowledge that you have read and
                    understood the information provided regarding the verbal
                    warning. Your signature serves as confirmation that you are
                    aware of the situation and the necessary actions moving
                    forward.
                  </p>
                </div>

                <div className="warning-doc-footer">
                  <div className="acknowledgment-sign">
                    <span className="footer-input-title">Signature</span>
                    <div className="sign-canvas">
                      <CustomSignaturePad
                        disabled={doc?.signature?.length}
                        className="test"
                        error={signError}
                        value={sign || doc?.signature}
                        onSave={(signature) => setSign(signature)}
                      />
                    </div>
                  </div>
                  <div className="acknowledgment-date">
                    <span className="footer-input-title">
                      Date of acknowledgement
                    </span>
                    <DatePicker
                      selected={submitDate}
                      onChange={setSubmitDate}
                      minDate={momentInstance}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      disabled={doc?.signature?.length}
                    />
                  </div>
                </div>
                <button
                  className="button"
                  onClick={onSubmitClick}
                  disabled={doc?.signature?.length}
                >
                  Submit
                  {isSubmitting && (
                    <img
                      alt="Loader"
                      className="btn-loader"
                      src={require("../../../assets/images/loader.gif").default}
                    />
                  )}
                </button>
              </div>
              <div className="warning-doc-body-right" />
            </div>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default withRouter(VerbalWarningAck);

VerbalWarningAck.propTypes = {
  isSingleDoc: PropTypes.bool.isRequired,
  docDetails: PropTypes.object,
  adminMode: PropTypes.bool.isRequired,
};
