import React from "react";
import ReactModal from "react-modal";

const Modal = (props) => {
  const { children, isOpen, className="" } = props;
  return (
      <ReactModal
        isOpen={isOpen}
        style={{width:props.width}}
        // onClose={}
        className={className}
      >
        {children}
      </ReactModal>
  );
};

export default Modal;
