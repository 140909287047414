import React, { forwardRef } from "react";
import styles from "./DateAndTimePicker.module.scss";
import { ReactComponent as Calendar } from "assets/icons/calendar-icon.svg";
import TimePicker from "_components/utils/TimePicker/TimePicker";
import DatePicker from "react-datepicker";

const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div
    className={styles.CustomDatePickerInput}
    onClick={onClick}
  >
    <input
      type="text"
      value={value}
      readOnly
      ref={ref}
    />
    <Calendar className="calendar-icon" />
  </div>
));

const DateAndTimePicker = (props) => {
  const { date, onChangeDate, time, onChangeTime, disabled = false} = props;
  return (
    <div className={`${styles.DateAndTimePicker} date-time-picker`}>
      <DatePicker
        selected={date}
        onChange={onChangeDate}
        showMonthDropdown
        showYearDropdown
        dateFormat="MM/DD/YYYY"
        dropdownMode="select"
        popperClassName=""
        disabled={disabled}
        customInput={<CustomDatePickerInput />}
      />
      <TimePicker
        value={time}
        onChange={onChangeTime}
        className="time-input-wrapper"
        disabled={disabled}
      />
    </div>
  );
};

export default DateAndTimePicker;
