import React from "react";
import { SwitchableTabs } from "_components/utils/Tabs/SwitchableTabs";
import styles from "./SystemManagementSettings.module.scss";
import { SystemNotificationsTabContent } from "Settings/SystemNotificationsTabContent/SystemNotificationsTabContent";

export const SystemManagementSettings = () => {
  return (
    <div className={styles.SystemManagementSettings}>
      <SwitchableTabs
        tab1Name="System Notifications"
        tab1Content={<SystemNotificationsTabContent />}
      />
    </div>
  );
};
