import React, { useState, useEffect } from "react";
import EmployeeResourceFilter from "HomePage/EmployeeResourceFilter";
import { resourceService } from "_services";
import { redirectToPdf } from "_helpers/redirectToPdf";
import AsyncSelect from "react-select/async";
import DropdownBtn from "_components/utils/DropdownBtn";
import { config } from "_helpers";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import CreatableAsyncSelect from "_components/utils/CreatableAsyncSelect/CreatableAsyncSelect";
import { handleErrorResponse } from "_helpers/helpers";

const filters = {
  Employees: false,
  Crew: false,
};

const generalQualifications = {
  Blue: false,
  Red: false,
  Yellow: false,
};

function FireCardContainer() {
  const [listFilters, setListFilters] = useState(filters);
  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState(null);
  const [selectedCrew, setSelectedCrew] = useState(null);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [printOption, setPrintOption] = useState(null);
  const [selectedEmployees,setSelectedEmployees] = useState([])
  const [emailPopup,setEmailPopup] = useState()

  const [listGeneralQualification, setListGeneralQualification] = useState(
    generalQualifications
  );
  const [selectedGeneralQualification, setSelectedGeneralQualification] =
    useState(null);

  const handleFilter = (key) => {
    const newCheckboxValues = { ...filters };
    Object.keys(newCheckboxValues).forEach((checkboxKey) => {
      newCheckboxValues[checkboxKey] = false;
    });

    setListFilters({ ...newCheckboxValues, [key]: true });
    setSelectedFilterCheckbox(key);
    // Reset selected crew when a new checkbox is selected
    setSelectedCrew(null);
  };

  const handleCancelEmailClick = () => {
    setSelectedEmployees([])
    setEmailPopup(false)
    setPrintOption(null);
  }
  const onClickRemoveEmployeeFromMailList = (data) => {
    setSelectedEmployees(selectedEmployees.filter(item=>item.label !== data.label))
  }


  const handleGeneralQualification = (key) => {
    const newCheckboxValues = { ...generalQualifications };
    Object.keys(newCheckboxValues).forEach((checkboxKey) => {
      newCheckboxValues[checkboxKey] = false;
    });

    setListGeneralQualification({ ...newCheckboxValues, [key]: true });
    setSelectedGeneralQualification(key);
  };

  const printFireCards = () => {
    let data = new FormData();
    data.append("type", selectedFilterCheckbox);
  
    if (selectedGeneralQualification && !selectedUser) {
      data.append("color", selectedGeneralQualification);
    }
  
    if (selectedFilterCheckbox === "Crew") {
      data.append("crew_id", selectedCrew);
    }
  
    if (selectedUser) {
      data.append("hash", selectedUser.value);
    }
  
    data.append("print", printOption);

    if(printOption == "email"){
      selectedEmployees?.forEach((employee, index) => {
        data.append(`email[${index}]`, employee.email);
      });
    }
  
    resourceService.printFireCards(data)
      .then((res) => {
        if(printOption == "pdf" ){
        redirectToPdf(res);
        }
        if(res.success){
          toast.success(res.message)
          setEmailPopup(false);
          setPrintOption(null)
          setSelectedEmployees([])
        }
        else{
          handleErrorResponse(res);
        }
      })
      .catch((error) => {
        toast.error(error.message)
      });
  };

  const EmailPopup = () => (
    <ReactModal
      isOpen={emailPopup}
      onClose={() => setEmailPopup(false)}
    >
      <div
        id="statusPopup"
        className="popupModal"
      >
        <div className="modal-head ro-email-modal-head">
          <h4 className="modal-title">Email Fire Cards</h4>
        </div>
        <div className="modal-body ro-modal-body">
          <CreatableAsyncSelect setSelectedEmployees={setSelectedEmployees} selectedEmployees={selectedEmployees} />
          {!!selectedEmployees.length && <div className="mail-maifest-wrapper">
                  <div className="mail-manifest wrapper">
                    <div className="head">
                      <div className="name ro-email-modal-name" style={{fontWeight:"bold"}}>Name</div>
                      <div className="email" style={{fontWeight:"bold"}}>Email</div>
                    </div>
                    {selectedEmployees?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="employee employee_item selected-employee"
                        >
                          <div className="pic image-holder ro-profile-pic-wrapper">
                            {item.profilePic ? (
                              <img
                                src={config.apiUrl + "/" + item.profilePic}
                                onError={(e) =>
                                  (e.target.src = `${
                                    config.apiUrl + "/" + config.altImg
                                  }`)
                                }
                                alt="prof-pic"
                              />
                            ) : item.name ? (
                              <img
                                src={config.apiUrl + "/" + config.altImg}
                                alt="prof-pic"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="name">
                            <span className="">{item.name}</span>
                          </div>
                          <div className="email">{item.email}</div>
                          <div className="close-btn">
                            <img
                              alt="close"
                              src={
                                require("../assets/images/close_button.png")
                                  .default
                              }
                              onClick={() => onClickRemoveEmployeeFromMailList(item)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>}
          <div className="resource_order_form_delete_modal_action">
            <button
              className="button grey"
              onClick={handleCancelEmailClick}
            >
              Cancel
            </button>
            <button
              name="save"
              className="button"
              onClick={printFireCards}
              disabled={!selectedEmployees.length}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );


  const handleSearchUsers = async (text) => {
    let options = [];
    let users = new FormData();
    users.append("keyword", text);
    const response = await resourceService?.searchUser(users);

    response?.data?.forEach((item) => {
      options?.push({
        label: `${item?.first_name} ${item?.last_name}`,
        value: item?.hash,
      });
    });
    return options;
  };

  useEffect(() => {
    if (printOption === "pdf") {
      printFireCards();
      setTimeout(() => {
        setPrintOption("");
      }, 1000);
    } else if (printOption === "email") {
      setEmailPopup(true);
    }
  }, [printOption]);

  return (
    <div className="activity-log">
      <div className="header">
        <div className="report_tbl_head sold_rpt_mbl_flx">
          <h4 className="graph_header">Fire Cards</h4>
          <div className="buttons">
            {((selectedFilterCheckbox == "Employees" &&
              selectedGeneralQualification) ||
              (selectedFilterCheckbox == "Crew" &&
                selectedGeneralQualification &&
                selectedCrew) ||
              (selectedFilterCheckbox == "Employees" && selectedUser)) && (
              <DropdownBtn
                setPrintOption={setPrintOption} // handlePrintOption is your function to handle the selected option
                label={"Fire Cards"}
                options={[
                  { label: "Email", value: "email" },
                  {
                    label: "Print",
                    value: "pdf",
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="white">
        <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
          <div>
            <h3>Filter</h3>
            {Object.keys(listFilters).map((key) => (
              <div
                className="current_qualification_item current_qualification_item_alignment"
                key={key}
              >
                <div className="current_qualification_item_alignment_left verification">
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={selectedFilterCheckbox === key}
                    onChange={() => handleFilter(key)}
                  />
                  <label htmlFor={key}>
                    <span />
                  </label>
                  <span
                    className="wpcf7-list-item-label mr-1"
                    style={{ wordBreak: "break-all" }}
                  >
                    {key}
                  </span>
                </div>
              </div>
            ))}
            {selectedFilterCheckbox === "Employees" && (
              <div style={{ width: 300 }}>
                <h3>Search User</h3>
                <AsyncSelect
                  value={selectedUser}
                  loadOptions={handleSearchUsers}
                  onChange={(selection) => setSelectedUser(selection)}
                  defaultOptions
                  isClearable
                  placeholder="Enter User name or Fire ID"
                  isDisabled={!!selectedGeneralQualification}
                />
              </div>
            )}

            {selectedFilterCheckbox === "Crew" && (
              <div className="mw-200">
                <h3>Select Crew</h3>
                <EmployeeResourceFilter
                  handleResourceTypeChange={(resourceId) =>
                    setSelectedCrew(resourceId)
                  }
                />
              </div>
            )}
          </div>

          <div>
            <h3>General Qualification</h3>
            {Object.keys(listGeneralQualification).map((key) => (
              <div
                className="current_qualification_item current_qualification_item_alignment"
                key={key}
              >
                <div className="current_qualification_item_alignment_left verification">
                  <input
                    type="checkbox"
                    id={key}
                    name={key}
                    checked={selectedGeneralQualification === key}
                    onChange={() => handleGeneralQualification(key)}
                    disabled={selectedUser}
                  />
                  <label htmlFor={key}>
                    <span />
                  </label>
                  <span
                    className="wpcf7-list-item-label mr-1"
                    style={{ wordBreak: "break-all" }}
                  >
                    {key}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <EmailPopup/>
    </div>
  );
}

export default FireCardContainer;
