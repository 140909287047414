import React from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { chatActions } from '../_actions';
import { config } from "../_helpers";

function Spinner() {
    return (
        <React.Fragment>
            <span className="conversation-search-loader user-search">
                <img
                    src={`${config.assetUrl}icons/circle.svg`}
                    className="spinner fa-spin"
                    alt=""
                />
            </span>
        </React.Fragment>
    );
}

class NewMessage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchText:'',
            searchLoader:true
        };

        this.userList = React.createRef();
        this.handleSearch = this.handleSearch.bind(this);
        this.createConversation = this.createConversation.bind(this);
        this.clearSearchText = this.clearSearchText.bind(this);
    }

    searchTimeout = null;

    handleSearch(e){
        this.setState({
            [e.target.name]:e.target.value,
            searchLoader:true
        },()=>{
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(()=>{

                let requestData = new FormData();
                requestData.append('keyword',this.state.searchText);

                this.props.dispatch(chatActions.getUsers(requestData));

            },600)
        })
    }

    clearSearchText(){

        this.setState({
            searchText:''
        },()=>{

            clearTimeout(this.searchTimeout);
            let requestData = new FormData();
            requestData.append('keyword',this.state.searchText);
            this.props.dispatch(chatActions.getUsers(requestData));

        })
    }

    createConversation(user,e){

        let { conversations, admin } = this.props.chat;

        let existingConversation = null;

        if(conversations.data.length){

            existingConversation =  conversations.data.find(conversation =>{
                let { type } = conversation.data;
                let recipients = conversation.recipients || [];
                if(type == 'single' && recipients.some(recipient=>recipient.hash == user.hash)){
                    return conversation;
                }
            })
        }

        if(existingConversation){
            this.props.dispatch(chatActions.getMessages({
                id:existingConversation.id,
                admin_mode:admin.active
            })).then(res => this.props.history.push("/chat"));
        }
        else{
            
            let requestData = new FormData();
            requestData.append('users[]',user.hash);
            requestData.append('type','single')
            this.props.dispatch(chatActions.createConversation(requestData));
        }
        
    }

    componentDidUpdate(oldProps){
        if(oldProps.chat.user != this.props.chat.user){
            this.setState({
                searchLoader:false
            })
            this.userList.scrollTop = 0;
        }
    }

    componentDidMount(){
        this.props.dispatch(chatActions.getConversations());
        this.props.dispatch(chatActions.getUsers());
    }

    render() {

        let path = this.props.match.path;

        let { user } = this.props.chat;

        return (
            <div className="container-fluid">
                <div className="row msg msg-header">
                    <div className="col-md-6">
                        <h1 className="msg-heading">New Message</h1>
                    </div>
                    <div className="col-md-6">
                        <Link to={`/chat`} className="btn msg-cancel-btn pull-right">Cancel</Link>
                    </div>
                </div>
                <div className="row msg">
                    <div className="msg-new new">
                        <div className="container-fluid">
                            <div className="row msg-mob-messageheader">
                                <div className="col-xs-8">
                                    <h3 className="title">New Message</h3>
                                </div>
                                <div className="col-xs-4">
                                    <ul className="admincontrols nav navbar-nav navbar-right">
                                        <li><Link to={`/chat`} className="msg-admincontrol">Cancel</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="new-container container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                   
                                    <form id="addNewMember" onSubmit={ (e)=>{ e.preventDefault() } }>
                                        <h3 className="new-title">Select Members</h3>
                                        <div className="form-group">
                                            <input className="form-control" type="text" name="searchText" id="selectUser" placeholder="Search People" value={this.state.searchText} onChange={this.handleSearch} />
                                            <button onClick={ this.clearSearchText } className="close-inputBtn"><img src={`${config.assetUrl}icons/search-close.svg`}/></button>
                                        </div>
                                    </form>
                                    <div className="group-member-list">
                                        <h3 className="membercount">SELECT RECIPIENT <a href="#" className="groupmember-edit pull-right"></a> </h3>
                                        <div ref={ (el)=>{ this.userList = el; } } className="group-member-list-scroll">
                                        {   user.data.length?
                                            user.data.map((user,index)=>{
                                                return(
                                                    <div onClick={this.createConversation.bind(this,user)} className="msg-users" key={index}>
                                                        <div className="msg-user-pic">
                                                            <img 
                                                                src={config.baseUrl+user.profile_photo} 
                                                                onError={ (e)=>{ e.target.setAttribute("src", config.apiUrl + "/" + config.altImg); } }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="msg-messageData">
                                                            <div className="msg-name">{user.first_name} {user.last_name}</div>
                                                            <div className="msg-title">{user.user_type}</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            this.state.searchLoader?
                                            <Spinner/>
                                            :<div className="no-results">No Results Found</div>
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { chat, user } = state;
    return { chat, user };
}

export default connect(mapStateToProps)(NewMessage);
