import React, { useEffect, useState } from "react";
import styles from "./ApplicantNoteCard.module.scss";
import { ReactComponent as ThreeDots } from "assets/icons/three-dots.svg";
import OutsideClickHandler from "react-outside-click-handler";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { formatDateTime } from "_helpers/helpers";

const NoteOptionsPopup = (props) => {
  const { note, onEditNoteClick, loading, setIsOpen, canEditOrDelete } = props;

  const onDelete = () => {
    if (!canEditOrDelete)
      return toast.error(
        "This note can't be deleted since you are not the author of it!"
      );

    if (loading) return;
    setIsOpen(true);
  };

  const onEdit = () => {
    if(props.toggleEditNoteFromRecent) props.toggleEditNoteFromRecent()
    if (!canEditOrDelete)
      return toast.error(
        "This note can't be edited since you are not the author of it!"
      );

    onEditNoteClick(note);
  };

  return (
    <div className={styles.NoteOptionsPopup}>
      <p onClick={onEdit}>Edit</p>
      <p onClick={onDelete}>Delete</p>
    </div>
  );
};

export const ApplicantNoteCard = (props) => {
  const { note, onEditNoteClick, onDeleteNoteClick, loading, canEditOrDelete } =
    props;
  const [isOpen, setIsOpen] = useState(false);

  const [selectedNote, setSelectedNote] = useState(null);

  const onOptionsClick = (note) => {
    if (selectedNote?.id) return setSelectedNote(null);
    setSelectedNote(note);
  };

  const onOutsideClick = () => {
    onOptionsClick();
  };

  return (
    <div className={styles.ApplicantNoteCard}>
      <ReactModal isOpen={isOpen}>
        <div className="modal-head">
          <h4>Are you sure you want to delete this note?</h4>
        </div>

        <div
          id="remove_applnt"
          className="modal-body"
        >
          <p>
            This item will be deleted immediately. You can't undo this action.
          </p>
          <button
            className="button grey"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={() => onDeleteNoteClick(note)}
          >
            Delete
          </button>
        </div>
      </ReactModal>

      <div className="applicant-note-header">
        <div className="name-time">
          <div className="author-name">{note?.author?.full_name_last_first}</div>
          <div className="note-time">{formatDateTime(note?.updated_at)}</div>
        </div>
        <div className="note-options-icon-wrapper">
          <OutsideClickHandler
            onOutsideClick={onOutsideClick}
            disabled={!selectedNote}
          >
            <div
              className="note-options-icon"
              onClick={() => onOptionsClick(note)}
            >
              <ThreeDots />
              {(note?.id === selectedNote?.id && selectedNote) && (
                <NoteOptionsPopup
                  onEditNoteClick={onEditNoteClick}
                  onDeleteNoteClick={onDeleteNoteClick}
                  setIsOpen={setIsOpen}
                  note={note}
                  loading={loading}
                  canEditOrDelete={canEditOrDelete}
                  toggleEditNoteFromRecent={props.toggleEditNoteFromRecent}
                />
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
      <div className="content">
        <p>{note?.notes}</p>
      </div>
    </div>
  );
};
