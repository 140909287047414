import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { resourceActions } from "_actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function validateEmail(email) {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    return re.test(email);
  }

const CreatableAsyncSelect = ({ setSelectedEmployees, selectedEmployees }) => {
  const [options, setOptions] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  let timeout;

  const dispatch = useDispatch();

  const loadOptions = (searchKeyword, callback) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      const res = await getEmployeeList(searchKeyword);
      setEmployeeList(res)
      const isNewOption = !res?.find(
        (option) => option.email === searchKeyword.toLowerCase()
      )

      if (isNewOption && !res.length) {
        const newOption = { value: searchKeyword, label: searchKeyword, email:searchKeyword };
        setOptions([...res,...options, newOption]);
        callback([...res, newOption]);
      } else {
        callback(res);
      }
    }, 500);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setOptions([...options, newOption]);
  };

  const getEmployeeList = async (inputValue) => {
    const data = new FormData();
    data.append("keyword", inputValue);
    const response = await dispatch(resourceActions.employeeEmailList(data));
    if (response.success) {
      const options = response.data.data
        .map((item) => ({
          value: item.email,
          email: item.email,
          label: `${item.last_name} ${item.first_name}`,
          name: `${item.last_name} ${item.first_name}`,
          profilePic: item.profile_photo,
        }))        
      return options;
    }
  };

  const handleChange = (data) => {
    const isEmailValid = validateEmail(data.value)
    const isAlreadyAdded = selectedEmployees.some(item=>item.value === data.value.toLowerCase())

    if(isAlreadyAdded){
      return toast.info("The email address you are trying to add is already added to the list.")
    }

    if(!isEmailValid){
      setSelectedEmployees([...selectedEmployees]);
      return toast.error("Invalid email address!")
    }
    setSelectedEmployees([...selectedEmployees, data]);
  };

  return (
    <AsyncSelect
      cacheOptions={true}
      loadOptions={loadOptions}
      defaultOptions={employeeList}
      onCreateOption={handleCreateOption}
      placeholder="Search employees or enter an email address"
      options={options}
      value={null}
      onChange={handleChange}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
      isValidNewOption={false}
    />
  );
};

export default CreatableAsyncSelect;
