import React from "react";
import { connect } from "react-redux";
import {
  resourceActions,
  permissionActions,
  manifestActions,
  inventoryActions,
  assignmentStatsActions,
} from "../_actions";
import { authHeader, config } from "../_helpers";
import CircularProgressbar from "react-circular-progressbar";
import "../assets/styles/your-crew.css";
import DatePicker from "react-datepicker";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import moment from "moment";
import Accordian from "_components/Accordian";
import { Link } from "react-router-dom";
import EmployeeList from "Employees/EmployeeList";
import AllManifest from "_components/AllManifest";
import Modal from "_components/utils/Modal/Modal";
import RadioButton from "Docs/Components/RadioButton";
import  FireHistoryEmployeeAssignDragAndDrop from "Resources/FireHistoryEmployeeAssignDragAndDrop";
import { formatDate } from "_helpers/helpers";

const unDefined = 'undefined';

const FIRE_HISTORY_TYPE_SLUG = {
  WITH_MANIFEST: "with_manifest",
  AUTO_ASSIGN_MANIFEST: "auto_assign",
  MANUAL_ASSIGN_MANIFEST: "manual_assign",
}
class YourCrewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.sortYourCrewRef = React.createRef()
    this.state = {
      typeSlug: FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST,
      editMode: false,
      resource_name: "",
      id: "",
      resultData: {},
      vehiclesOption: [],
      manifest: [],
      employeesOption: [],
      permList: [],
      flag: true,
      assignmentStatusOptions: ["Available", "Unavailable", "Committed"],
      currentAssignmentStatus: "Available", //variable to save current assignment status
      tempAssignmentStatus: "", //variable to save assignment status when the user change in the popup , for displaying the form fields based on the selection
      currentProjectDetails: [], //array to store all details about current project
      unavailable_reason_id: "",
      unavailable_reasons: [],
      onDate: moment(),
      offDate: "",
      currentAssignmentDetails: {
        name: "",
        date_off: "",
        date_on: "",
        assignment_type_id: "",
        assignment_type: "",
        shifts: "",
        notes: "",
        id: 0,
      },
      errorMessage: "",
      deleteHistoryOpen: false,
      historyId: "",
      assigmentCategoryOptions: [],
      assigmentStatusHistory: { data: [] },
      incidentProjects: [],
      projectId: null,
      projectName: "",
      projectNote: "",
      projectShift: "",
      tempAssignmentType: "",
      assignmentHistoryId: "",
      inventoryList: [],
      inventoryPage: 1,
      inventoryLastPage: 0,
      inventoryPerPage: 1,
      location: "",
      complexity: "",
      fuel_type: "",
      fire_size: "",
      hotline_shifts: "",
      isCrewCommitted: false,
      isAdminOrSup: false,
    };
    this.keyUpFn = this.keyUpFn.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.deleteHistoryCloseModal = this.deleteHistoryCloseModal.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.editResourceHistory = this.editResourceHistory.bind(this);
    this.selectProject = this.selectProject.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.inventoryPagination = this.inventoryPagination.bind(this);
    this.getInventoryList = this.getInventoryList.bind(this);
    this.getUnavailableReasons = this.getUnavailableReasons.bind(this);
    this.renderTotalShifts = this.renderTotalShifts.bind(this);

  }

  selectProject(e) {
    let id = e.target.value;
    this.setState({
      projectId: id || null,
    });
  }

  handleSelect(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderTotalShifts = (onDate, offDate) => {
    if (offDate && onDate) {
      const diffInDays = offDate.startOf('day').diff(onDate.startOf('day'), 'days') + 1;
      // const diffInDays = offDate.diff(onDate, 'days') + 1;
      this.setState({ projectShift: diffInDays});
      return;      
    } 
    this.setState({ projectShift: ""});      
    return;
  }

  componentDidMount() {
    this.setState({ flag: true });
    this.getUnavailableReasons();
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(assignmentStatsActions.getAssignemntStatusTypes());
    this.props.dispatch(resourceActions.getProjects());

    var self = this;
    fetch(config.apiUrl + "/api/user", {
      headers: authHeader(),
      method: "GET",
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState(
          {
            resource_name: data.data.crew_name,
            id: data.data.crew_identifier_id,
            flag: true,
          },
          function () {
            this.props.dispatch(
              assignmentStatsActions.getAssignemntStatus(self.state.id)
            );
            this.props.dispatch(
              assignmentStatsActions.getAssignemntHistory(self.state.id, 1)
            );
            self.getManifestList();
            let data = "?id=" + self.state.id;
            self.props.dispatch(inventoryActions.getInventoryStats(data));
            this.props
              .dispatch(
                inventoryActions.getPaginatedList({
                  id: this.state.id,
                  page: 1,
                })
              )
              .then((res) => {
                this.setState({
                  inventoryPage: res.current_page,
                  inventoryLastPage: res.last_page,
                  inventoryPerPage: res.per_page,
                });
              });
          }
        );

      

        self.props.dispatch(
          resourceActions.getResourceVehicles(data.data.crew_identifier_id)
        );

        self.getEmployeeList(data)
      
        let data2 = new FormData();
        data2.append("resource_id", data.data.crew_identifier_id);
        // self.props.dispatch(manifestActions.manifestList(data2));
      });

      this.props.dispatch(assignmentStatsActions.getAssignmentComplexity());
      this.props.dispatch(assignmentStatsActions.getAssignmentFireSize());
      this.props.dispatch(assignmentStatsActions.getAssignmentFuelType());
  }


  getEmployeeList(){
    let formData = new FormData();
    formData.append("crew_identifier_id", this.state.id);
    
    formData.append("active", true);
    formData.append("locked", true);
    formData.append("pagination", false);
    formData.append("guest", true);

    this.props.dispatch(resourceActions.employeeList(formData));
  }



  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    var body = document.body;
    body.classList.remove("inv-sidebar-opened");
  }
  deleteHistoryOpenModal = (id) => {
    this.setState({ deleteHistoryOpen: true, historyId: id });
  };

  deleteHistoryCloseModal() {
    this.setState({ deleteHistoryOpen: false, historyId: "" });
  }
  deleteHistory = () => {
    if (this.state.historyId == "") {
      return;
    }
    this.setState({ deleteHistoryOpen: false });
    this.props.dispatch(
      assignmentStatsActions.historyDelete(this.state.historyId)
    );
  };

  getManifestList = (manifestPageNum = 1) => {
    let data2 = new FormData();
    data2.append("resource_id", this.state.id);
    this.props.dispatch(manifestActions.manifestList(data2, manifestPageNum));
  };

  handleScroll(event) {
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight,
      applicationItem = [],
      sortVariable = "";

    if (this.state.sort !== "" && this.state.sort) {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll ? this.state.orderAll : "asc";
    }
    if (y >= contentHeight) {
      let data = this.state.resultData;
      if (data.current_page < data.last_page && this.state.flag !== false) {
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        fetch(data.next_page_url + sortVariable, requestOptions)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            this.setState({ resultData: data.data });

            if (data.data.current_page === data.data.last_page) {
              this.setState({ flag: false });
            }

            var appInf = [];
            data.data.data.map((item, index) => {
              appInf[item.hash] = item.applicant_info;
              this.setState((state) => ({
                applicant_info: { ...state.applicant_info, ...appInf },
              }));

              applicationItem.push(
                <div className="employee_item">
                  <div className="list_item image-holder second_width">
                    <img
                      alt={item.first_name + " " + item.last_name}
                      className={item.active}
                      src={
                        item.profile_photo
                          ? config.apiUrl + "/" + item.profile_photo
                          : config.apiUrl + "/" + config.altImg
                      }
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                  <div className="list_item second_width second_width">
                    <span className={item.colorcode + " color_code"} />
                    <span className="emp_name">
                      {item.first_name + " " + item.last_name}
                    </span>
                  </div>
                  <div className="list_item second_width">{item.email}</div>
                  <div
                    className="list_item sendMail_btn second_width"
                    onClick={this.sendMail.bind(this, item.email)}
                  >
                    Send Mail
                  </div>
                  <div
                    className="list_item second_width"
                    onClick={this.removeList.bind(this, item.email)}
                  >
                    <img
                      alt="close"
                      src={require("../assets/images/close_green.png").default}
                      onError={this.onImageError.bind(this)}
                    />
                  </div>
                </div>
              );
              return applicationItem;
            });
            this.setState({
              applicationItem: [
                ...this.state.applicationItem,
                ...applicationItem,
              ],
            });
          })
          .catch((err) => console.log(err));
      }
    }
  }

  editResourceHistory(item) {
    let assignment = this.state.assigmentStatusHistory.data.find(
      (i) => i.id === item.id
    );

    if (assignment) {
      let {
        id,
        status,
        incident_projects,
        notes,
        unavailable_reason_id,
      } = assignment;

      if (status == 3) {
        this.setState({
          editMode: true,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          tempAssignmentType: incident_projects.assignment_type_id,
          initialStatusId: status,
          projectId: incident_projects.id,
          projectName: incident_projects.name || "",
          projectShift: incident_projects.shifts || "",
          onDate: moment(incident_projects.date_on),
          offDate: incident_projects.date_off ? moment(incident_projects.date_off) : "",
          projectNote: notes || "",
          location:incident_projects.location||"",
          complexity: incident_projects.complexity||"",
          fuel_type: incident_projects.fuel_type||"",
          fire_size: incident_projects.fire_size||"",
          hotline_shifts: incident_projects.hotline_shifts||""
        });
      } else {
        this.setState({
          editMode: true,
          assignmentHistoryId: id,
          tempAssignmentStatus: this.state.assignmentStatusOptions[status - 1],
          initialStatusId: status,
          projectNote: notes || "",
          unavailable_reason_id,
        });
      }

      document.body.className += " " + "inv-sidebar-opened";
    }
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    const {
      vehicles,
      employee,
      permission,
      inventory,
      manifest,
      assignmentStats,
      resource,
    } = nextProps;

    if (permission && permission?.permissions?.data && permission?.permissions?.data.includes("Status Change Resource") ) {
      this.setState({ isAdminOrSup: true });
    }
    if (employee.list) {
      if (employee.list.data) {
        this.setState({ resultData: employee.list.data, flag: false });
      }
    }
    if (manifest && manifest.ManifestList) {
      this.setState({ manifest: manifest });
    }

    var permList = [],
      stats_array = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({ permList });
    }
    if (inventory.stats) {
      if (inventory.stats.data.stats.length > 0) {
        stats_array.push(<h5 key="title">Crew Inventory</h5>);
        inventory.stats.data.stats.map((item, index) => {
          stats_array.push(
            <div className="inventory_item" key={index}>
              <div className="inventory_progress">
                <CircularProgressbar
                  percentage={item.per}
                  textForPercentage={(percentage) => `${item.per}%`}
                  strokeWidth="9"
                />
              </div>
              <div className="inventory_data">
                <p>{item.value}</p>
                <span className="small_grey">
                  {item.total + " Total / " + item.assigned + " Assigned"}
                </span>
              </div>
            </div>
          );
        });
        this.setState({ stats_array });
      }
    }
    if (assignmentStats.applicantStats) {
      let { assignmentStatusOptions } = this.state;
      this.setState({
        currentAssignmentStatus:
          assignmentStatusOptions[
            assignmentStats.applicantStats.data.status - 1
          ],
        currentAssignmentDetails: {
          name: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].name
            : "",
          date_off: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].date_off
            : "",
          date_on: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].date_on
            : "",
          assignment_type_id: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].assignment_type_id
            : "",
          assignment_type: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].assignment_type
            : "",
          shifts: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].shifts
            : "",
          notes: assignmentStats.applicantStats.data.notes,
          id: assignmentStats.applicantStats.data.summary
            ? assignmentStats.applicantStats.data.summary[0].id
            : "",
          location: assignmentStats.applicantStats.data.summary && assignmentStats.applicantStats.data.summary[0].location,
          complexity: assignmentStats.applicantStats.data.summary && assignmentStats.applicantStats.data.summary[0].complexity,
          fuelType: assignmentStats.applicantStats.data.summary && assignmentStats.applicantStats.data.summary[0].fuel_type,
          fireSize: assignmentStats.applicantStats.data.summary && assignmentStats.applicantStats.data.summary[0].fire_size,
          hotlineShifts: assignmentStats.applicantStats.data.summary && assignmentStats.applicantStats.data.summary[0].hotline_shifts,
        },
      });

      if(this.state.currentAssignmentDetails?.date_off){
        const currentDate = new Date();
        this.setState({isCrewCommitted: new Date(this.state.currentAssignmentDetails?.date_off) > currentDate})
      }
      
    }
    if (assignmentStats.types) {
      this.setState({ assigmentCategoryOptions: assignmentStats.types });
    }
    if (assignmentStats.assignmentHistory) {
      this.setState({
        assigmentStatusHistory: assignmentStats.assignmentHistory.data,
      });
    }

    if (assignmentStats.assignmentStatusSaved) {
      if (assignmentStats.assignmentStatusSaved.success) {
        this.props.dispatch(
          assignmentStatsActions.getAssignemntStatus(this.state.id)
        );
        this.getEmployeeList()
        this.props.dispatch(
          assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
        );

        this.setState({
          tempAssignmentStatus: {
            name: "",
            date_off: "",
            date_on: "",
            assignment_type_id: "",
            assignment_type: "",
            shifts: "",
            notes: "",
            id: 0,
          },
        });
      } else {
        var message = assignmentStats.assignmentStatusSaved.message || {},
          err = [];
        if (Object.keys(message).length) {
          for (var key in message) {
            var ele = message[key];
            var _err = err.push(Array.isArray(ele) ? ele.join(",") : ele);
          }
        }

        if (err.length) {
          this.setState({
            errorMessage: err.join(","),
          });
          setTimeout(() => {
            this.setState({
              errorMessage: "",
            });
            this.props.dispatch(
              assignmentStatsActions.resetStatus()
            );
          }, 3000);
        }
      }
    }
    if (assignmentStats.historyDelete) {
      this.props.dispatch(
        assignmentStatsActions.getAssignemntStatus(this.state.id)
      );
      this.props.dispatch(
        assignmentStatsActions.getAssignemntHistory(this.state.id, 1)
      );
    }

    if (resource) {
      if (resource.projects) {
        this.setState({
          incidentProjects: resource.projects,
        });
      }
    }

    if (inventory?.list) {
      this.setState({
        inventoryList: inventory?.list || [],
      });
    }
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  keyUpFn(event) {
    var value = event.target.value;
    clearTimeout(this.state.timeout);
    this.state.timeout = setTimeout(
      function () {
        this.handleSearch(value, false);
      }.bind(this),
      1000
    );
  }

  handleSearch(text, email) {
    let data = new FormData();
    data.append("keyword", text);
    if (!email) {
      data.append("crew_identifier_id", this.state.id);
      this.props.dispatch(resourceActions.employeeList(data));
    } else {
      this.props.dispatch(resourceActions.employeeEmailList(data));
      this.setState({ search: true });
    }
  }

  profile(hash) {
    const { permission } = this.props;

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    if (permList.includes("View User Profile")) {
      this.props.profile(hash);
    }
  }
  /**
   * function to open slider to edit the assignment status
   */
  editStatus = () => {
    this.setState({
      editMode: false,
      tempAssignmentStatus: "",
      tempAssignmentType: "",
      projectId: null,
      projectName: "",
      projectShift: "",
      initialStatusId: null,
      onDate: moment(),
      offDate: "",
      projectNote: "",
      location: "",
      complexity: "",
      fuel_type: "",
      fire_size: "",
      hotline_shifts: ""
    });

    document.body.className += " " + "inv-sidebar-opened";
  };
  /**
   * function to handle the change in assignment status to make the changes in the form based on selected value
   */
  handleAssignmentChange = (event) => {
    this.setState({
      tempAssignmentStatus: event.target.value,
    });
  };

  handleFireHistoryAssignModal = () => {
    switch (this.state?.typeSlug) {
      case FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST:
        this.saveAssignmentChanges()
        this.setState({ enableAssignEmployeeModal: false })
        break;
      case FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST:
        this?.sortYourCrewRef?.current?.toVehicle((res) => {
          if (res?.length) {
            this.setState({ manualAssignManifestIds: res?.map(item => item?.userid), enableAssignEmployeeModal: false }, () => {
              this.saveAssignmentChanges()
            })
          }
        })
        break;
    }
  }

  handleAutoAssignModalClose() {
    this.setState({ enableAssignEmployeeModal: false, typeSlug: null })
  }
  /**
   * function to close slider to edit the assignment status
   */
  closeStatusPopup = () => {
    document.body.classList.remove("inv-sidebar-opened");
    this.setState({
      location: "",
      complexity: "",
      fuel_type: "",
      fire_size: "",
      hotline_shifts: ""
    })
  };

  // get unavailable reasons
  getUnavailableReasons() {
    const _requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    const _url = `${config.apiUrl}/api/getlist/unavailablereason`;
    fetch(_url, _requestOptions).then((response) =>
      response.json().then(
        (res) => {
          this.setState({ unavailable_reasons: res });
        },
        (err) => console.error(err)
      )
    );
  }
  // Submit Assignment Changes
  saveAssignmentChanges = (event) => {
    event?.preventDefault();
    let index = this.state.assignmentStatusOptions.findIndex((x) => x == this.state.tempAssignmentStatus);
    let data = new FormData();
    data.append("status", index + 1);
    data.append("resource_identifier_id", this.state.id);
    data.append("location", this.state.location);
    data.append("complexity", this.state.complexity);
    data.append("fuel_type", this.state.fuel_type);
    data.append("fire_size", this.state.fire_size);
    data.append("hotline_shifts", this.state.hotline_shifts);
    if (this.state.editMode == true) {
      data.append("history_id", this.state.assignmentHistoryId);
    }

    if (this.state.tempAssignmentStatus == "Committed") {
      data.append("assignment_type", this.refs.AssignmentType.value);

      if (this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      }

      if (this.state.editMode == true) {
        if (this.refs.ProjectName) {
          data.append("name", this.refs.ProjectName.value);
          data.append("date_on", moment(this.state.onDate).format("YYYY-MM-DD"));
          if (this.state.offDate) {
            data.append("date_off", moment(this.state.offDate).format("YYYY-MM-DD"));
          }
          data.append("shifts", this.refs.totalShifts.value);
        }
        data.append("notes", this.refs.note.value);
      } else if (this.state.editMode == false && this.state.projectId) {
        // data.append("incident_projects_id", this.state.projectId);
      } else if (this.state.editMode == false) {
        data.append("name", this.refs.ProjectName.value);
        data.append("assignment_type", this.refs.AssignmentType.value);
        data.append("date_on", moment(this.state.onDate).format("YYYY-MM-DD"));
        if (this.state.offDate) {
          data.append("date_off", moment(this.state.offDate).format("YYYY-MM-DD"));
        }
        data.append("shifts", this.refs.totalShifts.value);
        data.append("notes", this.refs.note.value);
      }
    }

    this.state.tempAssignmentStatus === "Unavailable" &&
      data.append("unavailable_reason_id", this.state.unavailable_reason_id);

    if (this.state.tempAssignmentStatus !== "Committed") {
      data.append("notes", this.refs.note.value);
    }


    // Fire history
    if (this.state.tempAssignmentStatus == "Committed") {
      if (this.state?.fireHistoryManifestId) {
        data.append("type_slug", this.state?.typeSlug);
        data.append("manifest_id", this.state?.fireHistoryManifestId);
      }
      else if (this.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST) {
        data.append("type_slug", this.state?.typeSlug);
      }
      else if (this.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST && this?.state?.manualAssignManifestIds?.length) {
        data.append("type_slug", this.state?.typeSlug);
        data.append("employees_fire_report", JSON.stringify(this.state?.manualAssignManifestIds));
      }
      else {
        this.closeStatusPopup(true)
        return this.setState({ enableAssignEmployeeModal: true, typeSlug: FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST })
      }
    }

    this.props.dispatch(assignmentStatsActions.saveStatus(data))
  .then(() => {
    this.props.dispatch(assignmentStatsActions.getAssignemntHistory(this.state.id, 1));
    this.props.dispatch(assignmentStatsActions.getAssignemntStatus(this.state.id, 1));
  })
  .catch((error) => {
    return toast.error(error?.message || 'An error occurred');
  });

    if (this.refs.note) this.refs.note.value = "";

    this.closeStatusPopup();
  };

  getHistoryList = (pageNum) => {
    this.props.dispatch(
      assignmentStatsActions.getAssignemntHistory(this.state.id, pageNum)
    );
  };

  inventoryPagination() {
    if (this.state.inventoryList.length > 0) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.inventoryPage;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.inventoryLastPage
          ? currentPage + 2
          : this.state.inventoryLastPage;
      let nextPage =
        currentPage + 1 > this.state.inventoryLastPage
          ? this.state.inventoryLastPage
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;

      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getInventoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? "active" : "inactive"}
            key={i}
            onClick={() => {
              this.getInventoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (lastPageIndex + 1 < this.state.inventoryLastPage) {
        pages.push(
          <li className="inactive" key={this.state.inventoryLastPage + 1}>
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.inventoryLastPage) {
        pages.push(
          <li
            className="inactive"
            key={this.state.inventoryLastPage}
            onClick={() => {
              this.getInventoryList(this.state.inventoryLastPage);
            }}
          >
            <a>{this.state.inventoryLastPage}</a>
          </li>
        );
      }


      if (pages.length > 0) {
        return (
          <div className="fs-pagination-wrapper-outer custome_old_pagination" key={7}>
            <div className="fs-pagination-wrapper">
              <span
                className={`prev ${prevPage===1 && currentPage ===1 ?"disabled":""}`}
                onClick={() => {
                  this.getInventoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className={`next ${nextPage == this.state.inventoryLastPage?"disabled":""}`}
                onClick={() => {
                  this.getInventoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="applicant_item" key="noEmployees">
          <p>No Inventory Assigned!</p>
        </div>
      );
    }
  }

  getInventoryList(page) {
    let { inventoryLastPage } = this.state;
    if (page && page >= 1 && page <= inventoryLastPage) {
      this.props
        .dispatch(
          inventoryActions.getPaginatedList({ id: this.state.id, page })
        )
        .then((res) => {
          this.setState({
            inventoryPage: res.current_page,
            inventoryLastPage: res.last_page,
            inventoryPerPage: res.per_page,
          });
        });
    }
  }

  render() {
    const { vehicles, employee, permission } = this.props;
    const { persist } = this.props.assignmentStats
    const { complexity, fuelType, fireSize } = persist
    let crew_data = null;
    this.props.assignmentStats &&
      this.props.assignmentStats.applicantStats &&
      (crew_data = this.props.assignmentStats.applicantStats.data);
    const { manifest } = this.state;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    let paginationHistoryHtml = [];
    var vehiclesOption = [];
    var manifestsOption = [];
    let paginationHtml = [];
    var employeesOption = [];

    let { inventoryList, inventoryPage, inventoryPerPage } = this.state;

    // let employeeList = this.state.resultData.data
    //   ? this.state.resultData.data
    //       .sort((employeeA, employeeB) => {
    //         return employeeA.is_locked - employeeB.is_locked;
    //       })
    //       .sort((employeeA, employeeB) => {
    //         return employeeA.is_guest - employeeB.is_guest;
    //       })
    //       .map((employee, index) => {
    //         return {
    //           ...employee,
    //           index,
    //         };
    //       })
    //   : [];


    const availableEmployees = this.state.resultData.data?.filter((employee) => !employee.is_locked && !employee.is_guest)
    const pendingEmployees = this.state.resultData.data?.filter((employee) => employee.is_guest)
    const lockedEmployees = this.state.resultData.data?.filter((employee) => employee.is_locked && !employee.is_guest)


    if (this.state.assigmentStatusHistory.data.length > 0) {
      let pages = [];
      // let totalPages = Math.ceil(x / y);
      let currentPage = this.state.assigmentStatusHistory.current_page;
      let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
      let lastPageIndex =
        currentPage + 2 < this.state.assigmentStatusHistory.last_page
          ? currentPage + 2
          : this.state.assigmentStatusHistory.last_page;
      let nextPage =
        currentPage + 1 > this.state.assigmentStatusHistory.last_page
          ? this.state.assigmentStatusHistory.last_page
          : currentPage + 1;
      let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
      if (currentPage > 3) {
        pages.push(
          <li
            className="inactive"
            key={0}
            onClick={() => {
              this.getHistoryList(1);
            }}
          >
            <a>1</a>
          </li>
        );
      }
      if (currentPage > 4) {
        pages.push(
          <li className="inactive" key={1}>
            <a>. . .</a>
          </li>
        );
      }
      for (let i = startingIndex; i <= lastPageIndex; i++) {
        // getManifestList
        pages.push(
          <li
            className={i == currentPage ? "active" : "inactive"}
            key={i}
            onClick={() => {
              this.getHistoryList(i);
            }}
          >
            <a>{i}</a>
          </li>
        );
      }
      if (
        lastPageIndex + 1 <
        this.state.assigmentStatusHistory.data.last_page
      ) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page + 1}
          >
            <a>. . .</a>
          </li>
        );
      }
      if (lastPageIndex < this.state.assigmentStatusHistory.data.last_page) {
        pages.push(
          <li
            className="inactive"
            key={this.state.assigmentStatusHistory.data.last_page}
            onClick={() => {
              this.getHistoryList(
                this.state.assigmentStatusHistory.data.last_page
              );
            }}
          >
            <a>{this.state.assigmentStatusHistory.data.last_page}</a>
          </li>
        );
      }

      if (pages.length > 0) {
        paginationHistoryHtml.push(
          <div className="fs-pagination-wrapper-outer custome_old_pagination" key={7}>
            <div className="fs-pagination-wrapper">
              <span
                className={`prev ${prevPage===1 && currentPage ===1 ?"disabled":""}`}
                onClick={() => {
                  this.getHistoryList(prevPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
              <ul className="fs-pagination">{pages}</ul>
              <span
                className={`next ${nextPage == this.state.assigmentStatusHistory.data.last_page?"disabled":""}`}
                onClick={() => {
                  this.getHistoryList(nextPage);
                }}
              >
                <img
                  alt="previous"
                  src={require("../assets/icons/next_arrow.svg").default}
                />
              </span>
            </div>
          </div>
        );
      }
    }
    if (vehicles.items) {
      if (vehicles.items.data.length > 0) {
        vehicles.items.data.map((item, index) => {
          vehiclesOption.push(
            <div className="applicant_item" key={index}>
              <div className="basic_info">
                <a
                  href={config.baseUrl + "inventory/inventory-view/" + item.eid}
                  className="vehicleLink"
                >
                  <div className="appln_name">{item.name}</div>
                  <div className="appln_email">
                    {item.user
                      ? item.user.first_name + " " + item.user.last_name
                      : ""}
                  </div>
                  <div className="appln_phone">
                    {item.user ? item.user.drivers_license : ""}
                  </div>
                  <div className="appln_date">{item.vehicle_model_or_make}</div>
                  <div className="appln_area">{item.vehicle_license === unDefined ? "" : item.vehicle_license}</div>
                </a>
              </div>
            </div>
          );
        });
      } else {
        vehiclesOption.push(
          <div className="applicant_item" key="noVehicles">
            <p>No Vehicles added!</p>
          </div>
        );
      }
    }

    if (employee?.list) {
      // if (employee.list.data.data.length > 0) {
      //   employee.list.data.data.map((item, index) => {
      //     employeesOption.push(
      //       <div
      //         className="employee_item"
      //         key={index}
      //         onClick={this.profile.bind(this, item.hash)}
      //       >
      //         <div className="list_item image-holder item_width">
      //           <img
      //             className={item.active}
      //             src={
      //               item.profile_photo
      //                 ? config.apiUrl + "/" + item.profile_photo
      //                 : config.apiUrl + "/" + config.altImg
      //             }
      //             onError={this.onImageError.bind(this)}
      //           />
      //         </div>
      //         <div className="list_item item_width">
      //           <span className={item.colorcode + " color_code"} />
      //           <span className="emp_name">
      //             {item.first_name + " " + item.last_name}
      //           </span>
      //         </div>
      //         <div className="list_item item_width usertype">
      //           {item.user_type}
      //         </div>
      //         <div className="list_item item_width phone">
      //           <a
      //             onClick={event => {
      //               event.stopPropagation();
      //             }}
      //             className="phone_email_link"
      //             href={`tel:${item.phone}`}
      //             target="_top"
      //           >
      //             {formatPhoneNumber(item.phone)}
      //           </a>
      //         </div>
      //         <div className="list_item item_width email">
      //           <a
      //             onClick={event => {
      //               event.stopPropagation();
      //             }}
      //             className="phone_email_link"
      //             href={`mailto:${item.email}`}
      //             target="_top"
      //           >
      //             {item.email}
      //           </a>
      //         </div>
      //       </div>
      //     );
      //   });
      // } else {
      //   employeesOption.push(
      //     <div className="applicant_item" key="noEmployees">
      //       <p>No Employees added!</p>
      //     </div>
      //   );
      // }
    }

    if (manifest.ManifestList) {
      if (manifest?.ManifestList?.data?.data?.length > 0) {
        let pages = [];
        let currentPage = manifest.ManifestList.data.current_page;
        let startingIndex = currentPage - 2 > 0 ? currentPage - 2 : 1;
        let lastPageIndex =
          currentPage + 2 < manifest.ManifestList.data.last_page
            ? currentPage + 2
            : manifest.ManifestList.data.last_page;
        let nextPage =
          currentPage + 1 > manifest.ManifestList.data.last_page
            ? manifest.ManifestList.data.last_page
            : currentPage + 1;
        let prevPage = currentPage - 1 > 0 ? currentPage - 1 : 1;
        if (currentPage > 3) {
          pages.push(
            <li
              className="inactive"
              key={0}
              onClick={() => {
                this.getManifestList(1);
              }}
            >
              <a>1</a>
            </li>
          );
        }
        if (currentPage > 4) {
          pages.push(
            <li className="inactive" key={1}>
              <a>. . .</a>
            </li>
          );
        }
        for (let i = startingIndex; i <= lastPageIndex; i++) {
          // getManifestList
          pages.push(
            <li
              className={i == currentPage ? "active" : "inactive"}
              key={i}
              onClick={() => {
                this.getManifestList(i);
              }}
            >
              <a>{i}</a>
            </li>
          );
        }
        if (lastPageIndex + 1 < manifest.ManifestList.data.last_page) {
          pages.push(
            <li
              className="inactive"
              key={manifest.ManifestList.data.last_page + 1}
            >
              <a>. . .</a>
            </li>
          );
        }
        if (lastPageIndex < manifest.ManifestList.data.last_page) {
          pages.push(
            <li
              className="inactive"
              key={manifest.ManifestList.data.last_page}
              onClick={() => {
                this.getManifestList(manifest.ManifestList.data.last_page);
              }}
            >
              <a>{manifest.ManifestList.data.last_page}</a>
            </li>
          );
        }

        if (pages.length > 0) {
          paginationHtml.push(
            <div className="fs-pagination-wrapper-outer custome_old_pagination" key={7}>
              <div className="fs-pagination-wrapper">
                <span
                  className={`prev ${prevPage===1 && currentPage ===1 ?"disabled":""}`}
                  onClick={() => {
                    this.getManifestList(prevPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/images/previous.svg").default}
                  />
                </span>
                <ul className="fs-pagination">{pages}</ul>
                <span
                  className={`next ${nextPage == this.state.assigmentStatusHistory.data.last_page?"disabled":""}`}
                  onClick={() => {
                    this.getManifestList(nextPage);
                  }}
                >
                  <img
                    alt="previous"
                    src={require("../assets/images/next.svg").default}
                  />
                </span>
              </div>
            </div>
          );
        }

        // this.setState({ paginationHtml });
        manifest.ManifestList.data.data.map((item, index) => {
          manifestsOption.push(
            // <div className="employee_item" key={index} onClick={this.editManifest.bind(this,item.mid)}>
            <div className="manifest_items" key={index}>
              <div className="employee_item">
                <div className="list_item">{item.abbreviation}</div>
                <div className="list_item">{item.contract_number}</div>
                <div className="list_item">{item.incident_name}</div>
                <div className="list_item">{item.resource_number}</div>
                <div className="list_item">{formatDate(item.added_date)}</div>

                {/* <div className="appln_email" onClick={this.profile.bind(this,item.hash)}>Profile View</div> */}
              </div>
            </div>
          );
        });
      } else {
        manifestsOption.push(
          <div className="applicant_item" key="noEmployees">
            <p>No Manifest added!</p>
          </div>
        );
      }
    }
    if (!this.state.flag) {
      // if(employee.list.data){
      return (
        <div className="resource_container your_crew_div">
          <div className="page_title float">
            <h1>{this.state.resource_name}</h1>
          </div>

          <div className="applicants_inner_head crew_outer">
            {/* <div className="inner_head">
                                <input type="text" name="keyword" className="resource_search" placeholder="Search Employees..." onKeyUp={this.keyUpFn.bind(this)}></input>
                            </div>*/}
            <div
              className="inner_container col-lg-12 crew_wrapper"
              id="applicants_inner"
            >
              {this.state.permList.includes("Assignment Status") && (
                <React.Fragment>
                  <div className="assignment-status">
                    {this.state.errorMessage != "" && (
                      <div
                        className="message-display-div alert alert-danger"
                        role="alert"
                      >
                        {this.state.errorMessage}
                      </div>
                    )}

                    <h5>Assignment Status</h5>
                    <div className="status-wrapper">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="status-left-box">
                            <div className="current-status">
                              <h5>Current Status</h5>
                              {this.state.currentAssignmentStatus ==
                                "Committed" && (
                                <div className="committed">
                                  <span className="status-image">
                                    <img
                                      src={
                                        require("../assets/images/committed.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  {this.state.currentAssignmentStatus}
                                </div>
                              )}
                              {this.state.currentAssignmentStatus ==
                                "Available" && (
                                <div className="available">
                                  <span className="status-image">
                                    <img
                                      src={
                                        require("../assets/images/tick.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  {this.state.currentAssignmentStatus}
                                </div>
                              )}
                              {this.state.currentAssignmentStatus ==
                                "Unavailable" && (
                                <div className="unavailable">
                                  <span className="status-image">
                                    <img
                                      src={
                                        require("../assets/images/unavailable.svg")
                                          .default
                                      }
                                    />
                                  </span>
                                  {this.state.currentAssignmentStatus}
                                </div>
                              )}

                              <span
                                className="editor"
                                onClick={this.editStatus}
                              >
                                <img
                                  src={
                                    require("../assets/images/edit.svg").default
                                  }
                                />
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-8">
                          <div className="summary-box">
                            <h5>Summary</h5>
                            {this.state.currentAssignmentStatus ==
                              "Committed" && (
                              <React.Fragment>
                                <p className="shifts">
                                  <strong>Incident Name:</strong>{" "}
                                  {this.state.currentAssignmentDetails.name}
                                </p>

                                <div className="lists-outer-row">
                                  <div className="shifts">
                                    <strong>Total Shifts:</strong>{" "}
                                    {this.state.currentAssignmentDetails.shifts}
                                  </div>
                                  <div className="shifts">
                                    <strong>Location:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .location
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Complexity:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .complexity
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Fuel Type:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .fuelType
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Fire Size:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .fireSize
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Hotline Shifts:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .hotlineShifts
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Type Of Assignment:</strong>{" "}
                                    {
                                      this.state.currentAssignmentDetails
                                        .assignment_type
                                    }{" "}
                                  </div>
                                  <div className="shifts">
                                    <strong>Date On:</strong>{" "}
                                    {
                                      formatDate(this.state.currentAssignmentDetails
                                        .date_on)
                                    }
                                  </div>
                                  <div className="shifts">
                                    <strong>Date Off:</strong>{" "}
                                    {
                                      formatDate(this.state.currentAssignmentDetails
                                        .date_off)
                                    }
                                  </div>
                                </div>
                              </React.Fragment>
                            )}
                            {this.state.currentAssignmentStatus ==
                              "Unavailable" &&
                              crew_data && (
                                <React.Fragment>
                                  <p className="shifts">
                                    <strong>Unavailable Reason:</strong>{" "}
                                    {crew_data.unavailable_reason}
                                  </p>
                                </React.Fragment>
                              )}
                            <p className="shifts">
                              <strong>Notes:</strong>{" "}
                              {this.state.currentAssignmentDetails.notes}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div className="inv-sidemenu-box">
                <div className="change-stat-head">
                  <h5>Change Status</h5>
                </div>
                <div className="change-status-pop">
                  <form onSubmit={this.saveAssignmentChanges} id="assignment-status-form">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">Select Status *</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        value={this.state.tempAssignmentStatus}
                        onChange={this.handleAssignmentChange}
                        required
                      >
                        <option value="">Assignment Status</option>
                        {((!this.state.isCrewCommitted && !this.state.editMode) || (this.state.editMode)) &&(
                        <option value="Committed">Committed</option>
                        )}
                        <option value="Available">Available</option>
                        <option value="Unavailable">Unavailable</option>
                      </select>
                    </div>

                    {/* Committed */}
                    {this.state.tempAssignmentStatus == "Committed" && (
                      <React.Fragment>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Type of Assignment *</label>
                          <select
                            className="form-control"
                            name="tempAssignmentType"
                            value={this.state.tempAssignmentType}
                            ref="AssignmentType"
                            onChange={this.handleSelect}
                            required
                          >
                            <option value="">Type of Assignment</option>
                            {this.state.assigmentCategoryOptions.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.value}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Select Existing Project */}
                        {/* {
                          this.state.initialStatusId !== 3 && 
                          <select 
                            className="form-control"
                            ref="ExistingProject"
                            value={this.state.projectId || ""}
                            onChange={this.selectProject}>
                            <option value="">Choose Existing Project</option>
                            {
                              this.state.incidentProjects
                                .map((item, index) => <option key={index} value={item.id}>{item.name}</option>)
                            }
                          </select>
                        } */}
                        {/* Manifest Selection "Select Manifest" */}
                        {!this.state.editMode && (
                          <AllManifest 
                            resourceId={this.state.id} 
                            onSelect={(manifestId) => {
                            this.setState({ typeSlug: manifestId ? FIRE_HISTORY_TYPE_SLUG?.WITH_MANIFEST : null, fireHistoryManifestId: manifestId })
                            }}
                            setIncident={(incident) => {this.setState({ projectName: incident})}}
                          />
                        )}
                        {/* End */}
                        {(this.state.projectId == null ||
                          (this.state.editMode == true && this.state.initialStatusId == 3)) && (
                          <div>
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">Incident Name *</label>
                              <input
                                required
                                ref="ProjectName"
                                name="projectName"
                                value={this.state.projectName}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">Location </label>
                              <input
                                ref="Location"
                                name="location"
                                value={this.state.location}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Complexity</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="complexity"
                                value={this.state.complexity}
                                onChange={this.handleChange}
                              >
                                <option value="">Choose Complexity Level</option>

                                {complexity.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Fuel Type</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="fuel_type"
                                value={this.state.fuel_type}
                                onChange={this.handleChange}
                              >
                                <option value="">Choose Fuel Type</option>

                                {fuelType.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}- {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Fire Size</label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="fire_size"
                                value={this.state.fire_size}
                                onChange={this.handleChange}
                              >
                                <option value="">Choose Fire Size</option>

                                {fireSize.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}- {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* {this.state.editMode == true && ( */}
                            <div className="form-group name-list-wrapper">
                              <label htmlFor="exampleFormControlInput1">Hotline Shifts </label>
                              <input
                                ref="Location"
                                name="hotline_shifts"
                                value={this.state.hotline_shifts}
                                onChange={this.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                            {/* )} */}
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Date On</label>
                              <span className="date-outer">
                                <span
                                  onClick={() => {
                                    this.refs.dateon.setFocus();
                                  }}
                                  className="datepicker-image"
                                >
                                  <img src={require("../assets/images/icon_calendar.svg").default} />
                                </span>
                                <DatePicker
                                  className="txt "
                                  ref="dateon"
                                  id="Dateon"
                                  name="Dateon"
                                  selected={this.state.onDate}
                                  onChange={(date) => {
                                    this.setState({ onDate: date }, () => {
                                      this.renderTotalShifts(this.state.onDate, this.state.offDate);
                                    });
                                    if (date > this.state.offDate) {
                                      this.setState({ offDate: date });
                                    }
                                  }}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Date Off</label>
                              <span className="date-outer">
                                <span
                                  onClick={() => {
                                    this.refs.dateoff.setFocus();
                                  }}
                                  className="datepicker-image"
                                >
                                  <img src={require("../assets/images/icon_calendar.svg").default} />
                                </span>
                                <DatePicker
                                  className="txt "
                                  ref="dateoff"
                                  id="Dateoff"
                                  name="assignDate"
                                  selected={this.state.offDate}
                                  onChange={(date) => {
                                    this.setState({ offDate: date }, () => {
                                      this.renderTotalShifts(this.state.onDate, this.state.offDate);
                                    });
                                  }}
                                  minDate={this.state.onDate}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select Date"
                                />
                              </span>
                            </div>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlInput1">Total Shifts</label>
                              <input
                                type="number"
                                name="projectShift"
                                value={this.state.projectShift}
                                disabled
                                ref="totalShifts"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {/* Unavailable */}
                    {this.state.tempAssignmentStatus === "Unavailable" && (
                      <React.Fragment>
                        <div className="form-group">
                          <label htmlFor="unavailable_reason">Unavailable Reason *</label>
                          <select
                            className="form-control"
                            id="unavailable_reason_id"
                            name="unavailable_reason_id"
                            value={this.state.unavailable_reason_id}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="">-no reason-</option>
                            {this.state.unavailable_reasons.map((reason) => {
                              return (
                                <option value={reason.id} key={reason.id}>
                                  {reason.value}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </React.Fragment>
                    )}

                    {(this.state.projectId == null || this.state.editMode == true) && (
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">Notes</label>
                        <textarea
                          ref="note"
                          name="projectNote"
                          value={this.state.projectNote}
                          onChange={this.handleChange}
                          className="form-control"
                          rows="3"
                        ></textarea>
                      </div>
                    )}
                    {this.state.editMode && (
                      <Link className="green" to={{
                        pathname: `/employee-fire-history/${this.state?.assignmentHistoryId}`, state: {
                          resourceId: this.state.id, nav:"crew",
                        }
                      }}>
                        Edit Employee Mapping
                      </Link>
                    )}
                    <div className="submit-area" style={{ marginTop: 15 }}>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      <button
                        onClick={(event) => {
                          this.closeStatusPopup();
                          event.preventDefault();
                        }}
                        className="btn btn-primary cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>

                  {permList.includes("List Resource Manifests") && (
                    <Accordian
                      title="Manifest"
                      persistKey="my_crew_manifest_list"
                    >
                      <div className="manifest_list outer_list">
                        <div className="list_header">
                          <div className="list_item_head">Contract Type</div>
                          <div className="list_item_head">Contract Number</div>
                          <div className="list_item_head">Incident Name</div>
                          <div className="list_item_head">Resource Number</div>
                          <div className="list_item_head">Date Added</div>
                        </div>
                        {manifestsOption}
                        {paginationHtml}
                      </div>
                    </Accordian>
                  )}

                  {this.state.assigmentStatusHistory.data.length > 0 && (
                    <Accordian
                      title="History"
                      persistKey="my_crew_inventory_list"
                    >
                      <div className="history_lists outer_list ">
                        <div className="mobile_hide">
                          <div className="list_header">
                            <div className="list_item_head">Status</div>
                            <div className="list_item_head">
                              Type of Assignment
                            </div>
                            <div className="list_item_head">Incident Name</div>
                            <div className="list_item_head">Location</div>
                            <div className="list_item_head">Complexity</div>
                            <div className="list_item_head">Fuel Type</div>
                            <div className="list_item_head">Fire Size</div>
                            <div className="list_item_head">Hotline Shifts</div>
                            <div className="list_item_head">Date On</div>
                            <div className="list_item_head">Date Off</div>

                            <div className="list_item_head list_item_head_trash"></div>
                            <div className="list_item_head list_item_head_trash"></div>
                          </div>
                          <div className="history_items ">
                            {this.state.assigmentStatusHistory.data.map(
                              (item, index) => (
                                <div
                                  className="status_item your_crew"
                                  key={index}
                                >
                                  <div className="list_item">
                                    {
                                      this.state.assignmentStatusOptions[
                                        item.status - 1
                                      ]
                                    }
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.assignment_type
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.name
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.location
                                      ? item.incident_projects.location
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.complexity
                                      ? item.incident_projects.complexity
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fuel_type
                                      ? item.incident_projects.fuel_type
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fire_size
                                      ? item.incident_projects.fire_size
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.hotline_shifts
                                      ? item.incident_projects.hotline_shifts
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? formatDate(item.incident_projects.date_on)
                                      : "-"}
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? formatDate(item.incident_projects.date_off)
                                      : "-"}
                                  </div>
                                  {/* <div className="list_item note_item">
                                    <div className="notes_ellipsis">
                                      {item.notes}
                                    </div>
                                    <div className="note_full">
                                      {item.notes}
                                    </div>
                                  </div> */}
                                 {this.state.isAdminOrSup && (
                              <div className="list_item list_item_trash">
                                <a
                                  href="#"
                                  onClick={() => {
                                    this.editResourceHistory(item);
                                  }}
                                >
                                  <img src={require("../assets/images/edit-black.svg").default} />
                                </a>
                              </div>
                            )}
                            {this.state.isAdminOrSup && (
                              <div className="list_item list_item_trash">
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    this.deleteHistoryOpenModal(item.id);
                                    event.preventDefault();
                                  }}
                                >
                                  <img alt="" src={require("../assets/images/trash.svg").default} />
                                </a>
                              </div>
                            )}
                                </div>
                              )
                            )}
                          </div>
                          {paginationHistoryHtml}
                        </div>
                      </div>
                      {this.state.assigmentStatusHistory.data.map(
                        (item, index) => (
                          <React.Fragment key={index}>
                            <hr></hr>
                            <div className="for_mobile_history_list">
                              <div className="history_items">
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Status</strong>
                                  </div>
                                  <div className="list_item">
                                    {
                                      this.state.assignmentStatusOptions[
                                        item.status - 1
                                      ]
                                    }
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Type of Assignment</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.assignment_type
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Incident Name</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.name
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Location</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.location
                                      ? item.incident_projects.location
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Complexity</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.complexity
                                      ? item.incident_projects.complexity
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Fuel Type</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fuel_type
                                      ? item.incident_projects.fuel_type
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Fire Size</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.fire_size
                                      ? item.incident_projects.fire_size
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Hotline Shifts</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects &&
                                    item.incident_projects.hotline_shifts
                                      ? item.incident_projects.hotline_shifts
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Date On</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.date_on
                                      : "-"}
                                  </div>
                                </div>
                                <div className="status_item">
                                  <div className="list_item">
                                    <strong>Date Off</strong>
                                  </div>
                                  <div className="list_item">
                                    {item.incident_projects
                                      ? item.incident_projects.date_off
                                      : "-"}
                                  </div>
                                </div>
                                {/* <div className="status_item">
                                  <div className="list_item">
                                    <strong>Notes</strong>
                                  </div>
                                  <div className="list_item">{item.notes}</div>
                                </div> */}
                                 <div className="status_item">
                            <div className="list_item">
                            {this.state.isAdminOrSup && (
                              <div className="list_item list_item_trash">
                                <a
                                  href="#"
                                  onClick={() => {
                                    this.editResourceHistory(item);
                                  }}
                                >
                                  <img src={require("../assets/images/edit-black.svg").default} />
                                </a>
                              </div>
                            )}
                           
                            </div>
                            <div className="list_item">
                            {this.state.isAdminOrSup && (
                              <div className="list_item list_item_trash">
                                <a
                                  href="#"
                                  onClick={(event) => {
                                    this.deleteHistoryOpenModal(item.id);
                                    event.preventDefault();
                                  }}
                                >
                                  <img alt="" src={require("../assets/images/trash.svg").default} />
                                </a>
                              </div>
                            )}
                            </div>
                          </div>
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      )}
                      <div className="for_mobile_history_list">
                        {paginationHistoryHtml}
                      </div>
                    </Accordian>
                  )}
                </React.Fragment>
              )}

              {permList.includes("Inventory") && (
                <Accordian
                  title="Inventory"
                  persistKey="resource_inventory_list"
                >
                  <div className="employee_list outer_list">
                    <div className="list_header">
                      <div className="list_item_head image-holder">
                        Inventory
                      </div>
                      <div className="list_item_head">Item Name</div>
                      <div className="list_item_head">Cost</div>
                      <div className="list_item_head">Assigned To</div>
                    </div>
                    {inventoryList?.length
                      ? inventoryList?.map((item, index) => {
                          return (
                            <div className="empployeeItem" key={index}>
                              <div className="emp_link_resource crew_wrapper resource_details bg-fill">
                                <div className="employee_item ">
                                  <div className="list_item image-holder">
                                    <span className="pos_counter">
                                      {(inventoryPage - 1) * inventoryPerPage +
                                        index +
                                        1}
                                    </span>
                                    <img
                                      alt=""
                                      className={`${
                                        item.user && item.user.hash
                                          ? "Available"
                                          : ""
                                      }`}
                                      src={
                                        item.pic
                                          ? config.s3BucketUrl + "/" + item.pic
                                          : config.apiUrl + "/" + config.altImg
                                      }
                                      onError={this.onImageError.bind(this)}
                                    />
                                  </div>
                                  <div className="list_item item_width">
                                    <Link
                                      className="link"
                                      to={`/inventory/inventory-view/${item.id}`}
                                    >
                                      {item.unique_id}
                                    </Link>
                                  </div>
                                  <div className="list_item item_width">
                                    {item.cost || "--"}
                                  </div>
                                  <div className="list_item item_width">
                                    {item.user ? (
                                      <Link
                                        className="link"
                                        to={`/resource-profile/${item.user.hash}/profile`}
                                      >
                                        {item.user.first_name +
                                          " " +
                                          item.user.last_name}
                                      </Link>
                                    ) : (
                                      "--"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                    {this.inventoryPagination()}
                  </div>
                </Accordian>
              )}

              {permList.includes("Inventory") && (
                <div className="crew_inventory">{this.state.stats_array}</div>
              )}

              {permList.includes("List Crew - Manifests") && (
                <Accordian title="Vehicles" persistKey="my_crew_vehicles_list">
                  <div className="vehicle_list outer_list">
                    <div className="">
                      <div className="list_header">
                        <div className="list_item_head">Unique Name</div>
                        <div className="list_item_head">Driver</div>
                        <div className="list_item_head">Driver License</div>
                        <div className="list_item_head appln_date">
                          Vehicle Make/Model
                        </div>
                        <div className="list_item_head">Vehicle License</div>
                      </div>
                      {vehiclesOption}
                    </div>
                  </div>
                </Accordian>
              )}

              {/* <Accordian title="Employees" persistKey="my_crew_employee_list">
                <div
                  className="employee_list outer_list"
                  onScroll={this.handleScroll}
                >
             I'll be back after breakfast :fries:      <div className="list_header">
                    <div className="list_item_head image-holder">Employee</div>
                    <div className="list_item_head">Name</div>
                    <div className="list_item_head usertype">Title</div>
                    <div className="list_item_head phone">Phone #</div>
                    <div className="list_item_head email">Email</div>
                  </div>
                  {employeesOption}
                </div>
              </Accordian> */}

              <Accordian
                title="Employees Available"
                persistKey="my_crew_available_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  permList={permList}
                  className="available"
                  resourceId={this.state.id}
                  employeeList={availableEmployees}
                  startIndex={1}
                  profile={this.profile.bind(this)}
                />
              </Accordian>

              <Accordian
                title="Pending Employees"
                persistKey="my_crew_guest_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  className="locked"
                  type="guest"
                  permList={permList}
                  resourceId={this.state.id}
                  employeeList={pendingEmployees}
                  startIndex={availableEmployees.length + 1}
                  profile={this.profile.bind(this)}
                />
              </Accordian>

              <Accordian
                title="Employees Locked"
                persistKey="my_crew_locked_employee_list"
              >
                <EmployeeList
                  {...this.props}
                  permList={permList}
                  className="locked"
                  resourceId={this.state.id}
                  employeeList={lockedEmployees}
                  startIndex={
                    pendingEmployees.length + availableEmployees.length + 1
                  }
                  profile={this.profile.bind(this)}
                />
              </Accordian>
            </div>
          </div>
          <ReactModal
            isOpen={this.state.deleteHistoryOpen}
            onClose={this.deleteHistoryCloseModal.bind(this)}
            overlayClassName="deletetPopup"
          >
            {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.deleteOpen} onClose={this.deleteCloseModal.bind(this)} little>*/}
            <div id="deletePopup">
              <div className="modal-head">
                <h4 className="modal-title">Confirm</h4>
                <img
                  alt="close"
                  className="closeModal"
                  onClick={this.deleteHistoryCloseModal.bind(this)}
                  src={require("../assets/images/close_green.png").default}
                />
              </div>

              <div className="modal-body">
                <div className="status_step step1">
                  <label>Do you really want to remove this History?</label>
                  <br />
                  <div className="btn_list_popup">
                    <button
                      className="button grey"
                      onClick={this.deleteHistoryCloseModal.bind(this)}
                    >
                      Cancel
                    </button>
                    <button className="button" onClick={this.deleteHistory}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
          <Modal
          isOpen={this?.state?.enableAssignEmployeeModal}
          className="SignatureModal fire_history_assign_modal"
          contentLabel="Example Modal"
        >
          <section>
            <div className="modal-header">
              <p className="sign_close">Action Required.</p>
              <div className="close-btn" onClick={()=>this.handleAutoAssignModalClose()}>
                <span>&times;</span>
              </div>
            </div>
            <hr />
            <div style={{ padding: "0px 15px" }}>
              <RadioButton checked={this?.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST} value={FIRE_HISTORY_TYPE_SLUG?.AUTO_ASSIGN_MANIFEST} onChange={(e) => this.setState({ typeSlug: e?.target?.value })} label={"Auto Assign Employees"} />
              <RadioButton checked={this?.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST} value={FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST} onChange={(e) => this.setState({ typeSlug: e?.target?.value })} label={"Assign employees Manually"} />
              {this?.state?.typeSlug === FIRE_HISTORY_TYPE_SLUG?.MANUAL_ASSIGN_MANIFEST && (
                <div>
                  <FireHistoryEmployeeAssignDragAndDrop resourceId={this.state.id} ref={this.sortYourCrewRef} />
                </div>
              )}
            </div>
            <div>
              <div style={{ display: "flex", justifyContent: "end", padding: 20 }}>
                <button
                  onClick={(event) => {
                    this.handleAutoAssignModalClose();
                    event.preventDefault();
                  }}
                  className="btn  cancel"
                  style={{ marginRight: 5, backgroundColor: "#f0f0f0", color: "#202020" }}
                >
                  Cancel
                </button>
                <button type="submit" className="button green" onClick={(e) => this.handleFireHistoryAssignModal(e)}>
                  Submit
                </button>
              </div>
            </div>
          </section>
        </Modal>
        </div>
      );
      // }
    }
    return (
      <div className="resource_container your_crew_div">
        <div className="page_title float">
          <h1>{this.state.resource_name}</h1>
        </div>

        <div className="applicants_inner_head crew_outer inner_container">
          <div className="inner_head">
            {/*<input type="text" name="keyword" className="resource_search" placeholder="Search Employees..." onKeyUp={this.keyUpFn.bind(this)}></input>*/}
          </div>
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img alt="Loader" src={require("../assets/images/loader.gif").default} />
          </center>
        </div>
      </div>
    );
  }
}

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

function mapStateToProps(state) {
  const {
    vehicles,
    employee,
    permission,
    manifest,
    inventory,
    assignmentStats,
    resource,
  } = state;
  return {
    vehicles,
    employee,
    permission,
    manifest,
    inventory,
    assignmentStats,
    resource,
  };
}

const connectedYourCrew = connect(mapStateToProps)(YourCrewDetails);
export { connectedYourCrew as YourCrewDetails };
