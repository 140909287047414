import React from "react";
import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "../assets/styles/crew-assignment-status.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { docsActions } from "_actions";
import { data } from "jquery";

const params = {
  tor_doc: 23,
  other_docs: 3,
  count: "TOR",
};

const TORWidget = ({ toggle, widgetid, visibility }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState("");

  const fetchPendingDocuments = async () => {
    let reqData = {
      doc_types: params?.tor_doc,
      count: params?.count,
      doc_category: params?.other_docs,
    };
    const res = await dispatch(docsActions.getAllDocs(reqData));
    if (res.success) {
      setCount(res?.data);
    }
  };

  useEffect(() => {
    fetchPendingDocuments();
  }, []);

  return (
    <div className="dashboard_expiration_date_outer">
      <div className="dashboard_expiration_date inner_head">
        <h2>
          Time Off Requests<span className="drag-icon-controller"></span>
        </h2>
        <span
          className="toggle-controller"
          aria-owns="tor-stats"
          onClick={(event) => {
            toggle(event, widgetid);
          }}
        ></span>
      </div>
      <div
        className="employee_stats_details assignment_stats-details"
        aria-expanded={visibility == 1 ? "true" : "false"}
        id="tor-stats"
      >
        <div>
          <h4>Requests Pending Approvals:</h4>
          {count ? (
            <div>
              <h3>{count}</h3>
            </div>
          ) : (
            <div className="dashboard_expiration_date_no_data">
              <img
                className="loader"
                alt="Loader"
                src={require("../assets/images/loader.gif").default}
              />
            </div>
          )}
        </div>
        <span className="view_tor_button">
          <Link to={`/tor-pending-docs`}>View TOR Documents</Link>
        </span>
      </div>
    </div>
  );
};

export default withRouter(TORWidget);
