import { manifestConstants } from "../_constants";
import { manifestService } from "../_services";

export const manifestActions = {
  manifestType,
  getUsers,
  getVehicles,
  getSuperintendent,
  manifestList,
  saveManifest,
  printManifest,
  emailManifest,
  manifestDetails,
  updateManifest,
  deleteManifest,
  dispatchMailList,
  getAllResourceOrderForm,
};

function manifestType() {
  return dispatch => {
    dispatch(request());

    manifestService
      .manifestType()
      .then(
        manifest_type => dispatch(success(manifest_type)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETTYPE_REQUEST };
  }
  function success(manifest_type) {
    return { type: manifestConstants.GETTYPE_SUCCESS, manifest_type };
  }
  function failure(error) {
    return { type: manifestConstants.GETTYPE_FAILURE, error };
  }
}

function getUsers(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(request(data));
      manifestService
        .getUsers(data)
        .then(
          crews =>{
            dispatch(success(crews))
            resolve(crews)
          } ,
          error =>{
            dispatch(failure(error))
            reject(error)
          } 
        );
    })
  };

  function request() {
    return { type: manifestConstants.GETUSERS_REQUEST };
  }
  function success(crews) {
    return { type: manifestConstants.GETUSERS_SUCCESS, crews };
  }
  function failure(error) {
    return { type: manifestConstants.GETUSERS_FAILURE, error };
  }
}

function dispatchMailList(){
  return dispatch => {

    dispatch(request());
    manifestService
      .dispatchMailList()
      .then(
        mailList => dispatch(success(mailList)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETDISPATCHMAILLIST_REQUEST };
  }
  function success(mailList) {
    return { type: manifestConstants.GETDISPATCHMAILLIST_SUCCESS, mailList:mailList.data };
  }
  function failure(error) {
    return { type: manifestConstants.GETDISPATCHMAILLIST_FAILURE, error };
  }
}

function getVehicles(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .getVehicles(data)
      .then(
        vehicleList => dispatch(success(vehicleList)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETVEH_REQUEST };
  }
  function success(vehicleList) {
    return { type: manifestConstants.GETVEH_SUCCESS, vehicleList };
  }
  function failure(error) {
    return { type: manifestConstants.GETVEH_FAILURE, error };
  }
}

function getSuperintendent(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .getSuperintendent(data)
      .then(
        superintendent => dispatch(success(superintendent)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETSUPERINTENDENT_REQUEST };
  }
  function success(superintendent) {
    return {
      type: manifestConstants.GETSUPERINTENDENT_SUCCESS,
      superintendent
    };
  }
  function failure(error) {
    return { type: manifestConstants.GETSUPERINTENDENT_FAILURE, error };
  }
}

function manifestList(data, pageNum = 0) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .manifestList(data, pageNum)
      .then(
        ManifestList => dispatch(success(ManifestList)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETMANIFESTLIST_REQUEST };
  }
  function success(ManifestList) {
    return { type: manifestConstants.GETMANIFESTLIST_SUCCESS, ManifestList };
  }
  function failure(error) {
    return { type: manifestConstants.GETMANIFESTLIST_FAILURE, error };
  }
}

function saveManifest(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .saveManifest(data)
      .then(
        manifest => dispatch(success(manifest)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.SAVEMANIFEST_REQUEST };
  }
  function success(manifest) {
    return { type: manifestConstants.SAVEMANIFEST_SUCCESS, manifest };
  }
  function failure(error) {
    return { type: manifestConstants.SAVEMANIFEST_FAILURE, error };
  }
}

function printManifest(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .printManifest(data)
      .then(
        manifest => dispatch(success(manifest)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.PRINTMANIFEST_REQUEST };
  }
  function success(manifest) {
    return { type: manifestConstants.PRINTMANIFEST_SUCCESS, manifest };
  }
  function failure(error) {
    return { type: manifestConstants.PRINTMANIFEST_FAILURE, error };
  }
}

function emailManifest(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .emailManifest(data)
      .then(
        manifestEmail => dispatch(success(manifestEmail)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.EMAILMANIFEST_REQUEST };
  }
  function success(manifestEmail) {
    return { type: manifestConstants.EMAILMANIFEST_SUCCESS, manifestEmail };
  }
  function failure(error) {
    return { type: manifestConstants.EMAILMANIFEST_FAILURE, error };
  }
}

function manifestDetails(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .manifestDetails(data)
      .then(
        manifest => dispatch(success(manifest)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.GETMANIFEST_REQUEST };
  }
  function success(manifest) {
    return { type: manifestConstants.GETMANIFEST_SUCCESS, manifest };
  }
  function failure(error) {
    return { type: manifestConstants.GETMANIFEST_FAILURE, error };
  }
}

function updateManifest(data) {
  return dispatch => {
    dispatch(request(data));

    manifestService
      .updateManifest(data)
      .then(
        manifest => dispatch(success(manifest)),
        error => dispatch(failure(error))
      );
  };

  function request() {
    return { type: manifestConstants.SAVEMANIFEST_REQUEST };
  }
  function success(manifest) {
    return { type: manifestConstants.SAVEMANIFEST_SUCCESS, manifest };
  }
  function failure(error) {
    return { type: manifestConstants.SAVEMANIFEST_FAILURE, error };
  }
}


function deleteManifest(data, data2) {
  return dispatch => {
    dispatch(request(data));

    manifestService.deleteManifest(data).then(
      manifest => {
        var toast = document.getElementById("manifest-delete-popup");
        toast.classList.add("fs-visible");
        setTimeout(() => {
          toast.classList.remove("fs-visible");
        }, 5000);
        dispatch(manifestList(data2));
      },
      error => dispatch(failure(error))
    );
  };

  function request() {
    return { type: manifestConstants.DELETEMANIFEST_REQUEST };
  }
  // function success(manifest) { return { type: manifestConstants.DELETEMANIFEST_SUCCESS, manifest } }
  function failure(error) {
    return { type: manifestConstants.DELETEMANIFEST_FAILURE, error };
  }
}



// March2022

function getAllResourceOrderForm(data) {
  return (dispatch) => {
    dispatch(request(data));

    manifestService.getAllResourceOrderForms(data).then(
      (allRos) => dispatch(success(allRos)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: manifestConstants.GET_ALL_RESOURCE_ORDER_FORM_REQUEST };
  }
  function success(response) {
    let allRof = [];
    const data = response?.data;

    if (data?.resource_uploaded_ro_list?.length) {
      allRof = allRof?.concat(
        data?.resource_uploaded_ro_list?.map((item) => ({
          ...item,
          fileType: "resource_uploaded_ro_list",
        }))
      );
    }
    
    if (data?.resource_created_ro_list?.length) {
      allRof = allRof?.concat(
        data?.resource_created_ro_list?.map((item) => ({
          ...item,
          fileType: "resource_created_ro_list",
        }))
      );
    }
    if (data?.uploaded_ro_list?.length) {
      allRof = allRof?.concat(
        data?.uploaded_ro_list?.map((item) => ({
          ...item,
          fileType: "uploaded_ro_list",
        }))
      );
    }
    if (data?.created_ro_list?.length) {
      allRof = allRof?.concat(
        data?.created_ro_list?.map((item) => ({
          ...item,
          fileType: "created_ro_list",
        }))
      );
    }
    return {
      type: manifestConstants.GET_ALL_RESOURCE_ORDER_FORM_SUCCESS,
      data: allRof,
      selectedManifestRofId: data?.ro_id,
      selectedManifestRofType: data?.ro_type,
    };
  }
  function failure(error) {
    return {
      type: manifestConstants.GET_ALL_RESOURCE_ORDER_FORM_ERROR,
      error,
    };
  }
}
