import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import { config } from "_helpers";

class SidebarAdmin extends Component {
  constructor(props) {
    super(props);
    this.navigate = this.navigate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
      rehire: new URLSearchParams(this.props.location.search).get("rehire") == "true",
    };
  }

  navigate(url) {
    this.props.resetForm(); // set edit to false
    this.props.history.push(url);
  }

  toggle(e) {
    this.props.toggle();
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    let types = this.props.docs.userDocs;

    let docs_count = this.props.giss
      ? this.props.docs.userDocs.filter((item) => item.docs_categories_id == 4).length
      : this.props.docs.userDocs.filter((item) => item.docs_categories_id == 1).length;

    let rehire_docs_count = this.props.giss
      ? this.props.docs.userDocs.filter((item) => item.docs_categories_id == 5).length
      : this.props.docs.userDocs.filter((item) => item.docs_categories_id == 2).length;

    let params = this.props.match.params;

    return (
      <div className='doc_info-lh doc-list-lh'>
        <div className='doc-title'>
          <h3>
            Documents{" "}
            <span className='doc-count'>{this.state.rehire ? rehire_docs_count : docs_count} </span>
          </h3>
          <span className='back-ico'>
            <Link to='/docs'>
              <img src={`${config.assetUrl}icons/doc-arrows.svg`} alt='' />
            </Link>
          </span>
        </div>
        <div className='doc-statusbar doc-listing'>
          <ul className='doc-lists'>
            {types.map((type, index) => {
              // if (this.props.giss) {
                // if (!this.state.rehire && type.docs_categories_id == 4) {
                //   return (
                //     <a
                //       onClick={this.navigate.bind(
                //         this,
                //         `/docs/${type.hash}/${type.slug}?rehire=false`,
                //       )}
                //       key={index}>
                //       <li
                //         key={type.id}
                //         className={`${type.slug === this.props.active ? "doc-active " : ""}${
                //           type.status == 2 ? "doc-complete " : ""
                //         }`}>
                //         <p>{type.doc_type_name}</p>
                //         {type.admin_edit === 1 && (
                //           <button
                //             disabled={type.slug !== this.props.active}
                //             className={`${
                //               type.slug !== this.props.active ? "disabled" : ""
                //             } cursor-pointer doc-list-item_edit`}
                //             onClick={this.toggle}>
                //             <img src={`${config.assetUrl}icons/doc-edit.svg`} alt='' />
                //           </button>
                //         )}
                //       </li>
                //     </a>
                //   );
                // } else if (this.state.rehire && type.docs_categories_id == 5) {
                //   return (
                //     <a
                //       onClick={this.navigate.bind(
                //         this,
                //         `/docs/${type.hash}/${type.slug}?rehire=true`,
                //       )}
                //       key={index}>
                //       <li
                //         key={type.id}
                //         className={`${type.slug === this.props.active ? "doc-active " : ""}${
                //           type.status == 2 ? "doc-complete " : ""
                //         }`}>
                //         <p>{type.doc_type_name}</p>
                //         {type.admin_edit === 1 && (
                //           <button
                //             disabled={type.slug !== this.props.active}
                //             className={`${
                //               type.slug !== this.props.active ? "disabled" : ""
                //             } cursor-pointer doc-list-item_edit`}
                //             onClick={this.toggle}>
                //             <img src={`${config.assetUrl}icons/doc-edit.svg`} alt='' />
                //           </button>
                //         )}
                //       </li>
                //     </a>
                //   );
                // }
              // } else {
                if (!this.state.rehire && (type.docs_categories_id == 1 || type.docs_categories_id == 4)) {
                  return (
                    <a
                      onClick={this.navigate.bind(
                        this,
                        `/docs/${type.hash}/${type.slug}?rehire=false`,
                      )}
                      key={index}>
                      <li
                        key={type.id}
                        className={`${type.slug === this.props.active ? "doc-active " : ""}${
                          type.status == 2 ? "doc-complete " : ""
                        }`}>
                        <p>{type.doc_type_name}</p>
                        {type.admin_edit === 1 && (
                          <button
                            disabled={type.slug !== this.props.active}
                            className={`${
                              type.slug !== this.props.active ? "disabled" : ""
                            } cursor-pointer doc-list-item_edit`}
                            onClick={this.toggle}>
                            <img src={`${config.assetUrl}icons/doc-edit.svg`} alt='' />
                          </button>
                        )}
                      </li>
                    </a>
                  );
                } else if (this.state.rehire && (type.docs_categories_id == 2 || type.docs_categories_id == 5)) {
                  return (
                    <a
                      onClick={this.navigate.bind(
                        this,
                        `/docs/${type.hash}/${type.slug}?rehire=true`,
                      )}
                      key={index}>
                      <li
                        key={type.id}
                        className={`${type.slug === this.props.active ? "doc-active " : ""}${
                          type.status == 2 ? "doc-complete " : ""
                        }`}>
                        <p>{type.doc_type_name}</p>
                        {type.admin_edit === 1 && (
                          <button
                            disabled={type.slug !== this.props.active}
                            className={`${
                              type.slug !== this.props.active ? "disabled" : ""
                            } cursor-pointer doc-list-item_edit`}
                            onClick={this.toggle}>
                            <img src={`${config.assetUrl}icons/doc-edit.svg`} alt='' />
                          </button>
                        )}
                      </li>
                    </a>
                  );
                }
              // }
            })}
            {/* dev-code */}
            {/* <a onClick={this.navigate.bind(this, `/docs/${params.hash}/employee-rehire-form`)}>
              <li className={`${params.slug === this.props.active ? "doc-active " : ""}`}>
                <p>Employee Rehire</p>
              </li>
            </a>
            <a onClick={this.navigate.bind(this, `/docs/${params.hash}/authorization-for-direct-deposit`)}>
              <li className={`${params.slug === this.props.active ? "doc-active " : ""}`}>
                <p>Authorization for direct deposit</p>
              </li>
            </a> */}
            {/* end of dev-code */}
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}

const connectedSidebarAdmin = compose(withRouter, connect(mapStateToProps))(SidebarAdmin);
export { connectedSidebarAdmin as SidebarAdmin };
