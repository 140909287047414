import React, { Component } from "react";
import { config } from "_helpers";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { userService } from "_services/user.service";
import { docsActions } from "_actions/docs.actions";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.closeEmpList = this.closeEmpList.bind(this);
    this.searchEmployees = this.searchEmployees.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.filter = this.filter.bind(this);

    this.state = {
      isFilterOn: false,
      empList: [],
      isEmpListShown: false,
      filter: { doc_types: "", status: "", from: "", to: "", user: "", employee_type:'' },
      employeeType:[],
      docTypes: [],
      docStatus: [],
      fromDate: "",
      toDate: "",
      user: null,
      employeeName:"",
    };

    // Refs
    this.search = React.createRef();
    this.empFilter = React.createRef();
    this.docType = React.createRef();
    this.docStatus = React.createRef();
  }

  // toggle filter dropdown
  toggleFilter() {
    this.state.isFilterOn
      ? this.setState({ isFilterOn: false })
      : this.setState({ isFilterOn: true });
  }

  // Debounce a function , call after a delay
  debounceTimer = null;
  debounce = (fn, args, delay) => {
    const context = this;
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => fn.call(context, args), delay);
  };

  // close emplist
  closeEmpList() {
    this.setState({
      empList: [],
      isEmpListShown: false,
    });
  }

  // search employees (inside Filter)
  searchEmployees(param) {
    this.clearFilter("user");
    // getEmployeeList need body as Form Data
    let fd = new FormData();
    for (var key in param) {
      fd.append(key, param[key]);
    }
    // API CALL
    userService.getEmployeeListForDocumentFilter(fd).then((res) => {
      this.setState({
        isEmpListShown: true,
        empList: res.data.data && res.data.data.length ? res.data.data.map((emp) => {
          return (
            <li key={emp.hash} onClick={() => this.setFilter("emp", emp)}>
              {`${emp.first_name}  ${emp.last_name}`}
            </li>
          );
        }): <li>{"No employees found"}</li>,
      });
    });
  }

  // HANDLE dATE
  handleDateChange(name, date) {
    if (date){
      this.setState({ [name]: date });
      this.setFilter(name, date.format("YYYY/MM/DD"));
    }
    else if (name === "fromDate" || name ==="toDate"){
      this.setState({ [name]: null });
      this.setFilter(name, "");
    }
  }

  handleCheckbox(key,value){
    let employeeType = [...this.state.employeeType];
    const index = this.state.employeeType.findIndex((element) => element === value)
    index === -1 ? employeeType.push(value) : employeeType.splice(index,1);
    this.setState({employeeType},() => this.setFilter(key,employeeType.toString()));
  }

  // select employee
  setFilter(key, value) {
    switch (key) {
      case "emp":
        this.setState({ filter: { ...this.state.filter, user: value.hash }, user: value, employeeName:`${value.first_name} ${value.last_name}` });
        this.empFilter.current.value = `${value.first_name} ${value.last_name}`;
        this.closeEmpList();
        break;
      case "type":
        this.setState({ filter: { ...this.state.filter, doc_types: value } });
        break;
      case "employee_type":
        this.setState({ filter: { ...this.state.filter, employee_type: value } });
        break;
      case "status":
        this.setState({ filter: { ...this.state.filter, status: value } });
        break;
      case "toDate":
        // to date
        this.setState({ filter: { ...this.state.filter, to: value } });
        break;
      case "fromDate":
        // from date
        this.setState({ filter: { ...this.state.filter, from: value } });
        break;
      default:
        this.clearFilter();
    }
  }

  filter(){
    this.props.onChange(this.state.filter, 0);
    this.toggleFilter();
  }

  clearFilter(key = "all") {
    // let filter = this.state.filter;
    if (key === "all") {
      this.empFilter.current.value = "";
      this.docType.current.value = "";
      this.docStatus.current.value = "";
      this.setState(
        {
          toDate: null,
          fromDate: null,
          filter: {},
          user: null,
          employeeType:[],
          employeeName:"",
        },
        () => {
          // calling document list with no filters and 0 debounce timer
          this.props.onChange({}, 0);
          moment().format("MM/DD/YYYY");
        },
      );
    }
  }

  componentDidMount() {
    this.setState({
      docStatus: [
        { id: 1, name: "Pending" },
        { id: 2, name: "Submitted" },
      ],
    });
    this.props.dispatch(docsActions.getDocTypes()).then(
      (res) => {
        this.setState({ docTypes: res });
      },
      (err) => console.log(err),
    );
    // to close employee dropdown list
    document.addEventListener("click", this.closeEmpList);
  }

  componentWillReceiveProps(props) {
    this.setState({ filter: props.filter });
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.closeEmpList);
  }

  render() {
    return (
      <section className={this.props.classes}>
        <div className='tabs-bar'>
          {/* Tabs */}
          {this.props.title && (
            <ul className='doc-tabs'>
              <li>{this.props.title}</li>
            </ul>
          )}
          {/* Filter */}
          <div className='search_filters'>
            {/* {this.state.isFilterOn && (
              <button onClick={this.props.clearFilter} className="btn-success clear-btn59a4398bb1f6aaba871321cb5244281b">
                <span>Clear</span>
              </button>
            )} */}
            <button
              onClick={this.toggleFilter}
              className={`${this.state.isFilterOn ? "filter-open" : ""} filter-btn btn-success`}>
              <img src={`${config.assetUrl}icons/doc-filter.svg`} alt='filter' />
              <span>Filter</span>
            </button>
            <input
              ref={this.search}
              type='text'
              className=''
              placeholder='Search'
              onChange={() => this.props.onChange({ keyword: this.search.current.value }, 1000)}
            />
          </div>
        </div>

        {/* Form */}
        {this.state.isFilterOn && (
          <div className='filter_form '>
            <div className='filter_background'></div>
            <div className='doc_info-form'>
              <form className='info-form'>
                <div className='row form-row'>
                  <div className='col'>
                    {/* Employee */}
                    <div className='inputs-col'>
                      <div className='inputs-head'>Employee Name</div>
                      <div className='inputs-items search_select'>
                        <input
                          name='crew'
                          id='crew'
                          ref={this.empFilter}
                          placeholder='Search employees'
                          onChange={(e) =>{
                            this.setState({
                              employeeName:e.target.value
                            })
                            this.debounce(this.searchEmployees, { keyword: e.target.value }, 1000)
                            // Reset User Hash
                            const shouldClearUserHash = !e.target.value && this?.state?.filter?.user
                            if (shouldClearUserHash) {
                              this.setState({ filter: { ...this.state.filter, user: "" } })
                            }
                            }
                          }
                          autoComplete='off'
                          value={this.state.employeeName}
                        />
                        {this.state.isEmpListShown && <ul>{this.state.empList}</ul>}
                      </div>
                    </div>

                    {/* Document */}
                    <div className='inputs-col'>
                      <div className='inputs-head'>Document Name</div>
                      <div className='inputs-items'>
                        <select
                          ref={this.docType}
                          name='position'
                          id='position'
                          onChange={(e) => this.setFilter("type", e.target.value)}
                          value={this.state.filter.doc_types}>
                          <option value=''>- Select -</option>
                          {this.state.docTypes.map((type) => {
                            return <option value={type.id}>{type.doc_type_name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                    {/* Status */}
                    <div className='inputs-col'>
                      <div className='inputs-head'>Current Status</div>
                      <div className='inputs-items'>
                        <select
                          ref={this.docStatus}
                          name='classification'
                          id='classification'
                          onChange={(e) => this.setFilter("status", e.target.value)}
                          value={this.state.filter.status}>
                          <option value=''>- Select -</option>
                          {this.state.docStatus.map((status) => {
                            return <option value={status.id}>{status.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Date */}
                <div className='row form-row'>
                  <div className='col'>
                    <div className='inputs-col'>
                      <div className='inputs-head'>Date</div>
                      <div className='inputs-items group'>
                        <div className='grouped'>
                          {/* <input ref={this.fromDate} type="text" name="ssn1" id="ssn1" placeholder="From" /> */}
                          <DatePicker
                            className='txt '
                            placeholderText='From'
                            id='fromDate'
                            name='fromDate'
                            selected={this.state.fromDate}
                            onChange={this.handleDateChange.bind(this, "fromDate")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            autoComplete='off'
                          />
                        </div>
                        <div className='grouped'>
                          {/* <input ref={this.toDate} type="text" name="ssn2" id="ssn2" placeholder="To" /> */}
                          <DatePicker
                            className='txt '
                            placeholderText='To'
                            id='toDate'
                            name='toDate'
                            selected={this.state.toDate}
                            onChange={this.handleDateChange.bind(this, "toDate")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            autoComplete='off'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* User Type */}
                <div className='row form-row'>
                  <div className='col'>
                    <div className='inputs-col mw-100'>
                      <div className='check_radio_group agreement_checks'>
                        <div className='check_radio_item'>
                          <input
                            type='checkbox'
                            name='employee_type'
                            id='giss'
                            value='giss'
                            onChange={(e) => this.handleCheckbox("employee_type", e.target.value)}
                            checked={this.state.employeeType.includes('giss')}
                          />
                          <span className='checkbox_checkbox'></span>
                          <label htmlFor='giss'>
                            <span>GISS</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='inputs-col mw-100'>
                      <div className='check_radio_group agreement_checks'>
                        <div className='check_radio_item'>
                          <input
                            type='checkbox'
                            name='employee_type'
                            id='firestorm'
                            value='firestorm'
                            onChange={(e) => this.handleCheckbox("employee_type", e.target.value)}
                            checked={this.state.employeeType.includes('firestorm')}
                          />
                          <span className='checkbox_checkbox'></span>
                          <label htmlFor='firestorm'>
                            <span>Firestorm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row form-row'>
                  <div className='col'>
                    <div className='doc-approve-btns'>
                      {/*
                                        <!--add class "enabled" to the buttons when enabled--> */}
                      <button
                        type='button'
                        className='aplcn-prev-button enabled'
                        onClick={this.filter}>
                        Filter Documents
                      </button>
                      <button
                        type='button'
                        className='aplcn-next-btn'
                        onClick={() => this.clearFilter("all")}>
                        Clear
                      </button>
                      <button
                        type='button'
                        className='aplcn-next-btn'
                        onClick={() => {
                          this.toggleFilter();
                          this.clearFilter("all");
                        }}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { user, docs } = state;
  return { user, docs };
}

let connectedDocs = connect(mapStateToProps)(Filter);
export { connectedDocs as Filter };
