import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgressbar from 'react-circular-progressbar';
import { userActions } from '../_actions';

class EmployeeStats extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats_list: [],
            total: "",
            available: "",
            unavailable: "",
            totPercentage: 0
        }
    }
    componentDidMount() {
        this.props.dispatch(userActions.employeeStats());
    }
    componentWillReceiveProps(nextProps) {
        var stats_list = [];
        if (nextProps.stats) {
            this.setState({
                total: nextProps.stats.data.total,
                available: nextProps.stats.data.available,
                unavailable: nextProps.stats.data.unavailable,
                totPercentage: nextProps.stats.data.per
            })
            nextProps.stats.data.stats.map((item, index) => {
                stats_list.push(
                    <div className="stats_item" key={index}>
                        {(item.count > 0 && item.name !== "Super Admin") &&
                            <a href={item.count > 0 ? "/employees/" + item.user_type_id : ""} className={item.count > 0 ? "" : "no_employee"}
                            >
                                <div className="percent_div">
                                    <span className="percent">{item.per}% </span>
                                    <span className="employee_type">{item.name}</span>
                                </div>
                                <div className="small_grey">{item.count} Employees</div>
                            </a>
                        }
                        {(item.count === "0" || item.name === "Super Admin") &&
                            <div>
                                <div className="percent_div">
                                    <span className="percent">{item.per}% </span>
                                    <span className="employee_type">{item.name}</span>
                                </div>
                                <div className="small_grey">{item.count} Employees</div>
                            </div>
                        }

                    </div>
                )
                return stats_list;
            })
            this.setState({ stats_list })
        }

    }
    render() {

        return (
            <div className="employee_stats_outer">
                <div className="employee_stats inner_head">
                    <h2>Employee Stats
                        <span className="drag-icon-controller"></span>
                    </h2>
                    <div className="toggle-controller" aria-owns="employe_status_contents" onClick={(event)=>{
                        this.props.toggle( event , this.props.widgetid );
                    }}></div>
                </div>
                <div className="employee_stats_details" aria-expanded={this.props.visibility == 1 ? "true" : "false"} id="employe_status_contents">
                    <div className="stats_progress_div">
                        <div className="progress_outer">
                            <CircularProgressbar percentage={this.state.totPercentage} textForPercentage={(percentage) => `${percentage}%`} strokeWidth="9" />
                        </div>
                        <div className="progress-text">
                            <p className="title"><Link to={"/employees/22"}>{this.state.total} Employees</Link></p>
                            <p className="text"><Link to={"/employees/20"}>{this.state.available} Available</Link><br />
                                <Link to={"/employees/21"}>{this.state.unavailable} Unavailable</Link>
                            </p>
                            <span className="view_employee_list_button"><Link to={"/employees/22"}>View Employees List</Link></span>
                        </div>
                    </div>
                    <div className="emp_progress"></div>
                    <div className="stats_list">
                        {this.state.stats_list}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { stats } = state.user;
    return {
        stats
    };
}

const connectedHomePage = connect(mapStateToProps)(EmployeeStats);
export { connectedHomePage as EmployeeStats };

// export default EmployeeStats;