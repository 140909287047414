const EmployeeTrainingInputs = [
  {
    title: "Physical Fitness",
    fields: [
      {
        label: "Pack Date",
        type: "date",
        hasExpirationDate: false,
        name: "physical_fitness_pk_date",
      },
      {
        label: "Pack Time",
        type: "text",
        hasExpirationDate: false,
        name: "physical_fitness_pack_tes",
      },
      {
        label: "2 Mile Date",
        type: "date",
        hasExpirationDate: false,
        name: "physical_fitness_two_mile",
      },
      {
        label: "2 Mile Time",
        type: "text",
        hasExpirationDate: false,
        name: "physical_fitness_2mi_date",
      },
    ],
  },
  {
    title: "FFT1 Training",
    fields: [
      {
        label: "S-131",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_131",
      },
      {
        label: "S-133",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_133",
      },
      {
        label: "S-134",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_134",
      },
      {
        label: "S-211",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_211",
      },
      {
        label: "S-212",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_212",
      },
      {
        label: "S-212 RT",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_212_rt",
      },
      {
        label: "S-212 Exp",
        type: "date",
        hasExpirationDate: false,
        name: "fft1_training_s_212_exp",
      },
    ],
  },
  {
    title: "Incident Courses",
    fields: [
      {
        label: "I-220",
        type: "date",
        hasExpirationDate: false,
        name: "incident_courses_i_220",
      },
      {
        label: "I-300",
        type: "date",
        hasExpirationDate: false,
        name: "incident_courses_i_300",
      },
      {
        label: "I-400",
        type: "date",
        hasExpirationDate: false,
        name: "incident_courses_i_400",
      },
      {
        label: "I-401",
        type: "date",
        hasExpirationDate: false,
        name: "incident_courses_i_401",
      },
      {
        label: "I-402",
        type: "date",
        hasExpirationDate: false,
        name: "incident_courses_i_402",
      },
    ],
  },
  {
    title: "Medical Training",
    fields: [
      // {
      //   label: "CPR/First Aid",
      //   type: "date",
      //   hasExpirationDate: true,
      //   name:"medical_training_cpr",
      //   expDateName:"medical_training_cpr_expiration_date"
      // },
      // {
      //   label: "FR",
      //   type: "date",
      //   hasExpirationDate: true,
      //   name:"medical_training_fr",
      //   expDateName:"medical_training_fr_expiration_date"
      // },
      // {
      //   label: "EMT",
      //   type: "date",
      //   hasExpirationDate: true,
      //   name:"medical_training_emt",
      //   expDateName:"medical_training_emt_expiration_date"
      // },
      {
        label: "Survival CPR/First Aid",
        type: "date",
        hasExpirationDate: false,
        name: "medical_training_survival_cpr",
      },
    ],
  },
  {
    title: "Basic 32",
    fields: [
      {
        label: "S-130",
        type: "date",
        hasExpirationDate: false,
        name: "basic_32_s_130",
      },
      {
        label: "S-190",
        type: "date",
        hasExpirationDate: false,
        name: "basic_32_s_190",
      },
      {
        label: "L-180",
        type: "date",
        hasExpirationDate: false,
        name: "basic_32_l_180",
      },
      {
        label: "24-hr Certificate",
        type: "date",
        hasExpirationDate: false,
        name: "basic_32_24_hr_cer",
      },
    ],
  },
  {
    title: "FEMA Courses",
    fields: [
      {
        label: "IS-100",
        type: "date",
        hasExpirationDate: false,
        name: "fema_courses_is_100",
      },
      {
        label: "IS-200",
        type: "date",
        hasExpirationDate: false,
        name: "fema_courses_is_200",
      },
      {
        label: "IS-00700",
        type: "date",
        hasExpirationDate: false,
        name: "fema_courses_is_00700",
      },
      {
        label: "IS-00800",
        type: "date",
        hasExpirationDate: false,
        name: "fema_courses_is_00800",
      },
    ],
  },
  {
    title: "Firestorm Courses",
    fields: [
      {
        label: "Business Ethics",
        type: "date",
        hasExpirationDate: false,
        name: "fs_courses_business_courses",
      },
      {
        label: "Driver/Operator",
        type: "date",
        hasExpirationDate: false,
        name: "fs_courses_driver_operator",
      },
    ],
  },
  {
    title: "Instructor Training",
    fields: [
      {
        label: "M-410",
        type: "date",
        hasExpirationDate: false,
        name: "instructor_training_m_410",
      },
    ],
  },
  {
    title: "SRB Training",
    fields: [
      {
        label: "S-215",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_215",
      },
      {
        label: "S-234/219",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_234_219",
      },
      {
        label: "S-230",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_230",
      },
      {
        label: "S-231",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_231",
      },
      {
        label: "S-260",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_260",
      },
      {
        label: "S-270",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_270",
      },
      {
        label: "S-290",
        type: "date",
        hasExpirationDate: false,
        name: "srb_training_s_290",
      },
    ],
  },
  {
    title: "Leadership Courses",
    fields: [
      {
        label: "L-280",
        type: "date",
        hasExpirationDate: false,
        name: "leadership_courses_l_280",
      },
      {
        label: "L-380",
        type: "date",
        hasExpirationDate: false,
        name: "leadership_courses_l_380",
      },
      {
        label: "L-381",
        type: "date",
        hasExpirationDate: false,
        name: "leadership_courses_l_381",
      },
      {
        label: "Fire Mgmt Leadership",
        type: "date",
        hasExpirationDate: false,
        name: "leadership_courses_fire_mgmt",
      },
    ],
  },
  {
    title: "Rx Burn Courses",
    fields: [
      {
        label: "Rx-300",
        type: "date",
        hasExpirationDate: false,
        name: "rx_burn_courses_rx_300",
      },
      {
        label: "Rx-340/310",
        type: "date",
        hasExpirationDate: false,
        name: "rx_burn_courses_rx_340_310",
      },
      {
        label: "Rx-410",
        type: "date",
        hasExpirationDate: false,
        name: "rx_burn_courses_rx_410",
      },
      {
        label: "Rx-540",
        type: "date",
        hasExpirationDate: false,
        name: "rx_burn_courses_rx_540",
      },
      // {
      //   label: "Rx Quals",
      //   type: "date",
      //   hasExpirationDate: false,
      //   name: "rx_burn_courses_rx_quals",
      // },
      {
        label: "Currency",
        type: "date",
        hasExpirationDate: false,
        name: "rx_burn_courses_currency",
      },
    ],
  },
  {
    title: "Specialty Training",
    fields: [
      {
        label: "HAZWOPER 40",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_hazwoper_40",
      },
      {
        label: "HAZWOPER 8HR",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_hazwoper_8hr",
      },
      {
        label: "TCIA Chipper Operator",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_tcia_co",
      },
      {
        label: "FWFS Chipper Operator",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_fwfs_co",
      },
      {
        label: "Certified Flagger",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_certified_flagger",
      },
      {
        label: "Hire Equipment",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_hired_equipment",
      },
      {
        label: "ATV",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_atv",
      },
      {
        label: "Chainsaw Operator and Safety Training",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_co_st",
      },
      {
        label: "Forklift Operator",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_forklift_operator",
      },
      {
        label: "Forklift Trainer",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_forklift_trainer",
      },
      {
        label: "Job Relations",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_job_relations",
      },
      {
        label: "Comm. For Leader Success",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_leader_success",
      },
      {
        label: "Colors Personality Training",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_colors_personality_training",
      },
      {
        label: "Inter/Advanced Sawyer/Felling",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_inter_advanced",
      },
      {
        label: "State-Certified Prescribed-Fire Burn Boss",
        type: "date",
        hasExpirationDate: false,
        name: "specialty_training_fire_burn_boss",
      },
    ],
  },

  {
    title: "Skills Courses (I)",
    fields: [
      {
        label: "S-200",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_200",
      },
      {
        label: "S-203",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_203",
      },
      {
        label: "S-216",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_216",
      },
      {
        label: "S-232",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_232",
      },
      {
        label: "S-233r",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_233",
      },
      {
        label: "S-235",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_235",
      },
      {
        label: "S-244/245",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_244_245",
      },
      {
        label: "S-248",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_248",
      },
      {
        label: "S-258",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_258",
      },
      {
        label: "S-259",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_259",
      },
      {
        label: "S-271",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_271",
      },
      {
        label: "S-273",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_273",
      },
      {
        label: "S-300",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_300",
      },
      {
        label: "S-301",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_301",
      },
      {
        label: "S-330",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_330",
      },
      {
        label: "S-331",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_331",
      },
      {
        label: "S-336",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_336",
      },
      {
        label: "S-339",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_339",
      },
      {
        label: "S-346",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_346",
      },
      {
        label: "S-348/347",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_348_347",
      },
      {
        label: "S-354",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_354",
      },
      {
        label: "S-355",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_355",
      },
      {
        label: "S-356",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_356",
      },
    ],
  },
  {
    title: "Skills Courses (II)",
    fields: [
      {
        label: "S-357",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_357",
      },
      {
        label: "S-360",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_360",
      },
      {
        label: "S-370",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_370",
      },
      {
        label: "S-371",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_371",
      },
      {
        label: "S-378",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_378",
      },
      {
        label: "S-390",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_390",
      },
      {
        label: "S-400",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_400",
      },
      {
        label: "S-402",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_402",
      },
      {
        label: "S-403",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_403",
      },
      {
        label: "S-404",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_404",
      },
      {
        label: "S-420",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_420",
      },
      {
        label: "S-430",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_430",
      },
      {
        label: "S-440",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_440",
      },
      {
        label: "S-443",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_443",
      },
      {
        label: "S-445",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_445",
      },
      {
        label: "S-450",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_450",
      },
      {
        label: "S-460",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_460",
      },
      {
        label: "S-470",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_470",
      },
      {
        label: "S-490",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_490",
      },
      {
        label: "S-491",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_491",
      },
      {
        label: "S-492",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_492",
      },
      {
        label: "S-493",
        type: "date",
        hasExpirationDate: false,
        name: "skills_courses_s_493",
      },
    ],
  },
];

export default EmployeeTrainingInputs;

export const defaultValues = {
  physical_fitness_pack_tes: "",
  physical_fitness_pk_date: "",
  basic_32_24_hr_cer: "",
  basic_32_l_180: "",
  basic_32_s_130: "",
  basic_32_s_190: "",
  fema_courses_is_100: "",
  fema_courses_is_200: "",
  fema_courses_is_00700: "",
  fema_courses_is_00800: "",
  fft1_training_s_131: "",
  fft1_training_s_133: "",
  fft1_training_s_134: "",
  fft1_training_s_211: "",
  fft1_training_s_212: "",
  fft1_training_s_212_exp: "",
  fft1_training_s_212_rt: "",
  fs_courses_business_courses: "",
  fs_courses_driver_operator: "",
  incident_courses_i_220: "",
  incident_courses_i_300: "",
  incident_courses_i_400: "",
  incident_courses_i_401: "",
  incident_courses_i_402: "",
  instructor_training_m_410: "",
  leadership_courses_fire_mgmt: "",
  leadership_courses_l_280: "",
  leadership_courses_l_380: "",
  leadership_courses_l_381: "",
  medical_training_cpr: "",
  medical_training_cpr_expiration_date: "",
  medical_training_emt: "",
  medical_training_emt_expiration_date: "",
  medical_training_fr: "",
  medical_training_fr_expiration_date: "",
  medical_training_survival_cpr: "",
  physical_fitness_2mi_date: "",
  physical_fitness_pk_date: "",
  physical_fitness_two_mile: "",
  rx_burn_courses_currency: "",
  rx_burn_courses_rx_300: "",
  rx_burn_courses_rx_340_310: "",
  rx_burn_courses_rx_410: "",
  rx_burn_courses_rx_540: "",
  rx_burn_courses_rx_quals: "",
  skills_courses_s_200: "",
  skills_courses_s_203: "",
  skills_courses_s_216: "",
  skills_courses_s_232: "",
  skills_courses_s_233: "",
  skills_courses_s_235: "",
  skills_courses_s_244_245: "",
  skills_courses_s_248: "",
  skills_courses_s_258: "",
  skills_courses_s_259: "",
  skills_courses_s_271: "",
  skills_courses_s_273: "",
  skills_courses_s_300: "",
  skills_courses_s_301: "",
  skills_courses_s_330: "",
  skills_courses_s_331: "",
  skills_courses_s_336: "",
  skills_courses_s_339: "",
  skills_courses_s_346: "",
  skills_courses_s_348_347: "",
  skills_courses_s_354: "",
  skills_courses_s_355: "",
  skills_courses_s_356: "",
  skills_courses_s_357: "",
  skills_courses_s_360: "",
  skills_courses_s_370: "",
  skills_courses_s_371: "",
  skills_courses_s_378: "",
  skills_courses_s_390: "",
  skills_courses_s_400: "",
  skills_courses_s_402: "",
  skills_courses_s_403: "",
  skills_courses_s_404: "",
  skills_courses_s_420: "",
  skills_courses_s_430: "",
  skills_courses_s_440: "",
  skills_courses_s_443: "",
  skills_courses_s_445: "",
  skills_courses_s_450: "",
  skills_courses_s_460: "",
  skills_courses_s_470: "",
  skills_courses_s_490: "",
  skills_courses_s_491: "",
  skills_courses_s_492: "",
  skills_courses_s_493: "",
  specialty_training_atv: "",
  specialty_training_certified_flagger: "",
  specialty_training_co_st: "",
  specialty_training_colors_personality_training: "",
  specialty_training_fire_burn_boss: "",
  specialty_training_forklift_operator: "",
  specialty_training_forklift_trainer: "",
  specialty_training_fwfs_co: "",
  specialty_training_hazwoper_8hr: "",
  specialty_training_hazwoper_40: "",
  specialty_training_hired_equipment: "",
  specialty_training_inter_advanced: "",
  specialty_training_job_relations: "",
  specialty_training_leader_success: "",
  specialty_training_tcia_co: "",
  srb_training_s_215: "",
  srb_training_s_230: "",
  srb_training_s_231: "",
  srb_training_s_234_219: "",
  srb_training_s_260: "",
  srb_training_s_270: "",
  srb_training_s_290: "",
};
