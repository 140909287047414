import { inventoryConstants } from "../_constants";
import { inventoryService } from "../_services";
import { notificationService } from "../_services";
import { notificationConstants } from "../_constants";

export const inventoryActions = {
  getListEquipments,
  getInventoryStats,
  createInventory,
  getList,
  equipmentDetails,
  getMemberList,
  assignEquipment,
  getAvailableList,
  getCrewAllList,
  getInventoryList,
  deleteInventory,
  forceDeleteInventory,
  deleteInvFile,
  uploadImage,
  printInventory,
  getArchivedList,
  unArchiveInventory,
  getPaginatedList,
  deleteMileageFile,
  getInventoryNotes,
  getMileageHistory
};

function printInventory(inventory) {
  let data = new FormData();
  data.append("equipment_type_id", inventory.equipment_type_id);

  return (dispatch) => {
    dispatch(request());

    inventoryService.printInventory(data, inventory.id).then(
      (res) => dispatch(success(res.data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.PRINTURL_REQUEST };
  }
  function success(url) {
    return { type: inventoryConstants.PRINTURL_SUCCESS, printUrl: url };
  }
  function failure(error) {
    return { type: inventoryConstants.PRINTURL_FAILURE, error };
  }
}

function uploadImage(imageObj, index) {
  let data = new FormData();
  data.append("type", imageObj.image.name.split(".").pop());

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService
        .getUploadUrl(data)
        .then((res) => {
          return Promise.all([
            res.data,
            inventoryService.uploadToStore(imageObj.image, res.data.ORIGINAL_URL),
            inventoryService.uploadToStore(imageObj.blob, res.data.THUMB_URL),
          ]);
        })
        .then(([data, imageSuccess, thumbSuccess]) => {
          dispatch(
            success({
              index: index,
              thumbName: data.THUMB_PATH,
              imgName: data.ORIGINAL_PATH,
            })
          );
          resolve({
            index: index,
            thumbName: data.THUMB_PATH,
            imgName: data.ORIGINAL_PATH,
          });
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };

  function request() {
    return { type: inventoryConstants.EQUPLOAD_REQUEST };
  }
  function success(uploadedImage) {
    return { type: inventoryConstants.EQUPLOAD_SUCCESS, uploadedImage };
  }
  function failure(error) {
    return { type: inventoryConstants.EQUPLOAD_FAILURE, error };
  }
}

function getListEquipments() {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getListEquipments().then(
      (equipments) => dispatch(success(equipments)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.GETEQP_REQUEST };
  }
  function success(equipments) {
    return { type: inventoryConstants.GETEQP_SUCCESS, equipments };
  }
  function failure(error) {
    return { type: inventoryConstants.GETEQP_FAILURE, error };
  }
}

function getArchivedList(options) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getArchivedList(options).then(
      (equipments) => dispatch(success(equipments)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.ARCHIVED_LIST_REQUEST };
  }
  function success(archived) {
    return { type: inventoryConstants.ARCHIVED_LIST_SUCCESS, archived };
  }
  function failure(error) {
    return { type: inventoryConstants.ARCHIVED_LIST_ERROR, error };
  }
}

function getInventoryStats(data) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getInventoryStats(data).then(
      (stats) => dispatch(success(stats)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.GETSTATS_REQUEST };
  }
  function success(stats) {
    return { type: inventoryConstants.GETSTATS_SUCCESS, stats };
  }
  function failure(error) {
    return { type: inventoryConstants.GETSTATS_FAILURE, error };
  }
}

function createInventory(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.createInventory(data).then(
        (inventory) => {
          dispatch(success(inventory));
          resolve(inventory);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.SETINV_REQUEST };
  }
  function success(inventory) {
    getCount();
    return { type: inventoryConstants.SETINV_SUCCESS, inventory };
  }
  function failure(error) {
    return { type: inventoryConstants.SETINV_FAILURE, error };
  }
}

function deleteInventory(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.deleteInventory(data).then(
        (dltInv) => {
          dispatch(success(dltInv));
          resolve(dltInv);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.DLTINV_REQUEST };
  }
  function success(dltInv) {
    getCount();
    return { type: inventoryConstants.DLTINV_SUCCESS, dltInv };
  }
  function failure(error) {
    return { type: inventoryConstants.DLTINV_FAILURE, error };
  }
}

function forceDeleteInventory(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService
        .forceDeleteInventory(data)
        .then((res) => {
          dispatch(success(res));
          resolve(res);
        })
        .catch((err) => {
          reject(err);
          dispatch(failure(err));
        });
    });

  function request() {
    return { type: inventoryConstants.FORCE_DLTINV_REQUEST };
  }
  function success(dltInv) {
    return { type: inventoryConstants.FORCE_DLTINV_SUCCESS, dltInv };
  }
  function failure(error) {
    return { type: inventoryConstants.FORCE_DLTINV_FAILURE, error };
  }
}

function unArchiveInventory(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.unArchiveInventory(data).then(
        (equipment) => {
          dispatch(success(equipment));
          resolve(equipment);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.UNARCHIVE_EQUIPMENT_REQUEST };
  }
  function success(equipment) {
    return {
      type: inventoryConstants.UNARCHIVE_EQUIPMENT_SUCCESS,
      unArchivedEquipment: equipment,
    };
  }
  function failure(error) {
    return { type: inventoryConstants.UNARCHIVE_EQUIPMENT_ERROR, error };
  }
}

function getList(data = "") {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getList(data).then(
      (list) => dispatch(success(list)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.GETLIST_REQUEST };
  }
  function success(list) {
    return { type: inventoryConstants.GETLIST_SUCCESS, list };
  }
  function failure(error) {
    return { type: inventoryConstants.GETLIST_FAILURE, error };
  }
}

function equipmentDetails(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.equipmentDetails(id).then(
        (equipList) => {
          dispatch(success(equipList));
          resolve(equipList);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.EQUIP_REQUEST };
  }
  function success(equipList) {
    return { type: inventoryConstants.EQUIP_SUCCESS, equipList };
  }
  function failure(error) {
    return { type: inventoryConstants.EQUIP_FAILURE, error };
  }
}

function getMemberList(data) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getMemberList(data).then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.GETUSER_REQUEST };
  }
  function success(users) {
    return { type: inventoryConstants.GETUSER_SUCCESS, users };
  }
  function failure(error) {
    return { type: inventoryConstants.GETUSER_FAILURE, error };
  }
}

function getCount() {
  return (dispatch) => {
    dispatch(request());

    notificationService.getCount().then(
      (count) => dispatch(success(count)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: notificationConstants.GETCOUNT_REQUEST };
  }
  function success(count) {
    return { type: notificationConstants.GETCOUNT_SUCCESS, count };
  }
  function failure(error) {
    return { type: notificationConstants.GETCOUNT_FAILURE, error };
  }
}

function assignEquipment(data) {
  return (dispatch) => {
    dispatch(request());

    return inventoryService.assignEquipment(data).then(
      (assign) => {
        dispatch(getCount());
        dispatch(success(assign));
        return assign; // Resolve with the assigned equipment data
      },
      (error) => {
        dispatch(failure(error));
        return Promise.reject(error); // Reject with the error
      }
    );
  };

  function request() {
    return { type: inventoryConstants.ASSIGN_REQUEST };
  }
  function success(assign) {
    return { type: inventoryConstants.ASSIGN_SUCCESS, assign };
  }
  function failure(error) {
    return { type: inventoryConstants.ASSIGN_FAILURE, error };
  }
}

function getAvailableList(data) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getAvailableList(data).then(
      (available) => {
        dispatch(success(available));
        dispatch({ type: inventoryConstants.ASSIGN_SUCCESS, assign: null });
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.AVAIL_REQUEST };
  }
  function success(available) {
    return { type: inventoryConstants.AVAIL_SUCCESS, available };
  }
  function failure(error) {
    return { type: inventoryConstants.AVAIL_FAILURE, error };
  }
}

function getPaginatedList(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.getPaginatedList(data).then(
        (res) => {
          dispatch(success(res));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.GET_PAGINATED_LIST_REQUEST };
  }
  function success(res) {
    return { type: inventoryConstants.GET_PAGINATED_LIST_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: inventoryConstants.GET_PAGINATED_LIST_FAILURE, error };
  }
}

function getCrewAllList(data) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getCrewAllList(data).then(
      (available) => dispatch(success(available)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.AVAIL_REQUEST };
  }
  function success(available) {
    return { type: inventoryConstants.AVAIL_SUCCESS, available };
  }
  function failure(error) {
    return { type: inventoryConstants.AVAIL_FAILURE, error };
  }
}

function getInventoryList(data) {
  return (dispatch) => {
    dispatch(request());

    inventoryService.getInventoryList(data).then(
      (inventory) => dispatch(success(inventory)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: inventoryConstants.INVENT_REQUEST };
  }
  function success(inventory) {
    return { type: inventoryConstants.INVENT_SUCCESS, inventory };
  }
  function failure(error) {
    return { type: inventoryConstants.INVENT_FAILURE, error };
  }
}
function deleteInvFile(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.deleteInvFile(id).then(
        (dltFile) => {
          dispatch(success(dltFile));
          resolve(dltFile);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.DELETEFILE_REQUEST };
  }
  function success(dltFile) {
    return { type: inventoryConstants.DELETEFILE_SUCCESS, dltFile };
  }
  function failure(error) {
    return { type: inventoryConstants.DELETEFILE_FAILURE, error };
  }
}

function deleteMileageFile(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.deleteMileageFile(id).then(
        (dltFile) => {
          dispatch(success(dltFile));
          resolve(dltFile);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.DELETE_MILEAGE_FILE_REQUEST };
  }
  function success(dltFile) {
    return { type: inventoryConstants.DELETE_MILEAGE_FILE_SUCCESS, dltFile };
  }
  function failure(error) {
    return { type: inventoryConstants.DELETE_MILEAGE_FILE_FAILURE, error };
  }
}


function getInventoryNotes(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.getNotes(data).then(
        (res) => {
          dispatch(success(res));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.GET_INVENTORY_NOTES_REQUEST };
  }
  function success(payload) {
    return { type: inventoryConstants.GET_INVENTORY_NOTES_SUCCESS, payload };
  }
  function failure(error) {
    return { type: inventoryConstants.GET_INVENTORY_NOTES_ERROR, error };
  }
}

function getMileageHistory(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(request());
      inventoryService.getMileageHistory(data).then(
        (res) => {
          dispatch(success(res));
          resolve(res);
        },
        (error) => {
          dispatch(failure(error));
          reject(error);
        }
      );
    });
  };

  function request() {
    return { type: inventoryConstants.GET_INVENTORY_MILEAGE_REQUEST };
  }
  function success(payload) {
    return { type: inventoryConstants.GET_INVENTORY_MILEAGE_SUCCESS, payload };
  }
  function failure(error) {
    return { type: inventoryConstants.GET_INVENTORY_MILEAGE_ERROR, error };
  }
}
