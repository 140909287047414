import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { dashboardActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import "../assets/styles/report.css";
import QualificationReportList from "./QualificationReportList";
import { getQualifications, getQualificationReport as getQualificationReportState } from "_helpers/reduxSelector";
import { SELECT_ALL_QUALIFICATIONS } from "QualificationReportWidget";

const QualificationReportListContainer = (props) => {
  const dispatch = useDispatch();
  const [currentFilters, setCurrentFilters] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const qualificationsRaw = useSelector((state) => getQualifications(state));
  const qualificationReport = useSelector((state) => getQualificationReportState(state));
  const [activeColumns, setActiveColumns] = React.useState([]);
  // console.log(SELECT_ALL_QUALIFICATIONS)

  const [currentSort, setCurrentSort] = React.useState("first_name");
  const [currentOrder, setCurrentOrder] = React.useState(true);

  const [printLoad, setPrintLoad] = React.useState(false);


  const qualifications = React.useMemo(() => {
    if (qualificationsRaw?.length) {
      let _qualifications = qualificationsRaw.map((item) => ({
        label: item?.key,
        value: item?.id,
        id: item?.id,
        key: item?.key,
        position: item?.position
      }));
      return SELECT_ALL_QUALIFICATIONS.concat(_qualifications);
    } else {
      return [];
    }
  }, [qualificationsRaw]);

  React.useEffect(() => {
    setActiveColumns(qualificationsRaw);
  }, [qualificationsRaw]);

  React.useEffect(() => {
    dispatch(dashboardActions.getQualifications());
  }, []);

  React.useEffect(() => {
    if (qualifications?.length) {
      let _currentFilters = props?.location?.state?.currentFilters?.length
        ? props?.location?.state?.currentFilters
        : ["all"];
      _currentFilters = _currentFilters?.length === qualificationsRaw?.length ? ["all"] : _currentFilters;
      const _currentFiltersRaw = _currentFilters?.includes("all")
        ? SELECT_ALL_QUALIFICATIONS
        : qualifications.filter((item) => _currentFilters?.includes(item?.value));
      setSelectedOptions(_currentFiltersRaw);
    }
  }, [qualifications]);

  React.useEffect(() => {
    setCurrentFilters(selectedOptions?.map((item) => item?.value));
    if (!selectedOptions?.length || selectedOptions.some((e) => e.value === "all")) {
      setActiveColumns(qualificationsRaw);
    } else {
      setActiveColumns(selectedOptions);
    }
  }, [selectedOptions]);

  const getQualificationReport = (newPage, sort, order) => {
    let data = new FormData();
    data.append("sort", sort || "first_name");
    data.append("order", order || "asc");
    data.append("page", newPage || 0);
    data.append(
      "filter",
      JSON.stringify(currentFilters.includes("all") ? qualificationsRaw?.map((item) => item.id) : currentFilters)
    );

    dispatch(dashboardActions.getQualificationReport(data));
  };
  
  const handleOnChange = (newSelectedOptions, selectAction) => {
    const isSelectedAll = selectAction?.action === "select-option" && selectAction?.option?.value === "all";
    if (isSelectedAll) {
      setSelectedOptions(SELECT_ALL_QUALIFICATIONS);
    } else {
      if (qualificationsRaw?.length === newSelectedOptions?.length) {
        setSelectedOptions(SELECT_ALL_QUALIFICATIONS);
      } else {
        setSelectedOptions(newSelectedOptions);
      }
    }
  };

  const onPrintClick = () => {
    setPrintLoad(true);
    let data = new FormData();
    data.append(
      "filter",
      JSON.stringify(currentFilters.includes("all") ? qualificationsRaw?.map((item) => item.id) : currentFilters)
    );
    data.append("sort", currentSort || "first_name");
    data.append("order", currentOrder || "asc");
    data.append("report_type","pdf")
    dashboardService.generateEmployeeQualificationReport(data).then((response) => {
      setPrintLoad(false);
      redirectToPdf(response);
    });
  };

  const onExportClick = () => {
    let data = new FormData();
    data.append(
      "filter",
      JSON.stringify(currentFilters.includes("all") ? qualificationsRaw?.map((item) => item.id) : currentFilters)
    );
    data.append("sort", currentSort || "first_name");
    data.append("order", currentOrder || "asc");
    data.append("report_type","csv")
    dashboardService.generateQualificationCsvReport(data)
  }

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require("../assets/images/back.svg").default} />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {currentFilters?.length && qualificationReport?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  <img alt="" src={require("../assets/images/print.svg").default} /> Print{" "}
                  {printLoad && <img alt="Loader" src={require("../assets/images/loader.gif").default} width="18" />}
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head">
                <h5 className="graph_header">Qualifications Report</h5>
                <div style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }} className="report_head_btn_grp">
                  <div className="report_multi_btn_grp">
                    <div className="inp_sub_container">
                      <ReactSelect
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 })
                        }}
                        value={selectedOptions}
                        onChange={handleOnChange}
                        isMulti
                        options={currentFilters.includes("all") ? SELECT_ALL_QUALIFICATIONS : qualifications}
                      />
                    </div>
                  </div>
                  {props.isReportMenu && currentFilters?.length && qualificationReport?.data?.length ? (
                    <button
                      href=""
                      className="print_button"
                      onClick={(event) => {
                        onExportClick();
                      }}
                    >
                      <img alt="" src="" /> Export{" "}
                      {/* {printLoad && (
                        <img
                          alt="Loader"
                          src={require("../assets/images/loader.gif").default}
                          width="18"
                          style={{ marginLeft: "8px" }}
                        />
                      )} */}
                    </button>
                  ) : (
                    <div className="dashboard-expiration-date-print-btn-mock" />
                  )}
                  {/* {props.isReportMenu && currentFilters?.length && qualificationReport?.data?.length ? (
                    <button
                      href=""
                      className="print_button"
                      onClick={(event) => {
                        onPrintClick();
                      }}
                    >Printtt */}
                      {/* <img alt="" src={require("../assets/images/print.svg").default} /> Print{" "} */}
                      {/* {printLoad && (
                        <img
                          alt="Loader"
                          src={require("../assets/images/loader.gif").default}
                          width="18"
                          style={{ marginLeft: "8px" }}
                        />
                      )} */}
                    {/* </button> */}
                  {/* ) : ( */}
                    {/* ""// <div className="dashboard-expiration-date-print-btn-mock" /> */}
                  {/* )} */}
                </div>
              </div>
            </div>
            <div>
              <QualificationReportList
                currentFilters={currentFilters}
                data={qualificationReport?.data ?? []}
                lastPage={qualificationReport?.last_page}
                getQualificationReport={getQualificationReport}
                qualifications={activeColumns}
                setCurrentSort={setCurrentSort}
                setCurrentOrder={setCurrentOrder}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QualificationReportListContainer;
