import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NewModal from "react-responsive-modal";
import { manifestActions } from "../_actions";
import { config } from "../_helpers";
import { AlertPopup } from "../_components/AlertPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from 'react-router-dom';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    width: "100%",

    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    background: "white",

    // styles we need to apply on draggables
    ...draggableStyle
  };
};

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
  background: "transparent"
});

class SortYourCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id: this.props.match && this.props.match.params ? this.props.match.params.id : null,
      confirmation: false,
      anyUnavailableCrew: false,
      crews: [],
      touch_id: "",
      h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
      alertPop: false,
      items: [],
      selected: ""
    };
    this.goToType = this.goToType.bind(this);
    this.toVehicle = this.toVehicle.bind(this);
    this.goBack = this.goBack.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.confirmationOpenModal = this.confirmationOpenModal.bind(this);
    this.confirmationCloseModal = this.confirmationCloseModal.bind(this);
  }

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the droppable container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    droppable: "items",
    droppable2: "selected"
  };

  confirmationOpenModal() {
    this.setState({ confirmation: true });
  }

  confirmationCloseModal(flag = false) {
    let anyUnavailableCrew = this.state.anyUnavailableCrew;
    // if (flag == true) {
      // anyUnavailableCrew = false;
    // }
    this.setState({
      confirmation: false,
      anyUnavailableCrew: anyUnavailableCrew
    });
  }

  componentDidMount() {
      let anyUnavailableCrew;
      let oncrewList = [],
        offcrewList = [];
      if (!this.props.edit) {
        if (!this.props.prev) {
          let data = new FormData();
          var mid = sessionStorage.getItem("MID");
          data.append("resource_id", this.state.resource_id);
          if (mid == 4) {
            data.append("base_manifest_type", mid);
          }
          else {
            data.append("base_manifest_type", this.props.type);
          }
          this.props.dispatch(manifestActions.getUsers(data));
        } else {
          this.props.savedCrews.forEach(t => {
            if (t.category === "oncrew") {
              oncrewList.push(t);
            } else if (t.category === "offcrew") {
              offcrewList.push(t);
            }
          });
  
          this.setState({
            items: oncrewList,
            selected: offcrewList
          }, () => {
            if (this.state.items.length > 0) {
              anyUnavailableCrew = this.state.items.some((item) => {
                return item.active == 'Unavailable'
              })
              this.setState({
                anyUnavailableCrew: anyUnavailableCrew
              })
  
            }
          });
        }
      } else {
          if (!this.props.prev) {
            let data = new FormData();
            data.append("resource_id", this.state.resource_id);
            data.append("manifest_id", this.props.manifest_id);
            data.append("base_manifest_type", this.props.type);
            this.props.dispatch(manifestActions.getUsers(data));
          } else {
            this.props.savedCrews.forEach(t => {
              if (t.category === "oncrew") {
                oncrewList.push(t);
              } else if (t.category === "offcrew") {
                offcrewList.push(t);
              }
            });
            this.setState({
              items: oncrewList,
              selected: offcrewList
            }, () => {
              if (this.state.items.length > 0) {
                anyUnavailableCrew = this.state.items.some((item) => {
                  return item.active == 'Unavailable'
                })
                this.setState({
                  anyUnavailableCrew: anyUnavailableCrew
                })
  
              }
            });
          }
      }
  }

  componentWillReceiveProps(nextProps) {
    const { crews } = nextProps;
    let anyUnavailableCrew;
    if (crews.list) {
      const { data } = crews.list;

      if (data.length > 0) {
        this.arrayFilter(data);
      }
    }
    if (crews.list && crews.list.data) {
      anyUnavailableCrew = crews.list.data.some((item) => {
        return item.active == 'Unavailable'
      })
      this.setState({
        anyUnavailableCrew: anyUnavailableCrew
      })

    }
  }
  componentDidUpdate() {
  }

  arrayFilter(data) {
    var oncrewList = [],
      offcrewList = [];
    data.forEach(t => {
      if (t.category === "oncrew") {
        oncrewList.push(t);
      } else if (t.category === "offcrew") {
        offcrewList.push(t);
      }
    });
    this.setState({
      items: oncrewList,
      selected: offcrewList
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false
    });
  }

  goToType(type) {
    if (!this.props.edit) {
      this.props.previous(type);
    } else {
      this.props.cancel(this.state.resource_id);
    }
  }

  goBack() {
    this.props.cancel(this.state.resource_id);
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  toVehicle() {
    const onCrewValidated = this.checkForUnAvailableInOnCrew()    
    if (onCrewValidated) {
      var crews = [];
      if (this.state.items.length > 0) {
        if (this.props.type === "4") {   
          this.state.items.filter((item, index) => {
            if (index < 6) {
              crews.push(item);
            }
            return crews;
          });
        } else {
          var on = this.state.items;
          this.state.selected.map((item, index) => {
            on.push(item);
            return on;
          });
          crews = on;
        }
        this.props.next(crews);
      } else {    
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: "Select at least 1 user",
          alertFunction: ""
        });
      }
    }
    else {
      this.setState({ anyUnavailableCrew: true }, () => {
        this.confirmationOpenModal()
      })

    }
  }

  checkForUnAvailableInOnCrew() {
    let isUnavailable = true
    const onCrew = this.state.items
    if (onCrew?.length) {
      for (const crew of onCrew) {
        if (crew.active === "Unavailable") {
          isUnavailable = false
          break
        }
      }
    }
    return isUnavailable
  }

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    let anyUnavailableCrew;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === "droppable2") {
        state = { selected: items };
      }
      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.droppable,
        selected: result.droppable2
      });
    }
    this.state.selected.forEach(t => {
      t.category = "offcrew";
    });
    this.state.items.forEach(t => {
      t.category = "oncrew";
    });
    if (this.state.items) {
      anyUnavailableCrew = this.state.items.some((item) => {
        return item.active == 'Unavailable'
      })
      this.setState({
        anyUnavailableCrew: anyUnavailableCrew
      })

    }
  };

  render() {
    // var i=1, j = 1;
    return (
      <div>
        <NewModal
          id=""
          className=""
          open={this.state.confirmation}
          onClose={this.confirmationCloseModal.bind(this)}

        >
          <div id="statusPopup">
            <div className="modal-head">
              <h4 className="modal-title">Confirm</h4>
            </div>
            <div className="modal-body">
              <p>On crew list contains unvailable employees, please review or click on Next button to continue.</p>
              <br />
              {/* <button className="button m-r-1" onClick={this.confirmationCloseModal}>Cancel</button> */}
              <button className="button" onClick={() => this.confirmationCloseModal(true)}>Close</button>
            </div>
          </div>
        </NewModal>
        <div className="applicants_container sort_your_crew">


          <div className="breadcrumbs">
            <Link to={"/resources"} className="green">
              Resources
          </Link>{" "}
            / <span onClick={this.goBack.bind(this)}>Details</span> / Sort Your
            Crew
        </div>
          <div className="page_title float">
            <h1>{this.state.h1}</h1>
          </div>
          <div className="manifest_type">
            <div className="inner_head">
              <h3>Sort Your Crew</h3>
            </div>
            <div className="content_div inner_container ">
              <div className="sort_crew_div">
                <div className="drag_sort_wrap">
                  <div>
                    <div className="row-flex">
                      <div className="heading-section task-header">On Crew</div>
                      <div className="heading-section task-header">Off Crew</div>
                    </div>
                    <div className="__drag-section">
                      <DragDropContext onDragEnd={this.onDragEnd}>
                        {/* Available Crew */}
                        <Droppable droppableId="droppable">
                          {(provided, snapshot) => (
                            <div
                              className="selected drag_manifest_box dragConList"
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.items &&
                                this.state.items.map((item, index) => (
                                  <Draggable
                                    key={index}
                                    draggableId={index}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="draggable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <span className="pos_hold">
                                          <b>{index + 1}</b>
                                        </span>
                                        <img
                                          className={item.active === 'Available' ? 'active' : 'inactive'}
                                          src={
                                            item.profile_photo
                                              ? config.apiUrl +
                                              "/" +
                                              item.profile_photo
                                              : config.apiUrl +
                                              "/" +
                                              config.altImg
                                          }
                                          alt={item.last_name}
                                          onError={this.onImageError.bind(this)}
                                        />
                                        <span>
                                          {item.first_name + " " + item.last_name}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                        {/* Selected */}
                        <Droppable droppableId="droppable2">
                          {(provided, snapshot) => (
                            <div
                              className="droppable droppable drag_manifest_box dragConList"
                              ref={provided.innerRef}
                              style={getListStyle(snapshot.isDraggingOver)}
                            >
                              {this.state.selected &&
                                this.state.selected.map((item, index) => (
                                  <Draggable
                                    key={index + "jj"}
                                    draggableId={index + "jj"}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        className="draggable"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        <span className="pos_hold" />
                                        <img
                                          className={item.active === 'Available' ? 'active' : 'inactive'}
                                          src={
                                            item.profile_photo
                                              ? config.apiUrl +
                                              "/" +
                                              item.profile_photo
                                              : config.apiUrl +
                                              "/" +
                                              config.altImg
                                          }
                                          alt={item.last_name}
                                          onError={this.onImageError.bind(this)}
                                        />
                                        <span>
                                          {item.first_name + " " + item.last_name}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                  </div>
                </div>
                <div className="clearFix" />
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={this.goToType.bind(this, this.props.type)}
                  >
                    Previous
                </button>
                  <button className="button" onClick={() =>  this.toVehicle()}>
                    Next
                </button>
                </div>
              </div>
            </div>
          </div>
          {this.state.alertPop && (
            <AlertPopup
              head={this.state.alertHead}
              message={this.state.alertMsg}
              viewOpen="true"
              alertFunction={this.state.alertFunction}
              close={this.closeAlert.bind(this)}
            />
          )}
        </div>
      </div>
    );
  }
}
// ReactDOM.render(<App />, document.getElementById('root'));
// export default Drag;

function mapStateToProps(state) {
  const { crews } = state;
  return {
    crews
  };
}

const connectedResourcePage = withRouter(connect(mapStateToProps)(SortYourCrew));
export { connectedResourcePage as SortYourCrew };
