import { authHeader, config } from "_helpers";
import { handleError, handleResponse } from "_helpers/helpers";

export const profileService = {
  getRecipientsList,
  addRecipientForTor,
  deleteRecipientForTor,
  sendDDForm,
  submitDirectDepositAuthorization,
};

function getRecipientsList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification",
    requestOptions
  ).then(handleResponse, handleError);
}

function addRecipientForTor(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification/create",
    requestOptions
  ).then(handleResponse, handleError);
}

function sendDDForm(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/direct-deposit-form/send",
    requestOptions
  ).then(handleResponse, handleError);
}

function submitDirectDepositAuthorization(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/direct-deposit-form/submit",
    requestOptions
  ).then(handleResponse, handleError);
}

function deleteRecipientForTor(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification/remove",
    requestOptions
  ).then(handleResponse, handleError);
}