import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import footerLogo from '../assets/images/firestorm.png';
import { createProfileActions, applicantsActions, userActions } from '../_actions';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { AlertPopup } from "../_components/AlertPopup";

class InformationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type : "password",
            user: {
                fname: "",
                lname: "",
                phone: "",
                secphone: "",
                fireid: "",
                ICANumber: "",
                resourceId: "",
                email: "",
                // reEmailId: "",
                password: "",
                tmpMailingAddress: "",
                tmpMailingCity: "",
                tmpMailingState: "",
                tmpMailingZip: "",
                mailingAddress: "",
                mailingCity: "",
                mailingState: "",
                mailingZip: "",
                chkSameAddress: false,
                physicalAddress: "",
                physicalCity: "",
                physicalState: "",
                physicalZip: "",
                sex: "",
                emgc1FName: "",
                emgc1LName: "",
                // emgc1PhoneCC: "",
                emgc1Phone: "",
                emgc1Relation: "",
                emgc2FName: "",
                emgc2LName: "",
                // emgc2PhoneCC: "",
                emgc2Phone: "",
                emgc2Relation: "",
                driversLicense: "",
                MSPA_ExpDate: "",
                pantSize: "",
                inseam_pant_size:"",
                shirtSize: "",
                certifiedEMT: "",
                generalQualification: "",
                lsa: "",
                weight: "",
                currentQualification: "",
                resourcetype: this.props.type,
                position: this.props.position,
            },
            generalQualificationList: "",
            maddress: false,
            mcity: false,
            mstate: false,
            mzip: false,
            fnameError: "",
            lnameError: "",
            phoneError: "",
            ResError: "",
            emailError: "",
            reEmailIdError: "",
            passwordError: "",
            mailingAddressError: "",
            mailingCityError: "",
            mailingStateError: "",
            mailingZipError: "",
            physicalAddressError: "",
            physicalCityError: "",
            physicalStateError: "",
            physicalZipError: "",
            sexError: "",
            emgc1FNameError: "",
            emgc1LNameError: "",
            emgc1PhoneError: "",
            emgc1RelationError: "",
            emgc2FNameError: "",
            emgc2LNameError: "",
            emgc2PhoneError: "",
            emgc2RelationError: "",
            QualificationError: "",
            weightError: "",
            checkboxesChecked: [],
            isLoading:false,
            alertPop: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddress = this.handleAddress.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidMount() {
        let { email } = this.props.location.state;
        this.setState({ user: { ...this.state.user, email } })
        this.props.dispatch(createProfileActions.getAll());
        this.componentWillReceiveProps(this.props)
        if (this?.props?.jobApplicantHash) {
            this.props.dispatch(applicantsActions.getApplicantDetails(this?.props?.jobApplicantHash)).then(res => {
                let dataToUpdate = {
                    fname: res?.first_name,
                    lname: res?.last_name,
                    phone: res?.phone_number,
                    secphone:res?.secondary_phone,
                    sex: res?.gender,
                    mailingAddress: res?.mailing_address1 + (res?.mailing_address2 ? ', ' + res?.mailing_address2 : ''),
                    mailingCity: res?.city_id,
                    mailingState: res?.state_id,
                    mailingZip: res?.zip_id,
                    physicalAddress: res?.physical_street_address1 ?? '' + (res?.physical_street_address2 ? ', ' + res?.physical_street_address2 : ''),
                    physicalCity: res?.physical_city_id ?? '',
                    physicalState: res?.physical_state_id ?? '',
                    physicalZip: res?.physical_zip_id ?? '',
                }
                this.setState({ user: { ...this.state.user, ...dataToUpdate } })
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.prevData) {
            this.setState({
                user: {
                    ...this.state.user,
                    fname: nextProps.prevData.fname,
                    lname: nextProps.prevData.lname,
                    phone: nextProps.prevData.phone,
                    secphone: nextProps.prevData.secphone,
                    fireid: nextProps.prevData.fireid,
                    ICANumber: nextProps.prevData.ICANumber,
                    resourceId: nextProps.prevData.resourceId,
                    email: nextProps.prevData.email,
                    reEmailId: nextProps.prevData.reEmailId,
                    password: nextProps.prevData.password,
                    mailingAddress: nextProps.prevData.mailingAddress,
                    mailingCity: nextProps.prevData.mailingCity,
                    mailingState: nextProps.prevData.mailingState,
                    mailingZip: nextProps.prevData.mailingZip,
                    chkSameAddress: nextProps.prevData.chkSameAddress,
                    physicalAddress: nextProps.prevData.physicalAddress,
                    physicalCity: nextProps.prevData.physicalCity,
                    physicalState: nextProps.prevData.physicalState,
                    physicalZip: nextProps.prevData.physicalZip,
                    sex: nextProps.prevData.sex,
                    emgc1FName: nextProps.prevData.emgc1FName,
                    emgc1LName: nextProps.prevData.emgc1LName,
                    // emgc1PhoneCC: nextProps.prevData.emgc1PhoneCC,
                    emgc1Phone: nextProps.prevData.emgc1Phone,
                    emgc1Relation: nextProps.prevData.emgc1Relation,
                    emgc2FName: nextProps.prevData.emgc2FName,
                    emgc2LName: nextProps.prevData.emgc2LName,
                    // emgc2PhoneCC: nextProps.prevData.,
                    emgc2Phone: nextProps.prevData.emgc2Phone,
                    emgc2Relation: nextProps.prevData.emgc2Relation,
                    driversLicense: nextProps.prevData.driversLicense,
                    MSPA_ExpDate: nextProps.prevData.MSPA_ExpDate,
                    pantSize: nextProps.prevData.pantSize,
                    inseam_pant_size: nextProps.prevData.inseam_pant_size,
                    shirtSize: nextProps.prevData.shirtSize,
                    certifiedEMT: nextProps.prevData.certifiedEMT,
                    generalQualification: nextProps.prevData.generalQualification,
                    lsa: nextProps.prevData.lsa,
                    weight: nextProps.prevData.weight,
                    currentQualification: nextProps.prevData.currentQualification,
                    // resourcetype: this.props.type,
                    // position: this.props.position,
                }
            })

        }
        if (nextProps.listAll) {
        }

        const resources = [], pantSize = [], shirtSize = [], qualification = [], generalQualificationList = [], lsa = [], inseamPantSize=[];
        let resource = [];

        let response = nextProps.listAll;
        if (response) {
            resource = response.FireFighter;
            if (resource) {
                resource.map((item, index) => {
                    resources.push(
                        <option className="position-option" value={item.id} key={index}>{item.resource_name}</option>
                    )
                    return resources;
                })
            }
            if (response.PantSize) {
                response.PantSize.map((item, index) => {
                    pantSize.push(
                        <option className="pant-size-option" value={item.id} key={index}>{item.value}</option>
                    )
                    return pantSize;
                })
            }
            if (response.ListInseamPantSize) {
                response.ListInseamPantSize.map((item, index) => {
                    inseamPantSize.push(
                        <option className="pant-size-option" value={item.id} key={index}>{item.value}</option>
                    )
                    return inseamPantSize;
                })
            }
            if (response.ShirtSize) {
                response.ShirtSize.map((item, index) => {
                    shirtSize.push(
                        <option className="shirt-size-option" value={item.id} key={index}>{item.value}</option>
                    )
                    return shirtSize;
                })
            }
            if (response.Qualification) {
                if (nextProps.prevData) {
                    var qual = nextProps.prevData.currentQualification.split(',');
                    response.Qualification.map((item, index) => {
                        qualification.push(
                            <span className="wpcf7-list-item" key={index}>
                                <input type="checkbox" className="currentQualification"
                                    defaultChecked={qual.indexOf((item.id).toString()) !== -1 ? 'checked' : ''}
                                    name="currentQualification[]" value={item.id} data-label={item.key} onClick={this.handleCheckbox} />
                                &nbsp;
                                <span className="wpcf7-list-item-label">{item.key}</span>
                            </span>
                        )
                        return qualification;
                    })
                } else {
                    response.Qualification.map((item, index) => {
                        qualification.push(
                            <span className="wpcf7-list-item" key={index}>
                                <input type="checkbox" className="currentQualification"
                                    name="currentQualification[]" value={item.id} data-label={item.key} onClick={this.handleCheckbox} />
                                &nbsp;
                                <span className="wpcf7-list-item-label">{item.key}</span>
                            </span>
                        )
                        return qualification;
                    })
                }
            }
            if (response.GeneralQualification) {
                response.GeneralQualification.map((item, index) => {
                    generalQualificationList.push(
                        <option className="qualification-option" value={item.id} key={index}>{item.name}</option>
                    )
                    return generalQualificationList;
                })
            }
            if (response.Lsa) {
                response.Lsa.map((item, index) => {
                    lsa.push(
                        <option className="lsa-option" value={item.id} key={index}>{item.value}</option>
                    )
                    return lsa;
                })
            }
            this.setState({
                resources, pantSize, shirtSize, qualification, generalQualificationList, lsa, inseamPantSize
            })
        }
    }
    //
    handleChange(e) {
        var name = e.target.name, value = e.target.value;
        const { user } = this.state;
        // if(value !== ""){
        var errorVar = name + "Error"
       
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
            [errorVar]: ""
        },()=>{
            var address = [
                "physicalAddress",
                "physicalCity",
                "physicalState",
                "physicalZip",
            ] ,  ob = {} ;
            if( address.indexOf( name )>-1  && this.state.user.chkSameAddress ){ 
                ob = {
                    mailingAddress: this.state.user.physicalAddress,
                    mailingCity: this.state.user.physicalCity,
                    mailingState: this.state.user.physicalState,
                    mailingZip: this.state.user.physicalZip,
                }
            }
            this.setState({
                user : {
                    ...this.state.user ,
                    ...ob
                }
            },()=>{
            })
        });
        e.target.setAttribute('errors', "");
    }

    closeAlert(){
        this.setState({
            alertPop: false
        })
    }

    handleCheckbox(e) {
        var checkboxesChecked = [], a;
        var name = e.target.name, value = e.target.value;
        if (e.target.checked) {
            this.state.checkboxesChecked.push(value);
        }
        else {
            a = this.state.checkboxesChecked.indexOf(value);
            if (a !== -1) {
                this.state.checkboxesChecked.splice(a, 1);
            }
        }
        var chkString = this.state.checkboxesChecked.toString();
        this.setState({
            user: {
                ...this.state.user,
                currentQualification: chkString
            }
        })

    }

    handleDateChange(date) {
        this.setState({
            user: {
                ...this.state.user,
                MSPA_ExpDate: date
            }
        });
    }

    toggleAddressCheckbox = () => {
        const chkSameAddressCheckbox = document.getElementById("chkSameAddress");
        chkSameAddressCheckbox.checked = !chkSameAddressCheckbox.checked; // Toggle the checkbox
        this.handleAddress({ target: chkSameAddressCheckbox }); // Call handleAddress function with checkbox event
    }

    handleAddress(e) {
        var name = e.target.name, value = e.target.value;

        if (e.target.checked) {
            this.setState({
                user: {
                    ...this.state.user,
                    chkSameAddress : true ,
                    tmpMailingAddress: this.state.user.mailingAddress,
                    tmpMailingCity: this.state.user.mailingCity,
                    tmpMailingState: this.state.user.mailingState,
                    tmpMailingZip: this.state.user.mailingZip,
                    mailingAddress: this.state.user.physicalAddress,
                    mailingCity: this.state.user.physicalCity,
                    mailingState: this.state.user.physicalState,
                    mailingZip: this.state.user.physicalZip,
                },
                maddress: true,
                mcity: true,
                mstate: true,
                mzip: true
            })

            if(document.getElementById("mailingAddress"))
            document.getElementById("mailingAddress").setAttribute('errors', "");

            if(document.getElementById("mailingCity"))
            document.getElementById("mailingCity").setAttribute('errors', "");

            if(document.getElementById("mailingState"))
            document.getElementById("mailingState").setAttribute('errors', "");

            if(document.getElementById("mailingZip"))
            document.getElementById("mailingZip").setAttribute('errors', "");
            
        } else {
            this.setState({
                maddress: false,
                mcity: false,
                mstate: false,
                mzip: false,
                fnameError: "" ,
                user : {
                    ...this.state.user,
                    chkSameAddress : false ,
                    mailingAddress: this.state.user.tmpMailingAddress,
                    mailingCity: this.state.user.tmpMailingCity,
                    mailingState: this.state.user.tmpMailingState,
                    mailingZip: this.state.user.tmpMailingZip,
                }

            })
        }
    }
    __isInvalidField( val ){
        if(!val) return true;
        if(!(String(val) || "").trim()) return true ;
        return false ;
    }

    validate(e) {
        let isError = false;
        
        if ( (this.state.user.weight||"").trim() === "" && this.props.type !== "AdminStaff") {
            isError = true;
            this.setState({
                weightError: "error"
            })
            this.weightInput.focus();
            // console.log("weight")
        }
        if ( (this.state.user.generalQualification||"").trim() === "" && this.props.type !== "AdminStaff") {
            isError = true;
            this.setState({
                QualificationError: "error"
            })
            this.QualificationInput.focus();
            // console.log("Qualification")
        }
        if ( this.__isInvalidField(this.state.user.emgc2Relation)) {
            isError = true;
            this.setState({
                emgc2RelationError: "error"
            })
            this.emgc2RelationInput.focus();
            // console.log("emgc2Relation")
        }
        if ( this.__isInvalidField(this.state.user.emgc2Phone)) {
            isError = true;
            this.setState({
                emgc2PhoneError: "error"
            })
            // this.emgc2PhoneInput.focus();
            // document.getElementById('emgc2Phone').focus();
            // console.log("emgc2Phone")
        }
        if ( this.__isInvalidField(this.state.user.emgc2LName)) {
            isError = true;
            this.setState({
                emgc2LNameError: "error"
            })
            this.emgc2LNameInput.focus();
            // console.log("emgc2LName")
        }
        if ( this.__isInvalidField(this.state.user.emgc2FName)) {
            isError = true;
            this.setState({
                emgc2FNameError: "error"
            })
            this.emgc2FNameInput.focus();
            // console.log("emgc2FName")
        }
        if (this.__isInvalidField(this.state.user.emgc1Relation)) {
            isError = true;
            this.setState({
                emgc1RelationError: "error"
            })
            this.emgc1RelationInput.focus();
            // console.log("emgc1Relation")
        }
        if ( this.__isInvalidField(this.state.user.emgc1Phone)) {
            isError = true;
            this.setState({
                emgc1PhoneError: "error"
            })
            // this.emgc1PhoneInput.focus();
            document.getElementById('emgc1Phone').focus();
            // console.log("emgc1Phone")
        }
        if ( this.__isInvalidField(this.state.user.emgc1LName)) {
            isError = true;
            this.setState({
                emgc1LNameError: "error"
            })
            this.emgc1LNameInput.focus();
            // console.log("emgc1LName")
        }
        if ( this.__isInvalidField(this.state.user.emgc1FName)) {
            isError = true;
            this.setState({
                emgc1FNameError: "error"
            })
            this.emgc1FNameInput.focus();
            // console.log("emgc1FName")
        }
        if ( this.__isInvalidField(this.state.user.sex)) {
            isError = true;
            this.setState({
                sexError: "error"
            })
            this.sexInput.focus();
            // console.log("sex")
        }
        if (this.__isInvalidField(this.state.user.physicalZip)) {
            isError = true;
            this.setState({
                physicalZipError: "error"
            })
            this.physicalZipInput.focus();
            // console.log("physicalZip")
        }

        if (this.__isInvalidField(this.state.user.physicalState)) {
            isError = true;
            this.setState({
                physicalStateError: "error"
            })
            this.physicalStateInput.focus();
            // console.log("physicalState")
        }

        if (this.__isInvalidField(this.state.user.physicalCity)) {
            isError = true;
            this.setState({
                physicalCityError: "error"
            })
            this.physicalCityInput.focus();
            // console.log("physicalCity")
        }
        if (this.__isInvalidField(this.state.user.physicalAddress)) {
            isError = true;
            this.setState({
                physicalAddressError: "error"
            })
            this.physicalAddressInput.focus();
            // console.log("physicalAddress")
        }
        if (this.__isInvalidField(this.state.user.mailingZip)) {
            isError = true;
            this.setState({
                mailingZipError: "error"
            })
            this.mailingZipInput.focus();
            // console.log("mailingZip")
        }
        if ( this.__isInvalidField(this.state.user.mailingState)) {
            isError = true;
            this.setState({
                mailingStateError: "error"
            })
            this.mailingStateInput.focus();
            // console.log("mailingState")
        }
        if (this.__isInvalidField(this.state.user.mailingCity )) {
            isError = true;
            this.setState({
                mailingCityError: "error"
            })
            this.mailingCityInput.focus();
            // console.log("mailingCity")
        }
        if ( this.__isInvalidField(this.state.user.mailingAddress)) {
            isError = true;
            this.setState({
                mailingAddressError: "error"
            })
            this.mailingInput.focus();
            // console.log("mailingAddress")
        }
        if ( this.__isInvalidField(this.state.user.password) || this.state.user.password.length < 6) {
            isError = true;
            this.setState({
                passwordError: "error"
            })
            this.passwordInput.focus();
            // console.log("password")
        }
        // if (this.__isInvalidField(this.state.user.reEmailId)) {
        //     isError = true;
        //     this.setState({
        //         reEmailIdError: "error"
        //     })
        //     this.reEmailInput.focus();
        //     // console.log("reEmailId")
        // }
        // if ( ( this.state.user.reEmailId||"").trim() !== "" && this.state.user.reEmailId !== this.state.user.email) {
        //     isError = true;
        //     if (this.state.user.reEmailId.length < this.state.user.email.length) {
        //         this.setState({
        //             reEmailIdError: "error"
        //         })
        //         this.reEmailInput.focus();
        //     } else {
        //         this.setState({
        //             emailError: "error"
        //         })
        //         this.emailInput.focus();
        //     }

        // }
        // if(this.state.user.reEmailId !== "" && this.state.reEmailId === this.state.emailId ){
        //     isError = false;
        //     this.setState({
        //       reEmaiError: ""
        //     })
        // }
        if (this.__isInvalidField(this.state.user?.email)) {
            isError = true;
            this.setState({
                emailError: "error"
            })
            this.emailInput.focus();
            // console.log("email")
        }
        // elseif(!this.IsValidEmail(this.state.email)){
        //     isError = true;
        //     this.setState({
        //         emailError: "error"
        //     })
        //     console.log("emailError1")
        //     this.emailInput.focus();
        // }
        // else{
        //     isError = false;
        //     this.setState({
        //       emailError: ""
        //     })
        // }
        if ( this.__isInvalidField(this.state.user.resourceId)) {
            isError = true;
            this.setState({
                ResError: "error"
            })
            this.resourceInput.focus();
            // console.log("resourceId")
        }
        if (this.__isInvalidField(this.state.user.phone)) {
            isError = true;
            this.setState({
                phoneError: "error"
            })
            // this.phoneInput.focus();
            document.getElementById('phone').focus();
            // console.log("phone")
        }
        if (this.__isInvalidField(this.state.user.lname)) {
            isError = true;
            this.setState({
                lnameError: "error"
            })
            this.lnameInput.focus();
            // console.log("lname")
        }
        if (this.__isInvalidField(this.state.user.fname)) {
            isError = true;
            this.setState({
                fnameError: "error",
                fnameError1: "fnameError"
            })
            this.fnameInput.focus();
            // console.log("fname")
        }

        return isError;
    }
    IsValidEmail(email) {
        var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return expr.test(email);
    };

    handleSubmit(e) {
        e.preventDefault();
        const err = this.validate();

        if (!err) {
          this.setState({ isLoading: true });
          let reqData = new FormData();
          for (var key in this.state.user) {
            reqData.append(key, this.state.user[key]);
          }

          this.props.dispatch(userActions.register(reqData)).then((res) => {
            if (res.success) {
            } else {
              this.setState({
                _loader: false
              });
              this.setState({
                alertPop: true,
                alertHead: "Error",
                alertMsg: res.message,
                alertFunction: ""
              });
            }
          });
        }
    }

    goBack() {
        this.props.choosePosition(this.props.type)
    }

    togglePassword(){
        this.setState({
            type : this.state.type === "password" ? "text" :"password"
        })
    }

    
    render() {
        return (
            <div className="resource_type">
                <div className="login_header">
                    <Link to={"/login"}>
                        <img src={footerLogo} alt="Firestorm" />
                    </Link>
                    <p>Employee Management System</p>
                </div>

                <div className="top_head">
                    <Link to={"/login"} className="back">Back to Login Page</Link>
                    <span className="title">Step 1 - Information: {this.props.position}</span>
                </div>

                <div className="signup-contentarea">
                    <div id="informationWrapA" className="information-fill">

                        <div className="half_div fname">
                            <label>First name*</label>
                            <input id="fname" type="text" name="fname" ref={(input) => { this.fnameInput = input; }} className="txt half1" value={this.state.user.fname} errors={this.state.fnameError} onChange={this.handleChange} />
                        </div>
                        <div className="half_div lname">
                            <label>Last name*</label>
                            <input id="lname" type="text" name="lname" ref={(input) => { this.lnameInput = input; }} className="txt half2" value={this.state.user.lname} errors={this.state.lnameError} onChange={this.handleChange} />
                        </div>

                        <p className="r-highlight">Must use legal name as shown on Gov't issued identification card</p>

                        <label>Cell Phone* (Visible To All Staff)</label>
                        <input id="phoneNumberCC" type="text" name="phoneNumberCC" readOnly="" placeholder="+1" value="+1" className="txt txt-country-code readonly" maxLength="2" />
                        {/*<input id="phone" type="text" name="phone" className="txt txt-phone phone-number-validate" ref={(input) => { this.phoneInput = input; }} value={this.state.user.phone} maxLength="10" errors={this.state.phoneError} onChange={this.handleChange}   />*/}
                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} id="phone" type="text" name="phone" className="txt txt-phone phone-number-validate" ref={(input) => { this.phoneInput = input; }} value={this.state.user.phone} errors={this.state.phoneError} />

                        <label>Secondary Phone</label>
                        <input id="secPhoneNumberCC" type="text" name="secPhoneNumberCC" readOnly="" placeholder="+1" value="+1" className="txt txt-country-code readonly" maxLength="2" />
                        {/*<input id="secPhoneNumber" type="text" name="secphone" className="txt txt-phone phone-number-validate" maxLength="10" value={this.state.user.secphone} onChange={this.handleChange}  />*/}
                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} id="secPhoneNumber" type="text" name="secphone" className="txt txt-phone phone-number-validate" value={this.state.user.secphone} />
                        
                        

                        <label>What Resource are you assigned to?*</label>
                        <select id="resourceId" ref={(input) => { this.resourceInput = input; }} name="resourceId" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" errors={this.state.ResError} onChange={this.handleChange} >
                            <option value="">What Resource are you assigned to?*</option>
                            {this.state.resources}
                        </select>

                        <label>Email* (Visible To All Staff)</label>
                        <input id={this.state.emailError} type="email" name="email" className="txt" ref={(input) => { this.emailInput = input; }} value={this.state.user.email} errors={this.state.emailError} onChange={this.handleChange} readOnly/>

                        {/* <label>Re-enter email*</label>
                        <input id="reEmailId" type="email" name="reEmailId" ref={(input) => { this.reEmailInput = input; }} className="txt" value={this.state.user.reEmailId} errors={this.state.reEmailIdError} onChange={this.handleChange} /> */}

                        <label>Re-enter password*</label>
                        <div className="inline-login-txt-wrapper">
                        <input id="password" type={this.state.type} ref={(input) => { this.passwordInput = input; }} name="password" className="txt" value={this.state.user.password} errors={this.state.passwordError} onChange={this.handleChange} />
                        <span className="toggle-icon-password toggle-icon-register" onClick={(event)=>{
                             this.togglePassword(event);
                         }} aria-hidden={this.state.type === "password" ? "true" : "false"}>
                        <img src={require("../assets/images/eye.png").default} />
                        <img src={require("../assets/images/eye-hide.png").default} />
                        </span>
                        </div>

                        <label>Physical Address*</label>
                        <input id="physicalAddress" type="text" ref={(input) => { this.physicalAddressInput = input; }} name="physicalAddress" className="txt" value={this.state.user.physicalAddress} errors={this.state.physicalAddressError} onChange={this.handleChange} />

                        <div className="address_div">
                            <div className="city_div">
                                <label>City*</label>
                                <input id="physicalCity" type="text" ref={(input) => { this.physicalCityInput = input; }} name="physicalCity" className="txt half1" value={this.state.user.physicalCity} errors={this.state.physicalCityError} onChange={this.handleChange} />
                            </div>

                            <div className="half2 half2_div">
                                <div className="addressData">
                                    <label>State*</label>
                                    <input id="physicalState" type="text" ref={(input) => { this.physicalStateInput = input; }} name="physicalState" className="txt half1" value={this.state.user.physicalState} errors={this.state.physicalStateError} onChange={this.handleChange} />
                                </div>

                                <div className="addressData">
                                    <label>Zip*</label>
                                    <input id="physicalZip" type="text" ref={(input) => { this.physicalZipInput = input; }} name="physicalZip" className="txt half2" value={this.state.user.physicalZip} errors={this.state.physicalZipError} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                        <span className="wpcf7-list-item"><input id="chkSameAddress" type="checkbox" name="chkSameAddress" value={this.state.user.chkSameAddress} onClick={this.handleAddress} style={{cursor: "pointer", marginRight: "0px"}} /><span className="wpcf7-list-item-label" onClick={this.toggleAddressCheckbox} style={{cursor: "pointer"}}><b>&nbsp;&nbsp;Same as Physical Address</b></span></span>
;
                        <label>Mailing Address*</label>
                        <input id="mailingAddress" type="text" ref={(input) => { this.mailingInput = input; }} name="mailingAddress" className="txt" value={this.state.user.mailingAddress} errors={this.state.mailingAddressError} onChange={this.handleChange} readOnly={this.state.maddress} style={{ pointerEvents: this.state.maddress ? 'none' : 'auto'  }} />

                        <div className="address_div">
                            <div className="city_div">
                                <label>City*</label>
                                <input id="mailingCity" type="text" ref={(input) => { this.mailingCityInput = input; }} name="mailingCity" className="txt half1" value={this.state.user.mailingCity} errors={this.state.mailingCityError} onChange={this.handleChange} readOnly={this.state.mcity} style={{ pointerEvents: this.state.mcity ? 'none' : 'auto'  }} />
                            </div>

                            <div className="half2 half2_div">
                                <div className="addressData">
                                    <label>State*</label>
                                    <input id="mailingState" type="text" ref={(input) => { this.mailingStateInput = input; }} name="mailingState" className="txt half1" value={this.state.user.mailingState} errors={this.state.mailingStateError} onChange={this.handleChange} readOnly={this.state.mstate} style={{ pointerEvents: this.state.mstate ? 'none' : 'auto'  }}  />
                                </div>

                                <div className="addressData">
                                    <label>Zip*</label>
                                    <input id="mailingZip" type="text" ref={(input) => { this.mailingZipInput = input; }} name="mailingZip" className="txt half2" value={this.state.user.mailingZip} errors={this.state.mailingZipError} onChange={this.handleChange} readOnly={this.state.mzip} style={{ pointerEvents: this.state.mzip ? 'none' : 'auto'  }}  />
                                </div>
                            </div>
                        </div>

                        <label>Sex*</label>
                        <select id="sex" name="sex" ref={(input) => { this.sexInput = input; }} className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" value={this.state.user.sex} aria-invalid="false" errors={this.state.sexError} onChange={this.handleChange} >
                            <option value="">Sex*</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                        </select>

                        <h3>Emergency Contact #1</h3>
                        <div className="half_div fname">
                            <label>First name*</label>
                            <input id="emgc1FName" type="text" ref={(input) => { this.emgc1FNameInput = input; }} name="emgc1FName" className="txt half1" value={this.state.user.emgc1FName} errors={this.state.emgc1FNameError} onChange={this.handleChange} />
                        </div>
                        <div className="half_div lname">
                            <label>Last name*</label>
                            <input id="emgc1LName" type="text" ref={(input) => { this.emgc1LNameInput = input; }} name="emgc1LName" className="txt half2" value={this.state.user.emgc1LName} errors={this.state.emgc1LNameError} onChange={this.handleChange} />
                        </div>

                        <label>Cell Phone*</label>
                        <input id="emgc1PhoneCC" type="text" name="emgc1PhoneCC" readOnly="" placeholder="+1" className="txt txt-country-code readonly" maxLength="2" value="+1" />
                        {/*<input id="emgc1Phone" type="text" ref={(input) => { this.emgc1PhoneInput = input; }} name="emgc1Phone" className="txt txt-phone phone-number-validate" maxLength="10" value={this.state.user.emgc1Phone} errors={this.state.emgc1PhoneError} onChange={this.handleChange}  />*/}
                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} id="emgc1Phone" type="text" ref={(input) => { this.emgc1PhoneInput = input; }} name="emgc1Phone" className="txt txt-phone phone-number-validate" value={this.state.user.emgc1Phone} errors={this.state.emgc1PhoneError} />

                        <label>Relationship*</label>
                        <input id="emgc1Relation" type="text" ref={(input) => { this.emgc1RelationInput = input; }} name="emgc1Relation" className="txt" value={this.state.user.emgc1Relation} errors={this.state.emgc1RelationError} onChange={this.handleChange} />

                        <h3>Emergency Contact #2</h3>
                        <div className="half_div fname">
                            <label>First name*</label>
                            <input id="emgc2FName" type="text" ref={(input) => { this.emgc2FNameInput = input; }} name="emgc2FName" className="txt half1" value={this.state.user.emgc2FName} errors={this.state.emgc2FNameError} onChange={this.handleChange} />
                        </div>
                        <div className="half_div lname">
                            <label>Last name*</label>
                            <input id="emgc2LName" type="text" ref={(input) => { this.emgc2LNameInput = input; }} name="emgc2LName" className="txt half2" value={this.state.user.emgc2LName} errors={this.state.emgc2LNameError} onChange={this.handleChange} />
                        </div>

                        <label>Cell Phone*</label>
                        <input id="emgc2PhoneCC" type="text" name="emgc2PhoneCC" readOnly="" placeholder="+1" className="txt txt-country-code readonly" maxLength="2" value="+1" />
                        {/*<input id="emgc2Phone" type="text" ref={(input) => { this.emgc2PhoneInput = input; }} name="emgc2Phone" className="txt txt-phone phone-number-validate" maxLength="10" value={this.state.user.emgc2Phone} errors={this.state.emgc2PhoneError} onChange={this.handleChange}  />*/}
                        <NumberFormat format="###-###-####" mask="_" onChange={this.handleChange} id="emgc2Phone" type="text" ref={(input) => { this.emgc2PhoneInput = input; }} name="emgc2Phone" className="txt txt-phone phone-number-validate" value={this.state.user.emgc2Phone} errors={this.state.emgc2PhoneError} />

                        <label>Relationship*</label>
                        <input id="emgc2Relation" type="text" ref={(input) => { this.emgc2RelationInput = input; }} name="emgc2Relation" className="txt" value={this.state.user.emgc2Relation} errors={this.state.emgc2RelationError} onChange={this.handleChange} />
                        <br /><br />


                        <label>Drivers License</label>
                        <input id="driversLicense" type="text" name="driversLicense" className="txt" value={this.state.user.driversLicense} onChange={this.handleChange} />


                        <label>Pant Size</label>
                        <select id="ddlPantSize" name="pantSize" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" value={this.state.user.pantSize} onChange={this.handleChange} >
                            <option value="">Pant Size</option>
                            {this.state.pantSize}
                        </select>

                        <label>Inseam Pant Size</label>
                        <select id="ddlInseamPantSize" name="inseam_pant_size" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" value={this.state.user.inseam_pant_size} onChange={this.handleChange} >
                            <option value="">Inseam Pant Size</option>
                            {this.state.inseamPantSize}
                        </select>

                        <label>Shirt Size</label>
                        <select id="ddlShirtSize" name="shirtSize" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" value={this.state.user.shirtSize} onChange={this.handleChange} >
                            <option value="">Shirt Size</option>
                            {this.state.shirtSize}
                        </select>

                        <label>Certified EMT?</label>
                        <select id="ddlCertifiedEMT" name="certifiedEMT" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" value={this.state.user.certifiedEMT} onChange={this.handleChange} >
                            <option value="">Certified EMT?</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>

                        <div>
                            <div id="firefigterOptions">
                                <label>Qualification*</label>
                                <select id="chooseQualification" ref={(input) => { this.QualificationInput = input; }} name="generalQualification" className="wpcf7-form-control wpcf7-select wpcf7-validates-as-required txt" aria-required="true" aria-invalid="false" value={this.state.user.generalQualification} errors={this.state.QualificationError} onChange={this.handleChange} >
                                    <option value="">Qualification*</option>
                                    {this.state.generalQualificationList}
                                </select>

                                <label>Weight in lbs (or pounds)*</label>
                                <input id="weight" type="text" ref={(input) => { this.weightInput = input; }} name="weight" className="txt" value={this.state.user.weight} errors={this.state.weightError} onChange={this.handleChange} />

                                {/* <div><span className="special-h-1">Current Fire Qualifications</span></div>
                                <br />
                                <div className="special-h-2">Check all that apply</div>
                                <div><span className="wpcf7-form-control-wrap chkCurrentQualification">

                                    <span id="currentQualification" className="wpcf7-form-control wpcf7-checkbox check-list-1">
                                        {this.state.qualification}
                                    </span>
                                </span></div> */}
                            </div>
                        </div>
                        <input type="submit" className="button nextBtn" value="Complete Profile" onClick={this.handleSubmit} />
                        <div className="clearfix"></div>
                    </div>
                </div>
                { this.state.alertPop &&
                    <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)}/>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { listAll } = state.createProfile;
    const { user, authentication } = state
    const jobApplicantHash = authentication?.user?.success?.hash
    return {
        listAll,
        user,
        jobApplicantHash
    };
}

const connectedRegisterPage = compose(withRouter,connect(mapStateToProps))(InformationForm);
export { connectedRegisterPage as InformationForm };
