import React from "react";
import { useForm, Controller } from "react-hook-form";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ErrorBoundary from "_components/ErrorBoundary";
import FormContainer from "../Components/FormContainer";
import TextInput from "../Components/TextInput";
import CustomDatePicker from "../Components/DatePicker";
import Checkbox from "../Components/Checkbox";
import RadioButton from "../Components/RadioButton";
import CustomSignaturePad from "../Components/SignaturePad";
import SubmitButton from "../Components/SubmitButton";
import { docsActions, createProfileActions, chatActions } from "_actions";
import mapEmptyToNull from "_helpers/mapEmptyToNull";
import "../../assets/styles/timeOfRequest.css";
import { usPhone } from "_utils/formatter";
import {
  getCurrentUserInfo,
  selectUserDocs,
  getResourceList,
} from "_helpers/reduxSelector";
import DocStatusTag from "_components/utils/DocStatusTag";
import { withRouter } from "react-router-dom";
import { config } from "_helpers";
import { dateTimeConstants } from '_constants';

const DATE_FORMAT = "MM/DD/YYYY";

const defaultValues = {
  duty_officer_name: "",
  duty_officer_signature: "",
  duty_officer_signature_date: "",
  note: "",
  cash_out_pph_check: false,
  date: "",
  employee_printed_name: "",
  employee_signature_date: "",
  additional_info: "",
  pph_applied: "",
  pph_available: "",
  pph_available_date: null,
  pph_balance: "",
  pph_email: "",
  pph_inquiry_check: false,
  pph_phone_call: false,
  pph_phone_text: false,
  pph_phone: "",
  pph_phone_method: "",
  request_for_time_off_check: false,
  request_sick_pay_check: false,
  sick_pay_applied: "",
  sick_pay_available: "",
  sick_pay_balance: "",
  sick_pay_date: "",
  sick_pay_dates: [""],
  sick_pay_hours: [""],
  superintendent_name: "",
  superintendent_signature: "",
  superintendent_signature_date: "",
  time_off_from: "",
  time_off_to: "",
  time_off_type: "",
  use_pph: "",
  with_pay_hours: "",
  disclaimer_check: false,
};

const toastConfig = {
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const resourceTypeList = {
  1: "ADMIN_STAFF_RESOURCE",
  2: "FIRE_SUPPRESSION_RESOURCE",
  3: "FIRE_PREVENTION_RESOURCE",
  4: "GISS_RESOURCE",
  5: "FUELS_RESOURCE",
};

const ADMIN_STAFF_RESOURCE = {
  ADMIN: "Admin",
  OPERATIONS: "Operations",
};

const DUTY_OFFICER = {
  "JIMMY" : "jimmy@firestormfire.com",
  "HENRY" : "henry@deercreekgis.com",
  "HR" : "hr@firestormfire.com",
  "RON" : "fwfsprevention@gmail.com"

}

const TimeOffRequest = ({
  isSingleDoc,
  docDetails: docDetailsProp,
  adminMode: adminModeProps,
  goBack,
  history,
  status: initialStatus,
}) => {
  const dispatch = useDispatch();

  const {
    // register,
    handleSubmit,
    watch,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
    shouldFocusError: true,
  });

  const uncheckOtherTypes = (checkedType) => {
    switch(checkedType) {
      case 'request_for_time_off_check':
        setValue('request_sick_pay_check', false);
        setValue('pph_inquiry_check', false);
        setValue('cash_out_pph_check', false);
        break;
      case 'request_sick_pay_check':
        setValue('request_for_time_off_check', false);
        setValue('pph_inquiry_check', false);
        setValue('cash_out_pph_check', false);
        break;
      case 'pph_inquiry_check':
        setValue('request_for_time_off_check', false);
        setValue('request_sick_pay_check', false);
        setValue('cash_out_pph_check', false);
        break;
      case 'cash_out_pph_check':
        setValue('request_for_time_off_check', false);
        setValue('pph_inquiry_check', false);
        setValue('request_sick_pay_check', false);
        break;
      default:
        setValue('request_sick_pay_check', false);
        setValue('pph_inquiry_check', false);
        setValue('cash_out_pph_check', false);
        setValue('request_for_time_off_check', false);
    };
  };

  const [isFormLoading, setIsFormLoading] = React.useState(false);
  const [docDetails, setDocDetails] = React.useState(docDetailsProp);
  const [adminMode, setAdminMode] = React.useState(adminModeProps);
  const [formData, setFormData] = React.useState({});
  const [formUserData, setFormUserData] = React.useState({});
  const [formOwnerData, setFormOwnerData] = React.useState({});
  const [resourceType, setResourceType] = React.useState("");
  const [ownerAdminOrSup, setOwnerAdminOrSup] = React.useState("");
  const [resourceTypeID, setResourceTypeID] = React.useState("");
  const [isDenied, setIsDenied] = React.useState(false);
  const [selectLeastOneOptionError, setSelectLeastOneOptionError] =
    React.useState(false);
  const [localStatus, setLocalStatus] = React.useState(initialStatus);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const userDocs = useSelector(selectUserDocs);
  const resourceList = useSelector(getResourceList);
  const signature = useSelector((state) => state?.chat.settings?.signature);
  

  const WatchDisclaimerCheck = watch("disclaimer_check");
  const WatchSickPayDates = watch("sick_pay_dates");
  const WatchSickPayHours = watch("sick_pay_hours");
  const WatchPphInquiryCheck = watch("pph_inquiry_check");
  const WatchSickPayDate = watch("request_sick_pay_check");
  const WatchRequestTimeOffCheck = watch("request_for_time_off_check");
  const WatchEmployeeSignature = watch("employee_signature");
  const WatchTimeOffTypeCheck =watch("time_off_type");

  const watchSupervisorName = watch("superintendent_name");
  const watchSupervisorSignature = watch("superintendent_signature");
  const watchSupervisorDate = watch("superintendent_signature_date");

  const watchAdditionalInfo = watch("additional_info");

  const isDutyOfficerName = watch("duty_officer_name");
  const isDutyOfficerSign = watch("duty_officer_signature");
  const isDutyOfficerDate = watch("duty_officer_signature_date");

  const pph_phone = watch("pph_phone");

  const isAdminsOwnDoc = currentUserInfo?.id === formUserData?.id;

  React.useEffect(() => {
    if (!docDetails?.docs_type_id && userDocs?.length) {
      let _docDetails = userDocs?.find(
        (item) =>
          item?.slug === docDetails?.slug && item?.docs_categories_id === 3
      );
      setDocDetails({ ..._docDetails, ...docDetails });
    }
  }, [docDetails, userDocs]);

  React.useEffect(() => {
    const fetchSettings = async () => {
      await dispatch(chatActions.getConversationSettings());
    };
    fetchSettings();
  }, []);

  const handleOnSubmit = async () => {
    let values = getValues();
    if (values.time_off_type == 'without') {
      values.with_pay_hours = '';
    }
    values.sick_pay_dates = `${values.sick_pay_dates}`;
    values.sick_pay_hours = `${values.sick_pay_hours}`;

    if (!!pph_phone.length && pph_phone.length < 10) {
      return toast.error("Invalid phone number format!");
    }

    if (formData.enable_payroll_section && !(values.pph_available && values.pph_available_date && values.pph_applied && values.pph_balance && values.sick_pay_available && values.sick_pay_date && values.sick_pay_applied && values.sick_pay_balance)) {
      return toast.error("Missing payroll fields!");
    }

    // Least on Checkbox
    let validateMandatoryCheckbox = [
      "pph_inquiry_check",
      "cash_out_pph_check",
      "request_sick_pay_check",
      "request_for_time_off_check",
    ]?.map((key) => !!values?.[key]);
    //
    if (!validateMandatoryCheckbox?.includes(true)) {
      setSelectLeastOneOptionError(true);
      return;
    }
    setIsFormLoading(true);
    // Remove dependent fields if it's not checked
    if (!values?.pph_inquiry_check) {
      values.pph_email = null;
      values.pph_phone = null;
      values.pph_phone_call = null;
      values.pph_phone_text = null;
    }
    values.is_denied = isDenied ? 1 : 0;
    if (!values?.request_sick_pay_check) {
      values.sick_pay_dates = "";
      values.sick_pay_hours = "";
    }
    if (!values?.request_for_time_off_check) {
      values.time_off_from = null;
      values.time_off_to = null;
    }
    values.is_duty_officer = formData.enable_duty_officer_section ? 1 : 0;
    values.is_superintendent = formData?.enable_superintendent_section ? 1 : 0;
    values.is_payroll = formData.enable_payroll_section ? 1 : 0;
    values.is_employee = formData.enable_employee_section ? 1 : 0;
    // values.is_duty_officer =
    //   values?.duty_officer_name && currentUserRoles?.includes("Staff Manager")
    //     ? 1
    //     : 0;
    // End
    values = mapEmptyToNull(values);
    const payload = {
      doc_type: docDetails?.docs_type_id,
      form: values,
      id: docDetails?.id,
      rehire: false,
      single_doc: true,
    };
    dispatch(docsActions.saveDoc(payload))
      .then((res) => {
        if (res?.success) {
          toast.success(
            isDenied
              ? "Time off request denied successfully."
              : "Time off request submitted!",
            {
              position: "top-right",
              autoClose: 1500,
              ...toastConfig,
            }
          );

          if (isSuperintendentOrAdmin) {
            if (docDetails.hash) {
              dispatch(docsActions.getUserDocs(docDetails.hash));
              history.push(`/profile/${docDetails.hash}`);
            } else {
              const url = history.location.pathname;
              const id = url.split("/")[2];
              dispatch(docsActions.getUserDocs(id));
              goBack();
              // history.push(`/resource-profile/${id}`)
            }
          } else {
            dispatch(docsActions.getUserDocs(formUserData.hash));
            history.push(`/settings`);
          }
          if (res?.message === "-Time off request is approved!") {
            const pdfUrl = config.apiUrl + "/docs/pdf/" + docDetails?.slug + "?id=" + docDetails?.id + "&signature=" + signature + "&uid=" + currentUserInfo?.hash;
        
            // Fetch the PDF content
            fetch(pdfUrl)
                .then(response => response.blob())
                .then(blob => {
                  // Create a Blob URL for the PDF content
                  const blobUrl = URL.createObjectURL(blob);

                  // Generate a unique filename
                  const timestamp = new Date().getTime(); // Current timestamp
                  const filename = `Time_Off_Request_${timestamp}_${currentUserInfo.hash}.pdf`;
        
                  // Create an anchor element
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.download = filename;               

        
                  // Programmatically click on the anchor element to trigger the download
                  link.click();
        
                  // Revoke the Blob URL after download
                  URL.revokeObjectURL(blobUrl);
                })
                .catch(error => console.error('Error downloading PDF:', error));
        }
        
        } else {
          throw res;
        }
        setIsDenied(false);
      })
      .catch((err) => {
        setIsFormLoading(false);
        toast.error(err?.message ?? "Submission Failed", {
          position: "top-right",
          autoClose: 5000,
          ...toastConfig,
        });
      });
  };

  React.useEffect(() => {
    // If the external status changes and you want to update the local status accordingly
    setLocalStatus(initialStatus);
  }, [initialStatus]);

  const updateStatus = (newStatus) => {
    setLocalStatus(newStatus);
    // Perform other actions such as dispatching redux actions or calling APIs
  };

  React.useEffect(() => {
    const unlisten = history.listen(() => {
      //after history has pushed the url in line number 194 or 97, go to listings page
      goBack();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  React.useEffect(() => {
    const docId = docDetails?.id;
    if (docId) {
      setIsFormLoading(true);
      dispatch(docsActions.getForm(docId))
        .then((res) => {
          if (res.success) {
            let initialData = res?.data?.form;
            let userData = res?.data?.user;
            if (initialData.tor_docs_status) {
              updateStatus(initialData.tor_docs_status)
            }

            if (!initialData.request_for_time_off_check && !initialData.request_sick_pay_check && !initialData.pph_inquiry_check && !initialData.cash_out_pph_check) {
              initialData.request_for_time_off_check = true;
            }

            if (initialData?.sick_pay_dates) {
              initialData.sick_pay_dates = initialData?.sick_pay_dates
                ?.replaceAll(",", " ")
                .split(" ");
            }
            // Check if sick_pay_hours exists and has data; if not, initialize it
            if (!initialData.sick_pay_hours || initialData.sick_pay_hours.trim() === "") {
              initialData.sick_pay_hours = new Array(initialData.sick_pay_dates.length).fill(8);
            } else {
              // If it does exist, process it similarly to sick_pay_dates
              initialData.sick_pay_hours = initialData.sick_pay_hours.replaceAll(",", " ").split(" ");
            }
            if (!initialData?.employee_printed_name) {
              initialData.employee_printed_name = userData?.full_name ?? "";
            }

            const options = { 
              timeZone: dateTimeConstants.PST_TIMEZONE,
              month: dateTimeConstants.TWO_DIGIT_FORMAT, 
              day: dateTimeConstants.TWO_DIGIT_FORMAT, 
              year: dateTimeConstants.NUMERIC_FORMAT
            };
            const formattedDate = new Date().toLocaleDateString(dateTimeConstants.LOCALE_DATE_STRING_FORMAT, options);
            console.log(formattedDate);

            if (initialData?.enable_employee_section) {
              initialData.date = initialData.date ? initialData.date : formattedDate;
            }

            if (initialData?.enable_superintendent_section) {
              initialData.superintendent_signature_date = initialData.superintendent_signature_date ? initialData.superintendent_signature_date : formattedDate;
            }

            if (initialData?.enable_duty_officer_section) {
              initialData.duty_officer_signature_date = initialData.duty_officer_signature_date ? initialData.duty_officer_signature_date : formattedDate;
            }

            setFormUserData(userData);
            setFormData(initialData);
            setFormOwnerData(res?.data?.form_owner);
          }
          setIsFormLoading(false);
        })
        .catch((err) => {
          setIsFormLoading(false);
          toast.error(err?.message ?? "Error on loading data!", {
            position: "top-right",
            autoClose: 5000,
            ...toastConfig,
          });
        });
    }
  }, []);

  React.useEffect(() => {
    dispatch(createProfileActions.getAllResource());
  }, [dispatch]);

  React.useEffect(() => {
    if (resourceList.length > 0) {
      const matchingItem = resourceList.find(
        (item) => item.id === formOwnerData?.crew_identifier_id
      );

      if (matchingItem) {
        setResourceTypeID(matchingItem.resource_type_id);
        setResourceType(resourceTypeList?.[matchingItem.resource_type_id]);
      }
    }
  }, [resourceList, formOwnerData?.crew_identifier_id]);

  const handleRequestForSickPayDelete = (itemIndex) => {
    let _WatchSickPayDates = [...WatchSickPayDates];
    _WatchSickPayDates.splice(itemIndex, 1);
    setValue("sick_pay_dates", _WatchSickPayDates);

    let _WatchSickPayHours = [...WatchSickPayHours];
    _WatchSickPayHours.splice(itemIndex, 1);
    setValue("sick_pay_hours", _WatchSickPayHours);
  };
  
  const datePickerNullSanitize = (date) =>
    date ? moment(date).format(DATE_FORMAT) : "";

  React.useEffect(() => {
    if (WatchSickPayDate && !WatchSickPayDates) {
      setValue(`sick_pay_dates.0`, "");
      setValue(`sick_pay_hours.0`, "");
    }
  }, [WatchSickPayDate]);

  React.useEffect(() => {
    reset(formData);
  }, [formData]);

  const currentUserRoles = React.useMemo(() => {
    return currentUserInfo?.roles?.map((item) => item?.name);
  }, [currentUserInfo]);

  const formOwnerRoles = React.useMemo(() => {
    return formOwnerData?.roles?.map((item) => item?.name);
  }, [formOwnerData]);

  const formBelongsToCurrentUser = React.useMemo(() => {
    return currentUserInfo?.hash === formUserData?.hash;
  }, [currentUserInfo, formUserData]);

  const renderDeniedStatus = () => {
    if (isSuperintendentOrAdmin && formData?.is_denied === "1") {
      return <p style={{ color: "red" }}>This document is already denied.</p>;
    } else if (!isSuperintendentOrAdmin && formData?.is_denied === "1") {
      return (
        <p style={{ color: "red" }}>
          This document is not approved, please contact the admin for more
          details.
        </p>
      );
    } else {
      return "";
    }
  };


  React.useEffect(() => {
    setAdminMode(
      currentUserRoles?.includes("Super Admin") ||
        currentUserRoles?.includes("Admin") ||
        currentUserRoles?.includes("Staff Manager") ||
        currentUserRoles?.includes("Superintendent")
    );
  }, [currentUserRoles]);

  React.useEffect(() => {
    setOwnerAdminOrSup(
      formOwnerRoles?.includes("Super Admin") ||
        formOwnerRoles?.includes("Admin") ||
        formOwnerRoles?.includes("Superintendent")
    );
  }, [formOwnerRoles]);

  const handleDeny = () => {
    setIsDenied(true);
  };

  const isSuperintendentOrAdmin =
    currentUserRoles?.includes("Superintendent") || adminMode;

  // const disableDutyOfficerFields = hasPermissionToEditStaffOrAdmin ? (WatchSuperVisorSignature && WatchEmployeeSignature) : true
  const disableDutyOfficerFields =
    !formData.superintendent_signature && adminMode;

  //if admin login, if emp signature then no modify employees data
  const adminLoginAndEmployeeSigned =
    currentUserInfo?.is_admin > 0 && formData?.employee_signature;

  //if employee login, if sup sign /duty-off sign , sheet full disabled
  const employeeLoginAndSupSigned =
    formData.superintendent_signature && !adminMode;

  let docStatusName = "";

  // status = status || docDetails?.tor_docs_status;

  let isApproved = localStatus == 4;

  const docStatusColor = isApproved ? "#3f7e39" : "#c9c188";

  switch (localStatus) {
    case 1:
      docStatusName = "Not approved";
      break;
    case 2:
      docStatusName = "Submitted";
      break;
    case 3:
      docStatusName = "In review";
      break;
    case 4:
      docStatusName = "Approved";
      break;
    case 5:
      docStatusName = "Not filled";
      break;
    default:
      docStatusName = "Not Filled"; // Handle any other value
  }

  return (
    <ErrorBoundary>
      <FormContainer title="TIME OFF REQUEST" isLoading={isFormLoading}>
        <div className="TOR_FORM_status_tag">
          <DocStatusTag
            status={docStatusName}
            bgColor={docStatusColor}
            fontColor={isApproved ? "#fff" : ""}
          />
        </div>

        {renderDeniedStatus()}
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Row className="form-row">
            {selectLeastOneOptionError && (
              <Col xs={12}>
                <div style={{ padding: "10px 0px" }}>
                  <p style={{ margin: 0, color: "red" }}>
                    {" "}
                    Employee has to select at least one (or more) option
                  </p>
                </div>
              </Col>
            )}
             <Col xs={12} md={3}>
              <Controller
                name="request_for_time_off_check"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioButton
                    checked={Boolean(value)}
                    label="REQUEST FOR TIME OFF"
                    onChange={(event) => {onChange(event?.target?.checked); uncheckOtherTypes('request_for_time_off_check');}}
                    disabled={!formData?.enable_employee_section}
                  />
                )}
              />
            </Col>
             <Col xs={12} md={3}>
              <Controller
                name="request_sick_pay_check"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioButton
                    checked={Boolean(value)}
                    label="REQUEST FOR SICK PAY"
                    onChange={(event) => {onChange(event?.target?.checked); uncheckOtherTypes('request_sick_pay_check');}}
                    disabled={!formData?.enable_employee_section}
                  />
                  );
                }}
              />
            </Col>
           
            <Col xs={12} md={3}>
              <Controller
                name="pph_inquiry_check"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioButton
                    checked={Boolean(value)}
                    label="PPH INQUIRY"
                    onChange={(event) => {onChange(event?.target?.checked); uncheckOtherTypes('pph_inquiry_check');}}
                    disabled={!formData?.enable_employee_section}
                  />
                )}
              />
            </Col>
            <Col xs={12} md={3}>
              <Controller
                name="cash_out_pph_check"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <RadioButton
                    checked={Boolean(value)}
                    label="CASH OUT PPH"
                    onChange={(event) => {onChange(event?.target?.checked); uncheckOtherTypes('cash_out_pph_check');}}
                    disabled={!formData?.enable_employee_section}
                  />
                )}
              />
            </Col>
           
          </Row>
          <Row className="form-row">
            <Col sm={12} md={8}>
              <Controller
                rules={{ required: true }}
                name="employee_printed_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label="Employee Name"
                    type="text"
                    value={value}
                    onChange={(event) => onChange(event?.target?.value)}
                    error={errors.employee_printed_name}
                    placeholder="- Enter here -"
                    disabled={!formData?.enable_employee_section}
                    required
                  />
                )}
              />
            </Col>
          </Row>

          <Row className="form-row">
            <Col sm={12}></Col>

            <Col sm={12}>
              <div className="form-main-group">
              {WatchRequestTimeOffCheck && (
                  <div className="form-sub-group">
                    <label className="control-label">
                      REQUEST FOR TIME OFF:
                    </label>
                    <Controller
                      rules={{ required: WatchRequestTimeOffCheck }}
                      name="time_off_from"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          label="From"
                          selected={value ? moment(value) : ""}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          error={errors.time_off_from}
                          required={WatchRequestTimeOffCheck}
                          disabled={!formData?.enable_employee_section}
                        />
                      )}
                    />
                    <Controller
                      rules={{ required: WatchRequestTimeOffCheck }}
                      name="time_off_to"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          label="To"
                          selected={value ? moment(value) : ""}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          error={errors.time_off_to}
                          required={WatchRequestTimeOffCheck}
                          disabled={!formData?.enable_employee_section}
                        />
                      )}
                    />

                    <div className="form-group-checkbox">
                      <Controller
                        name="time_off_type"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <RadioButton
                              checked={value === "with"}
                              label="WITH PAY:"
                              value="with"
                              name="time_off_type"
                              onChange={(event) => onChange(event.target.value)}
                              disabled={!formData?.enable_employee_section}
                            />
                              <Controller
                                name="with_pay_hours"
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <TextInput
                                    label="How many hours?"
                                    type="number"
                                    value={WatchTimeOffTypeCheck==='with' ? value : ''}
                                    onChange={(event) => onChange(event?.target?.value)}
                                    error={errors.with_pay_hours}
                                    required={WatchTimeOffTypeCheck ==='with'}
                                    disabled={!formData?.enable_employee_section || WatchTimeOffTypeCheck === '' || WatchTimeOffTypeCheck === 'without'}
                                  />
                                )}
                              />             
                            <RadioButton
                              checked={value === "without"}
                              label="WITHOUT PAY"
                              value="without"
                              name="time_off_type"
                              onChange={(event) => {
                                  onChange(event.target.value);
                                  setValue("with_pay_hours", "");
                                }
                              }
                              disabled={!formData?.enable_employee_section}
                            />
                          </>
                        )}
                      />
                    
                    </div>
                    <div>
                      <ul>
                        <li>
                          <p>
                            More than 5 days off requires one month advance
                            notice.
                          </p>
                        </li>
                        <li>
                          <p>
                            Less then 5 days requires 2 weeks advance notice.
                          </p>
                        </li>
                        <li>
                          <p>
                            If you do not notify your supervisor within the
                            timeframe above, you will not be paid for your leave
                            and you may jeopardize your employment.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div className="form-sub-group">
                    {WatchSickPayDate && (
                      <>
                        <label className="control-label">
                          REQUEST FOR SICK PAY: Date(s) & Hour(s)
                        </label>
                        {WatchSickPayDates &&
                          WatchSickPayDates.map((item, index) => (
                          <Row>
                            <Col sm={5}>
                              <Controller
                                rules={{ required: WatchSickPayDate }}
                                key={index}
                                name={`sick_pay_dates.${index}`}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <div className="datePickerMain">
                                    <CustomDatePicker
                                      selected={value ? moment(value) : ""}
                                      onChange={(newDate) => {
                                        const _date =
                                          datePickerNullSanitize(newDate);
                                        if (WatchSickPayDates?.includes(_date)) {
                                          onChange("");
                                        } else {
                                          onChange(
                                            datePickerNullSanitize(newDate)
                                          );
                                        }
                                      }}
                                      error={errors.sick_pay_dates?.[index]}
                                      required
                                      disabled={!formData?.enable_employee_section}
                                    />
                                  </div>
                                )}
                              />
                            </Col>
                            <Col sm={5}>

                              <Controller
                                rules={{ required: WatchSickPayDate }}
                                key={index}
                                name={`sick_pay_hours.${index}`}
                                control={control}
                                render={({ field: { value, onChange } }) => (
                                  <div className="datePickerMain">
                                    {
                                      !formData?.enable_employee_section ? 
                                        <input placeholder="Hours" disabled type="text" class="form-control" value={value} /> :  
                                        <select
                                          required
                                          style={{ backgroundColor: !formData?.enable_employee_section ? '#eee' : '' }}
                                          id={`hours-${index}`}
                                          value={value}
                                          onChange={(event) => {
                                            const selectedValue = event.target.value;
                                            setValue(`sick_pay_hours.${index}`, selectedValue);
                                          }}
                                          disabled={!formData?.enable_employee_section}
                                        >
                                          <option value="">Select hours</option>
                                          <option value="1">1</option>
                                          <option value="2">2</option>
                                          <option value="3">3</option>
                                          <option value="4">4</option>
                                          <option value="5">5</option>
                                          <option value="6">6</option>
                                          <option value="7">7</option>
                                          <option value="8">8</option>
                                        </select>
                                    }
                                   
                                  </div>
                                )}
                              />
                            </Col>

                            <Col sm={2}>
                              {!!formData?.enable_employee_section && <button
                                type="button"
                                className="primary"
                                onClick={() =>
                                  handleRequestForSickPayDelete(index)
                                }
                                disabled={!formData?.enable_employee_section}
                              >
                                Close
                              </button>}
                            </Col>
                              
                          </Row>

                          ))}
                            {formData?.enable_employee_section && <button
                              type="button"
                              className="button button-add-more"
                              onClick={() => {
                                  setValue(
                                    `sick_pay_dates.${WatchSickPayDates.length}`,
                                    ""
                                  );
                                  setValue(
                                    `sick_pay_hours.${WatchSickPayDates.length}`,
                                    ""
                                  );
                                }
                              }
                              disabled={!formData?.enable_employee_section}
                            >
                              Add More
                            </button>}
                      </>
                    )}
                  {WatchSickPayDate && (
                    <>
                      <Row>
                        <Col sm={6}>
                          <div className="list-block">
                            <ul>
                              <li>
                                <p>Available after 90 days employment</p>
                              </li>
                              <li>
                                <p>
                                  Must be scheduled to work on the day requested
                                </p>
                              </li>
                            </ul>
                          </div>
                        </Col>
                      </Row>
                      <div>
                        <p>If sick pay is unavailable use PPH:</p>
                        <div className="form-group-radio">
                          <Controller
                            name="use_pph"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <>
                                <RadioButton
                                  checked={value === "yes"}
                                  label="Yes"
                                  value="yes"
                                  name="use_pph"
                                  onChange={(event) =>
                                    onChange(event.target.value)
                                  }
                                  disabled={!formData?.enable_employee_section}
                                />
                                <RadioButton
                                  checked={value === "no"}
                                  label="No"
                                  value="no"
                                  name="use_pph"
                                  onChange={(event) =>
                                    onChange(event.target.value)
                                  }
                                  disabled={!formData?.enable_employee_section}
                                />
                              </>
                            )}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {WatchPphInquiryCheck && (
                  <div className="form-sub-group">
                    <Row>
                      <Col xs={12} style={{ marginBottom: 10 }}>
                        <label className="control-label">PPH INQUIRY:</label>
                      </Col>
                      <Col sm={12}>
                        <Controller
                          rules={{ required: WatchPphInquiryCheck }}
                          name="pph_email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextInput
                              label="Email info to"
                              type="email"
                              value={value}
                              onChange={(event) =>
                                onChange(event?.target?.value)
                              }
                              error={errors.pph_email}
                              placeholder="- Enter here -"
                              required={WatchPphInquiryCheck}
                              disabled={!formData?.enable_employee_section}
                            />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} md={8}>
                        <Controller
                          rules={{ required: WatchPphInquiryCheck }}
                          name="pph_phone"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <TextInput
                              label="Phone"
                              type="text"
                              value={usPhone(value)}
                              onChange={(event) =>
                                onChange(event?.target?.value)
                              }
                              error={errors.pph_phone}
                              placeholder="- Enter here -"
                              required={WatchPphInquiryCheck}
                              disabled={!formData?.enable_employee_section}
                            />
                          )}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="form-group-checkbox">
                          <Controller
                            name="pph_phone_call"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                label="Call"
                                checked={Boolean(value)}
                                onChange={(event) =>
                                  onChange(event?.target?.checked)
                                }
                                //   className={errors.employee_printed_name ? "error" : ""}
                                placeholder="- Enter here -"
                                disabled={!formData?.enable_employee_section}
                              />
                            )}
                          />

                          <Controller
                            name="pph_phone_text"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                label="Text"
                                checked={Boolean(value)}
                                onChange={(event) =>
                                  onChange(event?.target?.checked)
                                }
                                //   className={errors.employee_printed_name ? "error" : ""}
                                placeholder="- Enter here -"
                                disabled={!formData?.enable_employee_section}
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                
              </div>
            </Col>
          </Row>

          <div className="signatureMainWrap" style={{ display: 'flex' }}>
            <div className="signatureInputWrap" style={{width:'50%'}}>
                <div>
                  <label className="control-label clearfix">
                    EMPLOYEE SIGNATURE:
                    {!formBelongsToCurrentUser ? null : (
                      <span class="required-star">*</span>
                    )}
                  </label>
                </div>

                <Controller
                  name="employee_signature"
                  rules={{ required: formBelongsToCurrentUser }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomSignaturePad
                      disabled={!formData?.enable_employee_section}
                      error={errors.employee_signature}
                      value={value}
                      onSave={(signature) => {
                        onChange(signature);
                      }}
                    />
                  )}
                />
              </div>
              <div className="dateInputWrap" style={{ paddingLeft: "15px", width: "50%" }}>
                <Controller
                  rules={{ required: true }}
                  name="date"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CustomDatePicker
                      label="Date"
                      selected={value ? moment(value) : ''}
                      onChange={(newDate) => {
                        onChange(datePickerNullSanitize(newDate));
                      }}
                      error={errors.date}
                      required
                      disabled={!formData?.enable_employee_section}
                      minDate={moment()}
                    />
                  )}
                />
            </div>
          </div>
          {formData?.view_superintendent_section && (
              <>
                <div>
                  <p>
                    Proper notice has been given and time off has been approved
                    by:
                  </p>
                </div>
                <Controller
                  name="superintendent_name"
                  rules={{
                    required: formData?.enable_superintendent_section
                      ? watchSupervisorName?.length === 0
                      : false,
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="SUPERVISOR NAME"
                      type="text"
                      value={value}
                      disabled={!formData?.enable_superintendent_section}
                      error={errors.superintendent_name}
                      onChange={(event) => onChange(event?.target?.value)}
                    />
                  )}
                />
                <Row>
                  <Col md={6} sm={12}>
                    <Controller
                      rules={{
                        required: formData?.enable_superintendent_section
                          ? watchSupervisorSignature?.length === 0
                          : false,
                      }}
                      name="superintendent_signature"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomSignaturePad
                          error={errors.superintendent_signature}
                          disabled={
                            !formData?.enable_superintendent_section
                          }
                          value={value}
                          onSave={(signature) => onChange(signature)}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Controller
                      rules={{
                        required: formData?.enable_superintendent_section
                          ? watchSupervisorDate?.length === 0
                          : false,
                      }}
                      name="superintendent_signature_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          label="Date"
                          selected={value ? moment(value) : ''}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          error={errors.superintendent_signature_date}
                          required={formData?.enable_superintendent_section}
                          disabled={!formData?.enable_superintendent_section}
                          minDate={moment()}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </>
            )}
          {formData?.view_duty_officer_section && (
              <div>
                {/* Duty Offices */}
                <Controller
                  rules={{
                    required: formData?.enable_duty_officer_section
                      ? isDutyOfficerName?.length === 0
                      : false,
                  }}
                  name="duty_officer_name"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      label="DUTY OFFICER"
                      type="text"
                      value={value}
                      error={errors.duty_officer_name}
                      onChange={(event) => onChange(event?.target?.value)}
                      disabled={!formData?.enable_duty_officer_section}
                    />
                  )}
                />
                <Row>
                  <Col md={6} sm={12}>
                    <Controller
                      rules={{
                        required: formData?.enable_duty_officer_section
                          ? isDutyOfficerSign?.length === 0
                          : false,
                      }}
                      name="duty_officer_signature"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomSignaturePad
                          error={errors.duty_officer_signature}
                          disabled={!formData?.enable_duty_officer_section}
                          value={value}
                          onSave={(signature) => onChange(signature)}
                        />
                      )}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Controller
                      rules={{
                        required: formData?.enable_duty_officer_section
                          ? isDutyOfficerDate?.length === 0
                          : false,
                      }}
                      name="duty_officer_signature_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          label="Date"
                          selected={value ? moment(value) : ''}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          error={errors.duty_officer_signature_date}
                          required={formData?.enable_duty_officer_section}
                          disabled={!formData?.enable_duty_officer_section}
                          minDate={moment()}
                        />
                      )}
                    />
                    
                  </Col>
                </Row>
                <label>Notes</label>
                <Controller
                  control={control}
                  name="note"
                  render={({ field: { value, onChange } }) => (
                    <textarea
                      type="text"
                      value={value}
                      error={errors.note}
                      disabled={!formData?.enable_duty_officer_section}
                      onChange={(event) => onChange(event?.target?.value)}
                    />
                  )}
                />
              </div>
            )}
          {(formData?.view_superintendent_section || formData?.view_duty_officer_section) && (
              <>
              <label class="control-label">Additional Info</label>
              <Controller
                  name="additional_info"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <textarea
                      label="Additional Info"
                      type="text"
                      value={value}
                      disabled={!(formData?.enable_superintendent_section || formData?.enable_duty_officer_section)}
                      error={errors.additional_info}
                      onChange={(event) => onChange(event?.target?.value)}
                    />
                  )}
                />
              </>
          )}
          {formData?.view_payroll_section ? (
            <>
              <div className="officeBlock">
                <p className="officeBlock__title">
                  PLEASE RETURN FORM TO THE PAYROLL OFFICE
                  (naomi.tora@fwfsinc.com)
                </p>

                <Row>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="pph_available"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="Available PPH:"
                          type="text"
                          error={errors.pph_available}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="pph_available_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          // minDate={moment()}
                          label="Available PPH: as of"
                          selected={value ? moment(value) : ""}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          error={errors.pph_available_date}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>

                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="pph_applied"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="PPH Applied:"
                          type="text"
                          error={errors.pph_applied}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="pph_balance"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="PPH Balance:"
                          type="text"
                          error={errors.pph_balance}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="sick_pay_available"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="Available Sick Pay:"
                          type="text"
                          value={value}
                          error={errors.sick_pay_available}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="sick_pay_date"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <CustomDatePicker
                          label="Available Sick Pay: as of"
                          error={errors.sick_pay_date}
                          selected={value ? moment(value) : ""}
                          onChange={(newDate) =>
                            onChange(datePickerNullSanitize(newDate))
                          }
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="sick_pay_applied"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="Sick Pay Applied:"
                          type="text"
                          error={errors.sick_pay_applied}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={3}>
                    <Controller
                      rules={{ required: formData?.enable_payroll_section }}
                      name="sick_pay_balance"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextInput
                          label="Sick Pay Balance:"
                          type="text"
                          error={errors.sick_pay_balance}
                          value={value}
                          onChange={(event) => onChange(event?.target?.value)}
                          disabled={!formData?.enable_payroll_section}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
          {formData.enable_employee_section && (
            <>
              <div className="row form-row">
                <div className="col">
                  <div className="disclaimers">
                    <strong className="diss">Disclaimer: </strong>You must read
                    and carefully understand the documents that you are filling
                    out and signing. These are legally binding documents and
                    must be accurate. By filling these forms out, you are
                    agreeing to our{" "}
                    <a
                      href="https://firestormfire.com/terms-of-use/"
                      target="blank"
                    >
                      Terms of Use, Privacy Policy and Employee Agreements.
                    </a>
                  </div>
                </div>
              </div>
              <div className="disclaimer_checkbox">
                <Controller
                  name="disclaimer_check"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label=" I have confirmed the above details are true and valid."
                      value={value}
                      checked={Boolean(value)}
                      onChange={(event) => onChange(event?.target?.checked)}
                      placeholder="- Enter here -"
                      disabled={formData?.is_denied === "1" || isApproved || (!adminMode && formData?.employee_signature) }
                    />
                  )}
                />
              </div>
              </>
          )}
          {(formData?.enable_employee_section || formData?.enable_superintendent_section || formData?.enable_duty_officer_section || formData?.enable_payroll_section) && (
              <div className="submit_button_wrapper">
              {(formData?.enable_superintendent_section || formData?.enable_duty_officer_section) && (
                <button
                  onClick={handleDeny}
                >
                  Deny
                </button>
              )}
              <SubmitButton
                disabled={formData?.enable_employee_section && !WatchDisclaimerCheck}
                type="submit"
                label={
                  (formData?.enable_superintendent_section || formData?.enable_duty_officer_section) ? "Approve" : "Submit Document"
                }
              />
            </div>
          )}
        </form>
      </FormContainer>
    </ErrorBoundary>
  );
};

export default withRouter(TimeOffRequest);

TimeOffRequest.propTypes = {
  isSingleDoc: PropTypes.bool.isRequired,
  docDetails: PropTypes.object,
  adminMode: PropTypes.bool.isRequired,
};
