import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavItem, Nav } from "react-bootstrap";
import { dashboardActions } from "_actions";
import ResourceOrderFormList from "./ResourceOrderFormList";
import "../assets/styles/report.css";
import {
  getCreatedResourceOrderFormsFromStore,
  getResourceOrderFormsFromStore,
} from "_helpers/reduxSelector";
import ReactModal from "react-modal";
import ResourceOrderFormUploader from "_components/ResourceOrderFormUploader/ResourceOrderFormUploader";
import { history } from "_helpers";
import { manifestService } from "_services";
import TextInput from "Docs/Components/TextInput";
import ResourceOrderForm from "Docs/Forms/ResourceOrderForm/ResourceOrderForm";


const TABS = {
  UPLOADED_FILES: 0,
  CREATED_FILES: 1,
};
const ResourceOrderFormListContainer = (props) => {
  const [keyword, setKeyword] = React.useState("");
  const [createdFileKeyword, setCreatedFileKeyword] = React.useState("");
  const [currentTab, setCurrentTab] = React.useState(TABS?.UPLOADED_FILES);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [isEditOrderForm, setIsEditOrderForm] = React.useState(false);
  const [selectedROData, setSelectedROData] = React.useState();

  const [searchKeyword, setSearchKeyword] = React.useState({});

  const [selectedEmployees,setSelectedEmployees] = React.useState([])

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const updateSearchKeyword = debounce(function (event) {
    if (currentTab === TABS?.UPLOADED_FILES) {
      setKeyword(event?.target?.value);
    } else {
      setCreatedFileKeyword(event?.target?.value);
    }
  }, 1000);

  const resourceOrderForms = useSelector((state) =>
    getResourceOrderFormsFromStore(state)
  );

  const createdResourceOrderForms = useSelector((state) =>
    getCreatedResourceOrderFormsFromStore(state)
  );

  React.useEffect(() => {
    getCreatedResourceOrderForm();
  }, []);

  React.useEffect(() => {
    if (currentTab === TABS?.UPLOADED_FILES) {
      getResourceOrderForms(0);
    }
    if (currentTab === TABS?.CREATED_FILES) {
      getCreatedResourceOrderForm(0);
    }
  }, [keyword, createdFileKeyword]);

  const getAllResourceOrderForms = (newPage) => {
    getResourceOrderForms(newPage);
    getCreatedResourceOrderForm(newPage);
  };

  const getCreatedResourceOrderForm = (newPage) => {
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("keyword", createdFileKeyword);
    dispatch(dashboardActions.getCreatedResourceOrderForm(data));
  };
  const getResourceOrderForms = (newPage) => {
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("keyword", keyword);
    dispatch(dashboardActions.getResourceOrderForms(data));
  };

  const onPrintClick = (rofId) => {
    let data = new FormData();
    data.append("rof_id", rofId);
    manifestService
      ?.viewResourceOrderForm(data)
      .then((response) => redirectToPdf(response));
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const redirectToPdf = (data) => {
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  };

  const handleEditRof = (rof_id) => {
    let formData = new FormData();
    formData.append("rof_id", rof_id);
    manifestService?.getResourceOrderContent(formData).then(({data}) => {
      if(data) {
        setSelectedROData(data?.[0]);
        setIsEditOrderForm(true);
      }
    });
  };

    const handleOnEditSubmitted = () => {
      setIsEditOrderForm(false);
      getCreatedResourceOrderForm();
    };

  return (
    <div>
      <div className="dashboard_container">
        <div className="white">
          <div className="back_to_board dashboard_expiration_date_nav ro-header" style={{ marginTop: 30 }}>
            <h5 className="page-header-name">Resource Order</h5>

            <div className="page-header-inputs">
              <div className="search-resource-order-input">
                <TextInput
                  value={searchKeyword?.[currentTab] ?? ""}
                  // label="Search"
                  placeholder={
                    currentTab === TABS?.UPLOADED_FILES
                      ? "Search uploaded resource order"
                      : "Search created resource order"
                  }
                  onChange={(event) =>{
                    let _setSearchKeyword = { ...searchKeyword}
                    _setSearchKeyword[currentTab] = event?.target?.value
                    setSearchKeyword(_setSearchKeyword)
                    updateSearchKeyword(event)
                  } }
                />
              </div>
                      
              <button
                href=""
                className="print_button"
                onClick={(event) => {
                  setIsModalOpen(true);
                }}
              >
                Upload RO
              </button>
            </div>
          </div>
          {isEditOrderForm ? 
          <ResourceOrderForm onSubmit={() => handleOnEditSubmitted()} onCancel={() => setIsEditOrderForm(false)} editMode={isEditOrderForm} rofData={selectedROData}/> : (
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div className="resource_order_form_list">
              <Nav bsStyle="tabs" className="resource_order_form_list_nav">
                <NavItem
                  selected={currentTab === TABS?.UPLOADED_FILES}
                  eventKey="onboarding"
                  className={
                    currentTab === TABS?.UPLOADED_FILES
                      ? "resource_order_form_list_nav_tab_active"
                      : "resource_order_form_list_nav_tab"
                  }
                  onClick={() => setCurrentTab(TABS?.UPLOADED_FILES)}
                >
                  Uploaded Resource Order
                </NavItem>
                <NavItem
                  selected={currentTab === TABS?.CREATED_FILES}
                  eventKey="rehire"
                  className={
                    currentTab === TABS?.CREATED_FILES
                      ? "resource_order_form_list_nav_tab_active"
                      : "resource_order_form_list_nav_tab"
                  }
                  onClick={() => setCurrentTab(TABS?.CREATED_FILES)}
                >
                 Created Resource Order
                </NavItem>
              </Nav>
            </div>

            <div className="resource_order_form_list_wrap" style={{ width: "100%", maxWidth: "100%" }}>
              {currentTab === TABS?.UPLOADED_FILES && (
                <ResourceOrderFormList
                  data={resourceOrderForms?.data ?? []}
                  lastPage={resourceOrderForms?.last_page}
                  getResourceOrderForms={getResourceOrderForms}
                  isCreatedForm = {false}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                />
              )}

              {currentTab === TABS?.CREATED_FILES && (
                <ResourceOrderFormList
                  data={createdResourceOrderForms?.data ?? []}
                  lastPage={createdResourceOrderForms?.last_page}
                  getResourceOrderForms={getCreatedResourceOrderForm}
                  isCreatedForm = {true}
                  redirectToCreatedForm={(data) => onPrintClick(data)}
                  handleEditRof={handleEditRof}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                />
              )}
            </div>
          </div>)}
        </div>
      </div>
      <ReactModal isOpen={isModalOpen} onClose={() => handleModalClose()}>
        <ResourceOrderFormUploader
          onClose={() => handleModalClose()}
          onComplete={(data) => {
            getAllResourceOrderForms(0);
            handleModalClose();
          }}
          onCreate={() => {
            history.push("/resource-order-form-create");
            handleModalClose();
          }}
          disableExistingFiles
        />
      </ReactModal>
    </div>
  );
};

export default ResourceOrderFormListContainer;
