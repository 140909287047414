import React, { useState, useEffect } from "react";
import { Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import cx from "classnames";
import { useSelector } from "react-redux";
import { reportList } from "_utils/utils";
import QualificationReportList from "QualificationReportList";
import ExpirationDateReport from "ExpirationDateReport";
import VehicleReportList from "VehicleReportList";
import AffirmativeActionReportList from "AffirmativeActionReportList";
import BirthdayWidget from "BirthdayWidget";
import EquipmentSoldReport from "EquipmentSoldReport";
import AllInventoryReport from "AllInventoryReport"
import RefresherReport from "RefresherReport"
import MasterAdminList from "DocsStatusListReport"
import NoAccess from "./NoAccess";
import styles from "./reports.module.scss";
import MileageHobbsList from "MileageHobbsList";
import ApplicantsReport from "ApplicantsReport";
import FireHistoryReportContainer from "FireHistoryReportAll";

const ReportTabs = () => {
  const [activeTab, setActiveTab] = useState("no_reports_found");
  const permissions = useSelector((state) => state.permission?.permissions?.data) || [];
  const reports = reportList.filter(({ permission }) => permissions.includes(permission)) || [];

  useEffect(() => {
    const actTab = reports.length ? reports[0].key : "no_reports_found";
    setActiveTab(actTab);
  }, [permissions]);

  return (
    <div className={styles.report_container}>
      <section>
        <h1 className={styles.page_title}>Reports</h1>
      </section>
      <section className="d-flex w-100 is-report-page">
        <div className="col-vert pos-rel w-100">
          <div className="doc-list-items">
            <Tab.Container id="tabs-with-dropdown" activeKey={activeTab}>
              <Row>
                <Col sm={12}>
                  <div className={cx("m-0 custom-tabs", styles.p_custom)}>
                    <Nav bsStyle="tabs">
                      {reports.map((report) => (
                        <NavItem
                          eventKey={report.key}
                          className="custom-tab"
                          key={report.key}
                          onClick={() => setActiveTab(report.key)}
                        >
                          {report.title}
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </Col>

                <Col sm={12}>
                  <div className="custom-tab-content">
                    <Tab.Content animation>
                      <Tab.Pane eventKey="qualification">
                        <QualificationReportList isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="vehicle">
                        <VehicleReportList isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="expiration">
                        <ExpirationDateReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="birthday">
                        <BirthdayWidget isReportMenu visibility={true} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="equipment">
                        <EquipmentSoldReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="inventory">
                        <AllInventoryReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="refresher">
                        <RefresherReport isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="master">
                        <MasterAdminList isReportMenu />
                      </Tab.Pane>
                      <Tab.Pane eventKey="no_reports_found">
                        <NoAccess />
                      </Tab.Pane>
                      <Tab.Pane eventKey="mileage">
                        <MileageHobbsList />
                      </Tab.Pane>
                      <Tab.Pane eventKey="applicants">
                       <ApplicantsReport/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fire_history">
                       <FireHistoryReportContainer/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="affirmative_action">
                        <AffirmativeActionReportList isReportMenu />
                      </Tab.Pane>
                    </Tab.Content>
                    
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReportTabs;
