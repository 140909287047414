import React from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";

import "../assets/styles/crew-assignment-status.css";
import { dashboardActions } from "_actions";
import { getQualifications } from "_helpers/reduxSelector";

export const SELECT_ALL_QUALIFICATIONS = [{ label: "All", value: "all" }]

const QualificationReportWidget = ({ toggle, widgetid, visibility, history }) => {
  const dispatch = useDispatch();

  const qualificationsRaw = useSelector((state) => getQualifications(state));
  const [currentFilters, setCurrentFilters] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    dispatch(dashboardActions.getQualifications());
  }, []);

  const qualifications = React.useMemo(() => {
    let _qualifications = qualificationsRaw.map((item) => ({
      label: item?.value,
      value: item?.id,
    }));
    return SELECT_ALL_QUALIFICATIONS.concat(_qualifications);
  }, [qualificationsRaw]);

  React.useEffect(() => {
    setCurrentFilters(selectedOptions?.map((item) => item?.value));
  }, [selectedOptions]);

  const handleOnChange = (newSelectedOptions, selectAction) => {
    const isSelectedAll =
      selectAction?.action === "select-option" &&
      selectAction?.option?.value === "all";
    if (isSelectedAll) {
      setSelectedOptions(SELECT_ALL_QUALIFICATIONS);
    } else {
      if (qualificationsRaw?.length === newSelectedOptions?.length) {
        setSelectedOptions(SELECT_ALL_QUALIFICATIONS);
      } else {
        setSelectedOptions(newSelectedOptions);
      }
    }
  };


  const handleRunReport = () => {
    history.push("/qualification-report", {
      currentFilters,
    });
  };
    

  return (
    <div className="dashboard_expiration_date_outer">
      <div className="dashboard_expiration_date inner_head">
        <h2>
          Qualification Report<span className="drag-icon-controller"></span>
        </h2>
        <span
          className="toggle-controller"
          aria-owns="qualification-report-filter"
          onClick={(event) => {
            toggle(event, widgetid);
          }}
        ></span>
      </div>
      <div
        className="employee_stats_details assignment_stats-details"
        aria-expanded={visibility == 1 ? "true" : "false"}
        id="qualification-report-filter"
      >
        <h4>Filters</h4>
        <div style={{ marginTop: 20 }}>
          <ReactSelect
            value={selectedOptions}
            onChange={handleOnChange}
            isMulti
            options={currentFilters.includes("all") ? SELECT_ALL_QUALIFICATIONS : qualifications}
          />
        </div>
        <div className="assignment-status-outer-form">
          <div className="run-report" onClick={() => handleRunReport()}>
            Run Report
            {/* <Link to={"/report" + (typeof (this.refs.AssignmentType) != "undefined") ? this.refs.AssignmentType.value : ""}>Run Report</Link> */}
            {/* <a href="" className="run-report"></a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(QualificationReportWidget);
