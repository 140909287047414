import React from "react";
import { connect } from "react-redux";
import { AddEquipment } from "./AddEquipment";
import { InventoryDetails } from "./InventoryDetails";
import { InventoryDetailsMob } from "./InventoryDetailsMob";
import { InventoryView } from "./InventoryView";
import { ArchivedEquipments } from "./ArchivedEquipments";
import "../assets/styles/inventory.css";
import "../assets/styles/inventoryTab.scss";
import { authHeader, config } from "../_helpers";

class Inventory extends React.Component {
  constructor(props) {
    super(props);
    var step;
    if (this.props.match.path === "/inventory/inventory-view/:id") {
      step = "InventoryView";
    } else {
      step = "InventoryDetails";
    }
    this.state = {
      mainHtml: "",
      filter: {},
      step: step,
      width: window.innerWidth,
      permissions: [],
      inventory_state: null,
      crew_id: "",
      searchKey: "",
    };
    this.addEquip = this.addEquip.bind(this);
    this.archivedEquipments = this.archivedEquipments.bind(this);
    this.goBack = this.goBack.bind(this);
    this.inventoryView = this.inventoryView.bind(this);
    this.setInventoryState = this.setInventoryState.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }
  componentDidMount() {
    document.body.className += " " + "inv-mobile-view";
    this.componentWillReceiveProps(this.props);
    var self = this;
    fetch(config.apiUrl + "/api/permission/getuserpermissions", {
      headers: authHeader(),
      method: "GET",
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("inv-mobile-view");
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
    this.componentWillReceiveProps(this.props);
  };

  addEquip() {
    this.setState({ step: "AddEquipment", status: "add" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  archivedEquipments() {
    this.setState({ step: "ArchivedEquipments" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  setInventoryState(inventory_state) {
    this.setState({ inventory_state });
  }

  setCrewId(crew_id) {
    this.setState({
      crew_id,
    });
  }

  setSearchKeyword(searchKey) {
    this.setState({
      searchKey,
    });
  }

  inventoryView() {
    this.setState({ step: "InventoryView" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  editEquip(id, eid, view = "") {
    this.setState(
      {
        step: "AddEquipment",
        status: "edit",
        from: view,
        inv_id: id,
        equip_id: eid,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  getInventoryDetailsHTML = () => {
    let { width } = this.state,
      InventoryDetailsHTML;
    const isMobile = width <= 600;
    InventoryDetailsHTML = isMobile ? (
      <InventoryDetailsMob
        addEquip={this.addEquip.bind(this)}
        editInventory={this.editEquip.bind(this)}
        status={this.state.status}
        equip_id={this.state.equip_id}
        inv_id={this.state.inv_id}
        archivedEquipments={this.archivedEquipments}
        setFilter={this.setFilter}
      />
    ) : (
      <InventoryDetails
        addEquip={this.addEquip.bind(this)}
        editInventory={this.editEquip.bind(this)}
        status={this.state.status}
        equip_id={this.state.equip_id}
        inv_id={this.state.inv_id}
        setFilter={this.setFilter}
        archivedEquipments={this.archivedEquipments}
        setInventoryState={this.setInventoryState}
        inventory_state={this.state.inventory_state}
        setCrewId={this.setCrewId.bind(this)}
        setSearchKeyword={this.setSearchKeyword.bind(this)}
      />
    );
    return InventoryDetailsHTML;
  };

  goBack() {
    this.setState({ step: "InventoryDetails" }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  setFilter(data) {
    this.setState({ filter: { ...data } });
  }

/**
 * commented out to avoid glitch on component update
  componentDidUpdate() {
    // if (this.state.step === "InventoryDetails" && window.pageYOffset > 150) {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   }); // default screentop is 27 in this page.
    // }
  } */ 

  componentWillReceiveProps(nextProps) {
    let { width } = this.state;
    var options;
    if (this.state.step === "AddEquipment") {
      options = (
        <AddEquipment
          goBack={this.goBack.bind(this)}
          status={this.state.status}
          // equip_id={this.state.equip_id}
          filter={this.state.filter} // filter passed from parent
          inv_id={this.state.inv_id}
          from={this.state.from}
          inventoryView={this.inventoryView.bind(this)}
          crew_id={this.state.crew_id}
          searchKey={this.state.searchKey}
          inventory_id={this.state.equip_id}
        />
      );
    }
    if (this.state.step === "InventoryView") {
      options = (
        <InventoryView
          editInventory={this.editEquip.bind(this)}
          equip_id={this.state.equip_id}
          status={this.state.status}
          inv_id={this.state.inv_id}
          id={this.props.match.params.id}
          goBack={this.goBack.bind(this)}
        />
      );
    }

    if (this.state.step === "ArchivedEquipments") {
      options = (
        <ArchivedEquipments
          {...this.props}
          editInventory={this.editEquip.bind(this)}
          status={this.state.status}
          equip_id={this.state.equip_id}
          inv_id={this.state.inv_id}
          goBack={this.goBack.bind(this)}
        />
      );
    }

    this.setState({ mainHtml: options });
  }

  render() {
    const { permissions } = this.state;
    return (
      permissions.length > 0 &&
      (!permissions.includes("Inventory") ? (
        <div className="content_div">
          <div className="no_permission">No permission to this page</div>
        </div>
      ) : (
        <div>
          {/* Keeping state of Inventory Details */}
          <div style={this.state.step === "InventoryDetails" ? { display: "block" } : { display: "none" }}>
            {this.getInventoryDetailsHTML()}
          </div>
          {this.state.mainHtml}
        </div>
      ))
    );
  }
}
function mapStateToProps(state) {
  const { permission } = state;
  return {
    permission,
  };
}
const connectedHomePage = connect(mapStateToProps)(Inventory);
export { connectedHomePage as Inventory };
