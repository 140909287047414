import { createProfileConstants } from "../_constants";
import { createProfileService } from "../_services";
import { alertActions } from "./";

export const createProfileActions = {
  // getResources,
  // getPantSize,
  // getShirtSize,
  // getQualification,
  getAll,
  createProfile,
  getAllGISS,
  getAllResource
};

function getAll() {
  return (dispatch) => {
    dispatch(request());

    createProfileService.getAll().then(
      (listAll) => dispatch(success(listAll)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: createProfileConstants.GETALL_REQUEST };
  }
  function success(listAll) {
    return { type: createProfileConstants.GETALL_SUCCESS, listAll };
  }
  function failure(error) {
    return { type: createProfileConstants.GETALL_FAILURE, error };
  }
}

function getAllGISS() {
  return (dispatch) => {
    dispatch(request());

    createProfileService.getAllGISS().then(
      (listAll) => dispatch(success(listAll)),
      (error) => dispatch(failure(error))
    );
  };
  /**Keeping the same store value as its similar in structure */
  function request() {
    return { type: createProfileConstants.GETALL_REQUEST };
  }
  function success(listAll) {
    return { type: createProfileConstants.GETALL_SUCCESS, listAll };
  }
  function failure(error) {
    return { type: createProfileConstants.GETALL_FAILURE, error };
  }
}

function getAllResource() {
  return (dispatch) => {
    dispatch(request());

    createProfileService.getAllResource().then(
      (listAll) => dispatch(success(listAll)),
      (error) => dispatch(failure(error))
    );
  };
  /**Keeping the same store value as its similar in structure */
  function request() {
    return { type: createProfileConstants.GETALL_REQUEST };
  }
  function success(listAll) {
    return { type: createProfileConstants.GETALL_SUCCESS, listAll };
  }
  function failure(error) {
    return { type: createProfileConstants.GETALL_FAILURE, error };
  }
}

function createProfile(data) {
  return (dispatch) => {
    dispatch(request(data));

    createProfileService.createProfile(data).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success("Registration successful"));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request(data) {
    return { type: createProfileConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: createProfileConstants.CREATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: createProfileConstants.CREATE_FAILURE, error };
  }
}

// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         createProfileService.getAll()
//             .then(
//                 listAll => dispatch(success(listAll)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: createProfileConstants.GETALL_REQUEST } }
//     function success(listAll) { return { type: createProfileConstants.GETALL_SUCCESS, listAll } }
//     function failure(error) { return { type: createProfileConstants.GETALL_FAILURE, error } }
// }

// function getResources(res) {
//     return dispatch => {
//         dispatch(request(res));

//         createProfileService.getResources(res)
//             .then(
//                 createProfile => dispatch(success(createProfile)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: createProfileConstants.GETRES_REQUEST } }
//     function success(createProfile) { return { type: createProfileConstants.GETRES_SUCCESS, createProfile } }
//     function failure(error) { return { type: createProfileConstants.GETRES_FAILURE, error } }
// }

// function getPantSize() {
//     return dispatch => {
//         dispatch(request());

//         createProfileService.getPantSize()
//             .then(
//                 pantSize => dispatch(success(pantSize)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: createProfileConstants.GETPANT_REQUEST } }
//     function success(pantSize) { return { type: createProfileConstants.GETPANT_SUCCESS, pantSize } }
//     function failure(error) { return { type: createProfileConstants.GETPANT_FAILURE, error } }
// }

// function getShirtSize() {
//     return dispatch => {
//         dispatch(request());

//         createProfileService.getShirtSize()
//             .then(
//                 shirtSize => dispatch(success(shirtSize)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: createProfileConstants.GETSHIRT_REQUEST } }
//     function success(shirtSize) { return { type: createProfileConstants.GETSHIRT_SUCCESS, shirtSize } }
//     function failure(error) { return { type: createProfileConstants.GETSHIRT_FAILURE, error } }
// }

// function getQualification() {
//     return dispatch => {
//         dispatch(request());

//         createProfileService.getQualification()
//             .then(
//                 qualification => dispatch(success(qualification)),
//                 error => dispatch(failure(error))
//             );
//     };

//     function request() { return { type: createProfileConstants.GETQUALIFY_REQUEST } }
//     function success(qualification) { return { type: createProfileConstants.GETQUALIFY_SUCCESS, qualification } }
//     function failure(error) { return { type: createProfileConstants.GETQUALIFY_FAILURE, error } }
// }
