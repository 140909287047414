import ReactPaginate from "react-paginate";

const CustomPagination = ({ forcePage, pageCount, onPageChange,pageRangeDisplayed }) => {
  return (
    <div className="pagination-custom">
      <div
        className="fs-pagination-wrapper-outer"
        key={7}
      >
        <div className="customised-pagination right-align">
          <ReactPaginate
            forcePage={forcePage}
            previousLabel={""}
            nextLabel={""}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={onPageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomPagination;
