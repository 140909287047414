import React, { useState } from "react"
import { Document, Page } from 'react-pdf';



const HandbookView = ({ fileUrl, onCancel }) => {


    const [numPages, setNumPages] = useState(null)
    const pageWidth = window.innerWidth * 0.7;


    const onDocumentLoadSuccess = ({ numPages, }) => {
        setNumPages(numPages);
    }

    const handleDownloadFile = () => {
        let url = new URL(fileUrl);
        window.open(url.href);
    }

    return (
        <React.Fragment>
            <div className="page_title float">
                <h1 className="">Handbook</h1>
                <div className="btn_list">
                    <button className="button" onClick={() => onCancel()} >
                        Back
                    </button>
                    <button className="button" onClick={() => handleDownloadFile()} >
                        Download
                    </button>
                </div>
            </div>
            <Document onLoadSuccess={onDocumentLoadSuccess} file={fileUrl}>
                {numPages ? (
                    Array(numPages).fill(0).map((_, index) => (
                        <Page pageNumber={index + 1} key={index} width={pageWidth} />
                    ))
                ) : null}
            </Document>
        </React.Fragment>
    )
}


export default HandbookView