import { Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { userActions, createProfileActions } from "_actions";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  getSelectedUserInfo,
} from "_helpers/reduxSelector";
import { userService } from "_services";
import EmployeeTrainingInputs, {
  defaultValues,
} from "./EmployeeTrainingFields";

const DATE_FORMAT = "MM/DD/YYYY";

const UpdateEmployeeTraining = ({ userHash }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const currentUserInfo = useSelector(getSelectedUserInfo);

  const getCurrentUserInfo = () => {
    if (userHash) {
      dispatch(userActions?.getProfile(`?hash=${userHash}`));
    } else {
      dispatch(userActions?.getAll());
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const handleOnSubmit = async (values) => {
    try {
      const payload = {
        hash: userHash ?? currentUserInfo?.hash,
        form: values,
        form_id: currentUserInfo?.usertraining?.id ?? null,
      };

      setIsLoading(true);

      const res = await userService?.saveEmployeeTraining(
        JSON.stringify(payload)
      );
      if (res?.success) {
        getCurrentUserInfo();
        toast?.success(res?.message);
      } else {
        throw res;
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast?.error(error?.message ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const trainingInfoId = currentUserInfo?.usertraining?.id;
    if (trainingInfoId) {
      getFormDetails(trainingInfoId);
    }
  },[]);

  useEffect(() => {
    dispatch(createProfileActions?.getAll());
    getCurrentUserInfo();
  }, []);

  const getFormDetails = async (id) => {
    try {
      setIsLoading(true);
      const res = await userService?.getEmployeeTrainingById(id);
      setIsLoading(false);
      if (!res?.success) {
        throw res;
      }
      reset(res?.data?.form);
    } catch (error) {
      setIsLoading(false);
      toast?.error(error?.message ?? "Something went wrong");
    }
  };

  return (
    <div
      className="content_div settings"
      style={{
        display: "grid",
        opacity: isLoading ? 0.7 : 1,
        pointerEvents: isLoading ? "none" : "inherit",
      }}
    >
      <h1>Employee Trainings</h1>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Row>
          <Col xs={12} md={4}>
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[0]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[4]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[5]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[6]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[7]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[3]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[11]} />
          </Col>
          <Col xs={12} md={4}>
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[1]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[8]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[12]} />
          </Col>
          <Col xs={12} md={4}>
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[2]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[9]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[10]} />
            <CustomGrid control={control} data={EmployeeTrainingInputs?.[13]} />
          </Col>
          {/* <Col xs={12} md={3}>
   

          </Col> */}
        </Row>
        {/* <Row>
          {EmployeeTrainingInputs?.map((item, index) => (
            <Col key={index} xs={12} md={4}>
              <label style={{ textDecoration: "underline", marginBottom: 20 }}>
                {item?.title}
              </label>
              {item?.fields?.map((field, fieldIndex) => (
                <CustomField control={control} {...field} key={fieldIndex} />
              ))}
            </Col>
          ))}
        </Row> */}
        {/* Action Items */}
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button disabled={isLoading} type="submit" className="button">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const CustomGrid = ({ data, control }) => (
  <>
    <label style={{ textDecoration: "underline", marginBottom: 20 }}>
      {data?.title}
    </label>
    {data?.fields?.map((field, fieldIndex) => (
      <CustomField control={control} {...field} key={fieldIndex} />
    ))}
  </>
);
const CustomField = ({
  label: title,
  name,
  control,
  type,
  hasExpirationDate,
}) => (
  <Row>
    <Col xs={12} md={3}>
      <p>{title}</p>
    </Col>
    <Col xs={12} md={hasExpirationDate ? 5 : 9}>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            {type == "date" && (
              <div className="full_width_date">
                <DatePicker
                  type="date"
                  id="interview_date"
                  name={name}
                  selected={moment(value)?.isValid() ? moment(value) : ""}
                  onChange={(date) =>
                  onChange(date ? moment(date).format(DATE_FORMAT) : "")
                  }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                />
              </div>
            )}
            {type === "text" && (
              <input
                value={value}
                onChange={(event) => onChange(event?.target?.value)}
              />
            )}
          </>
        )}
      />
    </Col>
    {hasExpirationDate && (
      <Col xs={12} md={4}>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <div className="full_width_date">
                <DatePicker
                  type="date"
                  id="interview_date"
                  name={name}
                  selected={moment(value)?.isValid() ? moment(value) : ""}
                  onChange={(date) =>
                  onChange(date ? moment(date).format(DATE_FORMAT) : "") // Set null or empty string when date is cleared
                   }
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoComplete="off"
                  placeholderText="Expiration Date"
                />
              </div>
            </>
          )}
        />
      </Col>
    )}
  </Row>
);

export default UpdateEmployeeTraining;
