import React from "react";
import styles from "./BypassEmployeeApplication.module.scss";
import BulkImportPreview from "Settings/BulkImportPreview";
import { ReactComponent as Info } from "assets/icons/info-green.svg";

export const BypassEmployeeApplication = (props) => {
  const {
    isBulkImport,
    setIsBulkImport,
    setPaginatedData,
    paginatedData,
    rawData,
    onCancelBulkImport,
    setAddEmployeeModal,
    setBulkImportModal,
  } = props;
  
  return (
    <div className={styles.BypassEmployeeApplication}>
      {isBulkImport ? (
        <BulkImportPreview
          setIsBulkImport={setIsBulkImport}
          setPaginatedData={setPaginatedData}
          data={paginatedData}
          rawData={rawData}
          onCancelBulkImport={onCancelBulkImport}
        />
      ) : (
        <>
          <div className="banner">
            <h1>Bypass Employee Application</h1>
          </div>
          <div className="action-container">
            <div className="title">
              <h1>Add employees by overriding job application</h1>
            </div>
            <div className="information-box">
              <span className="info-title">
                <Info />
                <h3>Information</h3>
              </span>
              <p>
                Here you can add new employees without going through the job
                application process. After accounts are made, employees can log
                in and submit their documents.
              </p>
            </div>
            <div className="action-buttons">
              <button
                className="button"
                onClick={() => setAddEmployeeModal(true)}
              >
                Add Employee
              </button>
              <p>OR</p>
              <button
                className="button"
                onClick={() => setBulkImportModal(true)}
              >
                Bulk Import
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
