import React, { useState, useEffect, useMemo, forwardRef } from "react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { createProfileActions } from "_actions";
import {
  getAllQualifications,
  selectCurrentUserPermissions,
  generalQualifications,
  qualDeletedError,
  qualEditedError,
  qualCreatedError,
  qualFieldValidationError
} from "_helpers/reduxSelector";
import { ReactComponent as Calendar } from "assets/icons/calendar-icon.svg";
import deleteIcon from '../assets/icons/delete-btn.svg';
import editIcon from '../assets/icons/gold-edit-icon.svg';
import { qualificationActions } from "../_actions";

function ManageQualificationContainer() {
  const dispatch = useDispatch();

  const qualifications = useSelector(getAllQualifications);
  const generalQualification = useSelector(generalQualifications);
  const currentUserPermissions = useSelector(selectCurrentUserPermissions);
  const qualDeletionError = useSelector(qualDeletedError);
  const qualEditingError = useSelector(qualEditedError);
  const qualCreatingError = useSelector(qualCreatedError);
  const qualValidationError = useSelector(qualFieldValidationError);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedQualId, setSelectedQualId] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const red = "#FF0000";
  const grey = "#CCCCCC";

  // First form instance for Edit Qualifications
  const formAddQual = useForm({
    defaultValues: {
      quals_name: "",
      quals_short_form: "",
      color_code: "",
      quals_position_order: null,
    },
  });

  // Second form instance for Edit Qualifications
  const formEditQual = useForm({
    defaultValues: {
      quals_name: "",
      quals_short_form: "",
      color_code: "",
      quals_position_order: null,
    },
  });

  const {
    control: controlAddQual,
    handleSubmit: handleSubmitAddQual,
    formState: { errors: errorsAddQual },
    reset: resetAddQual,
  } = formAddQual;
  const {
    control: controlEditQual,
    handleSubmit: handleSubmitEditQual,
    formState: { errors: errorsEditQual },
    setValue: setValueEditQual,
    clearErrors: clearErrorsEditQual,
  } = formEditQual;

  const listColorCode = useMemo(() => {
    // list of available color codes for the qualifications
    let items = [];
    generalQualification?.forEach((item) => {
      items?.push({
        label: (
          <div>
            <span
              className="qual_color_code"
              style={{
                backgroundColor: item?.color_code,
              }}
            />
            {item?.color_code}
          </div>
        ),
        value: item?.id,
      });
    });
    return items;
  }, [generalQualification]);

  const openEditModal = (index) => {
    setSelectedIndex(index);
    setIsEditModalOpen(true);
  };

  const handleOnSubmit = async (values) => {
    //handle Add qualifications functionality
    setIsSubmitting(true);
    let data = new FormData();
    data.append("quals_short_form", values?.quals_short_form);
    data.append("quals_name", values?.quals_name);
    data.append("color_code", values?.color_code);
    data.append("quals_position_order", values?.quals_position_order);

    dispatch(qualificationActions.createQualification(data));
  };

  useEffect(() => {
    if (qualCreatingError != null && !qualCreatingError) {
      setIsAddModalOpen(false);
      setIsSubmitting(false);
      resetAddQual();
      toast.success("Qualification created successfully.");
    } else if (qualCreatingError) {
      setIsSubmitting(false);
      toast.error("Failed to create qualification.");
    }
  }, [qualCreatingError]); 


  const handleOnEdit = async (values, id) => {
    //handle Edit qualification functioanility
    setIsSubmitting(true);
    let data = new FormData();
    data.append("quals_short_form", values?.quals_short_form);
    data.append("quals_name", values?.quals_name);
    data.append("color_code", values?.color_code);
    data.append("quals_position_order", values?.quals_position_order);

    dispatch(qualificationActions.editQualification(id, data));
  };

  useEffect(() => {
    if (qualEditingError != null && !qualEditingError) {
      setSelectedQualId(null);
      setIsEditModalOpen(false);
      setIsSubmitting(false);
      toast.success("Qualification edited successfully.");
    } else if (qualEditingError) {
      setIsSubmitting(false);
      toast.error("Failed to edit qualification.");
    }
  }, [qualEditingError]); 

  const errorStyles = (field) => ({
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: field ? red : grey,
    }),
  });

  const openDeleteModal = (qualId) => {
    setSelectedQualId(qualId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteSubmitModal = () => {
    setSelectedQualId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDeleteQualSubmit = (event) => {
    event.preventDefault();
    try {
      dispatch(qualificationActions.deleteQualification(selectedQualId));
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setSelectedQualId(null);
    setIsDeleteModalOpen(false);
    if (qualDeletionError != null && !qualDeletionError) {
      toast.success("Qualification deleted successfully.");
    } else if (qualDeletionError) {
      toast.error("Failed to delete Qualification.");
    }
  }, [qualDeletionError]);

  
  useEffect(() => {
    const fetchQualificationList = () => {
      try {
        // Assuming dispatch is an async function
        dispatch(
          qualificationActions.fetchQualificationList()
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchQualificationList();
  }, []);


  useEffect(() => {
    if (!isDeleteModalOpen) {
      setSelectedQualId(null);
    }
  }, [isDeleteModalOpen]);

  useEffect(() => {
    clearErrorsEditQual();
    if (selectedIndex !== null) {
      const tempValue = qualifications[selectedIndex];

      setValueEditQual("quals_name", tempValue?.value);
      setValueEditQual("quals_short_form", tempValue?.key);
      setValueEditQual(
        "color_code",
        listColorCode?.find(
          (option) => option.value == tempValue?.color_code
        )?.value
      );
      setValueEditQual("quals_position_order", tempValue?.position);

    }
  }, [selectedIndex, isEditModalOpen]);

  useEffect(() => {
    dispatch(createProfileActions?.getAll());
  }, []);

  const QualificationItemMobile = ({ data, index }) => (
    <div key={index}>
      <hr></hr>
      <div className="status_item">
        <div className="list_item">
          <strong>Qualification</strong>
        </div>
        <div className="list_item">
          <span className="wpcf7-list-item-label">
            {data?.value}
          </span>
        </div>
      </div>
      <div className="status_item">
        <div className="list_item">
          <strong>Abbreviation</strong>
        </div>
        <div className="list_item">
          <span className="wpcf7-list-item-label">
            {data?.key}
          </span>
        </div>
      </div>
      <div className="status_item">
        <div className="list_item">
          <strong>Color Code</strong>
        </div>
        <div className="list_item">
        <span className="wpcf7-list-item-label">
          {generalQualification?.find(
              (option) =>
                option.id ==
                data?.color_code
            )?.color_code ?? '-'}
          </span>
        </div>
      </div>
      <div className="status_item">
        <div className="list_item">
          <strong>Position</strong>
        </div>
        <div className="list_item">
        <span className="wpcf7-list-item-label">
            {data?.position}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="outerMostContainer">
      <div className="settings_container settings-pre-wrapper">
        <div className="page_title float">
          <h1>
            Manage Qualifications
          </h1>
        <div className="content_div settings">
      <div>
      </div>
      <div className="qualifications_header-tab">
        <h3>Qualifications</h3>
        {currentUserPermissions?.includes("Manage Qualifications") && (
          <div className="qualifications_btn_grp">
            <button className="button" onClick={() => setIsAddModalOpen(true)}>
              Create Qualification
            </button>
          </div>
        )}
      </div>
      <table className="tab_hide qualification_table" style={{ width: "100%" }}>
        <thead>
          <tr className="qualification_table_row">
            <th>Qualification</th>
            <th>Abbreviation</th>
            <th>Color Code</th>
            <th>Position</th>
            <th>  </th>
          </tr>
        </thead>
        {/* Desktop List */}
        <tbody className="qualification_table_tbody">
          {qualifications?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className="current_qualification_item">
                    <div>
                      <span className="wpcf7-list-item-label">
                        {item?.value}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="current_qualification_item">
                    <div className="btn_label_wrapper">
                      <span className="wpcf7-list-item-label">
                        {item?.key}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                <div className="current_qualification_item">
                    <div className="btn_label_wrapper">
                      <span className="wpcf7-list-item-label">
                      {generalQualification?.find(
                        (option) =>
                          option.id ==
                          item?.color_code
                      )?.color_code ?? '-'}
                      </span>
                    </div>
                  </div>
                  
                </td>
                <td>
                  <div className="current_qualification_item">
                    <div className="btn_label_wrapper">
                      <span className="wpcf7-list-item-label">
                        {item?.position}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                {currentUserPermissions?.includes("Manage Qualifications") && (
                    <div>
                      <span
                        style={{ cursor: "pointer", marginRight: "15px" }}
                        onClick={() => {
                          openEditModal(index); 
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="delete document"
                          style={{ width: "15px" }}
                        />
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openDeleteModal(item?.id);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="delete document"
                          style={{ width: "15px" }}
                        />
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* Mobile List */}
      <div className="tab_show">
        <div className="history_items">
          {qualifications?.map((item, index) => (
            <QualificationItemMobile
              data={item}
              index={index}
              key={index}
            />
          ))}
        </div>
      </div>

      {/* Modal: Create Qual */}

      <ReactModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        overlayClassName="alertPopup"
        style={customStylesQualModals}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">Create Qualification</h4>
          </div>
          <form onSubmit={handleSubmitAddQual((data) =>
              handleOnSubmit(data)
            )}>
          <div className="modal-body" style={{ textAlign: "left" }}>
              <label>Qualification</label>
              <Controller
                rules={{
                  required: "Qualification name is required",
                  maxLength: {
                    value: 255,
                    message: "Qualification name must not exceed 255 characters"
                  }
                }}
                name="quals_name"
                control={controlAddQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="text"
                      id="input1"
                      onChange={field.onChange}
                      styles={errorStyles(errorsAddQual?.quals_name)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_name && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_name[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Abbreviation</label>
              <Controller
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 45,
                    message: "Abbreviation must not exceed 45 characters"
                  }
                }}
                name="quals_short_form"
                control={controlAddQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="text"
                      id="input2"
                      onChange={field.onChange}
                      styles={errorStyles(errorsAddQual?.quals_short_form)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_short_form && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_short_form[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Color Code</label>
              <Controller
                rules={{ required: true }}
                name="color_code"
                control={controlAddQual}
                render={({ field: { onChange } }) => (
                  <Select
                    isDisabled={isSubmitting}
                    options={listColorCode}
                    onChange={(newDivision) => {
                      onChange(newDivision?.value);
                    }}
                    placeholder={"Select Color Code"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={errorStyles(errorsAddQual?.color_code)}
                  />
                )}
              />

              <label style={{ marginTop: 20 }}>Position</label>
              <Controller
                rules={{
                  required: "Position order value is required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Only numbers are allowed",
                  },
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                }}
                name="quals_position_order"
                control={controlAddQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="number"
                      id="input4"
                      onChange={field.onChange}
                      styles={errorStyles(errorsAddQual?.quals_position_order)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_position_order && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_position_order[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />
              <div className="qual_btn_list">
                <button
                  disabled={isSubmitting}
                  className="button grey mr-1"
                  onClick={() => setIsAddModalOpen(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className="button"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* Modal: Edit Qual */}

      <ReactModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        overlayClassName="alertPopup"
        style={customStylesQualModals}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title">Edit Qualification</h4>
          </div>
          <form
            onSubmit={handleSubmitEditQual((data) =>
              handleOnEdit(
                data,
                qualifications[selectedIndex]?.id
              )
            )}
          >
            <div className="modal-body" style={{ textAlign: "left" }}>
              <label>Qualification</label>
              <Controller
                rules={{
                  required: "Qualification name is required",
                  maxLength: {
                    value: 255,
                    message: "Qualification name must not exceed 255 characters"
                  }
                }}
                name="quals_name"
                control={controlEditQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="text"
                      id="input1"
                      defaultValue={
                        qualifications[selectedIndex]?.value
                      }
                      onChange={field.onChange}
                      styles={errorStyles(errorsEditQual?.quals_name)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_name && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_name[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Abbreviation</label>
              <Controller
                rules={{
                  required: "Abbreviation is required",
                  maxLength: {
                    value: 45,
                    message: "Abbreviation must not exceed 45 characters"
                  }
                }}
                name="quals_short_form"
                control={controlEditQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="text"
                      id="input2"
                      defaultValue={
                        qualifications[selectedIndex]?.key
                      }
                      onChange={field.onChange}
                      styles={errorStyles(errorsEditQual?.quals_short_form)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_short_form && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_short_form[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />

              <label style={{ marginTop: 20 }}>Color Code</label>
              <Controller
                rules={{ required: true }}
                name="color_code"
                control={controlEditQual}
                render={({ field: { onChange } }) => (
                  <Select
                    isDisabled={isSubmitting}
                    defaultValue={listColorCode?.find(
                      (option) =>
                        option.value ==
                        qualifications[selectedIndex].color_code
                    )}
                    options={listColorCode}
                    onChange={(newDivision) => {
                      onChange(newDivision?.value);
                    }}
                    placeholder={"Select Color Code"}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    styles={errorStyles(errorsEditQual?.color_code)}
                  />
                )}
              />

              <label style={{ marginTop: 20 }}>Position</label>
              <Controller
                rules={{
                  required: "Position order value is required",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Only numbers are allowed",
                  },
                  min: {
                    value: 0,
                    message: "Value must be at least 0",
                  },
                }}
                name="quals_position_order"
                control={controlEditQual}
                render={({ field, fieldState }) => (
                  <div className="editqual_input_container">
                    <input
                      disabled={isSubmitting}
                      type="number"
                      id="input4"
                      defaultValue={
                        qualifications[selectedIndex]?.position
                      }
                      onChange={field.onChange}
                      styles={errorStyles(errorsEditQual?.quals_position_order)}
                    />
                    {fieldState.error && (
                      <div className="qual_error_container">
                        {fieldState.error.message}
                      </div>
                    )}
                    {qualValidationError && qualValidationError.quals_position_order && (
                      <div className="qual_error_container">
                        {qualValidationError.quals_position_order[0]} {/* Displaying only the first error for simplicity */}
                      </div>
                    )}
                  </div>
                )}
              />
              <div className="qual_btn_list">
                <button
                  disabled={isSubmitting}
                  className="button grey mr-1"
                  onClick={() => setIsEditModalOpen(false)}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className="button"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      {/* Modal: Delete Qual */}
      <ReactModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        overlayClassName="alertPopup"
        style={customStylesQualModals}
      >
        <div id="statusPopup" className="popupModal">
          <div className="modal-head">
            <h4 className="modal-title-qual">Delete Qualification</h4>
          </div>
          <form onSubmit={handleDeleteQualSubmit}>
            <div className="modal-body-qual" style={{ textAlign: "left" }}>
              <p>Are you sure you want to delete this qualification? This action will remove the qualification from all profiles where it has been added.</p>
              <div className="qual_btn_list">
                <button
                  disabled={isSubmitting}
                  className="button grey mr-1"
                  onClick={closeDeleteSubmitModal}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  disabled={isSubmitting}
                  className="button"
                  type="submit"
                  style={{ backgroundColor: "#DA120B" }}
                >
                  Delete
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

    </div>
        </div>
      </div>
    </div>
    
  );
}

export default ManageQualificationContainer;

const customStylesQualModals = {
  content: {
    maxWidth: 460,
    height: "auto",
  },
};
