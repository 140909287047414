import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';

class AlertPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewOpen: true
		}
		this.viewCloseModal = this.viewCloseModal.bind(this);
	}
	viewCloseModal() {
		this.setState({ viewOpen: false, alertPop: false });
		if (this.props.alertFunction) {
			this.props.alertFunction()
		}
		this.props.close()
	}
	componentDidMount() { }
	render() {
		const { secondaryButtonText, onSecondaryButtonClick } = this.props
		return (
			<div>
				<ReactModal
					isOpen={this.state.viewOpen} onClose={this.viewCloseModal.bind(this)}
					contentLabel="Minimal Modal Example" overlayClassName="alertPopup"
				>
					<div id="statusPopup" className="popupModal">
						<div className="modal-head">
							<h4 className="modal-title">{this.props.head}</h4>
						</div>
						<div className="modal-body">
							<p>{this.props.message}</p>
							<div className="btn_list">
								{secondaryButtonText && (
									<button className="button grey" onClick={() => onSecondaryButtonClick?.()}>{secondaryButtonText}</button>
								)}
								<button className="button" onClick={this.viewCloseModal.bind(this)}>Ok</button>
							</div>
						</div>
					</div>
				</ReactModal>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
	};
}
const connectedApp = connect(mapStateToProps)(AlertPopup);
export { connectedApp as AlertPopup };