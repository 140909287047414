import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { dashboardActions, resourceActions } from "_actions";
import { dashboardService } from "../_services/dashboard.service";
import "../assets/styles/report.css";
import RefresherReportList from "./RefresherReportList";
import {  selectRefresherReports } from "_helpers/reduxSelector";
import { redirectToPdf } from "_helpers/redirectToPdf";

const MENU_WIDTH = 300;

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const RefresherReportMain = (props) => {
  const dispatch = useDispatch();
  const [selectedResource, setSelectedResource] = React.useState();
  const [sortAndOrder, setSortAndOrder] = React.useState({
    order: "asc",
    sortBy: "last_name",
  });


  const refresherReports = useSelector(selectRefresherReports);
 



  const getRefresherReportWithFilters = (newPage, sort, order) => {
    handleSortAndOrderForPrint(sort, order);
    let data = new FormData();
    data.append("page", newPage || 0);
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sort || "last_name");
    data.append("order", order || "asc");
    dispatch(dashboardActions.getRefresherReport(data));
  };

  const onPrintClick = () => {
    let data = new FormData();
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("report_type","pdf")
    dashboardService.generateRefresherReport(data).then((response) => redirectToPdf(response));
  };
  
  const onExportClick = () => {
    let data = new FormData();
    data.append("resource_id", selectedResource?.value || 0);
    data.append("sort", sortAndOrder?.sortBy);
    data.append("order", sortAndOrder?.order);
    data.append("report_type","csv")
    dashboardService.generateRefresherReportReportCsv(data)
  }

  const handleSearch = async (text) => {
    let data = new FormData();
    data.append("keyword", text);
    const resources = dispatch(resourceActions.searchResource(data));
    let resourceList = [];

    const response = await resources;
    const items = response.data;
    var res = [];
    for (var key in items) {
      var arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }
    if (res.length > 0) {
      res.forEach((item) => {
        let resource = {
          label: item.resource_name,
          value: item.id,
        };
        resourceList.push(resource);
      });
    }
    return resourceList;
  };

  const loadResources = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(handleSearch(inputValue));
      }, 1000);
    });


    const handleSortAndOrderForPrint = (sortBy, order) => {
      let _sortAndOrder = { ...sortAndOrder };
      _sortAndOrder.sortBy = sortBy || _sortAndOrder.sortBy;
      _sortAndOrder.order = order || _sortAndOrder.order;
      setSortAndOrder(_sortAndOrder);
    };

  return (
    <div>
      <div className={props.isReportMenu ? "" : "dashboard_container"}>
        <div className="white">
          {!props.isReportMenu && (
            <div className="back_to_board dashboard_expiration_date_nav">
              <div className="back_button">
                <img src={require("../assets/images/back.svg").default} />{" "}
                <span>
                  <Link to="/">Back to dashboard</Link>
                </span>
              </div>
              {refresherReports?.data?.length ? (
                <button
                  href=""
                  className="print_button"
                  onClick={(event) => {
                    onPrintClick();
                  }}
                >
                  <img
                    alt=""
                    src={require("../assets/images/print.svg").default}
                  />{" "}
                  Print
                </button>
              ) : (
                <div className="dashboard-expiration-date-print-btn-mock" />
              )}
            </div>
          )}
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Refresher Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp exp_multi_head_flx"
                >
                  <div className="report_multi_btn_grp exp_multi_btn_flx">
                    <div
                      style={{ minWidth: 300 }}
                      className="inp_sub_container"
                    >
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadResources}
                        onChange={(selection) => setSelectedResource(selection)}
                        // isMulti
                        defaultOptions
                        isClearable
                        styles={selectStyles}
                        placeholder={"Choose Resource Filter"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    {refresherReports?.data?.length && props.isReportMenu ? (
                      <div className="button__div-flex">
                        <button
                          href=""
                          className="print_button"
                          style={{marginRight:"10px"}}
                          onClick={(event) => {
                            onExportClick();
                          }}
                        >
                          Export
                        </button>
                        <button
                          href=""
                          className="print_button"
                          onClick={(event) => {
                            onPrintClick();
                          }}
                        >
                          {/* <img
                            alt=""
                            src={require("../assets/images/print.svg").default}
                          />{" "} */}
                          Print
                        </button>
                      </div>
                    ) : (
                      <div className="dashboard-expiration-date-print-btn-mock" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RefresherReportList
                refresherReports={refresherReports}
                getRefresherReport={getRefresherReportWithFilters}
                selectedResource={selectedResource}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefresherReportMain;
