import { useEffect } from "react";
import AsyncSelect from "react-select/async";

export const CreatableAsyncSelectMulti = (props) => {
  const { onChange, placeholder,loadOptions,defaultValue } = props;

  useEffect(() => {
    onChange(defaultValue)
  },[defaultValue?.length])

  return (
    <div className="CreatableAsyncSelectMulti">
      <AsyncSelect
        loadOptions={loadOptions}
        placeholder={placeholder}
        onChange={onChange}
        defaultOptions
        defaultValue={defaultValue}
        cacheOptions={false}
        isClearable
        isMulti
        maxMenuHeight={200}
      />
    </div>
  );
};
