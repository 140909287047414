import { authHeader, config } from "../_helpers";

export const qualificationService = {
  // All Qualifications
  fetchAllQualifications,
  deleteQualification,
  editQualification,
  createQualification
};

function fetchAllQualifications() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/qualification/list",
    requestOptions
  ).then(handleResponse, handleError);
}

function deleteQualification(qualId) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/qualification/" + qualId + "/delete",
    requestOptions
  ).then(handleResponse, handleError);
}

function editQualification(id, data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/qualification/" + id + "/edit",
    requestOptions
  ).then(handleResponse, handleError);
}

function createQualification(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data
  };

  return fetch(
    config.apiUrl + "/api/qualification/create",
    requestOptions
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        // return json if it was returned in the response
        var contentType = response.headers.get("content-type");
        if (contentType && contentType.includes("application/json")) {
          response.json().then((json) => resolve(json));
        } else {
          resolve();
        }
      } else {
        // return error message from response body
        response.text().then((text) => reject(text));
      }
    });
  }
  
  function handleError(error) {
    return Promise.reject(error && error.message);
  }