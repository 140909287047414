import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import { authHeader, config } from "../_helpers";
import "../assets/styles/resource.css";
import EmployeeResourceFilter from "./EmployeeResourceFilter";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

class Employees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: true,
      employees: [],
      type: "",
      current_page: 0,
      last_page: 0,
      scroll: true,
      printLoading: false,
      employeFilter: {
        sort: "name",
        order: "asc",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.generateList = this.generateList.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    const id = this.props.match.params.id;
    let data = new FormData();
    let { employeFilter } = this.state;
    if (id === "20") {
      data.append("available", true);
    } else if (id === "21") {
      data.append("available", false);
    } else if (id === "22") {
      // data.append('available', true)
    } else {
      data.append("type", id);
    }
    data.append("sort", employeFilter.sort);
    data.append("order", employeFilter.order);
    this.setState({ type: id }, () => {
      this.props.dispatch(userActions.getEmployeeList(data));
    });
  }

  generateList(list) {
    // console.log("Generating List", list);
    let options = [];
    if (list.length > 0) {
      // this.setState({ scroll: false });
      list.map((item, index) => {
        options.push(
          <div className="applicant_item list_item" key={index}>
            <a href={"/employee-profile/" + item.hash} className="homeEmp_list">
              <div className="basic_info unavailable_employe">
                <div className=" item_cell">
                  <span>{item.first_name + " " + item.last_name}</span>
                </div>
                <div className="appln_phone item_cell">
                  <span>{item.user_type}</span>
                </div>
                <div className="item_cell">
                  <span>
                    {item.phone ? (
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`tel:${item.phone}`}
                        target="_top"
                      >
                        {formatPhoneNumber(item.phone)}
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="item_cell">
                  <span>
                    {item.email ? (
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                        className="phone_email_link"
                        href={`mailto:${item.email}`}
                        target="_top"
                      >
                        {item.email}
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </a>
          </div>
        );
      });
    } else {
      options.push(
        <div className="applicant_item list_item no_emp_result">
          <p>No employees</p>
        </div>
      );
    }
    return options;
  }

  componentWillReceiveProps(nextProps) {
    // console.log("CWRP", nextProps);
    let employees = this.state.employees;
    // let employees = [];
    const { user } = nextProps;
    if (user.items) {
      const { data} = user.items;
      const { current_page, last_page } = data;
      let options = [];
      if(this.state.current_page !== current_page && this.state.current_page < current_page){
        this.setState(
          {current_page,last_page, scroll:false},
          () => {
            options = this.generateList(data.data);
            this.setState({ employees: [...employees, options] });
          });

      }

      // if (data.data && data.data.length > 0) {
      //   this.setState({ scroll: false });
      // }
      //   let tempArray = [...this.state.employees, options];

      //   tempArray = tempArray.filter(
      //     (item, index, self) =>
      //       index === self.findIndex((t) => t.hash === item.hash)
      //   );
      //   employees = tempArray;
      // else {
      // 	options = this.generateList(data.data)
      // }
    }
  }

  handleChange(event) {
    let { employeFilter } = this.state;
    let role_id = event.target.value;
    let type = role_id;
    let data = new FormData();
    this.setState({ type, current_page: 0 });
    data.append("type", type);
    if (type === "21") {
      data.append("available", false);
    }
    if (type === "20") {
      data.append("available", true);
    }
    if (this?.state?.resourceId) {
      data.append("crew_identifier_id", this?.state?.resourceId);
    }
    // this.setState({ employees: [] });
    data.append("sort", employeFilter.sort);
    data.append("order", employeFilter.order);
    let promise = this.props.dispatch(userActions.getEmployeeList(data));
    promise.then((res) => {
      this.setState({ employees: this.generateList(res.user.data.data) });
    });
    this.props.history.push(role_id);
  }

  handleResourceTypeChange(resourceId) {
    let { employeFilter } = this.state;
    this.setState({ resourceId, current_page: 0 })
    let role_id =this?.state?.type|| this.props.match.params.id;
    let type = role_id;
    let data = new FormData();
    this.setState({ type });
    data.append("type", type);
    if (type === "21") {
      data.append("available", false);
    }
    if (resourceId){
      data.append("crew_identifier_id", resourceId);
    }
    if (type === "20") {
      data.append("available", true);
    }
    // this.setState({ employees: [] });
    data.append("sort", employeFilter.sort);
    data.append("order", employeFilter.order);
    let promise = this.props.dispatch(userActions.getEmployeeList(data));
    promise.then((res) => {
      this.setState({ employees: this.generateList(res.user.data.data) });
    }); 
  }

  handleScroll(event) {
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight;
    let { employeFilter } = this.state;
    if (y >= contentHeight) {
      if (
        this.state.current_page < this.state.last_page &&
        this.state.flag !== false
      ) {
        this.setState({ scroll: false });
        let data = new FormData();
        if (this?.state?.resourceId) {
          data.append("crew_identifier_id", this?.state?.resourceId);
        }
        if (this.props.match.params.id === "20") {
          data.append("available", true);
        } else if (this.props.match.params.id === "21") {
          data.append("available", false);
        } else if (this.props.match.params.id === "22") {
          // data.append('available', true)
        } else {
          data.append("type", this.props.match.params.id);
        }
        data.append("sort", employeFilter.sort);
        data.append("order", employeFilter.order);
        data.append("page", this.state.current_page + 1);

        this.props.dispatch(userActions.getEmployeeList(data));
      }
    }
  }
  printApplicationList = () => {
    let { employeFilter } = this.state;
    this.setState({ printLoading: true });
    let type = this.state.type ? this.state.type : this.props.match.params.id;
    let self = this;
    let data = new FormData();
    if (type === "20") {
      data.append("available", true);
    } else if (type === "21") {
      data.append("available", false);
    }
    if (this?.state?.resourceId) {
      data.append("crew_identifier_id", this?.state?.resourceId);
    }
    data.append("type", this.props.match.params.id); // this could be this.state.type
    data.append("page", this.state.current_page);
    data.append("sort", employeFilter.sort);
    data.append("order", employeFilter.order);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: data,
    };

    fetch(config.apiUrl + "/api/employee/printcontacts", requestOptions)
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        const pdfLink = data.data;
        var link = document.createElement("a");
        if (
          window.navigator.userAgent.match(/iPad/i) ||
          window.navigator.userAgent.match(/iPhone/i)
        ) {
          link.href = pdfLink;
        } else {
          link.href = pdfLink;
          link.target = "_blank";
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          document.body.removeChild(link);
          // For Firefox it is necessary to delay revoking the ObjectURL
          self.setState({ printLoading: false });
          window.URL.revokeObjectURL(pdfLink);
        }, 100);
      });
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  filterEmployeList = (field) => {
    let { type, employeFilter } = this.state;

    let data = new FormData();
    data.append("type", type);
    if (type === "21") {
      data.append("available", false);
    }
    if (type === "20") {
      data.append("available", true);
    }
    if (this?.state?.resourceId) {
      data.append("crew_identifier_id", this?.state?.resourceId);
    }

    let filter = "asc";
    if (employeFilter.sort == field) {
      filter = employeFilter.order == "asc" ? "desc" : "asc";
    }
    data.append("sort", field);
    data.append("order", filter);
    this.setState({
      employees: [],
      employeFilter: {
        sort: field,
        order: filter,
      },
    });
    // this.props.dispatch(userActions.getEmployeeList(data));
    let promise = this.props.dispatch(userActions.getEmployeeList(data));
    promise.then((res) => {
      this.setState({ employees: this.generateList(res.user.data.data) });
    });
  };
  render() {
    let h1 = "",
      employees = this.state.employees;
    switch (this.props.match.params.id) {
      case "4":
        h1 = "Superintendent";
        break;
      case "5":
        h1 = "Engine Captain";
        break;
      case "6":
        h1 = "Crew Captain";
        break;
      case "7":
        h1 = "Firefighter";
        break;
      case "8":
        h1 = "Staff Manager";
        break;
      case "9":
        h1 = "Office Staff";
        break;
      case "11":
        h1 = "GISS Technicians";
        break;
      case "20":
        h1 = "Available Employees";
        break;
      case "21":
        h1 = "Unavailable Employees";
        break;
      case "22":
        h1 = "Total Employees";
        break;
      default:
        h1 = "Employees";
        break;
    }
    if (!this.state.scroll) {
      var nameClass, emailClass, phoneClass, posstion;

      if (this.state.employeFilter.sort == "name") {
        if (
          this.state.employeFilter.order == "asc" &&
          this.state.employeFilter.sort == "name"
        ) {
          nameClass = "sort-arrow desc";
        } else {
          nameClass = "sort-arrow asc";
        }

        emailClass = phoneClass = posstion = "sort-arrow";
      }
      if (this.state.employeFilter.sort == "email") {
        if (
          this.state.employeFilter.order == "asc" &&
          this.state.employeFilter.sort == "email"
        ) {
          emailClass = "sort-arrow desc";
        } else {
          emailClass = "sort-arrow asc";
        }
        nameClass = phoneClass = posstion = "sort-arrow";
      }
      if (this.state.employeFilter.sort == "phone") {
        if (
          this.state.employeFilter.order == "asc" &&
          this.state.employeFilter.sort == "phone"
        ) {
          phoneClass = "sort-arrow desc";
        } else {
          phoneClass = "sort-arrow asc";
        }
        nameClass = emailClass = posstion = "sort-arrow";
      }
      if (this.state.employeFilter.sort == "type") {
        if (
          this.state.employeFilter.order == "asc" &&
          this.state.employeFilter.sort == "type"
        ) {
          posstion = "sort-arrow desc";
        } else {
          posstion = "sort-arrow asc";
        }
        nameClass = emailClass = phoneClass = "sort-arrow";
      }
      return (
        <div className="applicants_container applicant_listing home_employee">
          <div className="page_title">
            <h1>{h1}</h1>
          </div>

          <div className="applicants_inner_head inner_head" />
          <div className="inner_container col-lg-12" id="applicants_inner">
            <a className="goback" href="/dashboard">
              <p>Back</p>
            </a>
            <button
              className="button employees-print-button"
              onClick={this.printApplicationList}
            >
              <img
                className="print-icon"
                alt="Loader"
                src={require("../assets/images/printicon.svg").default}
              />
              Print
            </button>
            <div className="employees-print-button-loader">
              &nbsp;
              {this.state.printLoading && (
                <img
                  alt="loader"
                  className="login_loader"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              )}
            </div>
            <div className="employee_filter_container">
              <div className="employee_filter_resource">
                <EmployeeResourceFilter
                  handleResourceTypeChange={(resourceId) =>
                    this.handleResourceTypeChange(resourceId)
                  }
                />
              </div>
              <select
                name="changeEmployees"
                className="changeEmployees"
                value={
                  this.state.type ? this.state.type : this.props.match.params.id
                }
                onChange={this.handleChange.bind(this)}
              >
                {/*<option value="0">-- Select type --</option>*/}
                <option value="22">Total Employees</option>
                <option value="20">Available Employees</option>
                <option value="21">Unavailable Employees</option>
                <option value="4">Superintendents</option>
                <option value="5">Engine Captain</option>
                <option value="6">Crew Captain</option>
                <option value="7">Firefighter</option>
                <option value="8">Staff Manager</option>
                <option value="9">Office Staff</option>
                <option value="12">DCR Operations Manager</option>
                <option value="11">GISS Technician</option>
              </select>
            </div>
            <div className="clearFix" />
            <div
              className="applicants_list"
              id="applicants_list"
              onScroll={this.handleScroll}
            >
              <div className="appln_list_header">
                <div className="list_item_head">
                  Employee
                  <span
                    className={nameClass}
                    onClick={() => {
                      this.filterEmployeList("name");
                    }}
                  />
                </div>
                <div className="list_item_head">
                  Position
                  <span
                    className={posstion}
                    onClick={() => {
                      this.filterEmployeList("type");
                    }}
                  />
                </div>
                <div className="list_item_head">
                  Phone#
                  <span
                    className={phoneClass}
                    onClick={() => {
                      this.filterEmployeList("phone");
                    }}
                  />
                </div>
                <div className="list_item_head appln_date">
                  Email
                  <span
                    className={emailClass}
                    onClick={() => {
                      this.filterEmployeList("email");
                    }}
                  />
                </div>
              </div>
              {/* { this.state.applicationItemFirst } */}
              {employees}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="applicants_container applicant_listing home_employee">
        <div className="page_title">
          <h1>{h1}</h1>
        </div>

        <div className="applicants_inner_head inner_head" />
        <div className="inner_container col-lg-12" id="applicants_inner">
          {this.state.scroll ? (
            <center
              id="feedLoader"
              className={this.state.loader ? this.state.loader : ""}
            >
              <img
                alt="Loader"
                src={require("../assets/images/loader.gif").default}
              />
            </center>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return {
    user,
  };
}

const connectedResourcePage = connect(mapStateToProps)(Employees);
export { connectedResourcePage as Employees };
