import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { inventoryActions } from "_actions";
import {
  getSelectedUserInfo,
  selectAssignedInventoryList,
} from "../_helpers/reduxSelector";
import { formatDate } from "_helpers/helpers";

export default function AssignedInventoryList() {
  const dispatch = useDispatch();

  const currentUserInfo = useSelector(getSelectedUserInfo);
  const inventoryList = useSelector(selectAssignedInventoryList);

  React.useEffect(() => {
    if (currentUserInfo?.hash) {
      let data = new FormData();
      data.append("hash", currentUserInfo?.hash);
      dispatch(inventoryActions?.getInventoryList(data));
    }
  }, [currentUserInfo]);

  const totalCost = React.useMemo(() => {
    return inventoryList.length && inventoryList?.reduce((a, b) => a + parseInt(b?.cost, 10), 0);
  }, [inventoryList]);


  return (
    <div style={{ margin: "20px 0px" }}>
      {inventoryList?.length ? (
        <div>
          <h4 className="cp-title">Inventory Assigned</h4>
          {inventoryList?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5>{`${index + 1}. ${item.unique_id} (${item.type})`}</h5>
                  <p>{`Assigned on: ${formatDate(item?.assign_date)}`}</p>
                  {!!item?.assigned_by && <p>{`Assigned by: ${item?.assigned_by}`}</p>}
                  <p>
                    Status:{" "}
                    {item?.acknowledged ? (
                      <span style={{ color: "green" }}>Acknowledged</span>
                    ) : (
                      <span style={{ color: "red" }}>Not Acknowledged</span>
                    )}
                  </p>
                </div>

                <div>
                  <p>{`$ ${item?.cost}`}</p>
                </div>
              </div>
            );
          })}
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "bold", color: "black" }}>Total</p>
            <p
              style={{ fontWeight: "bold", color: "black" }}
            >{`$ ${totalCost}`}</p>
          </div>
          <p className="disclaimer">
            Employee is responsible for the condition, care, and replacement
            value of issued inventory. Issued Inventory not returned, lost or
            stolen will be the reasonability of the employee. If you do not see
            the cost above of your current issued equipment, please contact your
            supervisor.
          </p>
        </div>
      ) : null}
    </div>
  );
}
