export const qualificationConstants = {
    // List Qualifications
    ALL_QUALIFICATIONS_REQUEST: "ALL_QUALIFICATIONS_REQUEST",
    ALL_QUALIFICATIONS_SUCCESS: "ALL_QUALIFICATIONS_SUCCESS",
    ALL_QUALIFICATIONS_ERROR: "ALL_QUALIFICATIONS_ERROR",

    // Delete Qual
    DELETE_QUALIFICATION_REQUEST: "DELETE_QUALIFICATION_REQUEST",
    DELETE_QUALIFICATION_SUCCESS: "DELETE_QUALIFICATION_SUCCESS",
    DELETE_QUALIFICATION_ERROR: "DELETE_QUALIFICATION_ERROR",

    // Edit Qual
    EDIT_QUALIFICATION_REQUEST: "EDIT_QUALIFICATION_REQUEST",
    EDIT_QUALIFICATION_SUCCESS: "EDIT_QUALIFICATION_SUCCESS",
    EDIT_QUALIFICATION_ERROR: "EDIT_QUALIFICATION_ERROR",

    // Create Qual
    CREATE_QUALIFICATION_REQUEST: "CREATE_QUALIFICATION_REQUEST",
    CREATE_QUALIFICATION_SUCCESS: "CREATE_QUALIFICATION_SUCCESS",
    CREATE_QUALIFICATION_ERROR: "CREATE_QUALIFICATION_ERROR",

    RESET_QUALIFICATION_ERROR: "RESET_QUALIFICATION_ERROR"
}