import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import { manifestActions, resourceActions } from "../_actions";
import moment from "moment";
import "../assets/styles/manifest.css";
import { AlertPopup } from "../_components/AlertPopup";
import DatePicker from "react-datepicker";
import ReactModal from "react-modal";
import ResourceOrderFormUploader from "_components/ResourceOrderFormUploader/ResourceOrderFormUploader";
import { manifestService } from "_services";
import ResourceOrderForm from "Docs/Forms/ResourceOrderForm/ResourceOrderForm";
import { withRouter } from 'react-router-dom';
import { formatDate } from "_helpers/helpers";

ReactModal.setAppElement("#app");


const customStyles = {
  content: {
    overflow:"unset",
  },
  overlay: {
    zIndex:99,
  }
};

const manifestType = {
  IFCA_Manifest: "1",
  NCC: "3",
  VIPR: "4",
  DCRM: "5",
  PROJECT_MANIFEST: "6",
  ODF: "7",
  TYPE_2: "8"
};

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : "(" + m[1] + ")-" + m[2] + "-" + m[3];
}
const RESOURCE_ORDER_FILE_TYPE = {
  CREATE_FORM: 1,
  UPLOADED_FORM: 2

}
const initialState = {};

const unDefined = 'undefined';
class Finalize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_id: this.props.match && this.props.match.params ? this.props.match.params.id : null,
      added_date: null,
      crews: this.props.crews,
      vehicles: this.props.vehicles,
      manifestType: this.props.manifestType.toString(),
      superintendent: [],
      crewOptions: [],
      vehicleOptions: [],
      mailList: [],
      dispatchMailList: [],
      cclass: "a" + Math.floor(Math.random() * 60000),
      searchList: [],
      statusOpen: false,
      mdateError: false,
      lastNight: {},
      hideSearch: "hide",
      rof_type:"",
      rof_id:"",
      sup_name: "",
      h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
      form: {
        ordering_unit: "",
        incident_name: "",
        incident_number: "",
        resource_number: "",
        resource: "",
        ifca_number: "",
        project_name: "",
        project_code: "",
        dispatch_location: "",
        departure: "",
        report_to: "",
        des_place: "",
        des_eta: "",
        des_etd: "",
        inter_space: "",
        dep_place: "",
        dep_etd: "",
        dep_eta: "",
        inter_eta: "",
        vehicle_number: "",
        dispatch_time: "",
        perssonal: "Y",
        arr: "",
        dep_time: "",
        dep_arr: "",
        pdep_time: "",
        pedep_place: "",
        highq: [],
        sign_auth_representative: "",
        point_of_hire: "",
        job_loc: "",
      },
      shighq: [],
      print: false,
      email: false,
      submit: false,
      textarea: true,
      search: false,
      saved_id: "",
      printClick: false,
      alertPop: false,
      emailSuccess: "",
      saveBtn: false,
      validManifestMail: false,
      manifestEmail: "",
    };
    this.previous = this.previous.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitClick = this.submitClick.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.emailManifest = this.emailManifest.bind(this);
    this.emailChangeFn = this.emailChangeFn.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setMailer = this.setMailer.bind(this);
    this.removeList = this.removeList.bind(this);
    this.goBack = this.goBack.bind(this);
    this.printManifest = this.printManifest.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.addManifestEmail = this.addManifestEmail.bind(this);
    this.isValidEmail = this.isValidEmail.bind(this);
  }

  isValidEmail(email) {
    let re = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return re.test(email);
  }

  addManifestEmail() {
    if (this.state.validManifestMail) {
      this.setState({
        mailSearchKeyword: "",
        hideSearch: "hide",
        searchList: [],
        mailList: [
          ...this.state.mailList,
          {
            first_name: "-",
            last_name: "-",
            email: this.state.manifestEmail,
          },
        ],
      });
    }
  }

  previous() {
    this.props.previous(this.state.crews);
  }

  statusOpenModal(hash) {
    this.setState({ statusOpen: true, print: false });
  }

  goBack() {
    this.props.details(this.state.resource_id);
    this.setState({ printClick: false });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
      statusOpen:false,
    });
  }

  formatPhoneNumber(s) {
    var s2 = ("" + s).replace(/\D/g, "");
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return !m ? null : "(" + m[1] + ")-" + m[2] + "-" + m[3];
  }

  printManifest() {
    const err = this.validate();
    if (!err) {
      this.setState({ printClick: true });
      var data = "?manifest_id=" + this.state.saved_id + "&resource_id=" + this.state.resource_id;
      // this.props.dispatch(manifestActions.printManifest(data));
      let fdata = new FormData();
      if (this.state.crews.length > 0) {
        var count = 0;
        this.state.crews.map((item, index) => {
          if (item.category === "oncrew") {
            if (this.state.shighq[item.hash] !== undefined) {
              fdata.append("highq[" + count + "]", this.state.shighq[item.hash]);
            } else {
              fdata.append("highq[" + count + "]", item.incident_pos);
            }
            count++;
          }
          return fdata;
        });
      }
      const requestOptions = {
        method: "POST",
        // method: 'GET',
        headers: authHeader(),
        body: fdata,
      };

      // fetch(config.apiUrl + '/api/manifest/pdf1'+data, requestOptions)
      // .then(function(res){ return res.blob(); })
      // .then(function(blob){
        fetch(config.apiUrl + "/api/manifest/pdf" + data, requestOptions)
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          // var newBlob = new Blob([blob], {type: "application/pdf"})

          // // IE doesn't allow using a blob object directly as link href
          // // instead it is necessary to use msSaveOrOpenBlob
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // window.navigator.msSaveOrOpenBlob(newBlob);
          // return;
          // }

          // // For other browsers:
          // // Create a link pointing to the ObjectURL containing the blob.
          // const data = window.URL.createObjectURL(newBlob);
          const pdfLink = data.data;

          var link = document.createElement("a");
          if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
            link.href = pdfLink;
          } else {
            link.href = pdfLink;
            link.target = "_blank";
          }
          document.body.appendChild(link);
          link.click();
          setTimeout(function () {
            document.body.removeChild(link);
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(pdfLink);
          }, 100);
        });
    }
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  validate(e) {
    let isError = false;

    if (this.state.manifestType === "6") {
      if (this.state.form.est_start === "" || this.state.form.est_start === undefined) {
        isError = true;
        this.setState({
          estStartError: "error",
        });

        document.getElementById("estStart6").setAttribute("errors", "error");
      }

      if (this.state.form.est_finish === "" || this.state.form.est_finish === undefined) {
        isError = true;
        this.setState({
          estFinishError: "error",
        });

        document.getElementById("estFinish6").setAttribute("errors", "error");
      }

      if (!this.isValidDate(this.state.form.est_start, this.state.form.est_finish)) {
        isError = true;
        this.setState({
          estStartError: "error",
          estFinishError: "error",
        });

        document.getElementById("estStart6").setAttribute("errors", "error");
        document.getElementById("estFinish6").setAttribute("errors", "error");
      }

      if (this.state.form.project_code === "" || this.state.form.project_code === undefined) {
        isError = true;
        this.setState({
          projectCodeError: "error",
        });

        document.getElementById("projectCode6").focus();
      }

      if (this.state.form.project_name === "" || this.state.form.project_name === undefined) {
        isError = true;
        this.setState({
          projectNameError: "error",
        });

        document.getElementById("projectName6").focus();
      }

      if (this.state.form.resource === "" || this.state.form.resource === undefined) {
        isError = true;
        this.setState({
          resourceNameError: "error",
        });

        document.getElementById("resource6").focus();
      }

      if (this.state.form.point_of_hire === "" || this.state.form.point_of_hire === undefined) {
        isError = true;
        this.setState({
          pointOfHireError: "error",
        });

        document.getElementById("pointOfHire6").focus();
      }

      if (this.state.form.job_loc === "" || this.state.form.job_loc === undefined) {
        isError = true;
        this.setState({
          jobLocError: "error",
        });

        document.getElementById("jobLoc6").focus();
      }

      return isError;
    }

    if (this.state.manifestType === "5") {
      if (this.state.form.est_start === "" || this.state.form.est_start === undefined) {
        isError = true;
        this.setState({
          estStartError: "error",
        });

        document.getElementById("estStart5").setAttribute("errors", "error");
      }

      if (this.state.form.est_finish === "" || this.state.form.est_finish === undefined) {
        isError = true;
        this.setState({
          estFinishError: "error",
        });

        document.getElementById("estFinish5").setAttribute("errors", "error");
      }

      if (!this.isValidDate(this.state.form.est_start, this.state.form.est_finish)) {
        isError = true;
        this.setState({
          estStartError: "error",
          estFinishError: "error",
        });

        document.getElementById("estStart5").setAttribute("errors", "error");
        document.getElementById("estFinish5").setAttribute("errors", "error");
      }

      if (this.state.form.incident_number === "" || this.state.form.incident_number === undefined) {
        isError = true;
        this.setState({
          IncidentNumError: "error",
        });

        document.getElementById("incidentNumber5").focus();
      }

      if (this.state.form.incident_name === "" || this.state.form.incident_name === undefined) {
        isError = true;
        this.setState({
          incidentNameError: "error",
        });

        document.getElementById("incidentName5").focus();
      }

      if (this.state.form.resource === "" || this.state.form.resource === undefined) {
        isError = true;
        this.setState({
          resourceNameError: "error",
        });

        document.getElementById("resource5").focus();
      }

      if (this.state.form.point_of_hire === "" || this.state.form.point_of_hire === undefined) {
        isError = true;
        this.setState({
          pointOfHireError: "error",
        });

        document.getElementById("pointOfHire5").focus();
      }

      if (this.state.form.job_loc === "" || this.state.form.job_loc === undefined) {
        isError = true;
        this.setState({
          jobLocError: "error",
        });

        document.getElementById("jobLoc5").focus();
      }

      return isError;
    }

    if (
      (this.state.form.sign_auth_representative === "" || this.state.form.sign_auth_representative === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        signError: "error",
      });

      document.getElementById("sign4").focus();
    }

    if (
      (this.state.form.pedep_place === "" || this.state.form.pedep_place === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        perDepPlaceError: "error",
      });
      document.getElementById("perDepPlace").focus();
      // this.perDepPlaceInput.focus();
    }

    if (
      (this.state.form.pdep_time === "" || this.state.form.pdep_time === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        perDepTimeError: "error",
      });
      document.getElementById("perDepTime").focus();
      // this.perDepTimeInput.focus();
    }

    if ((this.state.form.dep_arr === "" || this.state.form.dep_arr === undefined) && this.state.manifestType === "4") {
      isError = true;
      this.setState({
        depArrError: "error",
      });
      document.getElementById("depArr").focus();
      // this.depArrInput.focus();
    }

    if (
      (this.state.form.dep_time === "" || this.state.form.dep_time === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        depTimeError: "error",
      });
      document.getElementById("depTime").focus();
      // this.depTimeInput.focus();
    }

    if ((this.state.form.arr === "" || this.state.form.arr === undefined) && this.state.manifestType === "4") {
      isError = true;
      this.setState({
        arrError: "error",
      });
      document.getElementById("arr").focus();
      // this.arrInput.focus();
    }

    if (
      (this.state.form.vehicle_number === "" || this.state.form.vehicle_number === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        vehNumError: "error",
      });
      document.getElementById("uniqVehNumber").focus();
      // this.vehNumInput.focus();
    }

    if (
      (this.state.form.inter_eta === "" || this.state.form.inter_eta === undefined) &&
      (this.state.manifestType === "3")
    ) {
      isError = true;
      this.setState({
        interEtaError: "error",
      });
      document.getElementById("inter_eta").focus();
      // this.interEtaInput.focus();
    }

    if ((this.state.form.dep_eta === "" || this.state.form.dep_eta === undefined) && (this.state.manifestType === "1" || this.state.manifestType === manifestType?.TYPE_2) ) {
      isError = true;
      this.setState({
        depEtaError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("desETA1").focus();
      }

      if (this.state.manifestType === "7") {
        document.getElementById("desETA1").focus();
      }

      if (this.state.manifestType === manifestType?.TYPE_2) {
        this.depEtaInput.focus();
      }
    }

    if (
      (this.state.form.dep_etd === "" || this.state.form.dep_etd === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "7" ||
        this.state.manifestType === manifestType?.TYPE_2)
    ) {
      isError = true;
      this.setState({
        depEtdError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("desETD1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("desETD1").focus();
      }
      if (this.state.manifestType === "3") {
        document.getElementById("desETD3").focus();
      }

      if (this.state.manifestType === manifestType?.TYPE_2) {
        this.depEtdInput.focus();
      }
    }

    if (this.state.form.dep_place === "" || this.state.form.dep_place === undefined) {
      isError = true;
      this.setState({
        depPlaceError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("depPlace1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("depPlace1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("depPlace3").focus();
      }
      if (this.state.manifestType === "4") {
        document.getElementById("depPlace4").focus();
      }
      // this.depPlaceInput.focus();
    }

    if (this.state.form.inter_space === "" || this.state.form.inter_space === undefined) {
      isError = true;
      this.setState({
        interSpaceError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("interSpace1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("interSpace1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("interSpace3").focus();
      }
      if (this.state.manifestType === "4") {
        document.getElementById("interSpace4").focus();
      }
      // this.interSpaceInput.focus();
    }

    if (
      (this.state.form.des_etd === "" || this.state.form.des_etd === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "7" ||
        this.state.manifestType === "8")
    ) {
      isError = true;
      this.setState({
        desEtdError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("desETD1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("desETD1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("desETD3").focus();
      }
      // this.desEtdInput.focus();
    }

    if (
      (this.state.form.des_eta === "" || this.state.form.des_eta === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "7" ||
        this.state.manifestType === "8")
    ) {
      isError = true;
      this.setState({
        desEtaError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("desETA1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("desETA1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("desETA3").focus();
      }
      // this.desEtaInput.focus();
    }

    if (
      (this.state.form.des_place === "" || this.state.form.des_place === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "1" ||
        this.state.manifestType === "8")
    ) {
      isError = true;
      this.setState({
        desPlaceError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("desPlace1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("desPlace1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("desPlace3").focus();
      }
      // this.desPlaceInput.focus();
    }

    if (this.state.form.report_to === "" || this.state.form.report_to === undefined) {
      isError = true;
      this.setState({
        reportToError: "error",
      });

      if (this.state.manifestType === "1") {
        document.getElementById("reportTo1").focus();
      }

      if (this.state.manifestType === "7") {
        document.getElementById("reportTo1").focus();
      }

      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("reportTo3").focus();
      }

      if (this.state.manifestType === "4") {
        document.getElementById("reportTo4").focus();
      }
      // this.reportToInput.focus();
    }

    if (
      (this.state.form.dispatch_time === "" || this.state.form.dispatch_time === undefined) &&
      this.state.manifestType === "4"
    ) {
      isError = true;
      this.setState({
        dispatchTimeError: "error",
      });
      document.getElementById("dispatchTime").focus();
      // this.dispatchInput.focus();
    }

    if (
      (this.state.form.dispatch_location === "" || this.state.form.dispatch_location === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "7")
    ) {
      isError = true;
      this.setState({
        dispatchError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("dispatchLocation1").focus();
      }

      if (this.state.manifestType === "7") {
        document.getElementById("dispatchLocation1").focus();
      }

      if (this.state.manifestType === "3") {
        document.getElementById("dispatchLocation3").focus();
      }
      // this.dispatchInput.focus();
    }

    if (
      (this.state.form.ifca_number === "" || this.state.form.ifca_number === undefined) &&
      (this.state.manifestType === "1" || this.state.manifestType === "4" || this.state.manifestType === "7" || this.state.manifestType === "8")
    ) {
      isError = true;
      this.setState({
        ifcaError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("ifcaNumber1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("ifcaNumber1").focus();
      }
      // if(this.state.manifestType === "3"){
      //     document.getElementById('ifcaNumber3').focus();
      // }
      if (this.state.manifestType === "4") {
        document.getElementById("ifcaNumber4").focus();
      }
      if (this.state.manifestType === "8") {
        document.getElementById("ifcaNumber8").focus();
      }
      // this.ifcaInput.focus();
    }

    if (this.state.form.resource_number === "" || this.state.form.resource_number === undefined) {
      isError = true;

      this.setState({
        resourceError: "error",
      });

      if (this.state.manifestType === "1") {
        document.getElementById("resourceNumber1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("resourceNumber1").focus();
      }

      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("resourceNumber3").focus();
      }

      if (this.state.manifestType === "4") {
        document.getElementById("resourceNumber4").focus();
      }
      // this.resourceInput.focus();
    }

    if (
      (this.state.form.incident_number === "" || this.state.form.incident_number === undefined) &&
      (this.state.manifestType === "1" ||
        this.state.manifestType === "3" ||
        this.state.manifestType === "7" ||
        this.state.manifestType === "8")
    ) {
      isError = true;
      this.setState({
        IncidentNumError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("incidentNumber1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("incidentNumber1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("incidentNumber3").focus();
      }
      if (this.state.manifestType === "4") {
        document.getElementById("incidentNumber4").focus();
      }
      // this.IncidentNumInput.focus();
    }

    if (this.state.form.incident_name === "" || this.state.form.incident_name === undefined) {
      isError = true;
      this.setState({
        incidentNameError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("incidentName1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("incidentName1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("incidentName3").focus();
      }
      if (this.state.manifestType === "4") {
        document.getElementById("incidentName4").focus();
      }
      // this.incidentNameInput.focus();
    }

    if (this.state.form.ordering_unit === "" || this.state.form.ordering_unit === undefined) {
      isError = true;
      this.setState({
        unitError: "error",
      });
      if (this.state.manifestType === "1") {
        document.getElementById("orderingUnit1").focus();
      }
      if (this.state.manifestType === "7") {
        document.getElementById("orderingUnit1").focus();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        document.getElementById("orderingUnit3").focus();
      }
      if (this.state.manifestType === "4") {
        document.getElementById("orderingUnit4").focus();
      }
      // this.unitInput.focus();
    }

    return isError;
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
    // this.props.details(this.props.resourceDetails)
  }

  sendMail(email) {
    this.setState({ to: email, emailSuccess: "true" }, function () {
      this.emailManifest();
      this.statusCloseModal();
    });
  }

  removeList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.mailList);
      mailList.splice(index, 1);
      this.setState({ mailList: mailList, textarea: true, to: "" });
      document.getElementById("txtSearchManiEmployees").value = "";
      // document.getElementById('multiple_emails').value = "";
    }
  }
  removeDispatchList(index) {
    if (index >= 0) {
      let mailList = Object.assign([], this.state.dispatchMailList);
      mailList.splice(index, 1);
      this.setState({ dispatchMailList: mailList, textarea: true, to: "" });
      document.getElementById("txtSearchManiEmployees").value = "";
      // document.getElementById('multiple_emails').value = "";
    }
  }

  setMailer(item) {
    this.setState({
      textarea: false,
      mailList: [...this.state.mailList, item],
      searchList: [],
      hideSearch: "hide",
    });
  }

  emailChangeFn(event) {
    var value = event.target.value;
    this.setState(
      {
        mailSearchKeyword: value,
        validManifestMail: false,
      },
      () => {
        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(
          function () {
            this.handleSearch(value, true);
          }.bind(this),
          1000
        );
      }
    );
  }

  handleSearch(text) {
    let data = new FormData();
    data.append("keyword", text);

    let nextState = {
      search: true,
    };

    if (this.isValidEmail(text)) {
      nextState.validManifestMail = true;
      nextState.manifestEmail = text;
    } else {
      nextState.validManifestMail = false;
      nextState.manifestEmail = "";
    }

    this.props.dispatch(resourceActions.employeeList(data));
    this.setState(nextState);
  }

  showError(index) {
    var ele = document.querySelector(".lnnsfa_date_date[data-index='" + index + "']");
    ele && ele.setAttribute("errors", "error");
    this.setState({saveBtn:false})
  }

  clearError(index) {
    var ele = document.querySelector(".lnnsfa_date_date[data-index='" + index + "']");
    ele && ele.removeAttribute("errors");
  }

  handleSubmit(e) {
    e?.preventDefault?.();

    const isSameResourceFile = (this?.state?.form?.resource_order_form?.resource_order_form_type === this?.state?.rof_type) && (this?.state?.form?.resource_order_form?.resource_order_form_id === this?.state?.rof_id)
    const newResourceOrderFormAdded = this?.state?.rof_id && !isSameResourceFile
    const resourceOrderFormIsNotAvailable = !this?.state?.rof_id
    if (!this?.state?.isSaveConfirmModal && newResourceOrderFormAdded || !this?.state?.isSaveConfirmModal && resourceOrderFormIsNotAvailable) {
      return this.setState({ isSaveConfirmModal: true })
    }

    this.setState({ isSaveConfirmModal: false })
    if (this.state.manifestType === "4") {
      var start = this.state.eff_start_date,
        end = this.state.eff_end_date;

      if (!start || !end || !this.isValidDate(start, end)) {
        this.setState({
          mdateError: true,
        });
        return;
      } else {
        this.setState({
          mdateError: false,
        });
      }
    }

    const err = this.validate();

    if (!err) {
      if (e.target.name === "save") {
        this.setState({ saveBtn: true });
      }

      if (!this.state.submit || e.target.name === "save") {
        let data = new FormData();

        if (this.state.vehicles.length > 0) {
          this.state.vehicles.map((item, index) => {
            if (item.category === "selected") {
              data.append("vehicle_no[" + index + "]", item.id);
            }

            return data;
          });
        }
        // For IFCA and NCC Manifest only
        if (
          this.state.manifestType == 1 ||
          this.state.manifestType == 3 ||
          this.state.manifestType == 7 ||
          this.state.manifestType == 8
        ) {
          data.append("contract_number_id", this.props.contract_number_id);
        }

        if (this.state.crews.length > 0) {
          var count = 0,
            obj = Object.assign(this.state.lastNight, {}),
            _err = false,
            _error_target;

          this.state.crews.map((item, index) => {
            if (_err) return;

            if (item.category === "oncrew") {
              data.append("user_hash[" + index + "]", item.hash);

              if (this.state.shighq[item.hash] !== undefined) {
                data.append("highq[" + count + "]", this.state.shighq[item.hash]);
              } else if (item.incident_pos) {
                data.append("highq[" + count + "]", item.incident_pos);
              } else if (this.state.manifestType == 5) {
                data.append("highq[" + count + "]", "Giss");
              } else if (this.state.manifestType == 6) {
                data.append("highq[" + count + "]", "Crew Member");
              }

              var val = "";
              if (obj.hasOwnProperty(count.toString())) {
                val = obj[count.toString()];
              }

              var _date = new Date(val).getTime();

              if (val) {
                if (isNaN(_date)) {
                  _err = true;
                  _error_target = index;
                  return;
                } else {
                  this.clearError(index);
                }
              }

              var key = "lnnsfa_date";
              data.append(key + "[" + count + "]", val);
              count++;
            }

            return data;
          });

          if (_err) {
            this.showError(_error_target);
            return;
          }
        }

        data.append("resource_id", this.state.resource_id);
        data.append("manifest_id", this.state.manifestType);
        data.append("ordering_unit", this.state.form.ordering_unit);
        data.append("incident_name", this.state.form.incident_name);
        data.append("incident_number", this.state.form.incident_number);
        data.append("resource_number", this.state.form.resource_number);
        data.append("ifca_number", this.state.form.ifca_number);
        data.append("dispatch_location", this.state.form.dispatch_location);
        data.append("report_to", this.state.form.report_to);
        data.append("des_place", this.state.form.des_place);
        data.append("des_eta", this.state.form.des_eta);
        data.append("des_etd", this.state.form.des_etd);
        data.append("inter_space", this.state.form.inter_space);
        data.append("dep_place", this.state.form.dep_place);
        data.append("dep_etd", this.state.form.dep_etd);
        data.append("project_name", this.state.form.project_name);
        data.append("project_code", this.state.form.project_code);
        data.append("job_loc", this.state.form.job_loc);
        data.append("point_of_hire", this.state.form.point_of_hire);
        data.append("rof_type", this.state.rof_type);
        data.append("rof_id", this.state.rof_id);
        if (this.state.form.dep_eta) {
          data.append("dep_eta", this.state.form.dep_eta);
        }

        if (this.state.form.sign_auth_representative && this.state.form.sign_auth_representative.length > 0) {
          data.append("sign_auth_representative", this.state.form.sign_auth_representative);
        }

        data.append("inter_eta", this.state.form.inter_eta);
        data.append("vehicle_number", this.state.form.vehicle_number);
        data.append("dispatch_time", this.state.form.dispatch_time);
        data.append("perssonal", this.state.form.perssonal);
        data.append("arr", this.state.form.arr);
        data.append("departure", this.state.form.departure);
        data.append("resource", this.state.form.resource);
        data.append("dep_time", this.state.form.dep_time);
        data.append("dep_arr", this.state.form.dep_arr);
        data.append("pdep_time", this.state.form.pdep_time);
        data.append("pedep_place", this.state.form.pedep_place);

        /* Manifest update 23-11-2018 (Siby)*/

        /*Append Effective date from and to */

        data.append("eff_start_date", this.convertDateFormat(start)); /*Start date */

        data.append("eff_end_date", this.convertDateFormat(end)); /*End date*/

        /*Append estimated duration */

        data.append("est_start", this.convertDateFormat(this.state.form.est_start)); /*Start date */

        data.append("est_finish", this.convertDateFormat(this.state.form.est_finish)); /*End date*/

        /*Last Night Not Spent On Fire Assignment (Date)*/
        // var key = "lnnsfa_date",
        //   obj = Object.assign(this.state.lastNight, {}),
        //   hash = null;
        // for (hash in obj) {
        //   data.append(
        //     key + "[" + hash + "]",
        //     obj[hash]
        //   ); /*Append all dates */
        // }

        /*Manifest update 23-11-2018*/

        if (!this.props.edit) {
          if (this.state.saved_id === "") {
            this.props.dispatch(manifestActions.saveManifest(data));
          } else {
            data.append("old_manifest_id", this.state.saved_id);
            this.props.dispatch(manifestActions.updateManifest(data));
          }
        } else {
          // if(this.state.saved_id !== ""){
          //     data.append('old_manifest_id',this.state.saved_id);
          //     this.props.dispatch(manifestActions.updateManifest(data));
          // } else{

          if (this.state.saved_id === "") {
            data.append("old_manifest_id", this.props.manifest_id);
          } else {
            data.append("old_manifest_id", this.state.saved_id);
          }

          this.props.dispatch(manifestActions.updateManifest(data));

          // }
        }
      }
    }
  }


  handleModalConfirmClick (e) {
    e.preventDefault()
    this.handleSubmit(e);
  }

  isValidDate(start, end) {
    return moment(end).isAfter(start) || moment(end).isSame(start);
  }

  convertDateFormat(date) {
    if (!date || typeof date === "undefined") return "";
    try {
      var _date = moment(date),
        _date = _date.format("MM/DD/YY");
      return _date;
    } catch (er) {
      return "";
    }
  }

 
 
 
 
 
 
 
 
 
 
 
 
  submitClick(e) {
    this.setState({ [e.target.name]: true });
    if (this.state.submit) {
      if (e.target.name === "print" && this.state.print) {
        this.printManifest();
      } else if (e.target.name === "email") {
        this.statusOpenModal();
      }
    }
  }

  onChange(e) {
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value },
    });

    e.target.setAttribute("errors", "");
  }

  setNCCDate(hash, event) {
    var obj = Object.assign(this.state.lastNight, {});
    obj[hash] = event.target.value;

    this.setState(obj, () => {
      setTimeout(() => {
        this.forceUpdate();
      });
    });

    return true;
  }

  onSelect(hash, e) {
    var value = e.target.value;
    this.state.shighq[hash] = value;

    this.setState({ shighq: this.state.shighq }, function () {
      if (this.state.manifestType === "1") {
        this.generateIFCA();
      }
      if (this.state.manifestType === "3" || this.state.manifestType === "8") {
        this.generateNCC();
      }
      if (this.state.manifestType === "4") {
        this.generateVIPR();
      }
      if (this.state.manifestType === "5") {
        this.generateDCRM();
      }
      if (this.state.manifestType === "6") {
        this.generatePM();
      }
      if (this.state.manifestType === "7") {
        this.generateODF();
      }
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.props.dispatch(manifestActions.dispatchMailList());

    var data = "resource_id=" + this.state.resource_id;
    if (!this.props.edit) {
      this.props.dispatch(manifestActions.getSuperintendent(data));

      if (this.state.manifestType === "1") {
        this.generateIFCA();
      }
      if (this.state.manifestType === "3") {
        this.generateNCC();
      }
      if (this.state.manifestType === "4") {
        this.generateVIPR();
      }
      if (this.state.manifestType === "5") {
        this.generateDCRM();
      }
      if (this.state.manifestType === "6") {
        this.generatePM();
      }
      if (this.state.manifestType === "7") {
        this.generateODF();
      }
      if (this.state.manifestType === "8") {
        this.generateNCC();
        if (this.props.dispatch_location)
          this.setState({
            form: {
              ...this.state.form,
              dispatch_location: this.props.dispatch_location,
            },
          });
      }
    } else {
      this.props.dispatch(manifestActions.getSuperintendent(data));

      let data2 = new FormData();
      data2.append("manifest_id", this.props.manifest_id);
      this.props.dispatch(manifestActions.manifestDetails(data2));
    }
  }

  generateIFCA() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>
                  {count}. {item.first_name + " " + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="td print_center" data-header-title="Crew Member Number">
                <p>{item.ica_number}</p>
              </div>
              <div className="td print_center" data-header-title="Incident Position">
                <select
                  name="highq"
                  className="pos_list_drag"
                  value={this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos}
                  onChange={this.onSelect.bind(this, item.hash)}
                >
                   <option value="--">--</option>
                    <option value="CRWB/ICT4">CRWB/ICT4</option>
                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                    <option value="CRWB">CRWB</option>
                    <option value="CRWB(T)">CRWB(T)</option>
                    <option value="ENGB/ICT4">ENGB/ICT4</option>
                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                    <option value="ENGB">ENGB</option>
                    <option value="ENGB(T)">ENGB(T)</option>
                    <option value="FFTI/ICT5">FFTI/ICT5</option>
                    <option value="FFTI/ICT5(T)">FFTI/ICT5(T)</option>
                    <option value="FFTI">FFTI</option>
                    <option value="FFTI(T)">FFTI(T)</option>
                    <option value="ICT5(T)">ICT5(T)</option>
                    <option value="FFTII">FFTII</option>
                    <option value="RXB3">RXB3</option>
                    <option value="RXB3(T)">RXB3(T)</option>
                    <option value="GISS">GISS</option>
                    <option value="GISS(T)">GISS(T)</option>
                    <option value="ENG(T)">ENG(T)</option>
                </select>
              </div>
              <div className="td print_center Sawyer" data-header-title="Sawyer">
                <p>{item.sawyer ? "X" : ""}</p>
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p>{item.certified_emt === "Yes" ? "X" : ""}</p>
              </div>
              <div className="td print_center bry" data-header-title="Experience and Lsa (Blue-Red-Yellow)">
                <p>{item.experience} </p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={"ifca" + i}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div className="td print_center" data-header-title="Crew Member Number">
                <p />
              </div>
              <div className="td print_center" data-header-title="Incident Position">
                <p />
                {/* <select className="pos_list_drag disabled">
                        <option value="--">--</option>
                        <option value="CRWB/ICT5">CRWB/ICT5</option>
                        <option value="ENGB/ICT5">ENGB/ICT5</option>
                        <option value="FFTI/ICT5">FFTI/ICT5</option>
                        <option value="FFTI">FFTI</option>
                        <option value="FFTII">FFTII</option>
                        <option value="CRWB">CRWB</option>
                        <option value="ENGB">ENGB</option>
                        <option value="CRWB(T)">CRWB(T)</option>
                        <option value="FFTI(T)">FFTI(T)</option>
                    </select> */}
              </div>
              <div className="td print_center Sawyer" data-header-title="Sawyer">
                <p />
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p />
              </div>
              <div className="td print_center bry" data-header-title="Experience and Lsa (Blue-Red-Yellow)">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === "selected") {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + " " + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{formatDate(item.mspa_exp_date)}</p>
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p>{item.vehicle_model_or_make}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license === unDefined ? "" : item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });
      if (count < 4) {
        // var tot = count;
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={"veh" + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  generateODF() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>
                  {count}. {item.first_name + " " + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="td print_center" data-header-title="Crew Member Number">
                <p>{item.ica_number}</p>
              </div>
              <div className="td print_center" data-header-title="Incident Position">
                <select
                  name="highq"
                  className="pos_list_drag"
                  value={this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos}
                  onChange={this.onSelect.bind(this, item.hash)}
                >
                   <option value="--">--</option>
                    <option value="CRWB/ICT4">CRWB/ICT4</option>
                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                    <option value="CRWB">CRWB</option>
                    <option value="CRWB(T)">CRWB(T)</option>
                    <option value="ENGB/ICT4">ENGB/ICT4</option>
                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                    <option value="ENGB">ENGB</option>
                    <option value="ENGB(T)">ENGB(T)</option>
                    <option value="FFTI/ICT5">FFTI/ICT5</option>
                    <option value="FFTI/ICT5(T)">FFTI/ICT5(T)</option>
                    <option value="FFTI">FFTI</option>
                    <option value="FFTI(T)">FFTI(T)</option>
                    <option value="ICT5(T)">ICT5(T)</option>
                    <option value="FFTII">FFTII</option>
                    <option value="RXB3">RXB3</option>
                    <option value="RXB3(T)">RXB3(T)</option>
                    <option value="GISS">GISS</option>
                    <option value="GISS(T)">GISS(T)</option>
                    <option value="ENG(T)">ENG(T)</option>
                </select>
              </div>
              <div className="td print_center Sawyer" data-header-title="Sawyer">
                <p>{item.sawyer ? "X" : ""}</p>
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p>{item.certified_emt === "Yes" ? "X" : ""}</p>
              </div>
              <div className="td print_center bry" data-header-title="Experience B-R-Y Blue-Red-Yellow">
                <p>{item.experience} </p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={"ifca" + i}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div className="td print_center" data-header-title="Crew Member Number">
                <p />
              </div>
              <div className="td print_center" data-header-title="Incident Position">
                <p />
              </div>
              <div className="td print_center Sawyer" data-header-title="Sawyer">
                <p />
              </div>
              <div className="td print_center emt" data-header-title="EMT">
                <p />
              </div>
              <div className="td print_center bry" data-header-title="Experience B-R-Y Blue-Red-Yellow">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === "selected") {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + " " + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{formatDate(item.mspa_exp_date)}</p>
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p>{item.vehicle_model_or_make}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license === unDefined ? "" : item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });
      if (count < 4) {
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={"veh" + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle Make/Model">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  generateNCC() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div data-header-title="Employee Name (Last, First)" className="print_hash_value td ncc_emp_name">
                <p className="num">{count}</p>
                <p className="ename">{item.first_name + " " + item.last_name}</p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="print_center td" data-header-title="Identification Number">
                <p>{item.fire_id}</p>
              </div>
              <div className="print_center td" data-header-title="Incident Position">
                <select
                  className="pos_list_drag "
                  value={this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos}
                  onChange={this.onSelect.bind(this, item.hash)}
                >
                  <option value="--">--</option>
                  <option value="CRWB/ICT4">CRWB/ICT4</option>
                  <option value="CRWB/ICT5">CRWB/ICT5</option>
                  <option value="CRWB">CRWB</option>
                  <option value="CRWB(T)">CRWB(T)</option>
                  <option value="ENGB/ICT4">ENGB/ICT4</option>
                  <option value="ENGB/ICT5">ENGB/ICT5</option>
                  <option value="ENGB">ENGB</option>
                  <option value="ENGB(T)">ENGB(T)</option>
                  <option value="FFTI/ICT5">FFTI/ICT5</option>
                  <option value="FFTI/ICT5(T)">FFTI/ICT5(T)</option>
                  <option value="FFTI">FFTI</option>
                  <option value="FFTI(T)">FFTI(T)</option>
                  <option value="ICT5(T)">ICT5(T)</option>
                  <option value="FFTII">FFTII</option>
                  <option value="RXB3">RXB3</option>
                  <option value="RXB3(T)">RXB3(T)</option>
                  <option value="GISS">GISS</option>
                  <option value="GISS(T)">GISS(T)</option>
                  <option value="ENG(T)">ENG(T)</option>
                </select>
              </div>
              <div className="print_center td sawyer" data-header-title={this.state.manifestType == manifestType?.TYPE_2 ? "Sawyer" : "Sawyer Class"}>
                <p>{item.sawyer ? "X" : ""}</p>
              </div>
              {this.state.manifestType === manifestType?.TYPE_2 && (
                <>
                 <div className="td print_center emt" data-header-title="EMT">
                 <p>{item.certified_emt === "Yes" ? "X" : ""}</p>
                 </div>
               </>
              )}
              <div className="print_center td" data-header-title={this.state.manifestType == manifestType?.TYPE_2 ? "Experience and LSA (B-R-Y=Blue-Red-Yellow)" : "Experience Blue-Red-Yellow (B_R_Y)"}>
                <p>{item.experience.charAt(0)}</p>
              </div>
            </div>
          );
        }
        return options;
      });
      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="tr" key={"manifest" + i}>
              <div data-header-title="Employee Name (Last, First)" className="print_hash_value td ncc_emp_name">
                <p className="num">{count}</p>
                <p className="ename" />
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p />
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p />
              </div>
              <div className="print_center td" data-header-title="Identification Number">
                <p />
              </div>
              <div className="print_center td" data-header-title="Incident Position">
                <p />
              </div>
              <div className="print_center td sawyer" data-header-title={this.state.manifestType == manifestType?.TYPE_2 ? "Sawyer" : "Sawyer Class"}>
                <p />
              </div>
              {this.state.manifestType === manifestType?.TYPE_2 && (
                <div className="td print_center emt" data-header-title="EMT">
                  <p />
                </div>
              )}
              <div className="print_center td" data-header-title={this.state.manifestType == manifestType?.TYPE_2 ? "Experience and LSA (B-R-Y=Blue-Red-Yellow)" : "Experience Blue-Red-Yellow (B_R_Y)"}>
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === "selected") {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + " " + item.last_name}</p>
              </div>
              {this.state.manifestType === manifestType?.TYPE_2 && (
                <div className="td mdate" data-header-title="MSPA Exp. Date">
                  <p>{formatDate(item.mspa_exp_date)}</p>
                </div>
              )}
              <div className="td" data-header-title="Vehicle Make/Model">
                <p>{item.vehicle_model_or_make}</p>
              </div>
              <div className="td st" data-header-title="Year">
                <p>{item.year}</p>
              </div>
              <div className="td exp_date" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license === unDefined ? "" : item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });

      if (count < 4) {
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={"vehicle" + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              {this.state.manifestType === manifestType?.TYPE_2 && (
                 <div className="td mdate" data-header-title="MSPA Exp. Date">
                 <p />
               </div>
              )}
              <div className="td" data-header-title="Vehicle Make/Model">
                <p />
              </div>
              <div className="td st" data-header-title="Year">
                <p />
              </div>
              <div className="td exp_date" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  updateDate() {
    var obj = Object.assign(this.state.lastNight, {}),
      key = null;

    for (key in obj) {
      var ele = document.querySelector(".lnnsfa_date_date[data-index='" + key + "']");
      ele && (ele.value = obj[key]);
    }
  }

  getValue(index) {
    var obj = Object.assign(this.state.lastNight, {});
    return obj.hasOwnProperty(index.toString()) ? obj[index] : "";
  }

  generateVIPR() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="tr middle_table" key={index}>
              <div className="print_hash_value td" data-header-title="Employee Name">
                <p>
                  {count}. {item.first_name + " " + item.last_name}
                </p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="print_center td" data-header-title="Position">
                <p>
                  <select
                    className="pos_list_drag disabled"
                    value={
                      this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos
                    }
                    onChange={this.onSelect.bind(this, item.hash)}
                  >
                     <option value="--">--</option>
                    <option value="CRWB/ICT4">CRWB/ICT4</option>
                    <option value="CRWB/ICT5">CRWB/ICT5</option>
                    <option value="CRWB">CRWB</option>
                    <option value="CRWB(T)">CRWB(T)</option>
                    <option value="ENGB/ICT4">ENGB/ICT4</option>
                    <option value="ENGB/ICT5">ENGB/ICT5</option>
                    <option value="ENGB">ENGB</option>
                    <option value="ENGB(T)">ENGB(T)</option>
                    <option value="FFTI/ICT5">FFTI/ICT5</option>
                    <option value="FFTI/ICT5(T)">FFTI/ICT5(T)</option>
                    <option value="FFTI">FFTI</option>
                    <option value="FFTI(T)">FFTI(T)</option>
                    <option value="ICT5(T)">ICT5(T)</option>
                    <option value="FFTII">FFTII</option>
                    <option value="RXB3">RXB3</option>
                    <option value="RXB3(T)">RXB3(T)</option>
                    <option value="GISS">GISS</option>
                    <option value="GISS(T)">GISS(T)</option>
                    <option value="ENG(T)">ENG(T)</option>
                  </select>
                </p>
              </div>
              <div className="print_center td" data-header-title="LAST NIGHT NOT SPENT ON FIRE ASSIGNMENT (DATE)">
                <div className="lastNightWrapper">
                  <input
                    className={"lnnsfa_date_date " + this.state.cclass}
                    type="text"
                    placeholder="MM/DD/YYYY"
                    data-index={index}
                    onChange={this.setNCCDate.bind(this, index)}
                  />
                </div>
              </div>
              <div className="print_center td" data-header-title="UNIQUE EMPLOYEE NUMBER (NO SSN)">
                <p>{item.fire_id}</p>
              </div>
            </div>
          );
        }
        setTimeout(() => {
          this.updateDate();
        }, 200);
        return options;
      });

      if (count < 6) {
        for (var i = count; i < 6; i++) {
          count++;
          options.push(
            <div className="tr" key={"manifest" + i}>
              <div className="print_hash_value td" data-header-title="Employee Name">
                <p>{count}. </p>
              </div>
              <div className="print_center gender td" data-header-title="M">
                <p />
              </div>
              <div className="print_center gender td" data-header-title="F">
                <p />
              </div>
              <div className="print_center td" data-header-title="Position">
                <p>
                  {/* <select className="pos_list_drag disabled">
                          <option value="--">--</option>
                          <option value="CRWB/ICT5">CRWB/ICT5</option>
                          <option value="ENGB/ICT5">ENGB/ICT5</option>
                          <option value="FFTI/ICT5">FFTI/ICT5</option>
                          <option value="FFTI">FFTI</option>
                          <option value="FFTII">FFTII</option>
                          <option value="CRWB">CRWB</option>
                          <option value="ENGB">ENGB</option>
                          <option value="CRWB(T)">CRWB(T)</option>
                          <option value="FFTI(T)">FFTI(T)</option>
                      </select> */}
                </p>
              </div>
              <div className="print_center td" data-header-title="LAST NIGHT NOT SPENT ON FIRE ASSIGNMENT (DATE)" />
              <div className="print_center td" data-header-title="UNIQUE EMPLOYEE NUMBER (NO SSN)">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }
  }

  generateDCRM() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>
                  {count}. {item.first_name + " " + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_crew_mem_no" data-header-title="Employee Id Number">
                <p>{item.fire_id}</p>
              </div>
              <div className="td print_center" data-header-title="Project Position">
                <select
                  name="highq"
                  className="pos_list_drag"
                  value={this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos}
                  onChange={this.onSelect.bind(this, item.hash)}
                >
                  <option value="Giss">Giss</option>
                  <option value="Gisst">Gisst</option>
                  <option value="Project Superindent">Project Superindent</option>
                  <option value="Crew Member">Crew Member</option>
                </select>
              </div>
              <div className="td em_phone print_hash_value" data-header-title="Phone Number">
                <p>{this.formatPhoneNumber(item.phone)}</p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={"ifca" + i}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div className="td print_center em_crew_mem_no" data-header-title="Employee Id Number">
                <p />
              </div>
              <div className="td print_center" data-header-title="Project Position">
                <p />
                {/* <select className="pos_list_drag disabled">
                      <option value="Giss">Giss</option>
                      <option value="Gisst">Gisst</option>
                      <option value="Project Superindent">Project Superindent</option>
                      <option value="Crew Member">Crew Member</option>
                    </select> */}
              </div>
              <div className="td em_phone print_hash_value" data-header-title="Phone Number">
                <p></p>
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    var optionsVeh = [];
    count = 0;
    if (this.state.vehicles.length > 0) {
      this.state.vehicles.map((item, index) => {
        if (item.category === "selected") {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + " " + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div className="td DLIssued" data-header-title="Drivers License Issued State">
                <p>{item.DLState}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{formatDate(item.mspa_exp_date)}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license === unDefined ? "" : item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });
    }
    if (count < 4) {
      // var tot = count;
      for (i = count; i < 4; i++) {
        count++;
        optionsVeh.push(
          <div className="tr" key={"veh" + i}>
            <div className="td" data-header-title="Driver Name">
              <p />
            </div>
            <div className="td" data-header-title="License Number/State">
              <p />
            </div>
            <div className="td DLIssued" data-header-title="Drivers License Issued State">
              <p />
            </div>
            <div className="td mdate" data-header-title="MSPA Exp. Date">
              <p />
            </div>
            <div className="td" data-header-title="Vehicle License No.">
              <p />
            </div>
          </div>
        );
      }
    }
    this.setState({ vehicleOptions: optionsVeh });
  }

  generatePM() {
    if (this.state.crews.length > 0) {
      var options = [],
        count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          count++;
          options.push(
            <div className="middle_table tr" key={index}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>
                  {count}. {item.first_name + " " + item.last_name}
                </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p>{item.gender === "Male" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p>{item.gender === "Female" ? "X" : ""}</p>
              </div>
              <div className="td print_center em_crew_mem_no" data-header-title="Employee Id Number">
                <p>{item.fire_id}</p>
              </div>
              <div className="td print_center" data-header-title="Project Position">
                <select
                  name="highq"
                  className="pos_list_drag"
                  value={this.state.shighq[item.hash] !== undefined ? this.state.shighq[item.hash] : item.incident_pos}
                  onChange={this.onSelect.bind(this, item.hash)}
                >
                  <option value="Crew Member">Crew Member</option>
                  <option value="Project Superindent">Project Superindent</option>
                  <option value="Sawyer">Sawyer</option>
                  <option value="Groundsmen">Groundsmen</option>
                  <option value="Saftey Officer">Saftey Officer</option>
                </select>
              </div>
              <div className="td em_phone print_hash_value" data-header-title="Phone Number">
                <p>{this.formatPhoneNumber(item.phone)}</p>
              </div>
            </div>
          );
        }
        return options;
      });

      if (count < 20) {
        for (var i = count; i < 20; i++) {
          count++;
          options.push(
            <div className="middle_table tr" key={"ifca" + i}>
              <div className="td print_hash_value" data-header-title="Crew Member Name">
                <p>{count}. </p>
              </div>
              <div className="td print_center em_gender" data-header-title="M">
                <p />
              </div>
              <div className="td print_center em_gender" data-header-title="F">
                <p />
              </div>
              <div className="td print_center em_crew_mem_no" data-header-title="Employee Id Number">
                <p />
              </div>
              <div className="td print_center" data-header-title="Project Position">
                <p />
                {/* <select className="pos_list_drag disabled">
                      <option value="--">--</option>
                      <option value="CRWB/ICT5">CRWB/ICT5</option>
                      <option value="ENGB/ICT5">ENGB/ICT5</option>
                      <option value="FFTI/ICT5">FFTI/ICT5</option>
                      <option value="FFTI">FFTI</option>
                      <option value="FFTII">FFTII</option>
                      <option value="CRWB">CRWB</option>
                      <option value="ENGB">ENGB</option>
                      <option value="CRWB(T)">CRWB(T)</option>
                      <option value="FFTI(T)">FFTI(T)</option>
                    </select> */}
              </div>
              <div className="td em_phone print_hash_value" data-header-title="Phone Number">
                <p></p>
              </div>
            </div>
          );
        }
      }
      this.setState({ crewOptions: options });
    }

    if (this.state.vehicles.length > 0) {
      var optionsVeh = [];
      count = 0;
      this.state.vehicles.map((item, index) => {
        if (item.category === "selected") {
          count++;
          optionsVeh.push(
            <div className="tr" key={index}>
              <div className="td" data-header-title="Driver Name">
                <p>{item.first_name + " " + item.last_name}</p>
              </div>
              <div className="td" data-header-title="License Number/State">
                <p>{item.drivers_license}</p>
              </div>
              <div className="td DLIssued" data-header-title="Drivers License Issued State">
                <p>{item.DLState}</p>
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p>{formatDate(item.mspa_exp_date)}</p>
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p>{item.vehicle_license === unDefined ? "" : item.vehicle_license}</p>
              </div>
            </div>
          );
        }
        return optionsVeh;
      });

      if (count < 4) {
        // var tot = count;
        for (i = count; i < 4; i++) {
          count++;
          optionsVeh.push(
            <div className="tr" key={"veh" + i}>
              <div className="td" data-header-title="Driver Name">
                <p />
              </div>
              <div className="td" data-header-title="License Number/State">
                <p />
              </div>
              <div className="td DLIssued" data-header-title="Drivers License Issued State">
                <p />
              </div>
              <div className="td mdate" data-header-title="MSPA Exp. Date">
                <p />
              </div>
              <div className="td" data-header-title="Vehicle License No.">
                <p />
              </div>
            </div>
          );
        }
      }
      this.setState({ vehicleOptions: optionsVeh });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { superintendent, manifest, employee, email, dispatchMailList } = nextProps;
if (superintendent.list) {
      const { data } = superintendent.list;
      if (data.length > 0) {
        this.setState({ superintendent: data[0] }, function () { });
        // }
      }
    }

    if (email) {
      if (email.manifestEmail) {
        var head = "";
        if (email.manifestEmail.success) {
          head = "Success";
        } else {
          head = "Error";
        }
        if (this.state.emailSuccess === "true") {
          this.setState({
            alertPop: true,
            alertHead: head,
            alertMsg: JSON.parse(JSON.stringify(email.manifestEmail.message)),
            alertFunction: this.goBack,
            emailSuccess: "",
          });
        }
      }
    }

    if (manifest) {
      if (manifest.print && this.state.printClick) {
        // var pdf = manifest.print.data;
        // window.open(pdf, '_blank');
        // this.props.details(this.props.resourceDetails)
      }

      if (manifest.dispatchMailList) {
        this.setState({ dispatchMailList: manifest.dispatchMailList });
      }
    }

    if (manifest.saved) {
      if (manifest.saved.success) {
        this.setState({ submit: true, saved_id: manifest.saved.data.id }, function () {
          if (this.state.print) {
            this.printManifest();
            setTimeout(this.goBack,3000);
            return
          } else if (this.state.saveBtn) {
            this.setState({
              // alertPop: true,
              statusOpen:true,
              alertHead: "Success",
              alertMsg: manifest.saved.message,
              alertFunction: this.goBack.bind(this),
            });
          } else if (!this.state.saveBtn) {
            this.statusOpenModal();
          }
        });
      } else {
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: manifest.saved.message,
          alertFunction: "",
          saveBtn: false,
        });
      }
    }

    if (this.state.search && employee.list) {
      var empOptions = [];
      employee.list.data.data.map((item, index) => {
        empOptions.push(
          <li key={index} onClick={this.setMailer.bind(this, item)}>
            {item.first_name + " " + item.last_name}
          </li>
        );
        return empOptions;
      });

      if (empOptions.length > 0) {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: "show",
        });
      } else {
        this.setState({
          searchList: empOptions,
          search: false,
          hideSearch: "hide",
        });
      }
    }

    if (manifest.list) {
      if (manifest.list.success) {
        const form = manifest.list.data;
        var state = {};

        if (form.added_date) {
          state["added_date"] = form.added_date;
        }

        if (form.eff_end_date) {
          state["eff_end_date"] = moment(form.eff_end_date);
        }

        if (form.eff_start_date) {
          state["eff_start_date"] = moment(form.eff_start_date);
        }

        if (form.est_start) {
          form["est_start"] = moment(form.est_start);
        }

        if (form.est_finish) {
          form["est_finish"] = moment(form.est_finish);
        }

        if (form?.resource_order_form) {
          state.rof_type = form?.resource_order_form?.resource_order_form_type
          state.rof_id = form?.resource_order_form?.resource_order_form_id
        }

        if (Object.keys(state).length) {
          this.setState(state);
        }


        this.setState({ ...this.state, ...state, form: form });

        this.setState({ manifestType: form.base_manifest_id.toString() }, function () {
          if (form.base_manifest_id.toString() === "1") {
            this.generateIFCA();
          }
          if (form.base_manifest_id.toString() === "3" || form.base_manifest_id.toString() === "8") {
            this.generateNCC();
          }
          if (form.base_manifest_id.toString() === "4") {
            /*Set dates*/
            //this.generateVIPR();
            var obj = {};
            this.state.crews.map((item, index) => {
              obj[index.toString()] = item.lnnsfa_date ? moment(item.lnnsfa_date).format("MM/DD/YY") : "";
            });

            this.setState(
              {
                lastNight: obj,
              },
              () => {
                this.generateVIPR();
              }
            );
          }
          if (form.base_manifest_id.toString() === "5") {
            this.generateDCRM();
          }
          if (form.base_manifest_id.toString() === "6") {
            this.generatePM();
          }
          if (form.base_manifest_id.toString() === "7") {
            this.generateODF();
          }
        });
      }
    }
  }

  // printManifest() {
  //     let data = new FormData();
  //     data.append('manifest_id', this.state.saved_id);
  //     data.append('resource_id', this.props.resourceDetails.id);
  //     this.props.dispatch(manifestActions.printManifest(data));
  // }

  emailManifest() {
    let data = new FormData();
    if (this.state.crews.length > 0) {
      var count = 0;
      this.state.crews.map((item, index) => {
        if (item.category === "oncrew") {
          if (this.state.shighq[item.hash] !== undefined) {
            data.append("highq[" + count + "]", this.state.shighq[item.hash]);
          } else {
            data.append("highq[" + count + "]", item.highq);
          }
          count++;
        }
        return data;
      });
    }
    data.append("manifest_id", this.state.saved_id);
    data.append("resource_id", this.state.resource_id);
    //data.append("to", this.state.to);

    let toList = this.state.dispatchMailList;

    if (this.state.mailList.length > 0) {
      toList = [...toList, ...this.state.mailList.map((item) => item.email)];
    }

    data.append("to", toList.join());

    this.props.dispatch(manifestActions.emailManifest(data));
    this.setState({ statusOpen: false, emailSuccess: "true" });
    // this.props.details(this.props.resourceDetails)
  }


  
  handleDateChange(key, date) {
    //this.setState({ manifest_start: date });
  }
  handleManifestDate(key, date) {
    var obj = {};
    obj[key] = date;
    this.setState(obj, () => {
      this.setState({
        mdateError: !this.isValidDate(this.state.eff_start_date, this.state.eff_end_date)
          ? true
          : false /*Show validation error*/,
      });
    });
  }
  handleEstimateDates(key, date) {
    this.setState({ form: { ...this.state.form, [key]: date } });
    document.getElementsByName(key).forEach((ele) => ele.setAttribute("errors", ""));
  }

  onResourceOrderFormAdded(payload) {
    this.setState({ rof_id: payload?.rof_id, rof_type: payload?.rof_type, rofModal: false, isCreateResourceOrderForm: false, rofFileName: payload?.fileName, isEditResourceOrderForm: false })
  }

  printResourceOrderForm(){
    let data = new FormData();
    data.append("manifest_id", this?.state?.form?.resource_order_form?.manifest_id);
    manifestService?.viewCreatedResourceOrderForm(data).then((response) => this?.redirectToPdf(response));
  }
  redirectToPdf(data){
    if (data?.success) {
      const pdfLink = data.data;
      var link = document.createElement("a");
      if (
        window.navigator.userAgent.match(/iPad/i) ||
        window.navigator.userAgent.match(/iPhone/i)
      ) {
        link.href = pdfLink;
      } else {
        link.href = pdfLink;
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      setTimeout(function () {
        document.body.removeChild(link);
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(pdfLink);
      }, 100);
    }
  }
  
  handleOnViewResourceOrderForm() {
    switch (this?.state?.rof_type) {
      case RESOURCE_ORDER_FILE_TYPE?.UPLOADED_FORM:
        window.open(this?.state?.form?.resource_order_form?.file_link, '_blank').focus();
        break;
      case RESOURCE_ORDER_FILE_TYPE?.CREATE_FORM:
        this?.printResourceOrderForm()
        break;
    }
  }

  handleOnCreateResourceOrderForm() {
    this.setState({ isCreateResourceOrderForm: true })
  }
  handleDeleteRof() {
    this.setState({ isDeleteModalOpen: true })
  }

  handleEditRof = () => {
    let formData = new FormData();
    formData.append("rof_id", this.state.rof_id);
    const defaultValues = manifestService?.getResourceOrderContent(formData).then(({data}) => {
      if(data) {
        this.setState({
          currentRoData: data?.[0],
          isCreateResourceOrderForm: true,
          isEditResourceOrderForm: true,
        });
      }
    });

    return defaultValues;
  };

  confirmDeleteResourceOrderForm() {
    this.setState({isResourceOrderFormDeleting:true})
    const manifestId = this?.state?.form?.resource_order_form?.manifest_id
    const clearSubmitPayload = this?.state?.form?.resource_order_form?.resource_order_form_type === this?.state?.rof_type && this?.state?.form?.resource_order_form?.resource_order_form_id === this?.state?.rof_id
    if (clearSubmitPayload) {
      const form = { ...this?.state?.form}
      delete form.resource_order_form
      this.setState({ rof_id: "", rof_type: "", form })
    }
    let data = new FormData();
    data.append("manifest_id", manifestId);
    manifestService?.deleteResourceOrderForm(data).then(res => {
      this.setState({ isDeleteModalOpen: false, isResourceOrderFormDeleting:false })
    }).catch(err => {
      console.log('err: ', err);
      this.setState({ isDeleteModalOpen: false, isResourceOrderFormDeleting: false  })
    })
  }
  render() {
    let yourDate = new Date();
    if (this.state.added_date) {
      yourDate = new Date(this.state.added_date);
    }

    var curDate = formatDate(yourDate);

    const form = this.state.form;
    const { superintendent } = this.props;
    var fname = "",
      lname = "",
      sup_name,
      sphone;

    // if(superintendent.list) {
    //     const { data } = superintendent.list
    //     if(data.length > 0) {

    if (superintendent.list) {
      const { data } = superintendent.list;
      if (data.length > 0) {
        sphone = formatPhoneNumber(data[0].phone);
        if (data[0].first_name !== "" && data[0].first_name !== undefined) {
          fname = data[0].first_name;
        }
        if (data[0].last_name !== undefined && data[0].last_name !== "") {
          lname = data[0].last_name;
        }
        if (lname !== "") {
          sup_name = fname + " " + lname;
        } else {
          sup_name = "-";
        }
      }
      // if(this.state.superintendent.first_name !== undefined && this.state.superintendent.first_name !== ""){
      //     fname = this.state.superintendent.first_name
      // }
      // if(this.state.superintendent.last_name !== undefined && this.state.superintendent.last_name !== ""){
      //     lname = this.state.superintendent.last_name
      // }
      // if(fname !== ""){
      //     sup_name = fname+" "+lname;
      // } else{
      //     sup_name ="-";
      // }
    }  
    return (
      <div className="finalize_manifest">
        <div className="breadcrumbs">
          <Link to={"/resources"} className="green">
            Resources
          </Link>{" "}
          / <span onClick={this.goBack.bind(this)}>Details</span> / Finalize Manifest
        </div>
        {this?.state?.isCreateResourceOrderForm ? (
          <ResourceOrderForm  onSubmit={(data) => this.onResourceOrderFormAdded(data)} onCancel={() => this.setState({ isCreateResourceOrderForm: false, isEditResourceOrderForm: false})} editMode={this?.state?.isEditResourceOrderForm} rofData={this.state.currentRoData}/>
        ) : (
          <div className="content_div inner_container">
              {/* Resource Order Form Uploader */}
              <ReactModal style={customStyles} isOpen={this?.state?.rofModal} onClose={() => this.setState({ rofModal: false })}>
                <ResourceOrderFormUploader resourceId={this?.props?.resourceDetails} manifestId={this.props.manifest_id} onClose={() => this.setState({ rofModal: false })} onComplete={(data) => this.onResourceOrderFormAdded(data)} onCreate={() => this?.handleOnCreateResourceOrderForm()} fromManifestForm />
              </ReactModal>
              {/* Delete Resourse Order File Remove Alert */}
              <ReactModal isOpen={this?.state?.isDeleteModalOpen} onClose={() => this.setState({ isDeleteModalOpen: false })} >
                <div id="statusPopup" className="popupModal">
                  <div className="modal-head">
                    <h4 className="modal-title">Delete File</h4>
                    {/* <img
                      alt="close"
                      className="closeModal"
                      onClick={() => this.setState({ isDeleteModalOpen: false })}
                      src={require("../assets/images/close_green.png").default}
                    /> */}
                  </div>
                  <div className="modal-body">
                    <p>The attached resource order will be deleted from this manifest , are you sure want to continue?</p>
                    <div className="resource_order_form_delete_modal_action">
                      <button disabled={this?.state?.isResourceOrderFormDeleting} className="button grey" onClick={() => this.setState({ isDeleteModalOpen: false })}>Cancel</button>
                      <button disabled={this?.state?.isResourceOrderFormDeleting} className="button" onClick={() => this.confirmDeleteResourceOrderForm()}>Confirm</button>
                    </div>
                  </div>
                </div>
              </ReactModal>

              {/* Save Manifest  */}
              <ReactModal isOpen={this?.state?.isSaveConfirmModal} onClose={() => this.setState({ isSaveConfirmModal: false })} >
                <div id="statusPopup" className="popupModal">
                  <div className="modal-head">
                    <h4 className="modal-title">Confirm Action</h4>
                    {/* <img
                      alt="close"
                      className="closeModal"
                      onClick={() => this.setState({ isSaveConfirmModal: false })}
                      src={require("../assets/images/close_green.png").default}
                    /> */}
                  </div>
                  <div className="modal-body">
                    <p>{this?.state?.rofFileName ? `There is a resource order (${this?.state?.rofFileName}) added to this manifest. By confirming, it will save the resource order to the manifest.` : "There is no resource order added to this manifest, are you sure want to continue?"}</p>
                    <div className="resource_order_form_delete_modal_action">
                      <button disabled={this?.state?.isResourceOrderFormDeleting} className="button grey" onClick={() => this.setState({ isSaveConfirmModal: false,print:false })}>Cancel</button>
                      <button disabled={this?.state?.isResourceOrderFormDeleting} name="save" className="button" onClick={(event) => this.handleModalConfirmClick(event)}>Confirm</button>
                      {!this?.state?.rofFileName &&
                      <ResourceOrderFormActions
                        enableViewButton={this?.state?.form?.resource_order_form} 
                        handleOnUpload={() => this.setState({ rofModal: true })}
                        handleOnView={() => this.handleOnViewResourceOrderForm()}
                        handleDeleteRof={() => this?.handleDeleteRof()}
                        handleOnEdit={() => this?.handleEditRof()}
                        className="ro_upload_btn"
                      />}
                    </div>
                  </div>
                </div>
              </ReactModal>
            {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}

            {(this.state.manifestType === "1" || this.state.manifestType === "7") && (
              <div id="view_print_manifest" className="print_manifest_wrap">
                <h2>Finalize Manifest</h2>
                <form ref="form3" id="form3" onSubmit={this.handleSubmit}>
                  <div id="view_printing_page">
                    <div className="responsive_table ifca_view_manifest" id="view_general_information_table">
                      <div className="table top_table">
                        <div className="tr">
                          <div className="print_center td top_title" colSpan="5">
                            <h3>
                              {this.state.manifestType === "1" ? "Exhibit P - " : "Exhibit 1 - "}
                              <span>Hand Crew Manifest Form</span>
                            </h3>
                          </div>
                        </div>
                        <div className="tr ifca_ordering">
                          <div className="td" data-header-title="Ordering Unit">
                            <h4>Ordering Unit</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.unitInput = input;
                              }}
                              errors={this.state.unitError}
                              placeholder="Fill..."
                              id="orderingUnit1"
                              name="ordering_unit"
                              value={form.ordering_unit}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Incident Name">
                            <h4>Incident Name</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.incidentNameInput = input;
                              }}
                              errors={this.state.incidentNameError}
                              placeholder="Fill..."
                              id="incidentName1"
                              name="incident_name"
                              value={form.incident_name}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Incident Number">
                            <h4>Incident Number</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.IncidentNumInput = input;
                              }}
                              errors={this.state.IncidentNumError}
                              placeholder="Fill..."
                              id="incidentNumber1"
                              name="incident_number"
                              value={form.incident_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td_split td" data-header-title="Resource Number">
                            <h4>Resource Number</h4>
                            <b>C-</b>{" "}
                            <span>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.resourceInput = input;
                                }}
                                errors={this.state.resourceError}
                                placeholder="Fill..."
                                id="resourceNumber1"
                                name="resource_number"
                                value={form.resource_number}
                                onChange={this.onChange.bind(this)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="table top_table no_border">
                        <div className="tr contrac1">
                          <div className="td" data-header-title="Contractor" width="36%">
                            <h4>Contractor</h4>
                            <p>Firestorm Wildland Fire Supression Inc.</p>
                          </div>
                          <div
                            className={this.state.manifestType === "7" ? "td_split_s td" : "td"}
                            data-header-title="Agreement Number"
                          >
                            <h4>Agreement Number</h4>
                            {this.state.manifestType === "7" ? <b>IRA </b> : ""}
                            <span>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.ifcaInput = input;
                                }}
                                errors={this.state.ifcaError}
                                placeholder="Fill..."
                                id="ifcaNumber1"
                                name="ifca_number"
                                value={form.ifca_number}
                                onChange={this.onChange.bind(this)}
                              />
                            </span>
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Designated Dispatch Location">
                            <h4>Designated Dispatch Location</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.dispatchInput = input;
                              }}
                              errors={this.state.dispatchError}
                              placeholder="Fill..."
                              id="dispatchLocation1"
                              name="dispatch_location"
                              value={form.dispatch_location}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="tr contrac2">
                          <div className="td" data-header-title="Contractor Representative">
                            <h4>Contractor Representative</h4>
                            <p id="sup_det_view">{sup_name}</p>
                          </div>
                          <div className="td" data-header-title="Contact Phone">
                            <h4>Contact Phone:</h4>
                            <p id="sup_phone_view">{sphone}</p>
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Report To">
                            <h4>Report To:</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.reportToInput = input;
                              }}
                              errors={this.state.reportToError}
                              placeholder="Fill..."
                              id="reportTo1"
                              name="report_to"
                              value={form.report_to}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table top_table no_border place">
                        <div className="tr">
                          <div width="36%" className="td print_center no_padding_border">
                            <div className="table top_table no_border departure_div">
                              <div className="tr">
                                <div
                                  className="print_center td_height td tr_title depar_div"
                                  data-header-title="Departure"
                                  colSpan="3"
                                >
                                  <h5>Departure</h5>
                                </div>
                              </div>
                              <div className="tr">
                                <div width="50%" className="double_field td" data-header-title="Place">
                                  <h5>Place</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.desPlaceInput = input;
                                      }}
                                      errors={this.state.desPlaceError}
                                      placeholder="Fill..."
                                      id="depPlace1"
                                      name="dep_place"
                                      value={form.dep_place}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="double_field td" data-header-title="ETD">
                                  <h5>ETD</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.desEtdInput = input;
                                      }}
                                      errors={this.state.desEtaError}
                                      placeholder="Fill..."
                                      id="depETD1"
                                      name="dep_etd"
                                      value={form.dep_etd}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="double_field td" data-header-title="ETA">
                                  <h5>ETA</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.desEtaInput = input;
                                      }}
                                      errors={this.state.desEtdError}
                                      placeholder="Fill..."
                                      id="depETA1"
                                      name="dep_eta"
                                      value={form.dep_eta}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="td print_center no_padding_border" width="23%">
                            <div className="table top_table no_border interm_div">
                              <div className="tr">
                                <div
                                  className="print_center td_height td tr_title"
                                  data-header-title="Intermediate Stops"
                                >
                                  <h5>Intermediate Stops</h5>
                                </div>
                              </div>
                              <div className="tr">
                                <div className="double_field td" data-header-title="Place">
                                  <h5>Place</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.interSpaceInput = input;
                                      }}
                                      errors={this.state.interSpaceError}
                                      placeholder="Fill..."
                                      id="interSpace1"
                                      name="inter_space"
                                      value={form.inter_space}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="td print_center no_padding_border">
                            <div className="table top_table no_border destin_div">
                              <div className="tr">
                                <div className="td print_center td_height tr_title" data-header-title="Place" colSpan="3">
                                  <h5>Destination</h5>
                                </div>
                              </div>
                              <div className="tr">
                                <div className="td double_field" data-header-title="Place">
                                  <h5>Place</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depPlaceInput = input;
                                      }}
                                      errors={this.state.depPlaceError}
                                      placeholder="Fill..."
                                      id="desPlace1"
                                      name="des_place"
                                      value={form.des_place}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="td double_field" data-header-title="ETD">
                                  <h5>ETD</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depEtdInput = input;
                                      }}
                                      errors={this.state.depEtdError}
                                      placeholder="Fill..."
                                      id="desETD1"
                                      name="des_etd"
                                      value={form.des_etd}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="td double_field" data-header-title="ETA">
                                  <h5>ETA</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depEtaInput = input;
                                      }}
                                      errors={this.state.depEtaError}
                                      placeholder="Fill..."
                                      id="desETA1"
                                      name="des_eta"
                                      value={form.des_eta}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="responsive_table">
                      <div className="table top_table table_space ifca_employee_details" id="view_employee_details_table">
                        <p className="mobile_crew">Crew Details</p>
                        <div className="thead">
                          <div className="tr">
                            <div className="print_center th" data-header-title="Crew Member Name">
                              <h4>Crew Member Name</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="M">
                              <h4>M</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="F">
                              <h4>F</h4>
                            </div>
                            <div className="print_center crew_mem_no th" data-header-title="Crew Member Number">
                              <h4>
                                Crew Member
                                <br />
                                Number
                              </h4>
                            </div>
                            <div className="print_center th" data-header-title="Incident Position">
                              <h4>
                                Incident
                                <br />
                                Position
                              </h4>
                            </div>
                            <div className="print_center th Sawyer" data-header-title="Sawyer">
                              <h4>Sawyer</h4>
                            </div>
                            <div className="print_center emt th" data-header-title="EMT">
                              <h4>EMT</h4>
                            </div>
                            <div
                              className="print_center th"
                              data-header-title={
                                this.state.manifestType === "1"
                                  ? "Experience and Lsa (Blue-Red-Yellow)"
                                  : "Experience B-R-Y Blue-Red-Yellow"
                              }
                            >
                              {this.state.manifestType === "1" ? (
                                <h4>
                                  Experience and Lsa
                                  <br />
                                  <span>(Blue-Red-Yellow)</span>
                                </h4>
                              ) : (
                                <h4>
                                  Experience
                                  <br />
                                  B-R-Y
                                  <br />
                                  <span>Blue-Red-Yellow</span>
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.crewOptions}</div>
                      </div>
                    </div>
                    <div className="responsive_table view_driver_vehicle_div">
                      <h3 className="inner_head_title">Driver and Vehicle Information</h3>
                      <div className="table top_table no_border ifca_driver_vehicle_table" id="view_driver_vehicle_table">
                        <div className="thead">
                          <div className="tr">
                            <div className="td no_border_td_right">
                              <h6>Driver Name</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>License Number/State</h6>
                            </div>
                            <div className="td no_border_td_right mdate">
                              <h6>MSPA Exp. Date</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Vehicle Make/Model</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Vehicle License No.</h6>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.vehicleOptions}</div>
                      </div>
                    </div>

                    <div className="responsive_table ifca_sign">
                      <div className="table top_table last_table">
                        <div className="tr">
                          <div className="td">
                            <span className="title_bottom">Signature of Authorized Company Representative/ (Print)</span>
                            <p id="sup_det_view">{sup_name}</p>
                          </div>
                          <div className="td">
                            <span className="title_bottom">Date</span>
                            <br />
                            <span className="print_date">{formatDate(curDate)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearFix" />
                  <div className="gear-option-btn-wrap">
                    <input
                      type="button"
                      className="button hide_view_manifest"
                      onClick={this.previous.bind(this)}
                      value="Previous"
                    />
                    <input
                      type="submit"
                      id="to_view_final_print"
                      className="button"
                      name="print"
                      onClick={this.submitClick.bind(this)}
                      value="Print Manifest"
                    />
                      <input
                        type="submit"
                        id="to_view_final_email"
                        className="button"
                        name="email"
                        onClick={this.submitClick.bind(this)}
                        value="Email Manifest"
                      />

                      <ResourceOrderFormActions
                        enableViewButton={this?.state?.form?.resource_order_form} 
                        handleOnUpload={() => this.setState({ rofModal: true })} 
                        handleOnView={() => this.handleOnViewResourceOrderForm()}
                        handleDeleteRof={() => this?.handleDeleteRof()}
                        handleOnEdit={() => this?.handleEditRof()}
                        onCreate={() => this?.handleOnCreateResourceOrderForm()}
                       />

                      <input
                        type="button"
                        disabled={this.state.saveBtn}
                        className={`button save_manifest ${this.state.saveBtn ? "save_manifest_disabled" : ""}`}
                        name="save"
                        onClick={this.handleSubmit.bind(this)}
                        value="Save"
                      />
                      {this.state.saveBtn && (
                        <img
                          className="login_loader"
                          alt="loader"
                          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                        />
                      )}
                    </div>
                </form>
              </div>
            )}

            {(this.state.manifestType === "3" || this.state.manifestType === "8") && (
              <div id="view_print_manifest" className="print_manifest_wrap ncc_manifest_wrapper">
                <h2>Finalize Manifest</h2>
                <form ref="form2" id="form2" onSubmit={this.handleSubmit}>
                  <div className="responsive_table ncc_view_manifest" id="general_information_table">
                    <div className="top_table table">
                      <div className="tr">
                        <div className="print_center td" colSpan="5">
                          <h3>Hand Crew Manifest Form</h3>
                        </div>
                      </div>
                      <div className="tr ncc_ordering">
                        <div className="td" data-header-title="Ordering Unit">
                          <h4>Ordering Unit</h4>
                          <input
                            type="text"
                            ref={(input) => {
                              this.unitInput = input;
                            }}
                            errors={this.state.unitError}
                            placeholder="Fill..."
                            id="orderingUnit3"
                            name="ordering_unit"
                            value={form.ordering_unit}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="td" data-header-title="Incident Name">
                          <h4>Incident Name</h4>
                          <input
                            type="text"
                            ref={(input) => {
                              this.incidentNameInput = input;
                            }}
                            errors={this.state.incidentNameError}
                            placeholder="Fill..."
                            id="incidentName3"
                            name="incident_name"
                            value={form.incident_name}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="td" data-header-title="Incident Number">
                          <h4>Incident Number</h4>
                          <input
                            type="text"
                            ref={(input) => {
                              this.IncidentNumInput = input;
                            }}
                            errors={this.state.IncidentNumError}
                            placeholder="Fill..."
                            id="incidentNumber3"
                            name="incident_number"
                            value={form.incident_number}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="td_split td" data-header-title="Resource Number">
                          <h4>Resource Number</h4>
                          <b>C-</b>{" "}
                          <span>
                            <input
                              type="text"
                              ref={(input) => {
                                this.resourceInput = input;
                              }}
                              errors={this.state.resourceError}
                              placeholder="Fill..."
                              id="resourceNumber3"
                              name="resource_number"
                              value={form.resource_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="top_table no_border table">
                      <div className="tr">
                        <div width="36%" className="td" data-header-title="Contractor">
                          <h4>Contractor</h4>
                          <p>Firestorm Wildland Fire Supression Inc.</p>
                        </div>
                        {(this.state.manifestType === "8") && ( 
                        <div
                            className="td"
                            data-header-title="Agreement Number"
                          >
                          <h4>Agreement Number</h4>
                          <span>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.ifcaInput = input;
                                }}
                                errors={this.state.ifcaError}
                                placeholder="Fill..."
                                id="ifcaNumber8"
                                name="ifca_number"
                                value={form.ifca_number}
                                onChange={this.onChange.bind(this)}
                              />
                            </span>
                        </div>
                        )}
                        {/* <div className="td" data-header-title="Federal Tax Id Number">
                                    <h4>Federal Tax Id Number</h4>
                                    <input type="text" ref={(input) => { this.ifcaInput = input; }} errors={this.state.ifcaError} placeholder="Fill..." id="ifcaNumber3" name="ifca_number" value={form.ifca_number} onChange={this.onChange.bind(this)}/>
                                </div> */}
                        <div className="td" data-header-title="Designated Dispatch Location">
                          <h4>Designated Dispatch Location</h4>
                          {this.state.manifestType === "3" ? (
                            <input
                              type="text"
                              ref={(input) => {
                                this.dispatchInput = input;
                              }}
                              errors={this.state.dispatchError}
                              placeholder="Fill..."
                              id="dispatchLocation3"
                              name="dispatch_location"
                              value={form.dispatch_location}
                              onChange={this.onChange.bind(this)}
                            />
                          ) : (
                            <p>{form.dispatch_location}</p>
                          )}
                        </div>
                      </div>
                      <div className="tr">
                        <div className="td" data-header-title="Contractor Represntative">
                          <h4>Contractor Represntative</h4>
                          <p id="sup_det_create">{sup_name}</p>
                        </div>
                        {this.state.manifestType === "3" ? (
                          <>
                        <div className="td" data-header-title="Report To">
                          <h4>Report To:</h4>
                          <input
                            type="text"
                            ref={(input) => {
                              this.reportToInput = input;
                            }}
                            errors={this.state.reportToError}
                            placeholder="Fill..."
                            id="reportTo3"
                            name="report_to"
                            value={form.report_to}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="td" data-header-title="If Delayed, Contact">
                          <h4>If Delayed, Contact:</h4>
                          <p id="sup_phone_create">{sphone}</p>
                        </div>
                        </>
                        ) : (
                          <>
                            <div className="td" data-header-title="If Delayed, Contact">
                              <h4>Contact Phone:</h4>
                              <p id="sup_phone_create">{sphone}</p>
                            </div>  
                            <div className="td" data-header-title="Report To">
                              <h4>Report To:</h4>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.reportToInput = input;
                                }}
                                errors={this.state.reportToError}
                                placeholder="Fill..."
                                id="reportTo3"
                                name="report_to"
                                value={form.report_to}
                                onChange={this.onChange.bind(this)}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="top_table no_border table ncc_place">
                      <div className="tr outer_place_div">
                        <div width="36%" className="print_center no_padding_border td">
                          <div className="top_table no_border table">
                            <div className="tr">
                              <div className="print_center td_height td" colSpan="3" data-header-title="Departure">
                                <h5>Departure</h5>
                              </div>
                            </div>
                            <div className="tr ncc_dep">
                              <div width="50%" className="double_field td" data-header-title="Place">
                                <h5>Place</h5>
                                <span>
                                  <input
                                    type="text"
                                    ref={(input) => {
                                      this.depPlaceInput = input;
                                    }}
                                    errors={this.state.depPlaceError}
                                    placeholder="Fill..."
                                    id="depPlace3"
                                    name="dep_place"
                                    value={form.dep_place}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </span>
                              </div>
                              <div className="double_field td" data-header-title="ETD">
                                <h5>ETD</h5>
                                <span>
                                  <input
                                    type="text"
                                    maxLength={4}
                                    ref={(input) => {
                                      this.depEtdInput = input;
                                    }}
                                    errors={this.state.depEtdError}
                                    placeholder="Fill..."
                                    id="depETD8"
                                    name="dep_etd"
                                    value={form.dep_etd}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </span>
                              </div>
                            {this.state.manifestType === manifestType?.TYPE_2 && (
                              <div className="double_field td" data-header-title="ETA">
                              <h5>ETA</h5>
                              <span>
                                <input
                                  type="text"
                                  maxLength={4}
                                  ref={(input) => {
                                    this.depEtaInput = input;
                                  }}
                                  errors={this.state.desEtdError}
                                  placeholder="Fill..."
                                  id="depETA8"
                                  name="dep_eta"
                                  value={form.dep_eta}
                                  onChange={this.onChange.bind(this)}
                                />
                              </span>
                            </div>
                            )}
                              {/* <div className="double_field td" data-header-title="ETA"><h5>ETA</h5><span><input type="text" ref={(input) => { this.depEtaInput = input; }} errors={this.state.depEtaError}  placeholder="Fill..." id="depETA3" name="dep_eta" value={form.dep_eta} onChange={this.onChange.bind(this)}/></span></div> */}
                            </div>
                          </div>
                        </div>
                        <div className="print_center no_padding_border td" width="23%">
                          <div className="top_table no_border table">
                            <div className="tr">
                              <div
                                className="print_center td_height td"
                                colSpan="2"
                                data-header-title="Intermediate Stops"
                              >
                                <h5>Intermediate Stops</h5>
                              </div>
                            </div>
                            <div className="tr ncc_inter">
                              {this.state.manifestType === manifestType?.NCC && (
                                <div className="double_field td" data-header-title="ETA">
                                  <h5>ETA</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.interEtaInput = input;
                                      }}
                                      errors={this.state.interEtaError}
                                      placeholder="Fill..."
                                      id="inter_eta"
                                      name="inter_eta"
                                      value={form.inter_eta}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              )}
                              <div className="double_field td" data-header-title="Place">
                                <h5>Place</h5>
                                <span>
                                  <input
                                    type="text"
                                    ref={(input) => {
                                      this.interSpaceInput = input;
                                    }}
                                    errors={this.state.interSpaceError}
                                    placeholder="Fill..."
                                    id="interSpace3"
                                    name="inter_space"
                                    value={form.inter_space}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="print_center no_padding_border td">
                          <div className="top_table no_border table">
                            <div className="tr">
                              <div className="print_center td_height td" colSpan="3" data-header-title="Destination">
                                <h5>Destination</h5>
                              </div>
                            </div>
                            <div className="tr ncc_des">
                              {this.state.manifestType === manifestType?.TYPE_2 && (
                                 <div className="double_field td" data-header-title="Place">
                                 <h5>Place</h5>
                                 <span>
                                   <input
                                     type="text"
                                     ref={(input) => {
                                       this.desPlaceInput = input;
                                     }}
                                     errors={this.state.desPlaceError}
                                     placeholder="Fill..."
                                     id="desPlace3"
                                     name="des_place"
                                     value={form.des_place}
                                     onChange={this.onChange.bind(this)}
                                   />
                                 </span>
                               </div>
                              )}
                              <div className="double_field td flex-des" data-header-title="ETD">
                                <h5>ETD</h5>
                                <span>
                                  <input
                                    type="text"
                                    maxLength={4}
                                    ref={(input) => {
                                      this.desEtdInput = input;
                                    }}
                                    errors={this.state.desEtdError}
                                    placeholder="Fill..."
                                    id="desETD3"
                                    name="des_etd"
                                    value={form.des_etd}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </span>
                              </div>
                              <div className="double_field td flex-des" data-header-title="ETA">
                                <h5>ETA</h5>
                                <span>
                                  <input
                                    type="text"
                                    maxLength={4}
                                    ref={(input) => {
                                      this.desEtaInput = input;
                                    }}
                                    errors={this.state.desEtaError}
                                    placeholder="Fill..."
                                    id="desETA3"
                                    name="des_eta"
                                    value={form.des_eta}
                                    onChange={this.onChange.bind(this)}
                                  />
                                </span>
                              </div>
                              {this.state.manifestType === manifestType?.NCC && (
                                <div className="double_field td" data-header-title="Place">
                                  <h5>Place</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.desPlaceInput = input;
                                      }}
                                      errors={this.state.desPlaceError}
                                      placeholder="Fill..."
                                      id="desPlace3"
                                      name="des_place"
                                      value={form.des_place}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="top_table table_space table ncc_employee_details" id="view_employee_details_table">
                    <p className="mobile_crew">Employee Details</p>
                    <div className="thead">
                      <div className="tr">
                        <div className="print_center th" data-header-title="Employee Name (Last, First)">
                          <h4>Employee Name (Last, First)</h4>
                        </div>
                        <div className="print_center th gender" data-header-title="M">
                          <h4>M</h4>
                        </div>
                        <div className="print_center th gender" data-header-title="F">
                          <h4>F</h4>
                        </div>
                        <div className="print_center th" data-header-title="Identification Number">
                          <h4>
                            Identification
                            <br />
                            Number
                          </h4>
                        </div>
                        <div className="print_center th" data-header-title="Incident Position">
                          <h4>
                            Incident
                            <br />
                            Position
                          </h4>
                        </div>
                        <div className="print_center th sawyer" data-header-title="Sawyer Class">
                          <h4>
                            Sawyer
                          </h4>
                        </div>
                        {this.state.manifestType === "8" && (         
                          <div className="print_center th emt" data-header-title="EMT">
                            <h4>
                              EMT
                            </h4>
                          </div>
                        )}
                        <div className="print_center th" data-header-title="Experience Blue-Red-Yellow (B_R_Y)">
                          {/* <h4>
                            Experience
                            <br />
                            <span>Blue-Red-Yellow (B_R_Y)</span>
                          </h4> */}
                          {this.state.manifestType === manifestType?.TYPE_2 && (         
                          <h4>
                          Experience and LSA
                          <br />
                          <span>(B-R-Y=Blue-Red-Yellow)</span>
                        </h4>
                        )}
                          {this.state.manifestType === manifestType?.NCC && (         
                          <h4>
                            Experience
                            <br />
                            <span>Blue-Red-Yellow (B_R_Y)</span>
                          </h4>
                        )}

                        </div>
                      </div>
                    </div>
                    <div className="tbody">{this.state.crewOptions}</div>
                  </div>
                  <div className="responsive_table view_driver_vehicle_div_ncc">
                    <h3 className="inner_head_title driver_vehicle_title">Driver and Vehicle Information</h3>

                    <div className="top_table no_border table" id="view_driver_vehicle_table">
                      <div className="thead">
                        <div className="tr">
                          <div className="no_border_td_right th">
                            <h6>Driver Name</h6>
                          </div>
                          {this.state.manifestType === manifestType?.TYPE_2 && (
                            <div className="no_border_td_right th">
                              <h6>DL/MSPA Exp. Date</h6>
                            </div>
                          )}
                          <div className="no_border_td_right th">
                            <h6>Vehicle Make/Model</h6>
                          </div>
                          <div className="no_border_td_right th st">
                            <h6>Year</h6>
                          </div>
                          <div className="no_border_td_right th exp_date">
                            <h6>Vehicle License No.</h6>
                          </div>
                        </div>
                      </div>
                      <div className="tbody">{this.state.vehicleOptions}</div>
                    </div>
                  </div>

                  <div className="table top_table last_table ncc_sign">
                    <div className="tr">
                      <div className="td">
                        <span className="title_bottom">Contractor Representative Name (Print)</span>
                        <br />
                        <p id="sup_det_create">{sup_name}</p>
                      </div>
                      <div className="td">
                        <span className="title_bottom">Date</span>
                        <br />
                        <p className="print_date">{formatDate(curDate)}</p>
                      </div>
                      <div className="td">
                        <span className="title_bottom">Contact Phone Number</span>
                        <br />
                        <p id="sup_phone_create">{sphone}</p>
                      </div>
                    </div>
                          {/* <div className="tr">
                      <div className="td">
                        {this?.state?.rofFileName}
                        </div>
                      </div> */}
                  </div>
                  <div className="clearFix" />
                  <div className="gear-option-btn-wrap">
                    <input
                      type="button"
                      className="button hide_view_manifest"
                      onClick={this.previous.bind(this)}
                      value="Previous"
                    />
                    <input
                      type="submit"
                      id="to_view_final_print"
                      className="button"
                      name="print"
                      onClick={this.submitClick.bind(this)}
                      value="Print Manifest"

                      
                      
                    />
                    <input
                      type="submit"
                      id="to_view_final_email"
                      className="button"
                      name="email"
                      onClick={this.submitClick.bind(this)}
                      value="Email Manifest"
                    />
                    <ResourceOrderFormActions enableViewButton={this?.state?.form?.resource_order_form}
                      handleOnUpload={() => this.setState({ rofModal: true })}
                      handleDeleteRof={() => this?.handleDeleteRof()}   
                      handleOnView={() => this.handleOnViewResourceOrderForm()} 
                      handleOnEdit={() => this?.handleEditRof()}
                      onCreate={() => this?.handleOnCreateResourceOrderForm()}/>
                    <input
                      type="button"
                      disabled={this.state.saveBtn}
                      className={`button save_manifest ${this.state.saveBtn ? "save_manifest_disabled" : ""}`}
                      name="save"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                    />
                  </div>
                </form>
              </div>
            )}

            {this.state.manifestType === "4" && (
              <div id="view_print_manifest" className="print_manifest_wrap vipr_manifest_wrapper">
                <h2>Manifest</h2>
                <div className={"effective_date " + (this.state.mdateError ? "end-date-error" : "")}>
                  <span className="mani_title">Manifest</span>
                  <span className="from">
                    Effective dates:
                    <div className="manifesttDateWrapper">
                      <DatePicker
                        name="start_date"
                        placeholderText=""
                        dateFormat="MM/DD/YYYY"
                        popperPlacement="bottom-end"
                        selected={this.state.eff_start_date}
                        onChange={this.handleManifestDate.bind(this, "eff_start_date")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                    <span className="to">To</span>
                    <div className="manifesttDateWrapper">
                      <DatePicker
                        name="start_date"
                        placeholderText=""
                        dateFormat="MM/DD/YYYY"
                        popperPlacement="bottom-end"
                        selected={this.state.eff_end_date}
                        onChange={this.handleManifestDate.bind(this, "eff_end_date")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </span>
                </div>
                <form ref="form1" id="form1" onSubmit={this.handleSubmit}>
                  <div id="view_printing_page">
                    <div className="responsive_table vipr_view_manifest" id="general_information_table">
                      <div className="top_table table">
                        <div className="tr order_info">
                          <div
                            className="td"
                            data-header-title="Ordering office and name of dispatcher (dispatch location)"
                          >
                            <h4>
                              Ordering office and name of <br />
                              dispatcher (dispatch location)
                            </h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.unitInput = input;
                              }}
                              errors={this.state.unitError}
                              placeholder="Fill..."
                              id="orderingUnit4"
                              name="ordering_unit"
                              value={form.ordering_unit}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Fire name and unit (Destination)">
                            <h4>Fire name and unit (Destination)</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.incidentNameInput = input;
                              }}
                              errors={this.state.incidentNameError}
                              placeholder="Fill..."
                              id="incidentName4"
                              name="incident_name"
                              value={form.incident_name}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Resource order number">
                            <h4>Resource order number</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.resourceInput = input;
                              }}
                              errors={this.state.resourceError}
                              placeholder="Fill..."
                              id="resourceNumber4"
                              name="resource_number"
                              value={form.resource_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="top_table no_border table">
                        <div className="tr contractor_info">
                          <div className="td" data-header-title="Name of contractor">
                            <h4>Name of contractor</h4>
                            <p id="sup_det_create">Firestorm Wildland Fire Supression Inc.</p>
                          </div>
                          <div className="td" data-header-title="Unique vehicle number">
                            <h4>Unique vehicle number</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.vehNumInput = input;
                              }}
                              errors={this.state.vehNumError}
                              placeholder="Fill..."
                              id="uniqVehNumber"
                              name="vehicle_number"
                              value={form.vehicle_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Aggrement Number">
                            <h4>Aggrement Number</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.ifcaInput = input;
                              }}
                              errors={this.state.ifcaError}
                              placeholder="Fill..."
                              id="ifcaNumber4"
                              name="ifca_number"
                              value={form.ifca_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                        </div>
                        <div className="tr location">
                          <div className="td" data-header-title="Time of departure (dispatch location)">
                            <h4>
                              Time of departure (dispatch <br />
                              location):
                            </h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.dispatchInput = input;
                              }}
                              errors={this.state.dispatchTimeError}
                              placeholder="Fill..."
                              id="dispatchTime"
                              name="dispatch_time"
                              value={form.dispatch_time}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Time of arrival to (final destination)">
                            <h4>
                              Time of arrival to (final <br />
                              destination):
                            </h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.reportToInput = input;
                              }}
                              errors={this.state.reportToError}
                              placeholder="Fill..."
                              id="reportTo4"
                              name="report_to"
                              value={form.report_to}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Do you have the personnel to rotate crews?">
                            <h4>
                              Do you have the personnel to <br />
                              rotate crews?
                            </h4>

                            <select
                              className="perssonal"
                              name="perssonal"
                              value={form.perssonal}
                              onChange={this.onChange.bind(this)}
                            >
                              <option value="Y">Y</option>
                              <option value="N">N</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="top_table no_border place table">
                        <div className="tr">
                          <div className="print_center no_padding_border td">
                            <div className="top_table no_border table place1">
                              <div className="tr">
                                <div className="print_center td_height td" data-header-title="INTERMEDIATE STOPS (PLACE)">
                                  <h5>INTERMEDIATE STOPS (PLACE)</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.interSpaceInput = input;
                                      }}
                                      errors={this.state.interSpaceError}
                                      placeholder="Fill..."
                                      id="interSpace4"
                                      name="inter_space"
                                      value={form.inter_space}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="print_center td_height td" data-header-title="ARR TIME">
                                  <h5>
                                    ARR <br />
                                    TIME
                                  </h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.arrInput = input;
                                      }}
                                      errors={this.state.arrError}
                                      placeholder="Fill..."
                                      id="arr"
                                      name="arr"
                                      value={form.arr}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="print_center no_padding_border td" width="23%">
                            <div className="top_table no_border table place2">
                              <div className="tr">
                                <div className="print_center td_height td" data-header-title="DEP TIME">
                                  <h5>
                                    DEP <br />
                                    TIME
                                  </h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depTimeInput = input;
                                      }}
                                      errors={this.state.depTimeError}
                                      placeholder="Fill..."
                                      id="depTime"
                                      name="dep_time"
                                      value={form.dep_time}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="print_center td_height td" data-header-title="PLACE">
                                  <h5>PLACE</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depPlaceInput = input;
                                      }}
                                      errors={this.state.depPlaceError}
                                      placeholder="Fill..."
                                      id="depPlace4"
                                      name="dep_place"
                                      value={form.dep_place}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="print_center td_height td" data-header-title="ARR TIME">
                                  <h5>
                                    ARR <br />
                                    TIME
                                  </h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.depArrInput = input;
                                      }}
                                      errors={this.state.depArrError}
                                      placeholder="Fill..."
                                      id="depArr"
                                      name="dep_arr"
                                      value={form.dep_arr}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="print_center no_padding_border td">
                            <div className="top_table no_border table place3">
                              <div className="tr">
                                <div className="print_center td_height td" colSpan="3" data-header-title="DEP TIME">
                                  <h5>
                                    DEP <br />
                                    TIME
                                  </h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.perDepTimeInput = input;
                                      }}
                                      errors={this.state.perDepTimeError}
                                      placeholder="Fill..."
                                      id="perDepTime"
                                      name="pdep_time"
                                      value={form.pdep_time}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                                <div className="print_center td_height td" colSpan="3" data-header-title="PLACE">
                                  <h5>PLACE</h5>
                                  <span>
                                    <input
                                      type="text"
                                      ref={(input) => {
                                        this.perDepPlaceInput = input;
                                      }}
                                      errors={this.state.perDepPlaceError}
                                      placeholder="Fill..."
                                      id="perDepPlace"
                                      name="pedep_place"
                                      value={form.pedep_place}
                                      onChange={this.onChange.bind(this)}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="responsive_table">
                      <div className="top_table table_space vipr_employee_details table" id="view_employee_details_table">
                        <p className="mobile_crew">Employee Details</p>
                        <div className="thead">
                          <div className="tr">
                            <div className="print_center th">
                              <h4>Employee Name</h4>
                            </div>
                            <div className="print_center th gender">
                              <h4>M</h4>
                            </div>
                            <div className="print_center th gender">
                              <h4>F</h4>
                            </div>
                            <div className="print_center th">
                              <h4>Position</h4>
                            </div>
                            <div className="print_center th">
                              <h4>
                                LAST NIGHT NOT SPENT
                                <br /> ON FIRE ASSIGNMENT
                                <br /> (DATE)
                              </h4>
                            </div>
                            <div className="print_center th">
                              <h4>
                                UNIQUE EMPLOYEE <br />
                                NUMBER <br />
                                (NO SSN)
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.crewOptions}</div>
                      </div>
                    </div>
                    <div className="top_table no_border vipr_sign signature table">
                      <div className="tbody">
                        <div className="tr">
                          <div width="74.3%" className="td">
                            <h4 className="title_bottom">SIGNATURE OF AUTHORIZED REPRESENTATIVE</h4>
                            <p>
                              <input
                                type="text"
                                // ref={input => {
                                //   this.depPlaceInput = input;
                                // }}
                                //errors={this.state.depPlaceError}
                                placeholder="Fill..."
                                id="sign4"
                                ref={(input) => {
                                  this.signInput = input;
                                }}
                                errors={this.state.signError}
                                name="sign_auth_representative"
                                value={form.sign_auth_representative}
                                onChange={this.onChange.bind(this)}
                              />
                            </p>
                          </div>
                          <div className="td">
                            <h4>DATE</h4>
                            <span className="print_date">{formatDate(curDate)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearFix" />
                  <div className="gear-option-btn-wrap">
                    <input
                      type="button"
                      className="button hide_view_manifest"
                      onClick={this.previous.bind(this)}
                      value="Previous"
                    />
                    <input
                      type="submit"
                      id="to_view_final_print"
                      className="button"
                      name="print"
                      onClick={this.submitClick.bind(this)}
                      value="Print Manifest"
                    />
                    <input
                      type="submit"
                      id="to_view_final_email"
                      className="button"
                      name="email"
                      onClick={this.submitClick.bind(this)}
                      value="Email Manifest"
                    />
                    <ResourceOrderFormActions
                      enableViewButton={this?.state?.form?.resource_order_form}
                      handleOnUpload={() => this.setState({ rofModal: true })}
                      handleDeleteRof={() => this?.handleDeleteRof()}   
                      handleOnView={() => this.handleOnViewResourceOrderForm()} 
                      handleOnEdit={() => this?.handleEditRof()}
                      onCreate={() => this?.handleOnCreateResourceOrderForm()}/>
                    <input
                      type="button"
                      disabled={this.state.saveBtn}
                      className={`button save_manifest ${this.state.saveBtn ? "save_manifest_disabled" : ""}`}
                      name="save"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                    />
                  </div>
                </form>
              </div>
            )}

            {/* -------------------------------------------------------------------------------------------------------------------- */}

            {this.state.manifestType === "5" && (
              <div id="finalize_manifest" className="dcrm print_manifest_wrap">
                <h2>Finalize Manifest</h2>
                <form ref="form5" id="form5" onSubmit={this.handleSubmit}>
                  <div id="view_printing_page">
                    <div className="responsive_table dcrm_view_manifest" id="view_general_information_table">
                      <div className="table top_table">
                        <div className="tr">
                          <div className="print_center td top_title" colSpan="5">
                            <h3>DEER CREEK RESOURCES MANIFEST FORM</h3>
                          </div>
                        </div>
                        <div className="tr">
                          <div className="td" data-header-title="Incident Name">
                            <h4>Incident Name</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.incidentNameInput = input;
                              }}
                              errors={this.state.incidentNameError}
                              placeholder="Fill..."
                              id="incidentName5"
                              name="incident_name"
                              value={form.incident_name}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Incident Number">
                            <h4>Incident Number</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.IncidentNumInput = input;
                              }}
                              errors={this.state.IncidentNumError}
                              placeholder="Fill..."
                              id="incidentNumber5"
                              name="incident_number"
                              value={form.incident_number}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td_split td" data-header-title="Resource Number">
                            <h4>Resource</h4>
                            <b>E-</b>{" "}
                            <span>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.resourceNameInput = input;
                                }}
                                errors={this.state.resourceNameError}
                                placeholder="Fill..."
                                id="resource5"
                                name="resource"
                                value={form.resource}
                                onChange={this.onChange.bind(this)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="table top_table no_border">
                        <div className="tr contrac5">
                          <div className="td" data-header-title="Contractor Represntative">
                            <h4>Contractor Represntative</h4>
                            <p id="sup_det_view">{sup_name}</p>
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Representative Phone Number">
                            <h4>Representative Phone Number</h4>
                            <p id="sup_phone_view">{sphone}</p>
                          </div>
                        </div>
                        <div className="tr contrac5">
                          <div className="td" data-header-title="Point of Hire">
                            <h4>Point of Hire</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.pointOfHireInput = input;
                              }}
                              errors={this.state.pointOfHireError}
                              placeholder="Fill..."
                              id="pointOfHire5"
                              name="point_of_hire"
                              value={form.point_of_hire}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Job Location">
                            <h4>Job Location</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.departureInput = input;
                              }}
                              errors={this.state.jobLocError}
                              placeholder="Fill..."
                              id="jobLoc5"
                              name="job_loc"
                              value={form.job_loc}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Estimated Duration">
                            <h4>Estimated Duration</h4>
                            <div className="duration_date_wrap">
                              <div className="manifesttDateWrapper">
                                <DatePicker
                                  name="est_start"
                                  placeholderText="Start"
                                  dateFormat="MM/DD/YYYY"
                                  popperPlacement="bottom-end"
                                  selected={this.state.form.est_start}
                                  onChange={this.handleEstimateDates.bind(this, "est_start")}
                                  ref={(input) => {
                                    this.estStartInput = input;
                                  }}
                                  errors={this.state.estStartError}
                                  id="estStart5"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                              <span className="to">To</span>
                              <div className="manifesttDateWrapper">
                                <DatePicker
                                  name="est_finish"
                                  placeholderText="Finish"
                                  dateFormat="MM/DD/YYYY"
                                  popperPlacement="bottom-end"
                                  selected={this.state.form.est_finish}
                                  onChange={this.handleEstimateDates.bind(this, "est_finish")}
                                  ref={(input) => {
                                    this.estFinishInput = input;
                                  }}
                                  errors={this.state.estFinishError}
                                  id="estFinish5"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="responsive_table">
                      <div className="table top_table table_space ifca_employee_details" id="view_employee_details_table">
                        <p className="mobile_crew">Crew Details</p>
                        <div className="thead">
                          <div className="tr">
                            <div className="print_center th" data-header-title="Crew Member Name">
                              <h4>Employee Name (Last, First)</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="M">
                              <h4>M</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="F">
                              <h4>F</h4>
                            </div>
                            <div className="print_center crew_mem_no th" data-header-title="Employee Id Number">
                              <h4>
                                Employee Id
                                <br />
                                Number
                              </h4>
                            </div>
                            <div className="print_center th" data-header-title="Project Position">
                              <h4>Project Position</h4>
                            </div>
                            <div className="print_center th phone" data-header-title="Phone Number">
                              <h4>
                                Phone <br /> Number
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.crewOptions}</div>
                      </div>
                    </div>
                    <div className="responsive_table view_driver_vehicle_div">
                      <h3 className="inner_head_title">Driver and Vehicle Information</h3>
                      <div className="table top_table no_border ifca_driver_vehicle_table" id="view_driver_vehicle_table">
                        <div className="thead">
                          <div className="tr">
                            <div className="td no_border_td_right">
                              <h6>Driver Name (Last, First)</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Driver License No</h6>
                            </div>
                            <div className="td no_border_td_right mdate DLIssued">
                              <h6>ST</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Exp. Date</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Vehicle License No</h6>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.vehicleOptions}</div>
                      </div>
                    </div>
                    <div className="table top_table last_table ncc_sign">
                      <div className="tr">
                        <div className="td">
                          <span className="title_bottom">Crew Leader Represntative (Print)</span>
                          <br />
                          <p id="sup_det_create">{sup_name}</p>
                        </div>
                        <div className="td date">
                          <span className="title_bottom">Date</span>
                          <br />
                          <p className="print_date">{formatDate(curDate)}</p>
                        </div>
                        <div className="td">
                          <span className="title_bottom">Contact Phone Number</span>
                          <br />
                          <p id="sup_phone_create">{sphone}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearFix" />
                  <div className="gear-option-btn-wrap">
                    <input
                      type="button"
                      className="button hide_view_manifest"
                      onClick={this.previous.bind(this)}
                      value="Previous"
                    />
                    <input
                      type="submit"
                      id="to_view_final_print"
                      className="button"
                      name="print"
                      onClick={this.submitClick.bind(this)}
                      value="Print Manifest"
                    />
                    <input
                      type="submit"
                      id="to_view_final_email"
                      className="button"
                      name="email"
                      onClick={this.submitClick.bind(this)}
                      value="Email Manifest"
                    />
                    <ResourceOrderFormActions 
                      enableViewButton={this?.state?.form?.resource_order_form}
                      handleOnUpload={() => this.setState({ rofModal: true })}
                      handleDeleteRof={() => this?.handleDeleteRof()}   
                      handleOnView={() => this.handleOnViewResourceOrderForm()}
                      handleOnEdit={() => this?.handleEditRof()}
                      onCreate={() => this?.handleOnCreateResourceOrderForm()}
                    />
                    <input
                      type="button"
                      disabled={this.state.saveBtn}
                      className={`button save_manifest ${this.state.saveBtn ? "save_manifest_disabled" : ""}`}
                      name="save"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                    />
                    {this.state.saveBtn && (
                      <img
                        className="login_loader"
                        alt="loader"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                  </div>
                </form>
              </div>
            )}

            {/* -------------------------------------------------------------------------------------------------------------------- */}

            {this.state.manifestType === "6" && (
              <div id="finalize_manifest" className="pm print_manifest_wrap">
                <h2>Finalize Manifest</h2>
                <form ref="form6" id="form6" onSubmit={this.handleSubmit}>
                  <div id="view_printing_page">
                    <div className="responsive_table pm_view_manifest" id="view_general_information_table">
                      <div className="table top_table">
                        <div className="tr">
                          <div className="print_center td top_title" colSpan="5">
                            <h3>Project Manifest</h3>
                          </div>
                        </div>
                        <div className="tr ifca_ordering">
                          <div className="td" data-header-title="Project Name">
                            <h4>Project Name</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.projectNameInput = input;
                              }}
                              errors={this.state.projectNameError}
                              placeholder="Fill..."
                              id="projectName6"
                              name="project_name"
                              value={form.project_name}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Project Code">
                            <h4>Project Code</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.projectCodeInput = input;
                              }}
                              errors={this.state.projectCodeError}
                              placeholder="Fill..."
                              id="projectCode6"
                              name="project_code"
                              value={form.project_code}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td_split td" data-header-title="Resource">
                            <h4>Resource</h4>
                            <b>C-</b>{" "}
                            <span>
                              <input
                                type="text"
                                ref={(input) => {
                                  this.resourceNameInput = input;
                                }}
                                errors={this.state.resourceNameError}
                                placeholder="Fill..."
                                id="resource6"
                                name="resource"
                                value={form.resource}
                                onChange={this.onChange.bind(this)}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="table top_table no_border">
                        <div className="tr contrac6">
                          <div className="td" data-header-title="Crew Leader Represntative">
                            <h4>Crew Leader Represntative</h4>
                            <p id="sup_det_view">{sup_name}</p>
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Representative Phone Number">
                            <h4>Representative Phone Number</h4>
                            <p id="sup_phone_view">{sphone}</p>
                          </div>
                        </div>
                        <div className="tr contrac6">
                          <div className="td" data-header-title="Point of Hire">
                            <h4>Point of Hire</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.pointOfHireInput = input;
                              }}
                              errors={this.state.pointOfHireError}
                              placeholder="Fill..."
                              id="pointOfHire6"
                              name="point_of_hire"
                              value={form.point_of_hire}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          <div className="td" data-header-title="Job Location">
                            <h4>Job Location</h4>
                            <input
                              type="text"
                              ref={(input) => {
                                this.departureInput = input;
                              }}
                              errors={this.state.jobLocError}
                              placeholder="Fill..."
                              id="jobLoc6"
                              name="job_loc"
                              value={form.job_loc}
                              onChange={this.onChange.bind(this)}
                            />
                          </div>
                          {/*<div className="tdw-15 td"></div>*/}
                          <div className="td" data-header-title="Estimated Duration">
                            <h4>Estimated Duration</h4>
                            <div className="duration_date_wrap">
                              <div className="manifesttDateWrapper">
                                <DatePicker
                                  name="est_start"
                                  placeholderText="Start"
                                  dateFormat="MM/DD/YYYY"
                                  popperPlacement="bottom-end"
                                  selected={this.state.form.est_start}
                                  onChange={this.handleEstimateDates.bind(this, "est_start")}
                                  ref={(input) => {
                                    this.estStartInput = input;
                                  }}
                                  errors={this.state.estStartError}
                                  id="estStart6"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                              <span className="to">To</span>
                              <div className="manifesttDateWrapper">
                                <DatePicker
                                  name="est_finish"
                                  placeholderText="Finish"
                                  dateFormat="MM/DD/YYYY"
                                  popperPlacement="bottom-end"
                                  selected={this.state.form.est_finish}
                                  onChange={this.handleEstimateDates.bind(this, "est_finish")}
                                  ref={(input) => {
                                    this.estFinishInput = input;
                                  }}
                                  errors={this.state.estFinishError}
                                  id="estFinish6"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="responsive_table">
                      <div className="table top_table table_space ifca_employee_details" id="view_employee_details_table">
                        <p className="mobile_crew">Crew Details</p>
                        <div className="thead">
                          <div className="tr">
                            <div className="print_center th" data-header-title="Crew Member Name">
                              <h4>Employee Name (Last, First)</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="M">
                              <h4>M</h4>
                            </div>
                            <div className="print_center gender th" data-header-title="F">
                              <h4>F</h4>
                            </div>
                            <div className="print_center crew_mem_no th" data-header-title="Employee Id Number">
                              <h4>
                                Employee Id
                                <br />
                                Number
                              </h4>
                            </div>
                            <div className="print_center th" data-header-title="Project Position">
                              <h4>
                                Project
                                <br />
                                Position
                              </h4>
                            </div>
                            <div className="print_center th phone" data-header-title="Phone Number">
                              <h4>
                                Phone <br /> Number
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.crewOptions}</div>
                      </div>
                    </div>
                    <div className="responsive_table view_driver_vehicle_div">
                      <h3 className="inner_head_title">Driver and Vehicle Information</h3>
                      <div className="table top_table no_border ifca_driver_vehicle_table" id="view_driver_vehicle_table">
                        <div className="thead">
                          <div className="tr">
                            <div className="td no_border_td_right">
                              <h6>Driver Name (Last, First)</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Driver License No</h6>
                            </div>
                            <div className="td no_border_td_right mdate DLIssued">
                              <h6>ST</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Exp. Date</h6>
                            </div>
                            <div className="td no_border_td_right">
                              <h6>Vehicle License No</h6>
                            </div>
                          </div>
                        </div>
                        <div className="tbody">{this.state.vehicleOptions}</div>
                      </div>
                    </div>
                    <div className="table top_table last_table ncc_sign">
                      <div className="tr">
                        <div className="td">
                          <span className="title_bottom">Crew Leader Represntative (Print)</span>
                          <br />
                          <p id="sup_det_create">{sup_name}</p>
                        </div>
                        <div className="td">
                          <span className="title_bottom">Date</span>
                          <br />
                          <p className="print_date">{formatDate(curDate)}</p>
                        </div>
                        <div className="td">
                          <span className="title_bottom">Contact Phone Number</span>
                          <br />
                          <p id="sup_phone_create">{sphone}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearFix" />
                  <div className="gear-option-btn-wrap">
                    <input
                      type="button"
                      className="button hide_view_manifest"
                      onClick={this.previous.bind(this)}
                      value="Previous"
                    />
                    <input
                      type="submit"
                      id="to_view_final_print"
                      className="button"
                      name="print"
                      onClick={this.submitClick.bind(this)}
                      value="Print Manifest"
                    />
                    <input
                      type="submit"
                      id="to_view_final_email"
                      className="button"
                      name="email"
                      onClick={this.submitClick.bind(this)}
                      value="Email Manifest"
                    />
                    <ResourceOrderFormActions
                      enableViewButton={this?.state?.form?.resource_order_form}
                      handleOnUpload={() => this.setState({ rofModal: true })}
                      handleDeleteRof={() => this?.handleDeleteRof()}   
                      handleOnView={() => this.handleOnViewResourceOrderForm()}
                      handleOnEdit={() => this?.handleEditRof()}
                      onCreate={() => this?.handleOnCreateResourceOrderForm()}
                    />
                    <input
                      type="button"
                      disabled={this.state.saveBtn}
                      className={`button save_manifest ${this.state.saveBtn ? "save_manifest_disabled" : ""}`}
                      name="save"
                      onClick={this.handleSubmit.bind(this)}
                      value="Save"
                    />
                    {this.state.saveBtn && (
                      <img
                        className="login_loader"
                        alt="loader"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                      />
                    )}
                  </div>
                </form>
              </div>
            )}

            {/*<Modal style={"width: 200px"} id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>*/}
            <ReactModal
              isOpen={this.state.statusOpen}
              onClose={this.statusCloseModal.bind(this)}
              contentLabel="Minimal Modal Example"
              overlayClassName="emailManifestPopup"
            >
              <div id="statusPopup" className="popupModal">
                <div className="modal-head">
                  <h4 className="modal-title">Email Manifest</h4>
                  <img
                    alt="close"
                    className="closeModal"
                    onClick={this.statusCloseModal.bind(this)}
                    src={require("../assets/images/close_green.png").default}
                    onError={this.onImageError.bind(this)}
                  />
                </div>

                <div className="modal-body">
                  <div className="mail_div">
                    <div className="left">
                      <input
                        type="text"
                        className="txtSearchManiEmployees"
                        id="txtSearchManiEmployees"
                        name="keyword"
                        value={this.state.mailSearchKeyword}
                        placeholder="Search and add emplyees for sending email..."
                        onChange={this.emailChangeFn.bind(this)}
                        autoComplete="off"
                      />
                      {this.state.validManifestMail && this.state.searchList.length === 0 && (
                        <button className="button add_mail" onClick={this.addManifestEmail}>
                          Add Email
                        </button>
                      )}
                      <ul className={"searchResultUl " + this.state.hideSearch}>{this.state.searchList}</ul>
                    </div>
                    {/* <div className="right">
                    {this.state.textarea && (
                      <div>
                        <textarea
                          id="multiple_emails"
                          name="to"
                          rows="3"
                          cols="10"
                          placeholder="Enter email-id and use comma for multiple emails..."
                          value={this.state.to}
                          onChange={this.handleChange.bind(this)}
                        />
                        <button
                          className="button send_mail"
                          onClick={this.emailManifest.bind(this)}
                        >
                          Send
                        </button>
                      </div>
                    )}
                  </div> */}

                    <div className="clearFix" />
                    <div className="mail-maifest-wrapper">
                      <div className="mail-manifest wrapper">
                        <div className="head">
                          <div className="emp">Employee</div>
                          <div className="name">Name</div>
                          <div className="email">Email</div>
                          {/* <div className="send-mail" /> */}
                          <div className="close" />
                        </div>
                        {this.state.dispatchMailList.map((item, index) => {
                          return (
                            <div className="employee employee_item">
                              <div className="pic image-holder">
                                <img src={config.apiUrl + "/" + config.altImg} onError={this.onImageError.bind(this)} />
                              </div>
                              <div className="name">
                                <span className="">Dispatch Email</span>
                              </div>
                              <div className="email">{item}</div>
                              <div className="close-btn" onClick={this.removeDispatchList.bind(this, index)}>
                                <img
                                  alt="close"
                                  src={require("../assets/images/close_button.png").default}
                                  onError={this.onImageError.bind(this)}
                                />
                              </div>
                            </div>
                          );
                        })}
                        {this.state.mailList.map((item, index) => {
                          return (
                            <div className="employee employee_item">
                              <div className="pic image-holder">
                                <img
                                  alt={item.first_name + " " + item.last_name}
                                  className={item.active}
                                  src={
                                    item.profile_photo
                                      ? config.apiUrl + "/" + item.profile_photo
                                      : config.apiUrl + "/" + config.altImg
                                  }
                                  onError={this.onImageError.bind(this)}
                                />
                              </div>
                              <div className="name">
                                <span className="">
                                  <span className={item.colorcode + " color_code"} />
                                  {item.first_name + " " + item.last_name}
                                </span>
                              </div>
                              <div className="email">{item.email}</div>
                              {/* <div
                            className="send-button sendMail_btn"
                            onClick={this.sendMail.bind(this, item.email)}
                          >
                            Send Mail
                          </div> */}
                              <div className="close-btn" onClick={this.removeList.bind(this, index)}>
                                <img
                                  alt="close"
                                  src={require("../assets/images/close_button.png").default}
                                  onError={this.onImageError.bind(this)}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <p className="sig_message">This email will automatically be sent to the Dispatch email as well.</p>
                    <br />
                    <button className="button send_mail" onClick={this.emailManifest.bind(this)}>
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </ReactModal>
            {/*</Modal>*/}
          </div>  
        )}
      
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { superintendent, manifest, employee, email } = state;
  return {
    superintendent,
    manifest,
    employee,
    email,
  };
}

const connectedResourcePage = withRouter(connect(mapStateToProps)(Finalize));
export { connectedResourcePage as Finalize };




const ResourceOrderFormActions = ({
  handleOnUpload,
  handleOnView,
  enableViewButton,
  handleDeleteRof,
  handleOnEdit,
  className,
  onCreate,
}) => {
  console.log('enableViewButton: ', enableViewButton);
  return (
    <>
      <input
        type="button"
        id="upload_ROF"
        className={`${className} button`}
        onClick={() => handleOnUpload()}
        value="Resource Order"
        name="upload_ROF"
      ></input>
      {/* {onCreate && 
        <input
        type="button"
        id="create_ROF"
        className={`${className} button`}
        onClick={() => onCreate()}
        value="Create RO"
        name="create_ROF"/>   
      } */}
      {enableViewButton && (
        <>
          <input
            type="button"
            id="delete_ROF"
            className="button"
            onClick={() => handleDeleteRof()}
            value="Delete RO"
            name="delete_ROF"
          ></input>
          <input
            type="button"
            id="view_ROF"
            className="button"
            onClick={() => handleOnView()}
            value="View RO"
            name="view_ROF"
          ></input>
          {enableViewButton?.resource_order_form_type === 1 && (
            <input
              type="button"
              id="edit_ROF"
              className="button"
              onClick={() => handleOnEdit()}
              value="Edit RO"
              name="edit_ROF"
            ></input>
          )}
        </>
      )}
    </>
  );
};