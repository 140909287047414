import ResourceOverviewCard from "_components/Dashboard/ResourceOverviewCard";
import React from "react";
import { connect } from "react-redux";
import { resourceActions } from "../_actions";
import { config } from "../_helpers";
import "../assets/styles/resource.css";

class Firefighter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resource_list: [],
    };
    this.onImageError = this.onImageError.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(resourceActions.getAll("?stats=true"));
    // this.componentWillReceiveProps(this.props)
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  componentWillReceiveProps(nextProps) {
    const { resource } = nextProps;
    var resource_list = [];

    if (this.props.resource != resource) {
      if (resource.items) {
        Object.keys(resource.items.data).map((key) => {
          if (key && key != "") {
            resource.items.data[key].map((item, index) => {
              var sup;
              if (item.Superintendent) {
                sup =
                  item.Superintendent.length > 0
                    ? item.Superintendent[0].first_name +
                      " " +
                      item.Superintendent[0].last_name
                    : "";
              }
              resource_list.push(
                <ResourceOverviewCard
                  id={item.id}
                  img={item.resource_photo}
                  title={item.resource_name}
                  status={item.assignment_status || "-"}
                  recentIncident={item.recent_incident || "-"}
                  superintendent={sup ? sup : "-"}
                  totalEmployees={
                    item.committed_employees +
                    item.available_not_committed +
                    item.not_committed_employees
                  }
                  committed_employees={item.committed_employees}
                  available_not_committed={item.available_not_committed}
                  unavailable_not_committed={item.not_committed_employees}
                  locked={item.locked}
                />
              );
            });
          }
        });
        this.setState({ resource_list });
      }
    }
  }

  render() {
    return (
      <div className="employee_stats_outer firestorm_overview">
        <div className="inner_head">
          <h2>
            Firestorm Overview <span className="drag-icon-controller"></span>
          </h2>
          <span
            className="toggle-controller"
            aria-owns="overview_main_details"
            onClick={(event) => {
              this.props.toggle(event, this.props.widgetid);
            }}
          ></span>
        </div>
        <div
          className="crew_details content_div"
          aria-expanded={this.props.visibility == 1 ? "true" : "false"}
          id="overview_main_details"
        >
          <div className="crew_list">{this.state.resource_list}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { resource } = state;
  return {
    resource,
  };
}

const connectedHomePage = connect(mapStateToProps)(Firefighter);
export { connectedHomePage as Firefighter };
