import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { config } from '../_helpers';
// import { applicantsActions } from '../_actions';
import { manifestActions } from '../_actions';
import '../assets/styles/resource.css';
import './DragDropTouch.js';
import { AlertPopup } from '../_components/AlertPopup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { withRouter } from 'react-router-dom';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;


};

const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) =>{
    return ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid*2,
    margin: `0 0 ${grid}px 0`,
    width: '100%'
    ,


    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : 'grey',

    background: 'white',

    // styles we need to apply on draggables
    ...draggableStyle
}); }

const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 250,
    background: 'transparent'
});

class VehicleList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resource_id: this.props.match && this.props.match.params ? this.props.match.params.id : null,
            vehicles: [],
            h1: this.props.edit ? "Edit Manifest" : "Create Manifest",
            alertPop: false,
            items: '',
            selected: '',
            type: this.props.type,
        }
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.previous = this.previous.bind(this);
        this.finalize = this.finalize.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.goBack = this.goBack.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };


    finalize() {
        if(this.state.items.length > 0 || this.state.type == 5){

            var all = this.state.items;
            this.state.selected.map((item, index)=>{
                all.push(item);
                return all;
            })
            this.props.next(all)
        } else{
            // alert("Select at least 1 vehicle")
            this.setState({
                alertPop: true,
                alertHead: "Error",
                alertMsg: "Select at least 1 vehicle",
                alertFunction: ""
            })
        }

    }

    closeAlert(){
        this.setState({
            alertPop: false
        })
    }

    previous() {
        this.props.previous(this.props.crews);
    }

    goBack() {
        this.props.cancel(this.state.resource_id)
    }

    componentDidMount() {
        var selectedList = [], leftoutList = [];
        if(!this.props.edit) {
            if(!this.props.prev) {
                let data = new FormData();
                data.append('resource_id', this.state.resource_id);
                this.props.dispatch(manifestActions.getVehicles(data));
            }
            else {
                if(this.props.SelVehicles){
                    this.props.SelVehicles.forEach ((t) => {
                        if(t.category === "selected"){
                            selectedList.push(t)
                        } else {
                            leftoutList.push(t)
                        }
                    });
                    this.setState({
                        items: selectedList,
                        selected: leftoutList
                    });
                }
                // this.setState({ vehicles:this.props.vehicles.list.data });
            }
        }
        else {
            if(this.props.prev) {
                if(this.props.SelVehicles){
                    this.props.SelVehicles.forEach ((t) => {
                        if(t.category === "selected"){
                            selectedList.push(t)
                        } else {
                            leftoutList.push(t)
                        }
                    });
                    this.setState({
                        items: selectedList,
                        selected: leftoutList
                    });
                }
            } else{
            // if(!this.props.prev) {console.log('on edit')
                let data = new FormData();
                data.append('resource_id', this.state.resource_id);
                data.append('manifest_id', this.props.manifest_id);
                this.props.dispatch(manifestActions.getVehicles(data));
            // }
            // else {
                // this.setState({ vehicles:this.props.vehicles.list.data });
            // }
            }
        }

    }

    componentWillReceiveProps(nextProps){
        const { vehicles } = nextProps
        var selectedList = [], leftoutList = [];
        if(vehicles.vehicleList) {
            const { data } = vehicles.vehicleList
            if(data.length > 0 || this.state.type == 5) {
                data.forEach ((t) => {
                    if(t.category === "selected"){
                        selectedList.push(t)
                    } else {
                        leftoutList.push(t)
                    }
                });
                // this.setState({ crews:data, oncrewList, offcrewList });
                this.setState({
                    items: selectedList,
                    selected: leftoutList
                });

                // this.setState({ vehicles:data });
            } else{
                this.setState({
                    alertPop: true,
                    alertHead: "Error",
                    alertMsg: "Assign drivers to vehicle",
                    alertFunction: this.goBack.bind(this)
                })
            }
        }
    }

    onDragStart(ev,id) {
        ev.dataTransfer.setData("id",id)
    }

    onTouchStart(ev,id) {
        this.setState({ touch_id:id })
    }

    onDragOver(ev) {
        ev.preventDefault();
    }

    onTouchMove(ev) {
        // ev.preventDefault();
    }

    onDrop(ev, cat) {
        let id = ev.dataTransfer.getData("id");
        let vehicles = this.state.vehicles.filter((vehicle) => {
            if (vehicle.id == id) {
                vehicle.category = cat;
            }
             return vehicle;
        });
        this.setState({ ...this.state, vehicles });
    }

    onTouchEnd(ev, cat) {
        let id = this.state.touch_id;
        let crews = this.state.crews.filter((crew) => {
            if (crew.hash == id) {
                crew.category = cat;
            }
            return crew;
        });
        this.setState({ ...this.state, crews });
    }

    onImageError(e){
        e.target.setAttribute('src', config.apiUrl + "/" + config.altImg);
    }

    getList = id => this.state[this.id2List[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }
            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2
            });
        }
        this.state.selected.forEach ((t) => {
            t.category = "leftout"
        });
        this.state.items.forEach ((t) => {
            t.category = "selected"
        });
    };

    render() {
        return (
            <div className="applicants_container applicant_listing vehicle_manifest">
                <div className="breadcrumbs"><Link to={"/resources"} className="green">Resources</Link> / <span onClick={this.goBack.bind(this)}>Details</span> / {this.state.h1}</div>
                <div className="page_title float"><h1>{this.state.h1}</h1></div>
                <div className="manifest_type">
                    <div className="inner_head"><h3>Select Vehicles</h3></div>

                    <div className="content_div inner_container ">
                        <div className="sort_crew_div">
                            <div className="drag_sort_wrap">
                                <div>
                                <div className="row-flex">
                                    <div className="heading-section task-header">
                                    Selected Vehicles
                                    </div>
                                    <div className="heading-section task-header">
                                    Left Out Vehicles
                                    </div>
                                </div>
                                <div className="__drag-section">

                                <DragDropContext onDragEnd={this.onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div className="selected drag_manifest_box dragConList"
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.items&&
                                                    this.state.items.map((item, index) => (
                                                        <Draggable
                                                            key={index}
                                                            draggableId={index}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="draggable"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                <span className="pos_hold"><b>{index+1}</b></span>
                                                                <img src={item.pic ? config.s3BucketUrl+item.pic : config.apiUrl+"/"+config.altImg} alt={item.last_name} onError={this.onImageError.bind(this)} /><span>{item.name}</span>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                        <Droppable droppableId="droppable2" >
                                            {(provided, snapshot) => (
                                                <div className="droppable droppable drag_manifest_box dragConList"
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}>
                                                    {this.state.selected &&
                                                    this.state.selected.map((item, index) => (
                                                        <Draggable
                                                            key={index+'jj'}
                                                            draggableId={index+'jj'}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="draggable"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                    <span className="pos_hold"></span><img src={item.pic ? config.s3BucketUrl+item.pic : config.apiUrl+"/"+config.altImg} alt={item.last_name} onError={this.onImageError.bind(this)} /><span>{item.name}</span>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    </div>
                                </div>
                            </div>
                            <div className="clearFix"></div>
                            <div className="btn_list">
                                <button className="button grey" onClick={this.previous.bind(this)}>Previous</button>
                                <button className="button" onClick={this.finalize.bind(this)}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.alertPop &&
                    <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { vehicles } = state;
    return {
        vehicles
    };
}

const connectedManifest = withRouter(connect(mapStateToProps)(VehicleList));
export { connectedManifest as VehicleList };
