import { assignmentStatsConstants } from '../_constants';



const INITIAL_DATA = {
  persist: {
    complexity: [],
    fireSize: [],
    fuelType: []
  }
}

export function assignmentStats(state = INITIAL_DATA, action) {

    switch (action.type) {
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_REQUEST:
            return {
              persist: state.persist,
              loading: true,       
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_SUCCESS:
            return {
                ...state,
                types: action.applicantStats
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSTYPE_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_REQUEST:
            return {
              persist: state.persist,
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_SUCCESS:
            return {
                ...state,
                applicantStats: action.applicantStats
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUS_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_REQUEST:
            return {
              persist: state.persist,
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_SUCCESS:
            return {
                ...state,
                assignmentHistory: action.assignmentHistory
            };
        case assignmentStatsConstants.GETASSIGNMENTHISTORY_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_REQUEST:
            return {
              persist: state.persist,
                loading: true
            };
        case assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_SUCCESS:
            return {
                ...state,
                assignmentStatusSaved: action.assignmentStatusSaved
            };
        case assignmentStatsConstants.ASSIGNMENT_STATUS_SAVED_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_REQUEST:
            return {
              persist: state.persist,
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_SUCCESS:
            return {
                ...state,
                assignmentStatusGraph: action.assignmentStatusGraph
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSGRAPH_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_REQUEST:
            return {
              persist: state.persist,
                loading: true
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_SUCCESS:
            return {
                ...state,
                assignemntStatusProgress: action.assignemntStatusProgress
            };
        case assignmentStatsConstants.GETASSIGNMENTSTATUSPROGRESS_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };
        case assignmentStatsConstants.DELETEHISTORY_SUCCESS:
            return {
                ...state,
                historyDelete: true
            };
        case assignmentStatsConstants.DELETEHISTORY_FAILURE:
            return {
              persist: state.persist,
                error: action.error
            };

      // Complexity
      case assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_REQUEST:
        return {
          persist: state.persist,
          loading: true,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_SUCCESS:
        return {
          ...state,
          persist: {...state.persist,complexity: action.data},
          loading: false,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_COMPLEXITY_FAILURE:
        return {
          persist: state.persist,
          error: action.error,
        };
      // Fire Size
      case assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_REQUEST:
        return {
          persist: state.persist,
          loading: true,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_SUCCESS:
        return {
          ...state,
          persist: {...state.persist,fireSize: action.data},
          loading: false,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_FIRE_SIZE_FAILURE:
        return {
          persist: state.persist,
          error: action.error,
        };
      // Fuel Type
      case assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_REQUEST:
        return {
          persist: state.persist,
          loading: true,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_SUCCESS:
        return {
          ...state,
          persist: {...state.persist,fuelType: action.data},
          loading: false,
        };
      case assignmentStatsConstants.GET_ASSIGNMENT_FUEL_TYPE_FAILURE:
        return {
          persist: state.persist,
          error: action.error,
        };

        default:
            return state
    }
    //
}