import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isMileageLoading } from "_helpers/reduxSelector";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { formatDate } from "_helpers/helpers";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  idClass: ASC_CLASS,
  soldDateClass: ASC_CLASS,
  soldValueClass: ASC_CLASS,
  resourceClass:ASC_CLASS
};

const MileageHobbsList = (props) => {
  const {
    data,
    setSortParams,
    getMileageHobbsReport,
    fromDate,
    toDate,
    lastPage,
  } = props;

  const [{ idClass, soldDateClass, soldValueClass,resourceClass }, setStyleState] =
    useState(initialStyleState);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("equipment_name");
  const [page, setPage] = useState(0);

  const isLoading = useSelector((state) => isMileageLoading(state));

  const handlePageChange = (newPage) => {
    const sortOrder = order ? "asc" : "desc";
    getMileageHobbsReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected+1);
  };

  const noDataAvailable = !isLoading && !data.length;

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getMileageHobbsReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setPage(0);
  }, [fromDate, toDate]);


  return (
    <>
    <div className="history_lists outer_list table__scroll">
      <table className="mobile_hide report_list_width_full">
        <thead>
          <tr className="report_list_table_row">
            <th onClick={() => sortList("idClass", idClass, "equipment_name")}>
              <span>Inventory</span>
              <span className={idClass} />
            </th>
            <th
              onClick={() =>
                sortList("soldDateClass", soldDateClass, "mileage")
              }
            >
              <span>Mileage/Hobbs</span>
              <span className={soldDateClass} />
            </th>
            <th
              onClick={() =>
                sortList("resourceClass", resourceClass, "resource_name")
              }
            >
              <span>Resource</span>
              <span className={soldDateClass} />
            </th>
            <th
              onClick={() =>
                sortList("soldValueClass", soldValueClass, "mileage_date")
              }
            >
              <span>Date</span>
              <span className={soldValueClass} />
            </th>
          </tr>
        </thead>
        <tbody className="report_list_table_tbody">
          {noDataAvailable ? (
            <tr>
              <td colSpan="3">
                <div className="dashboard_expiration_date_no_data">
                  No Data Found
                </div>
              </td>
            </tr>
          ) : (
            data?.map((item, index) => {
              const name =
                item.equipment_type_id === 1 ? item.name : item.unique_id;
              return (
                <tr key={index}>
                  <td>{name??"-"}</td>
                  <td>{item?.mileage || "-"}</td>
                  <td>{item?.resource_name || "-"}</td>
                  <td>{formatDate(item?.mileage_date)}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {/* Mobile */}
      {noDataAvailable ? (
        <div className="dashboard_expiration_date_no_data for_mobile_history_list">
          No Data Found
        </div>
      ) : (
        <MileageHobbsListMobileView
          data={data}
          getMileageHobbsReport={getMileageHobbsReport}
          setSortParams={setSortParams}
        />
      )}

      
    </div>
    {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page ? page-1:page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MileageHobbsList;

const MileageHobbsListMobileView = ({
  data,
  getMileageHobbsReport,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("equipment_name");
  const [{ idClass, soldDateClass, soldValueClass }, setStyleState] =
    useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      getMileageHobbsReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("idClass", idClass, "equipment_name")}
            >
              <strong>Inventory</strong>
              <span className={idClass} />
            </div>
            <div className="list_item">{item?.unique_id ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("soldValueClass", soldValueClass, "mileage_date")
              }
            >
              <strong>Mileage/Hobbs</strong>
              <span className={soldDateClass} />
            </div>
            <div className="list_item">{item?.sold_date ?? "-"}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("soldValueClass", soldValueClass, "mileage_date")
              }
            >
              <strong>Date</strong>
              <span className={soldValueClass} />
            </div>
            <div className="list_item">{item?.sold_value ?? "-"}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};
