import React, { useEffect, useState } from "react";
import styles from "./SystemNotificationsTabContent.module.scss";
import { ReactComponent as DropDownToggle } from "assets/icons/expand_more.svg";
import { ReactComponent as Add } from "assets/icons/add-plus-green.svg";
import { Table } from "react-bootstrap";
import ReactModal from "react-modal";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { resourceActions } from "_actions";
import { useDispatch, useSelector } from "react-redux";
import { CreatableAsyncSelectMulti } from "_components/utils/CreatableAsyncSelectMulti/CreatableAsyncSelectMulti";
import { handleErrorResponse, validateEmail } from "_helpers/helpers";
import { profileActions } from "_actions/profile.actions";
import { toast } from "react-toastify";

export const SystemNotificationsTabContent = () => {
  let timeout;
  const dispatch = useDispatch();
  const resourceTypes = useSelector(
    (state) => state.resource.resourceTypes
  )?.map((resource) => ({ ...resource, label: resource.value }));

  const [isTableOpen, setIsTableOpen] = useState(false);
  const [isAddModalIOpen, setIsAddModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modifyResourceItem, setModifyResourceItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [emails, setEmails] = useState([]);
  const [resources, setResources] = useState([]);
  const [sortOrder, setSortOrder] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const [isAddEditLoading, setIsAddEditLoading] = useState(false);
  const [selectedResource, setSelectedResource] = useState({
    resource_id: "",
    resource_type_id: "",
  });

  const loadResources = (value, callback) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      let data = new FormData();
      data.append("keyword", value);
      const resources = await dispatch(resourceActions.searchResource(data));

      const options = []
        .concat(...Object.values(resources?.data))
        .map((opt) => ({
          ...opt,
          label: opt.resource_name,
          value: opt.resource_name,
        }));
      callback(options);
    }, 500);
  };

  const loadEmployeeEmails = (inputValue, callback) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      let data = new FormData();
      data.append("keyword", inputValue);
      const resources = await dispatch(resourceActions.employeeEmailList(data));
      const options = resources?.data?.data.map((opt) => ({
        ...opt,
        label: opt.email,
        value: opt.email,
      }));

      const optionNotNew = options?.find(
        (option) => option.label === inputValue
      );

      if (validateEmail(inputValue) && !optionNotNew) {
        callback([{ label: inputValue, value: inputValue }]);
      } else {
        callback(options);
      }
    }, 500);
  };

  const onSelectEmail = (data) => {
    setEmails(data);
  };

  const onDeleteResource = (resource) => {
    setModifyResourceItem(resource);
    setIsDeleteModalOpen(true);
  };

  const onClickDeleteConfirm = async () => {
    const reqData = new FormData();
    reqData.append("id", modifyResourceItem.id);
    const res = await dispatch(profileActions.deleteRecipientForTor(reqData));
    if (res.success) {
      toast.success(res.message);
      setIsDeleteModalOpen(false);
      fetchSystemNotifications();
      return;
    }
    handleErrorResponse(res);
  };

  const onEditResource = (resource) => {
    setIsEditMode(true);
    setModifyResourceItem(resource);
    setSelectedResource({
      resource_id: resource.resource_id,
      resource_type_id: resource.resource_type_id,
    });
  };

  const onCancelEdit = () => {
    setIsEditMode(false);
    setModifyResourceItem(null);
  };

  const handleResourceTypeSelect = (selected, name) => {
    setSelectedResource({ ...selectedResource, [name]: selected?.id });
  };

  const onClickAdd = async () => {
    const reqData = new FormData();
    reqData.append("notification_type", 1);
    if (selectedResource.resource_id)
      reqData.append("resource_id", selectedResource.resource_id);
    if (selectedResource.resource_type_id)
      reqData.append("resource_type_id", selectedResource.resource_type_id);

    if (emails.length) {
      emails.forEach((email, index) =>
        reqData.append(`emails[${index}]`, email?.email || email.value)
      );
    }
    setIsAddEditLoading(true);
    const res = await dispatch(profileActions.addRecipientForTor(reqData));
    if (res.success) {
      toast.success(res.message);
      setIsAddModalOpen(false);
      fetchSystemNotifications();
      setIsEditMode(false);
      setIsSaveModalOpen(false);
      setIsAddEditLoading(false);
      setSelectedResource({resource_id:"",resource_type_id:""})
    } else {
      setIsAddEditLoading(false);
      handleErrorResponse(res);
    }
  };

  const sortTable = (name) => {
    setSortOrder(!sortOrder);
  };

  const fetchSystemNotifications = async () => {
    const reqData = new FormData();
    reqData.append("notification_type", 1);
    reqData.append("sort", "name");
    reqData.append("order", sortOrder ? "asc" : "desc");
    setIsListLoading(true);
    const res = await dispatch(profileActions.getRecipientsList(reqData));
    if (res.success) {
      setResources(res.data);
      setIsListLoading(false);
    }
  };

  const renderDefaultValues = (emails) => {
    if (emails?.length) {
      return emails.map((email) => ({ label: email, value: email }));
    } else return null;
  };

  useEffect(() => {
    fetchSystemNotifications();
    dispatch(resourceActions.getResourceTypes());
  }, [sortOrder]);

  return (
    <div className={styles.SystemNotificationsTabContent}>
      {/* Add resource */}
      <ReactModal
        isOpen={isAddModalIOpen}
        className={styles.AddResourceModal}
        overlayClassName={"systemNotification__modal"}
      >
        <div className="modal-head">
          <h4>Update Applicant Status</h4>
        </div>
        <div className="add-modal-body">
          <div className="select-box">
            <strong>Resource Type</strong>
            <Select
              className="select-without-search"
              isDisabled={selectedResource.resource_id}
              isClearable
              isSearchable={false}
              options={resourceTypes}
              onChange={(selected) =>
                handleResourceTypeSelect(selected, "resource_type_id")
              }
            />
          </div>
          <span>OR</span>
          <div className="select-box">
            <strong>Resource</strong>
            <AsyncSelect
              isDisabled={selectedResource.resource_type_id}
              loadOptions={loadResources}
              onChange={(selected) =>
                handleResourceTypeSelect(selected, "resource_id")
              }
              isClearable
            />
          </div>
          <div className="select-box hr__top">
            <strong>Email Address</strong>
            <CreatableAsyncSelectMulti
              onChange={onSelectEmail}
              placeholder="Enter email address"
              loadOptions={loadEmployeeEmails}
            />
          </div>
        </div>
        <div className="add-modal-footer">
          <button
            className="button grey"
            onClick={() => setIsAddModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={onClickAdd}
            disabled={isAddEditLoading}
          >
            Add
          </button>
        </div>
      </ReactModal>

      {/* Confirm Delete */}
      <ReactModal
        isOpen={isDeleteModalOpen}
        className={styles.ConfirmDeleteModal}
        overlayClassName={"systemNotification__modal"}
      >
        <div className="delete-modal-head">
          <h4>Removal Confirmation</h4>
        </div>

        <div className="delete-modal-body">
          <p>
            Are you sure you want to remove{" "}
            <strong>Fuels Resource / Fuel Crew 01Z</strong> from the table? This
            action cannot be undone.
          </p>
        </div>
        <div className="delete-modal-footer">
          <button
            className="button grey"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="button"
            onClick={onClickDeleteConfirm}
            disabled={isAddEditLoading}
          >
            Delete
          </button>
        </div>
      </ReactModal>

      {/* Confirm save */}
      <ReactModal
        isOpen={isSaveModalOpen}
        className={styles.ConfirmSaveModal}
        overlayClassName={"systemNotification__modal"}
      >
        <div className="save-modal-head">
          <h4>Are you sure want to save?</h4>
        </div>

        <div className="save-modal-body">
          <p>
            If the email address field is left empty, the time off request would
            be forwarded to the admin.
          </p>
        </div>
        <div className="save-modal-footer">
          <button
            className="button grey"
            onClick={() => setIsSaveModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="button"
            disabled={isAddEditLoading}
            onClick={onClickAdd}
          >
            Update
          </button>
        </div>
      </ReactModal>

      {isListLoading && (
        <center id="feedLoader">
          <img alt="Loader" src={require("assets/images/loader.gif").default} />
        </center>
      )}

      <div
        className="tor-info-banner"
        onClick={() => setIsTableOpen(!isTableOpen)}
      >
        <strong>Time Off Request</strong>
        <p className="description">
          In this section, you can define the recipient(s) to whom an employee’s
          approved time off request should be forwarded. If no forwarding
          recipient is specified, the approved time off request will be
          automatically sent to the admin or superintendents for handling.
        </p>
        <div className={`dropdown ${isTableOpen && "open"}`}>
          <DropDownToggle />
        </div>
      </div>
      {isTableOpen && (
        <div className="sys-notifications-table">
          {!isListLoading && (
            <Table responsive>
              <thead>
                <tr>
                  <th
                    className={`${sortOrder && "arrow_up"}`}
                    onClick={() => sortTable("resources")}
                  >
                    Resource/Resource Type
                  </th>
                  <th>Email address</th>
                  <th></th>
                  <th>
                    <Add onClick={() => setIsAddModalOpen(true)} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {resources.map((item) => (
                  <tr key={item.id}>
                    <td>{item?.resource?.resource_name || item?.resource_type?.value || "-"}</td>
                    {modifyResourceItem?.id === item.id && isEditMode ? (
                      <td className="inline-select">
                        <div className="email__select">
                          <CreatableAsyncSelectMulti
                            onChange={onSelectEmail}
                            placeholder="Enter email address"
                            loadOptions={loadEmployeeEmails}
                            defaultValue={() =>
                              renderDefaultValues(item.emails)
                            }
                          />
                          <div className="button__div">
                            <button
                              className="button grey"
                              onClick={onCancelEdit}
                            >
                              Cancel
                            </button>
                            <button
                              className="button"
                              onClick={() => setIsSaveModalOpen(true)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td>{item.emails?.map((email) => email).join(", ")}</td>
                    )}
                    {!modifyResourceItem || !isEditMode ? (
                      <>
                        <td onClick={() => onDeleteResource(item)}>Remove</td>
                        <td onClick={() => onEditResource(item)}>Edit</td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}
    </div>
  );
};
