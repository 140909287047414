export const getCurrentUserInfo = (state) => state?.user?.profile?.data;

export const getSelectedUserInfo = (state) => state?.user?.items?.data;

export const getAllResources = (state) => state?.resource?.items?.data ?? {};

export const getUsersExpirationDates = (state) => state?.dashboard?.usersExpirationDates;

export const getAssignmentStats = (state) => state?.assignmentStats;

export const getAssignmentStatusTypes = (state) => state?.assignmentStats?.types;

// All Qualifications
export const getAllQualifications = (state) => state?.qualification?.qualificationList;
export const qualDeletedError = (state) => state?.qualification?.isDeleteQualificationError;
export const qualEditedError = (state) => state?.qualification?.isEditQualificationError;
export const qualCreatedError = (state) => state?.qualification?.isCreateQualificationError;
export const qualFieldValidationError = (state) => state?.qualification?.qualFieldValidationError;

export const getQualifications = (state) => state?.dashboard?.qualifications;
export const getQualificationReport = (state) => state?.dashboard?.qualificationReport;
export const isUsersExpirationDatesLoading = (state) => state?.dashboard?.isUsersExpirationDatesLoading;

export const isQualificationReportLoading = (state) => state?.dashboard?.isQualificationReportLoading;

// export const getVehicleReport = (state) => state?.dashboard?.vehicleReport;
// export const isVehicleReportLoading = (state) => state?.dashboard?.isVehicleReportLoading;

// export const getEmployeesBirthdays = (state) => state?.dashboard?.employeeBirthdays;
// export const isEmployeeBirthdayLoading = (state) => state?.dashboard?.isEmployeeBirthdayLoading;

export const getEquipmentSoldReport = (state) => state?.dashboard?.equipmentSoldReport;
export const isEquipmentSoldReportLoading = (state) => state?.dashboard?.isEquipmentSoldReportLoading;

export const getInterviewFormList = (state) => state?.interview?.list?.interview_forms;
export const getInterviewForm = (state) => state?.interview?.form;
export const isInterviewFomLoading = (state) => state?.interview?.loading;
// export const isQualificationReportLoading = (state) => state?.dashboard?.isQualificationReportLoading

export const getVehicleReport = (state) => state?.dashboard?.vehicleReport
export const isVehicleReportLoading = (state) => state?.dashboard?.isVehicleReportLoading

export const getAffirmativeActionReport = (state) => state?.dashboard?.affirmativeActionReport
export const isAffirmativeActionReportLoading = (state) => state?.dashboard?.isAffirmativeActionReportLoading

export const getEmployeesBirthdays = (state) => state?.dashboard?.employeeBirthdays
export const isEmployeeBirthdayLoading = (state) => state?.dashboard?.isEmployeeBirthdayLoading

export const getResourceOrderFormsFromStore = (state) => state?.dashboard?.resourceOrderForms
export const isResourceOrderFormsLoading = (state) => state?.dashboard?.resourceOrderFormLoading

export const selectAllResourceOrderForm = (state) => state?.dashboard?.allResourceOrderForm;
export const selectIsAllResourceOrderFormLoading = (state) =>  state?.dashboard?.isAllResourceOrderFormLoading;
export const selectSelectedManifestRofId = (state) => state?.dashboard?.selectedManifestRofId;
export const selectSelectedManifestRofType = (state) => state?.dashboard?.selectedManifestRofType;

export const getCreatedResourceOrderFormsFromStore = (state) => state?.dashboard?.createdResourceOrderForm
export const isCreatedResourceOrderFormsLoading = (state) => state?.dashboard?.createdResourceOrderFormsLoading
export const getAllInventoryReport = (state) => state?.dashboard?.allInventoryReport;
export const isAllInventoryReportLoading = (state) => state?.dashboard?.isAllInventoryReportLoading;



export const selectInventoryEquipments = (state) => state?.inventory?.equipments;


// April2022

export const selectCurrentUserPermissions = (state) => state?.permission?.permissions?.data ?? []
export const selectRefresherReports = (state) => state?.dashboard?.refresherReports;
export const selectMasterList = (state) => state?.dashboard?.masterListReport;

export const certificates = (state) => state?.docs?.certificates;

// Get Qualifications
export const selectQualifications = (state) => state?.createProfile?.listAll?.Qualification ?? []
export const generalQualifications = (state) => state?.createProfile?.listAll?.GeneralQualification ?? []
export const listLSA = (state) => state?.createProfile?.listAll?.Lsa?? []

export const issuingAuthorities = (state) => state?.createProfile?.listAll?.ListIssuingAuthority ?? []
export const selectUserRoles = (state) => state?.user?.userRoles ?? []
export const selectVerificationDetails = (state) => state?.employee?.items?.data ?? null

export const getCertificatesTypes = (state) => state?.createProfile?.listAll?.ListCertificateType ?? []

export const getResourceList = (state) => state?.createProfile?.listAll?.FireFighter ?? []

//Mileage Hobbs Report
export const isMileageLoading = state=> state.dashboard.mileageHobbsListLoading
export const mileageHobbsReport = state=> state.dashboard.mileageHobbsReport

export const isApplicantsReportLoading = state=> state.dashboard.applicantsReportLoading
export const applicantsReport = state=> state.dashboard.applicantsReport
export const fireHistory = state=> state.dashboard.fireHistoryReport;
export const isFireHistoryLoading = state=> state.dashboard.fireHistoryReportLoading;




export const selectAssignedInventoryList = (state) => state?.inventory?.inventory?.data ?? []

export const selectFireHistoryReport = (state) => state?.dashboard?.individualFireHistory;
export const selectFireHistoryReportLoading = (state) => state?.dashboard?.individualFireHistoryLoading;
export const selectEmployeesFireHistoryReport = (state) => state?.dashboard?.employeesFireHistoryReport;
export const selectEmployeesFireHistoryReportLoading = (state) => state?.dashboard?.isEmployeesFireHistoryReportLoading;

export const selectJobApplicant = state => state?.applicants?.list?.data


export const selectUserDocs = (state) => state?.docs?.userDocs

export const selectInventoryNotes = (state) => state?.inventory?.inventoryNotes?.data ?? []

export const selectInventoryMileage = (state) => state?.inventory?.inventoryMileage?.data ?? []


export const docsStatusAllEmpsLoading = state=>state?.dashboard?.docsStatusReportAllUsersLoading
export const docsStatusAllEmps = state=>state?.dashboard?.docsStatusReportAllUsers || []
export const docsStatusAllEmpsError = state=>state?.dashboard?.docsStatusReportAllUsersError || {}

export const imBulkImportLoading = (state) =>
  state.user.isImportEmployeesLoading;

export const isActivityLogLoading = state=>state?.dashboard?.activityLogLoading
export const activityLog = state=>state?.dashboard?.activityLog
export const activityLogError = state=>state?.dashboard?.activityLogError 

export const filteredEmployees = (state) => state?.employee?.list ?? null

export const resourceTypes = state=>state.resourceTypes;
export const isResourceRecipientsLoading = state=>state.loading;
export const recipients = state=>state.recipients;

