import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "_helpers/helpers";

const _assignmentStatus = (status) => {
  let status_text;
  switch (status) {
    case 1:
      status_text = "Available";
      break;
    case 2:
      status_text = "Unavailable";
      break;
    case 3:
      status_text = "Committed";
      break;
  }
  return status_text;
};

const ListView = ({ items }) => {
  return (
    <div className="container-fluid resource_list_container">
      <div className=" outer_list resource_list">
        {/* Table Head */}
        <table className="table table-hover resource_list_table">
          <thead className="resource_list-head">
            <td >Resource</td>
            <td >Contract Number</td>
            <td >Status</td>
            <td>Type of Assignment</td>
            <td>Incident Name</td>
            <td>Location</td>
            <td>Complexity</td>
            <td>Fuel Type</td>
            <td>Fire Size</td>
            <td>Date Started</td>
          </thead>
          <tbody>
            {/* Table Body */}
            {items.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.resource_name? item.resource_name : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.contract_number ? item.contract_number : '-'}
                    </Link>
                  </td>
                  <td className=" ">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {_assignmentStatus(item.assignment_status)}
                      {item.assignment_status == 2 && item.unavailable_reason && (
                        <span>
                          <small> ({item.unavailable_reason})</small>
                        </span>
                      )}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.assignment_type ? item.assignment_type : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.project_name ? item.project_name : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.location ? item.location : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.complexity ? item.complexity : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.fuel_type ? item.fuel_type : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.fire_size ? item.fire_size : '-'}
                    </Link>
                  </td>
                  <td className="">
                    <Link to={"/resources/" + item.id} target="_blank" className="list-view">
                      {item.date_on ? formatDate(item.date_on)  : '-'}
                    </Link>
                  </td>
                
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { ListView };
