/* eslint-disable eqeqeq */
import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
//import { usPhone } from "_utils/formatter";
import { toast } from "react-toastify";
//import { docsService } from "_services/docs.service";
import moment from "moment";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import { SignPad } from "Docs/Components";
import { getCurrentUserInfo } from "_helpers/reduxSelector";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";

class DisclosureStatement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rehire_doc: false,
      user: {
        mailing_address: {},
      },
      form: {
        begin_date: moment().format("MM/DD/YYYY"),
        end_date: moment().format("MM/DD/YYYY"),
        signed_date: moment().format("MM/DD/YYYY"),
        employer_signed_date: moment().format("MM/DD/YYYY"),
        rate_of_pay: "hourly_rate",
        hourly_rate_desc: "$15 - Unless otherwise stated",
        bonuses: "no",
        personal_loans: "no",
        day_care_services_check: "no",
        disclaimer_check: false,
        employee_signature: null,
        full_name: " ",
      },
      errors: {},
      form_edited: false,
    };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.validate = validate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }

  handleFormChange(e) {
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }

  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
  }

  handleDateChange(field, date) {
    let nextState = this.state;
    if (date){
    _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
  }

  submitForm(e) {
    e.preventDefault();

    let err = this.validate();

    if (err) {
      return;
    }

    let { form, doc } = this.state;

    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };

    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc ? (data.single_doc = true) : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);

    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }

  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user} = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.signed_date = form.signed_date || currentState.form.signed_date;
        form.employer_signed_date =
          form.employer_signed_date || currentState.form.employer_signed_date;
        form.begin_date = moment("06 15", "MM DD"); // form.begin_date || currentState.form.begin_date;
        form.end_date = moment("10 15", "MM DD"); //form.end_date || currentState.form.end_date;

        form.working_hours = "Varies"; // static as per requirements
        form.special_conditions = "N/A"; // static as per requirements
        form.no_cost_equipemnt_check = true;
        form.necessary_equipment_check = true;
        form.no_cost_equipemnt = "See Attached Job Description";
        form.necessary_equipment = "See Attached Job Description";
        form.labor_dispute = "yes";
        form.other_work_conditions = "See Attached Job Description";


        if (!form?.employee_printed_name) {
          form.employee_printed_name = user?.full_name ?? ""
        }

        // spread the data coming from API into state
        this.setState({
          form,
        });
      }
    });
  }

  goBack() {
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true)
    } else {
      this.props.setScreen('sidebar');
    }
  }

  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }
    if (this.props.giss) {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 5,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 4,
        );
      }
    } else {
      if (this.props.rehire) {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
        );
      } else {
        doc = this.props.docs.userDocs.find(
          (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
        );
      }
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }

  render() {
    let is_admin,
      { form, errors } = this.state;
    const { adminMode ,currentUserInfo} = this.props;
    
    if (this.props.user && this.props.user.profile) {
      is_admin = this.props.user.profile.data.is_admin;
    }

    
    const shouldLockDocs = checkIfDocsShouldBeLocked(currentUserInfo, form, adminMode)

    return (
      <div className='doc_info-rh'>
        {/* Title */}
        <div className='doc-title'>
          <img src={`${config.assetUrl}icons/doc-title-img.svg`} className='doc-title-img' />
          <span className='close-button' onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>Disclosure Statement &amp; Work Agreement</h3>
        </div>
        <div className='doc_info-form'>
          <form
            className='info-form'
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            noValidate>
         <fieldset disabled={shouldLockDocs}>

            <div className='row'>
              <div className='col-md-12'>
                <p>
                  Labor contractors must provide a written statement disclosing the terms and
                  conditions of employment to workers at the time they are hired, recruited or
                  solicited or at the time they are supplied to another by that contractor,
                  whichever occurs first. Additionally, labor contractors must execute a written
                  agreement with each of their workers prior to the start of work. A copy of the
                  agreement must be furnished to each worker prior to starting work.
                </p>
                <p>
                  This form, together with WH-151 summarizing certain rights of workers, may be used
                  to disclose terms of employment for recruitment purposes and, when signed by both
                  the contractor and the worker, may also be used as a written agreement regarding
                  the terms of employment.
                </p>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    {" "}
                    Rate of Pay <span className='required-star'>&#x2A;</span>
                  </div>
                  <p>This job will be paid at the following rate:</p>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='rate_of_pay'
                        value='hourly_rate'
                        id='rate_of_pay'
                        className='discl_input'
                        field='form.rate_of_pay'
                        checked={form.rate_of_pay === "hourly_rate"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='rate_of_pay'>
                        <strong>Hourly Rate</strong>
                      </label>
                    </div>
                    {form.rate_of_pay === "hourly_rate" && (
                      <div className='inputs-col  inputs-col-g  mar-bot-0'>
                        <div className='inputs-items'>
                          <textarea
                            className={`discl_input  ${
                              errors["form.hourly_rate_desc"] ? "error" : ""
                              } `}
                            type='text'
                            name='hourly_rate_desc'
                            id='hourly_rate_desc'
                            placeholder='Enter here'
                            value={form.hourly_rate_desc}
                            field='form.hourly_rate_desc'
                            required
                            // disabled={!is_admin}
                            disabled
                          />
                          {errors["form.hourly_rate_desc"] && (
                            <label htmlFor='hourly_rate_desc' className='error_label'>
                              {errors["form.hourly_rate_desc"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='rate_of_pay'
                        value='piece_work_rate'
                        id='piece_work_rate_of_pay'
                        className='discl_input'
                        field='form.rate_of_pay'
                        checked={form.rate_of_pay === "piece_work_rate"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='piece_work_rate_of_pay'>
                        <strong>Piece-work Rate</strong>
                      </label>
                    </div>
                    {form.rate_of_pay === "piece_work_rate" && (
                      <div className='inputs-col  inputs-col-g'>
                        <div className='inputs-items'>
                          <textarea
                            className={` discl_input ${
                              errors["form.piece_work_desc"] ? "error" : ""
                              }`}
                            type='text'
                            name='piece_work_desc'
                            id='piece_work_desc'
                            placeholder='Enter here'
                            value={form.piece_work_desc}
                            field='form.piece_work_desc'
                            required
                            // disabled={!is_admin}
                            disabled
                          />
                          {errors["form.piece_work_desc"] && (
                            <label htmlFor='piece_work_desc' className='error_label'>
                              {errors["form.piece_work_desc"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    {" "}
                    Bonuses <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='bonuses'
                        value='yes'
                        id='bonuses'
                        className='discl_input'
                        field='form.bonuses'
                        checked={form.bonuses === "yes"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='bonuses'>
                        <strong>Bonuses will be given under the following conditions</strong>
                      </label>
                    </div>
                    {form.bonuses === "yes" && (
                      <div className='inputs-col  inputs-col-g'>
                        <div className='inputs-items'>
                          <textarea
                            className={` discl_input ${errors["form.bonus_desc"] ? "error" : ""}`}
                            type='text'
                            name='bonus_desc'
                            id='bonus_desc'
                            placeholder='Enter here'
                            value={form.bonus_desc}
                            field='form.bonus_desc'
                            required
                            // disabled={!is_admin}
                            disabled
                          />
                          {errors["form.bonus_desc"] && (
                            <label htmlFor='bonus_desc' className='error_label'>
                              {errors["form.bonus_desc"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='bonuses'
                        value='no'
                        id='no_bonuses'
                        className='discl_input'
                        field='form.bonuses'
                        checked={form.bonuses === "no"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='no_bonuses'>
                        <strong>There will be no bonuses</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Personal Loans <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='personal_loans'
                        value='yes'
                        id='personal_loans'
                        className='discl_input'
                        field='form.personal_loans'
                        checked={form.personal_loans === "yes"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='personal_loans'>
                        <strong>Personal loans will be given under the following conditions</strong>
                      </label>
                    </div>
                    {form.personal_loans === "yes" && (
                      <div className='inputs-col  inputs-col-g'>
                        <div className='inputs-items'>
                          <textarea
                            className={` discl_input ${errors["form.loan_desc"] ? "error" : ""}`}
                            type='text'
                            name='loan_desc'
                            id='loan'
                            placeholder='Enter here'
                            value={form.loan_desc}
                            field='form.loan_desc'
                            required
                            // disabled={!is_admin}
                            disabled
                          />
                          {errors["form.loan_desc"] && (
                            <label htmlFor='loan_desc' className='error_label'>
                              {errors["form.loan_desc"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='personal_loans'
                        value='no'
                        id='no_personal_loans'
                        className='discl_input'
                        field='form.personal_loans'
                        checked={form.personal_loans === "no"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='no_personal_loans'>
                        <strong>There will be no personal loans</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Housing and Day Care Services <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='day_care_services_check'
                        value='yes'
                        id='day_care_services_check'
                        className='discl_input'
                        field='form.day_care_services_check'
                        checked={form.day_care_services_check === "yes"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='day_care_services_check'>
                        <strong>
                          Housing and/or day care services are provided under the following
                          conditions
                        </strong>
                        <br />
                        <span>
                          (Only the fair market value of housing furnished for the private benefit
                          of the employee may be deducted from wages)
                        </span>
                      </label>
                    </div>
                    {form.day_care_services_check === "yes" && (
                      <div className='inputs-col  inputs-col-g'>
                        <div className='inputs-items'>
                          <textarea
                            className={` discl_input  ${
                              errors["form.day_care_desc"] ? "error" : ""
                              }`}
                            type='text'
                            name='day_care_desc'
                            id='day_care_desc'
                            placeholder='Enter here'
                            value={form.day_care_desc}
                            field='form.day_care_desc'
                            required
                            // disabled={!is_admin}
                            disabled
                          />
                          {errors["form.day_care_desc"] && (
                            <label htmlFor='day_care_desc' className='error_label'>
                              {errors["form.day_care_desc"]}
                            </label>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='day_care_services_check'
                        value='no'
                        id='no_day_care_services_check'
                        className='discl_input'
                        field='form.day_care_services_check'
                        checked={form.day_care_services_check === "no"}
                        required
                        // disabled={!is_admin}
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='no_day_care_services_check'>
                        <strong>Housing and day care services are not provided.</strong>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col m-0'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Employment Conditions <span className='required-star'>&#x2A;</span>
                  </div>
                  <p>
                    Your employment under this agreement will begin on and end approximately on the
                    following dates
                  </p>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Begin Date <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    {/* <DatePicker
                      className={`${errors["form.begin_date"] ? "error" : ""} w-100`}
                      name="begin_date"
                      id="begin_date"
                      required
                    //   selected={moment(form.begin_date)}
                      selected={moment("06/15", "MM DD")}
                      field="form.begin_date"
                      placeholder="MM/DD"
                      onChange={this.handleDateChange.bind(this, "form.begin_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    /> */}
                    {/* A Static value set as per requirement */}
                    <p>06/15</p>
                    {errors["form.begin_date"] && (
                      <label htmlFor='begin_date' className='error_label'>
                        {errors["form.begin_date"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    End Date <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    {/* <DatePicker
                      className={`${errors["form.end_date"] ? "error" : ""} w-100`}
                      name="end_date"
                      id="end_date"
                      required
                      selected={moment(form.end_date)}
                      field="form.end_date"
                      placeholder="MM/DD"
                      onChange={this.handleDateChange.bind(this, "form.end_date")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    /> */}
                    {/* A Static value set as per requirement */}
                    <p>10/15</p>
                    {errors["form.end_date"] && (
                      <label htmlFor='end_date' className='error_label'>
                        {errors["form.end_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Working Hours and Days <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      className={` discl_input ${errors["form.working_hours"] ? "error" : ""}`}
                      type='text'
                      name='working_hours'
                      id='working_hours'
                      placeholder=''
                      value={form.working_hours}
                      field='form.working_hours'
                      required
                      disabled
                    />
                    {errors["form.working_hours"] && (
                      <label htmlFor='working_hours' className='error_label'>
                        {errors["form.working_hours"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Special conditions, if any: <span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      className={` discl_input ${errors["form.special_conditions"] ? "error" : ""}`}
                      type='text'
                      name='special_conditions'
                      id='special_conditions'
                      placeholder=''
                      value={form.special_conditions}
                      field='form.special_conditions'
                      required
                      disabled
                    />
                    {errors["form.special_conditions"] && (
                      <label htmlFor='special_conditions' className='error_label'>
                        {errors["form.special_conditions"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>Equipment and Clothing</div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='no_cost_equipemnt_check'
                        value='paychecks'
                        id='no_cost_equipemnt_check'
                        className='discl_input'
                        field='form.no_cost_equipemnt_check'
                        checked={form.no_cost_equipemnt_check}
                        disabled
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='no_cost_equipemnt_check'>
                        <strong>
                          The following necessary equipment and clothing will be provided at no cost
                          by the employer
                        </strong>
                      </label>
                    </div>
                    <div className='inputs-col  inputs-col-g'>
                      <div className='inputs-items'>
                        <textarea
                          className={` discl_input ${
                            errors["form.no_cost_equipemnt"] ? "error" : ""
                            }`}
                          type='text'
                          name='no_cost_equipemnt'
                          id='no_cost_equipemnt'
                          placeholder='Enter here'
                          value={form.no_cost_equipemnt}
                          field='form.no_cost_equipemnt'
                          required={form.no_cost_equipemnt_check}
                          disabled
                        />
                        {errors["form.no_cost_equipemnt"] && (
                          <label htmlFor='no_cost_equipemnt' className='error_label'>
                            {errors["form.no_cost_equipemnt"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='necessary_equipment_check'
                        id='necessary_equipment_check'
                        className='discl_input'
                        field='form.necessary_equipment_check'
                        checked={form.necessary_equipment_check}
                        disabled
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='necessary_equipment_check'>
                        <strong>
                          Necessary equipment and clothing must be provided by each worker.
                          Necessary equipment and clothing for this job is
                        </strong>
                      </label>
                    </div>
                    <div className='inputs-col  inputs-col-g'>
                      <div className='inputs-items'>
                        <textarea
                          className={` discl_input  ${
                            errors["form.necessary_equipment"] ? "error" : ""
                            }`}
                          type='text'
                          name='necessary_equipment'
                          id='necessary_equipment'
                          placeholder='Enter here'
                          value={form.necessary_equipment}
                          field='form.necessary_equipment'
                          required={form.necessary_equipment_check}
                          disabled
                        />
                        {errors["form.necessary_equipment"] && (
                          <label htmlFor='necessary_equipment' className='error_label'>
                            {errors["form.necessary_equipment"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='equipment_prices_check'
                        id='equipment_prices_check'
                        className='discl_input'
                        field='form.equipment_prices_check'
                        checked={form.equipment_prices_check}
                        disabled
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='equipment_prices_check'>
                        <strong>
                          Necessary equipment and clothing may be purchased or borrowed from the
                          employer. The prices and/or conditions for obtaining equipment and
                          clothing are as follows
                        </strong>
                      </label>
                    </div>
                    <div className='inputs-col inputs-col-g'>
                      <div className='inputs-items'>
                        <textarea
                          className={` discl_input ${
                            errors["form.equipment_prices"] ? "error" : ""
                            }`}
                          type='text'
                          name='equipment_prices'
                          id='equipment_prices'
                          placeholder='Enter here'
                          value={form.equipment_prices}
                          field='form.equipment_prices'
                          required={form.equipment_prices_check}
                          disabled
                        />
                        {errors["form.equipment_prices"] && (
                          <label htmlFor='equipment_prices' className='error_label'>
                            {errors["form.equipment_prices"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    Labor Dispute<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='check_radio_group agreement_checks'>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='labor_dispute'
                        id='labor_dispute_yes'
                        className='discl_input'
                        value='yes'
                        field='form.labor_dispute'
                        checked={form.labor_dispute === "yes"}
                        required
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='labor_dispute_yes'>
                        There is no labor dispute at the work site.
                      </label>
                    </div>
                    <div className='check_radio_item'>
                      <input
                        type='radio'
                        name='labor_dispute'
                        id='labor_dispute_no'
                        className='discl_input'
                        value='no'
                        field='form.labor_dispute'
                        checked={form.labor_dispute === "no"}
                        required
                        disabled
                      />
                      <span className='radio_checkbox'></span>
                      <label htmlFor='labor_dispute_no'>
                        There is a labor dispute at the work site.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            {adminMode && (
              <div className='row form-row'>
                <div className='col m-0'>
                  <div className='inputs-col'>
                    <div className='inputs-head'>
                      Owner of Operations<span className='required-star'>&#x2A;</span>
                    </div>
                    <p>For this job, the owner of the land or operation is</p>
                  </div>
                </div>
                <div className='col'>
                  <div className='inputs-col'>
                    <div className='inputs-head'>
                      Name<span className='required-star'>&#x2A;</span>
                    </div>
                    <div className='inputs-items'>
                      <input
                        className={` discl_input  ${errors["form.owner_name"] ? "error" : ""}`}
                        type='text'
                        name='owner_name'
                        id='owner_name'
                        placeholder='Enter here'
                        value={form.owner_name}
                        field='form.owner_name'
                        required
                      />
                      {errors["form.owner_name"] && (
                        <label htmlFor='owner_name' className='error_label'>
                          {errors["form.owner_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='inputs-col'>
                    <div className='inputs-head'>
                      Mailing <span className='required-star'>&#x2A;</span>
                    </div>
                    <div className='inputs-items'>
                      <input
                        className={` discl_input  ${
                          errors["form.owner_address_street"] ? "error" : ""
                          }`}
                        type='text'
                        name='address'
                        id='address'
                        placeholder='Street or Box Number'
                        value={form.owner_address_street}
                        field='form.owner_address_street'
                        required
                      />
                      {errors["form.owner_address_street"] && (
                        <label htmlFor='address' className='error_label'>
                          {errors["form.owner_address_street"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='col'>
                  <div className='inputs-col'>
                    <div className='inputs-items group'>
                      <div className='grouped'>
                        <input
                          className={` discl_input  ${
                            errors["form.owner_address_city"] ? "error" : ""
                            }`}
                          type='text'
                          name='city'
                          id='city'
                          placeholder='City'
                          value={form.owner_address_city}
                          field='form.owner_address_city'
                          required
                        />
                        {errors["form.owner_address_city"] && (
                          <label htmlFor='city' className='error_label'>
                            {errors["form.owner_address_city"]}
                          </label>
                        )}
                      </div>
                      <div className='grouped'>
                        <input
                          type='text'
                          className={`discl_input ${
                            errors["form.owner_address_state"] ? "error" : ""
                            }`}
                          name='state'
                          id='state'
                          value={form.owner_address_state}
                          field='form.owner_address_state'
                          placeholder='State'
                          required
                        />
                        {errors["form.owner_address_state"] && (
                          <label htmlFor='state' className='error_label'>
                            {errors["form.owner_address_state"]}
                          </label>
                        )}
                      </div>
                      <div className='grouped'>
                        <input
                          className={` discl_input  ${
                            errors["form.owner_address_zip"] ? "error" : ""
                            }`}
                          type='text'
                          name='zip'
                          id='zip'
                          placeholder='Zip'
                          value={form.owner_address_zip}
                          field='form.owner_address_zip'
                          required
                        />
                        {errors["form.owner_address_zip"] && (
                          <label htmlFor='zip' className='error_label'>
                            {errors["form.owner_address_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='inputs-head'>
                    {" "}
                    Other Working Conditions <span>(If applicable)</span>
                  </div>
                  <div className='inputs-items'>
                    <textarea
                      className={` discl_input  ${
                        errors["form.other_work_conditions"] ? "error" : ""
                        }`}
                      type='text'
                      name='other_work_conditions'
                      id='other_work_conditions'
                      placeholder='See Attached Job Description'
                      value={form.other_work_conditions}
                      field='form.other_work_conditions'
                      disabled
                    />
                    {errors["form.other_work_conditions"] && (
                      <label htmlFor='other_work_conditions' className='error_label'>
                        {errors["form.other_work_conditions"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col m-0'>
                <p>
                  In witness whereof, both parties agree to the above and have indicated so by
                  signing below.
                </p>
              </div>
              <div className='col'>
                <div className='inputs-col w-50 sign-input'>
                  <div className='inputs-head'>
                    Printed Name<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <input
                      type='text'
                      className={`${errors["form.employee_printed_name"] ? "error" : ""}`}
                      name='employee_printed_name'
                      id='employee_printed_name'
                      field='form.employee_printed_name'
                      value={form.employee_printed_name}
                      placeholder='Print Name'
                      required
                    />
                    {errors["form.employee_printed_name"] && (
                      <label htmlFor='employee_printed_name' className='error_label'>
                        {errors["form.employee_printed_name"]}
                      </label>
                    )}
                  </div>
                  <div className='inputs-head'>
                    Employee Signature<span className='required-star'>&#x2A;</span>
                  </div>
                  <div className='inputs-items'>
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className='sign_here'>
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            id="disclosureEmpSignPad"
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.employee_signature",
                            )}
                            signature={this.state.form.employee_signature}
                            disabled={adminMode}
                          />
                          <input
                            type='hidden'
                            value={form.employee_signature}
                            name='employee_signature'
                            field='form.employee_signature'
                            required></input>
                          {errors["form.employee_signature"] && (
                            <label htmlFor='employee_signature' className='error_label'>
                              {errors["form.employee_signature"]}
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className='inputs-head'>
                      Date<span className='required-star'>&#x2A;</span>
                    </div>
                    <div className='inputs-items'>
                      <DatePicker
                        className={`${errors["form.signed_date"] ? "error" : ""} w-100`}
                        name='signed_date'
                        id='signed_date'
                        required
                        selected={moment(form.signed_date)}
                        field='form.signed_date'
                        placeholder='mm/dd/yyyy'
                        onChange={this.handleDateChange.bind(this, "form.signed_date")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        autoComplete='off'
                        disabled={adminMode}
                      />
                      {errors["form.signed_date"] && (
                        <label htmlFor='signed_date' className='error_label'>
                          {errors["form.signed_date"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='inputs-col w-50 sign-input'>
                  {adminMode && (
                    <Fragment>
                      <div className='inputs-head'>
                        Printed Name<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <input
                          type='text'
                          className={`${errors["form.employer_printed_name"] ? "error" : ""}`}
                          name='employer_printed_name'
                          id='employer_printed_name'
                          field='form.employer_printed_name'
                          value={form.employer_printed_name}
                          placeholder='Print Name'
                          required
                        />
                        {errors["form.employer_printed_name"] && (
                          <label htmlFor='employer_printed_name' className='error_label'>
                            {errors["form.employer_printed_name"]}
                          </label>
                        )}
                      </div>
                      <div className='inputs-head'>
                        Employer Signature<span className='required-star'>&#x2A;</span>
                      </div>
                      <div className='inputs-items'>
                        <div
                          ref={(element) => {
                            this.signContainer = element;
                          }}
                          className='sign_here'>
                          {this.state.sign && (
                            <Fragment>
                              <SignPad
                                id="disclosureSupSignPad"
                                height={this.state.sign.height}
                                width={this.state.sign.width}
                                handleSignature={this.handleSignature.bind(
                                  this,
                                  "form.employer_signature",
                                )}
                                signature={form.employer_signature}
                              />
                              <input
                                type='hidden'
                                value={form.employer_signature}
                                name='employer_signature'
                                field='form.employer_signature'
                                required></input>
                              {errors["form.employer_signature"] && (
                                <label htmlFor='employer_signature' className='error_label'>
                                  {errors["form.employer_signature"]}
                                </label>
                              )}
                            </Fragment>
                          )}
                        </div>
                        <div className='inputs-head'>
                          Date<span className='required-star'>&#x2A;</span>
                        </div>
                        <div className='inputs-items'>
                          <DatePicker
                            className={`${
                              errors["form.employer_signed_date"] ? "error" : ""
                              } w-100`}
                            name='employer_signed_date'
                            id='employer_signed_date'
                            required
                            selected={moment(form.employer_signed_date)}
                            field='form.employer_signed_date'
                            placeholder='mm/dd/yyyy'
                            onChange={this.handleDateChange.bind(this, "form.employer_signed_date")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            autoComplete='off'
                          />
                          {errors["form.employer_signed_date"] && (
                            <label htmlFor='employer_signed_date' className='error_label'>
                              {errors["form.employer_signed_date"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row'>
              <div className='col'>
                <div className='disclaimers'>
                  <strong className='diss'>Disclaimer: </strong>You must read and carefully
                  understand the documents that you are filling out and signing. These are legally
                  binding documents and must be accurate. By filling these forms out, you are
                  agreeing to our{" "}
                  <a href='https://firestormfire.com/terms-of-use/' target="blank">Terms of Use, Privacy Policy and Employee Agreements.</a>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className='row form-row last'>
              <div className='col'>
                <div className='inputs-col'>
                  <div className='check_radio_group check_disclaimer'>
                    <div className='check_radio_item'>
                      <input
                        type='checkbox'
                        name='disclaimer_check'
                        id='disclaimer_check'
                        field='form.disclaimer_check'
                        checked={form.disclaimer_check}
                        required
                      />
                      <span className='checkbox_checkbox'></span>
                      <label htmlFor='disclaimer_check'>
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.disclaimer_check}
              mobileMode={this.props.mobileMode}
            />
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, user } = state;
  return { docs, user,currentUserInfo:getCurrentUserInfo(state) };
}

let connectedDisclosureStatement = compose(
  withRouter,
  connect(mapStateToProps),
)(DisclosureStatement);
export { connectedDisclosureStatement as DisclosureStatement };
