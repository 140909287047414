import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authHeader, config } from '../_helpers';
import Modal from 'react-responsive-modal';
// import { applicantsActions } from '../_actions';
// import { userActions } from '../_actions';
import '../assets/styles/applicants.css';
import { AlertPopup } from '../_components/AlertPopup';
import {

    EditorState,
    ContentState,
    // convertFromHTML,
    convertToRaw

} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
// import htmlReactParser from "html-react-parser"
import ReactHtmlParser from 'react-html-parser';

class EmailApplicant extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText("")
            ),
            to: this.props.email,
            message: "",
            template: "",
            statusOpen: false,
            selected:'',
            alertPop: false,
            borderError: ""
        }
        this.chooseTemplate = this.chooseTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);  
        this.statusOpenModal = this.statusOpenModal.bind(this);  
        this.statusCloseModal = this.statusCloseModal.bind(this);   
        this.goBack = this.goBack.bind(this);
        this.cancel = this.cancel.bind(this);
        this.validate = this.validate.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidMount() {
    }   

    componentWillReceiveProps(nextProps){
    }

    statusOpenModal(template) {
	    this.setState({ statusOpen: true, selected: template });
	}
    
    statusCloseModal() {
        this.setState({ statusOpen: false });
        // Added the message in custom template section
        if(this.state.message !== ""){
            this.setState({
                msg: "show",
                temp: "hide",
                custom: this.state.message
            })
        }
    }

    closeAlert(){
        this.setState({
            alertPop: false
        })
    }

    cancel() {
        this.setState({ message: "", statusOpen: false });
        this.statusCloseModal();
    }
    
    validate() {
        var err = false;
        if(this.state.template === '') {
            return true;
        }
    }

    sendMail() {
        var err = this.validate();
        if(!err)
        {
            let data = new FormData();
            this.statusCloseModal();
            data.append('template', this.state.template)
            data.append('message', this.state.message)
            data.append('to', this.state.to || this.props?.applicants?.list?.data?.email_address)
            var self = this;
            fetch(config.apiUrl + '/api/mail/sendmail',
            {
                headers: authHeader(),
                method: "POST",
                body: data
            })
            .then(function(res){ return res.json(); })
            .then(function(data){ 
                if(data.success){
                    self.setState({ 
                        alertPop: true, 
                        alertHead: "Success", 
                        alertMsg: JSON.parse(JSON.stringify( data.message )),
                        alertFunction: self.goBack.bind(self)
                    })
                } else{
                    self.setState({ 
                        alertPop: true, 
                        alertHead: "Error", 
                        alertMsg: JSON.parse(JSON.stringify( data.message )),
                        alertFunction: ""
                    })
                }
            })
        }
        else {
            this.setState({
                borderError: "borderError",
            })
        }
        
    };

    goBack(){
        this.props.goBack()
    }

    chooseTemplate(template) {
        this.setState({ template: template, selected: template, borderError: "" })
    }

    isActive(value){
        return ((value===this.state.selected) ?'active':'default');
    }

    // handleChange(event) {

    //     this.setState({ [event.target.name]: event.target.value, template:'custommail' });
        
    // }
    
    handleChange = editorState=> {
        this.setState(
            { 
                editorState,
                message: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                template:'custommail'
            }
        );
    }

    render() {
        const { editorState } = this.state;
        return (
            <div className="applicants_container">
                <div className="breadcrumbs"><span onClick={this.goBack.bind(this)} className="green">Applicants</span> / Email Applicant</div>
                <div className="page_title">
                    <h1>Email Applicant</h1>
                </div>
                {/*<div className="top_btn_group">
                    <button className="button" onClick={this.goBack}>Back</button>
                </div>*/}
                <div className="applicants_inner_head inner_head"></div>
                <div className="inner_container col-md-12" id="email_template_inner">
                    {/*<div className="goback" onClick={this.goBack.bind(this)}><p>Back</p></div>*/}
                    <div className="email_template_outer row">
                        <div className={this.isActive('template1')+" email_template_wrapper template1 col-md-4 "+ this.state.borderError} onClick={this.chooseTemplate.bind(this,'template1')}>
                            <div className="email_template">
                                <h3>No Prior Training</h3>
                                <p>Thank you for interest in Firestorm Wildland Fire Suppression, Inc. We have received your application. On your application you noted that you have no prior training or the certificate that you provided ...</p>
                            </div>
                        </div>
                        <div className={this.isActive('template2') + " email_template_wrapper template2 col-md-4  "+ this.state.borderError } onClick={this.chooseTemplate.bind(this,'template2')}>
                            <div className="email_template">
                                <h3>Prior Training - Not Current</h3>
                                <p>Thank you for interest in Firestorm Wildland Fire Suppression, Inc. We have received your application. According to your application, you have stated that you have had prior wildland ...</p>
                            </div>
                        </div>
                        <div className={this.isActive('template3') + " email_template_wrapper template3 col-md-4 "+ this.state.borderError} onClick={this.chooseTemplate.bind(this,'template3')}>
                            <div className="email_template">
                                <h3>Prior Training - Current</h3>
                                <p>Thank you for interest in Firestorm Wildland Fire Suppression, Inc. We have received your application.You noted on your application that you have or will have completed the Basic Wildland Fire Training ...</p>
                            </div>
                        </div>
                        <div className={this.isActive('template4') + " email_template_wrapper template4 col-md-12 "+ this.state.borderError} onClick={this.statusOpenModal.bind(this, 'template4')}>
                            <div className="email_template custom_email">
                                <h3>Custom template</h3>
                                <p className={this.state.temp+" tmp-desc"}>
                                <img className="email-pen" alt="Email pen" src={require("../assets/images/email-pen.svg").default} /> Write email
                                </p>
                                <div  className={this.state.msg+" description"}>
                                    {ReactHtmlParser(this.state.custom ||"")}
                                </div>
                              
                            </div>
                        </div>
                    </div>
                    <button className="button" onClick={this.sendMail.bind(this)}>Send Email</button>
                </div>
                <Modal styles={{ modal:{ width:'100%' } }} id="status_popup" className="status_popup" open={this.state.statusOpen} onClose={this.statusCloseModal.bind(this)} little>
                    <div id="statusPopup" className="custom_template_mail">                        
                        <div className="modal-head">
                            <h4>Custom Template</h4>                                  
                        </div> 
                        <div className="modal-body">

                            {/* <textarea name="message" cols="80" rows="10" value={this.state.message} onChange={this.handleChange.bind(this)}></textarea> */}

                            <div className="fs-block">
                                <Editor
                                    toolbar={{
                                        options: [
                                            "inline",
                                            "blockType",
                                            "fontSize",
                                            "list",
                                            "history"
                                        ]
                                    }}
                                    editorState={editorState}
                                    placeholder="Write a message"
                                    onEditorStateChange={this.handleChange}
                                />
                            </div>

                            <p className="step_no">Signature will automatically be included.</p>
                            <div className="btn_list">
                                <button className="button grey" onClick={this.cancel}>Cancel</button>
                                <button className="button" onClick={this.statusCloseModal.bind(this)}>Save</button>
                            </div>
                        </div>                   
                    </div>	
                </Modal>
                { this.state.alertPop &&
                    <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)}/>
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user,applicants } = state;

    return {
         user,applicants
    };
}

const connectedApplicantPage = connect(mapStateToProps)(EmailApplicant);
export { connectedApplicantPage as EmailApplicant };
