import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import { authHeader, config } from '../_helpers';
import footerLogo from '../assets/images/firestorm.png';
import { AlertPopup } from '../_components/AlertPopup';
import { userActions } from '../_actions';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        this.props.dispatch(userActions.logout());

        this.state = {
            email: '',
            submitted: false,
            redirect: "",
            alertPop: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
        e.target.setAttribute('errors', "");
    }

    componentWillReceiveProps(nextProps) {
        const { loading, changePwd, user } = nextProps;

        if (changePwd) {
            // alert(changePwd.message)
            this.setState({
                alertPop: true,
                alertHead: "Success",
                alertMsg: changePwd.message,
                alertFunction: this.goBack.bind(this)
            })
            // if(changePwd.data){
            //     this.setState({ redirect: "/login"})
            // }
        } else if (!user.loading && user.error) {
            // alert(JSON.parse(user.error).message)

            this.setState({
                alertPop: true,
                alertHead: "Error",
                alertMsg: JSON.parse(user.error).message,
                alertFunction: ""
            })
        }
        this.setState({ loading })
    }

    closeAlert() {
        this.setState({
            alertPop: false
        })
    }

    goBack() {
        this.props.history.push('/login')
    }

    validate(e) {
        let isError = false;

        if (this.state.email === "" || !this.IsValidEmail(this.state.email)) {
            isError = true;
            this.setState({
                emailError: "error"
            })
            document.getElementById('email').focus();
        }

        return isError;
    }

    IsValidEmail(email) {
        var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return expr.test(email);
    };
    // parsing url search params based on the key provided
    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // Would write the value of the QueryString-variable called name to the console

    handleSubmit(e) {
        e.preventDefault();
        var err = this.validate();
        this.setState({ submitted: true });
        const { email } = this.state;
        const { dispatch } = this.props;
        let data = new FormData();
        if (this.getQueryStringValue('type') === 'applicants') {
            data.append('type', 'applicants')
        }
        data.append('email', email);
        if (!err) {
            dispatch(userActions.resetPassword(data));
        }
    }
    componentDidMount() {
        console.log(this.getQueryStringValue('type'));
    }

    render() {
        // const { loading } = this.props.user;
        const { email, submitted } = this.state;
        if (this.state.redirect === "/login") {
            return <Redirect to='/login' />
        }
        return (
            <div className="forgot_pwd_outer">
                <div className="login_header">
                    <Link to={"/login"}>
                        <img src={footerLogo} alt="Firestorm" />
                    </Link>
                    <p>Employee Management System</p>
                </div>
                <div className="loginForm forgot_pwd">
                    <form name="form" onSubmit={this.handleSubmit}>
                        <div className={'form-group' + (submitted && !email ? ' has-error' : '')}>
                            <label htmlFor="email">Enter your email address below to reset your password.</label>
                            <input type="text" errors={this.state.emailError} id="email" className="form-control" name="email" value={email} onChange={this.handleChange} />
                            { /*submitted && !email &&
                                <div className="help-block">Email address is required</div>
                            */}
                        </div>
                        <hr />
                        <div className="form-group btnGroup">
                            <button className="btn btn-primary">Send Reset Link</button>
                            {this.state.loading &&
                                <img alt="loader" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </div>
                    </form>
                </div>
                {this.state.alertPop &&
                    <AlertPopup head={this.state.alertHead} message={this.state.alertMsg} viewOpen="true" alertFunction={this.state.alertFunction} close={this.closeAlert.bind(this)} />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { changePwd, error, loading } = state.user;
    const { user } = state
    return {
        changePwd, error, loading, user
    };
}

const connectedLoginPage = connect(mapStateToProps)(ForgotPassword);
export { connectedLoginPage as ForgotPassword };