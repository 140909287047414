import React from "react";
import "./customToolTip.css";

function CustomToolTip({ label, children }) {
  return (
    <div className="root">
      <div className="custom_tooltip_children">
        {label && <p className="custom_tooltip">{label}</p>}
        {children}
      </div>
    </div>
  );
}

export default CustomToolTip;
