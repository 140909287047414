import React from "react"
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    
    // Catch errors in any components below and re-render with error message
    this.setState({
      hasError:true
    })
    // You can also log error messages to an error reporting service here
  }


  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h3>Something went wrong.</h3>;
    }

    return this.props.children;
  }
}


export default ErrorBoundary;